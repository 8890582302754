import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventHandlerDirective,
  TupDocumentEventType,
  TupDocumentService,
  TupDocumentStorageComponent,
  TupDocumentTypeId,
  TupUserContainerService,
  isNotNullOrUndefined,
} from '@telmar-global/tup-document-storage';
import {
  TupSaveDocumentDialogData,
  TupSaveDocumentDialogOptions,
  TupSaveDocumentDialogResult,
  TupUserMessageService,
  getSaveDocumentDialogData,
} from '@telmar-global/tup-user-message';
import { isNil, omitBy, pick, merge } from 'lodash';
import { Subject } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { AppPackage } from 'src/app/app.package';
import { SurveyTimeDocument, ViewType } from 'src/app/models';
import { CreateNewDocumentService, ImportService } from 'src/app/services';

@Component({
  selector: 'app-dashboard-reports',
  templateUrl: './dashboard-reports.component.html',
  styleUrls: ['./dashboard-reports.component.scss'],
})
export class DashboardReportsComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  private unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  @ViewChild(TupDocumentEventHandlerDirective, { static: false })
  public documentEventHandler: TupDocumentEventHandlerDirective;
  @ViewChild(TupDocumentStorageComponent, { static: true })
  public documentStorage: TupDocumentStorageComponent;
  @ViewChild('deleteDocDialogContent') deleteDocDialogContent: TemplateRef<any>;
  @ViewChild('deleteOtherDocDialogContent')
  deleteOtherDocDialogContent: TemplateRef<any>;
  @ViewChild('documents') documents: TupDocumentStorageComponent;

  public isLoading = false;
  public isPageLoading = false;
  public documentOwnerName = '';
  public documentName = '';
  public userEmail: string;
  public docOptionsType = TupDocumentTypeId.SURVEYTIME_CAMPAIGN;

  constructor(
    private router: Router,
    private userMessageService: TupUserMessageService,
    private tupDocumentService: TupDocumentService,
    private importService: ImportService,
    private userContainerService: TupUserContainerService,
    private authService: TupAuthService,
    private createNewDocumentService: CreateNewDocumentService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUserEmail();
    this.userContainerService.getContainer();
    // Handle default TupDocumentEvents
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      // Click event emitted by the tup-document-storage actions menu
      if (event.data) {
        switch (event.data.action) {
          case 'edit':
            this.openExisting(event.document);
            break;
          case 'duplicate':
            this.duplicateDoc(event.document);
            break;
          case 'delete':
            this.documentOwnerName = event.document.metadata.by.attributes.name;
            this.documentName = event.document.metadata.name;
            if (!this.isDocumentOwner(event.document)) {
              this.userMessageService
                .openCustomMessageDialog(
                  this.deleteOtherDocDialogContent,
                  'Delete document',
                  {
                    confirmText: 'OK',
                    centered: true,
                    width: '400px',
                  }
                )
                .afterClosed()
                .subscribe(() => {
                  this.resetDocumentOwnerName();
                });
              return;
            }
            this.userMessageService
              .openCustomMessageDialog(
                this.deleteDocDialogContent,
                'Delete document',
                {
                  confirmText: 'Delete',
                  cancelText: 'Keep',
                  centered: true,
                  width: '400px',
                }
              )
              .afterClosed()
              .subscribe((result) => {
                this.resetDocumentOwnerName();
                if (!result) {
                  return;
                }
                this.isLoading = true;
                this.tupDocumentService
                  .delete(
                    event.document.metadata.container.name,
                    event.document.metadata.id
                  )
                  .subscribe(() => {
                    this.documentEventHandler.reload.emit(true);
                    this.isLoading = false;
                  });
              });
            break;
          case 'export':
            const doc = event.document;
            this.exportJSON(doc);
            break;
        }
        // Click event emitted by the user clicking on a table row
      } else {
        this.openExisting(event.document);
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public startupOptions(): void {
    const isTemplateDocType = false;
    this.createNewDocumentService.create(isTemplateDocType);
  }

  public openExisting(doc: TupDocument<unknown>): void {
    const currentDoc = Object.assign(doc) as TupDocument<SurveyTimeDocument>;
    this.router.navigate([`doc/${currentDoc.metadata.id}/data`], {
      queryParams: {
        tab: ViewType.crossTab,
      },
    });
    return;
  }

  public import(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.isLoading = true;
    const file = target.files[0];
    this.importService
      .importJSON(file, this.userEmail, TupDocumentTypeId.SURVEYTIME_CAMPAIGN)
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.documentEventHandler.reload.emit(true);
        },
        (error) => {
          this.isLoading = false;
          const duration = 5000;
          this.userMessageService.showSnackBar(
            `Error importing document - ${file.name}: document isn't valid`,
            'OK',
            duration
          );
        }
      );
  }

  private resetDocumentOwnerName(): void {
    this.documentOwnerName = null;
    this.documentName = null;
  }

  private exportJSON(doc: TupDocument<unknown>): void {
    const documentName = doc.metadata.name;
    const download = (data: string, filename: string, type: string) => {
      var file = new Blob([data], { type: type });
      var a = window.document.createElement('a');
      var url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      window.document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    };
    this.tupDocumentService
      .get(doc.metadata.container.name, doc.metadata.id)
      .subscribe((fullDoc: TupDocument<SurveyTimeDocument>) => {
        const docWithVersion = Object.assign(fullDoc, {
          version: AppPackage.version,
        });

        const fileSpace = 2;

        const data = JSON.stringify(docWithVersion, null, fileSpace);
        download(data, `${documentName}_document.json`, 'json');
      });
  }

  private duplicateDoc(doc: TupDocument<unknown>): void {
    const dialogOptions: TupSaveDocumentDialogOptions = {
      nameFormControlValue: doc.metadata.name,
      descriptionFormControlValue: doc.metadata.description,
      enableSaveOnInit: true,
    };

    const dialogData: TupSaveDocumentDialogData = getSaveDocumentDialogData(
      'Duplicate',
      'Document',
      dialogOptions
    );

    const dialogConfig: MatDialogConfig = { data: dialogData };

    let _dialogResult: TupSaveDocumentDialogResult;

    this.userMessageService
      .openSaveDocumentDialog(dialogConfig)
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        tap(
          (dialogResult: TupSaveDocumentDialogResult) =>
            (_dialogResult = dialogResult)
        ),
        // AE-168 Fetch the entire document before duplicating it
        concatMap(() => {
          return this.tupDocumentService.get(
            doc.metadata.container.name,
            doc.metadata.id
          );
        })
      )
      .subscribe((_document: TupDocument<SurveyTimeDocument>) => {
        const document: TupDocument<SurveyTimeDocument> = merge(
          {},
          pick(_document, ['metadata.type', 'metadata.status', 'content']),
          { metadata: omitBy(_dialogResult, isNil) }
        );

        this.documentEventEmitter.emit(document, TupDocumentEventType.CREATE);
      });
  }

  private isDocumentOwner(doc: TupDocument<unknown>): boolean {
    return doc.metadata.by.attributes.email === this.userEmail;
  }

  private getUserEmail(): void {
    const user = this.authService.user;
    this.userEmail = user.attributes.email;
  }
}
