export const doChecks = () => {
  // clickjacking in iframe
  if (self === top) {
    const cover = document.getElementById('cover');
    if (cover) cover.parentNode.removeChild(cover);
  } else {
    // we want to be the top-most document
    top.location = self.location;
  }
};
