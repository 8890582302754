import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import { Operator, Target, TargetType } from '../models';
import { DropActionItem } from '../models/action.model';
import { CombinedCodingThresholdService } from '../services/combined-coding-threshold.service';

export interface SolusOperatorActionContext {
  targetType: TargetType;
  selectedTargets: Target[];
  actionItem: DropActionItem;
}

@Injectable({
  providedIn: 'root',
})
export class SolusOperatorAction extends ActionDefinition<SolusOperatorActionContext> {
  constructor(
    private documentService: DocumentService,
    private targetService: TargetService,
    private codebookSelectionService: CodebookSelectionService,
    private combinedCodingThresholdService: CombinedCodingThresholdService
  ) {
    super();
  }

  public invoke(context: SolusOperatorActionContext): void {
    if (
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        context.selectedTargets
      )
    ) {
      this.combinedCodingThresholdService.showMaxLimitAlert();
      return;
    }

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(
        context.selectedTargets
      )
    ) {
      return;
    }

    let targets = this.targetService.groupTargets(
      context.selectedTargets,
      context.actionItem.groupOperator,
      true
    );
    targets[0].countCoding = {
      operator: Operator.equal,
      value: '#1',
    };
    targets[0].titlePrefix = context.actionItem.operator;
    targets = this.targetService.groupTargets(targets, Operator.and, true);
    targets = this.targetService.groupTargets(targets, Operator.and, true);
    this.documentService.addDocumentData(targets, context.targetType, true);
    this.codebookSelectionService.unselectNodes();
  }
}
