import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingNode, LoadingSource } from '@telmar-global/tup-audience-groups';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Statement, TargetType } from 'src/app/models';
import {
  CombineActionItem,
  COMBINE_BUTTON_ACTION_ITEMS,
} from 'src/app/models/action.model';
import { CodebookSelectionService } from 'src/app/services';

@Component({
  selector: 'app-action-controls',
  templateUrl: './action-controls.component.html',
  styleUrls: ['./action-controls.component.scss'],
})
export class ActionControlsComponent implements OnInit {
  public actionItems: CombineActionItem[] = COMBINE_BUTTON_ACTION_ITEMS;
  public isLoadingFromButtons = false;
  public isLoadingSelectedNodes = false;
  public selectedNodes: Statement[] = [];
  public targetType = TargetType;

  @Output() separateClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() separateCountClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() addToMultipleClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() expandAllClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() combineActionClick: EventEmitter<{
    actionItem: CombineActionItem;
    type: TargetType;
  }> = new EventEmitter<{
    actionItem: CombineActionItem;
    type: TargetType;
  }>();

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private codebookSelectionService: CodebookSelectionService) {}

  ngOnInit(): void {
    this.listenToSelectedNodesChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoadingSelectedNodes = false;
    this.isLoadingFromButtons = false;
  }

  public onUnselectAll(): void {
    this.codebookSelectionService.unselectNodes();
  }

  public clickSeparateButton(type: TargetType): void {
    this.separateClick.emit(type);
  }

  public clickSeparateCountButton(type: TargetType): void {
    this.separateCountClick.emit(type);
  }

  public expandAllClicked(): void {
    this.expandAllClick.emit();
  }

  public onCombineActionClicked(
    actionItem: CombineActionItem,
    type: TargetType
  ): void {
    this.combineActionClick.emit({
      actionItem,
      type,
    });
  }

  public addToMultiple(type: TargetType): void {
    this.addToMultipleClick.emit(type);
  }

  private listenToSelectedNodesChanges(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        this.selectedNodes = selectedNodes;
      });

    this.codebookSelectionService.loadingSelectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isLoading: LoadingNode) => {
        this.isLoadingSelectedNodes = isLoading.inProgress;
        this.isLoadingFromButtons =
          isLoading.origin === LoadingSource.fromButton;
      });
  }
}
