<div class="single-table-wrapper">
  <div
    class="table-title"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <b data-qa="separated-rank-survey-title"
        >[{{ targetData.surveyCode }}]
        {{
          targetData.columnTarget || targetData
            | targetTitle
              : targetData.columnTarget?.activeTitleMode
              : targetData.columnTarget?.titleLevels
            | truncate: 80
        }}</b
      >
      <mat-chip-list class="separated-rank-filters">
        <mat-chip
          *ngIf="columnHeaderFilter"
          [disableRipple]="false"
          [matTooltip]="columnHeaderFilter.filterConditions"
          (removed)="onColumnHeaderFilterRemove()"
          (click)="onColumnHeaderFilterClick()"
        >
          {{ columnHeaderFilter.filterConditions | truncate: 60 }}
          <mat-icon
            matChipRemove
            matTooltipPosition="above"
            matTooltip="Remove column filter"
          >
            cancel
          </mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <button
      data-tracking="separated-report-filter"
      mat-mini-fab
      color="primary"
      (click)="openFilter()"
      matTooltip="Separated rank filter"
      class="separated-rank__filter-btn"
    >
      <mat-icon> filter_list </mat-icon>
    </button>
  </div>
  <cdk-virtual-scroll-viewport
    tvsItemSize="33"
    bufferMultiplier="4"
    headerHeight="44"
    class="virtual-scroll-viewport"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="separated-rank-table"
      (matSortChange)="onSortChange($event)"
    >
      <ng-container
        *ngFor="let column of displayedColumns"
        matColumnDef="{{ column }}"
        [sticky]="column === 'title'"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="table-header-cell"
          [class]="column === 'title' ? 'title-header' : 'column-header'"
        >
          <span
            matTooltip="Sort column"
            matTooltipPosition="above"
          >
            <ng-container *ngIf="hasVolumetricCoding">
              {{
                dataItemMap[column]?.id === dataItemType.audience
                  ? dataItemMap[column]?.volumetricDisplayName
                  : dataItemMap[column]?.displayName
              }}
            </ng-container>
            <ng-container *ngIf="!hasVolumetricCoding">
              {{ dataItemMap[column]?.displayName }}
              {{
                dataItemMap[column]?.id === dataItemType.audience
                  ? (reportUnits | formatReportUnits)
                  : ""
              }}
            </ng-container>
          </span>

          <mat-icon
            *ngIf="columnFiltersMap.has(dataItemMap[column]?.id)"
            class="column-title-filter-icon"
            >filter_alt</mat-icon
          >
        </th>

        <ng-container *ngIf="column === 'title'">
          <td
            mat-cell
            [hidden]="element.filteredOutCell"
            *matCellDef="let element"
            class="title-cell"
            title="{{
              element.rowTarget || element
                | targetTitle
                  : element.rowTarget?.activeTitleMode
                  : element.rowTarget?.titleLevels
            }}"
          >
            <span>
              {{
                element.rowTarget || element
                  | targetTitle
                    : element.rowTarget?.activeTitleMode
                    : element.rowTarget?.titleLevels
                  | truncate: 30
              }}
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column !== 'title'">
          <td
            [hidden]="element.filteredOutCell"
            mat-cell
            *matCellDef="let element"
            [ngStyle]="
              cellStyleStatus !== 'none' && {
                'background-color': cellColors[iCol + '_' + element.rowIndex]
              }
            "
            [ngClass]="{
              'z-score-green':
                zScoreHighlight &&
                !element.isTotalsColumn &&
                dataItemMap[column]?.id === dataItemType.filterZScore &&
                element[column.split('#')[0]]?.toFixed(
                  decimalPoints[dataItemMap[column]?.displayName]
                ) > zScoreHighlightProb,
              'z-score-red':
                zScoreHighlight &&
                !element.isTotalsColumn &&
                dataItemMap[column]?.id === dataItemType.filterZScore &&
                element[column.split('#')[0]]?.toFixed(
                  decimalPoints[dataItemMap[column]?.displayName]
                ) < zScoreHighlightProb
            }"
            [class.is-active-sort-data-item]="
              !!sortSettings &&
              sortSettings.columnId !== '' &&
              dataItemMap[column]?.id === sortSettings.dataItem
            "
            [class.is-highlighted-data-item]="
              !element.isTotalsColumn &&
              cellStyleStatus === 'highlight' &&
              highlightValues.dataItemId === dataItemMap[column]?.id
            "
          >
            <ng-container
              *ngIf="
                !element.metadata?.isVolumetricCoding ||
                (element.metadata?.isVolumetricCoding &&
                  volumetricDataItemIds.includes(dataItemMap[column]?.id))
              "
            >
              <span
                *ngIf="
                  stabilityFlagStatus &&
                  dataItemMap[column]?.id === dataItemType.audience
                "
              >
                <span *ngIf="element.sample <= stabilityFlagStatus.firstLevel">
                  *
                </span>
                <span *ngIf="element.sample <= stabilityFlagStatus.secondLevel">
                  *
                </span>
              </span>

              <span>
                {{
                  element[column.split("#")[0]]?.toFixed(
                    decimalPoints[dataItemMap[column]?.displayName]
                  ) | number: dataItemMap[column]?.digitsInfo
                }}
              </span>
            </ng-container>

            <ng-container
              *ngIf="
                element.metadata?.isVolumetricCoding &&
                !volumetricDataItemIds.includes(dataItemMap[column]?.id)
              "
            >
              <span>-</span>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <ng-container
        *ngFor="let totalColumn of displayedTotals"
        matColumnDef="{{ totalColumn }}"
        [sticky]="totalColumn.split('&')[0] === 'title'"
      >
        <ng-container *ngIf="totalColumn.split('&')[0] === 'title'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="totals-row-header totals-row-header--totals"
          >
            {{ singleTableData[0][totalColumn.split("&")[0].split("#")[0]] }}
          </th>
        </ng-container>

        <ng-container *ngIf="totalColumn.split('&')[0] !== 'title'">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="totals-row-header"
          >
            {{
              singleTableData[0][
                totalColumn.split("&")[0].split("#")[0]
              ]?.toFixed(
                decimalPoints[
                  dataItemMap[totalColumn.split("&")[0]]?.displayName
                ]
              ) | number: dataItemMap[totalColumn.split("&")[0]]?.digitsInfo
            }}
          </th>
        </ng-container>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedTotals"
      ></tr>
      <tr
        mat-row
        [hidden]="row.filteredOutCell"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
