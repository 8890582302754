import {
  Border,
  Borders,
  Cell,
  CellValue,
  Color,
  Column,
  Row,
  ValueType,
} from 'exceljs';
import { cloneDeep, compact, merge, pick } from 'lodash';

import { TupXlsxBuilder } from '@telmar-global/tup-document-exporter';

import {
  CellStyleStatus,
  ColumnFilter,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemType,
  HighlightValues,
  SortSettings,
  Survey,
  SurveyCode,
  SurveyCodeBackgroundColor,
  Target,
  TargetColumn,
  CellColors,
  VOLUMETRIC_DATA_ITEM_IDS,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
  HEATMAP_QUARTILES,
  HeatmapTile,
  HEATMAP_QUINTILES,
} from '../models';
import { TargetTitlePipe } from '../pipes';

export type SurveyColors = Record<SurveyCode, SurveyCodeBackgroundColor>;

export interface CrosstabTableXlsxData {
  documentName: string;
  targetColumns: TargetColumn[];
  data: CrossTabTableData[];
  dataItems: DataItem[];
  reportUnits: string;
  sortSettings: SortSettings[];
  filters: ColumnHeaderFilter[];
  surveys: Survey[];
  surveyColors: SurveyColors;
  highlightValues: HighlightValues;
  bins: number[][];
  cellStyleStatus: CellStyleStatus;
  cellColors: CellColors;
  heatmapIndexPercentage: number;
}

export class CrosstabTableXlsxBuilder extends TupXlsxBuilder {
  private readonly colours: any = {
    green: {
      fill: '00B1FAC6',
    },
    red: {
      fill: '00FFE8F4',
    },
    yellow: {
      fill: 'FFFF00',
    },
    blue: {
      fill: '0054FF',
    },
  };
  private readonly badCharsReg = /[?*:\\/\[\]]/g;
  private readonly sheetNameLength = 27;

  constructor(private targetTitlePipe: TargetTitlePipe) {
    super();
  }

  public addSeparateColumnTableSheets(data: CrosstabTableXlsxData): void {
    data.data[0].data.forEach(
      (cell: CrossTabTableDataCell, columnIndex: number) => {
        const sheetName = [
          `${columnIndex + 1}. ${cell.surveyCode}`,
          this.formatTitle(cell.columnTarget, cell.title),
        ]
          .join('-')
          .replace(this.badCharsReg, '')
          .substring(0, this.sheetNameLength);

        this.addWorksheet(sheetName);

        const hasVolumetricCoding =
          data.data.filter(
            (crossTableData: CrossTabTableData) =>
              crossTableData.data[columnIndex].metadata?.isVolumetricCoding
          ).length > 0;
        this.addHeaderOrFooter(this.getHeaderRows(data, cell, columnIndex))
          .addSeparatedTableHeader(
            cell,
            data.dataItems,
            data.reportUnits,
            hasVolumetricCoding
          )
          .addSeparatedTableBody(data, columnIndex)
          .addHeaderOrFooter(this.getFooterRows(data, cell));
      }
    );
  }

  public addCombinedRankTableSheet(data: CrosstabTableXlsxData): void {
    this.addWorksheet('Combined rank');
    this.addHeaderOrFooter(this.getHeaderRows(data))
      .addCombinedRankTableHeader(data.data, data.dataItems, data.reportUnits)
      .addCombinedRankTableBody(data)
      .addHeaderOrFooter(this.getFooterRows(data));
  }

  public addEntireTableSheet(data: CrosstabTableXlsxData): void {
    this.addWorksheet('Entire table');
    this.addHeaderOrFooter(this.getHeaderRows(data, null, 0))
      .addCombinedTableHeader(data.data)
      .addCombinedTableBody(data)
      .addHeaderOrFooter(this.getFooterRows(data));
  }

  private getHeaderRows(
    data: CrosstabTableXlsxData,
    cell?: CrossTabTableDataCell,
    columnIndex?: number
  ): string[][] {
    const rows = [
      [],
      ['Survey Time Report:', data.documentName],
      ...this.getSourceParts(data.surveys, cell?.surveyCode),
    ];

    const sortSettings =
      data.sortSettings.length > 1
        ? data.sortSettings[columnIndex]
        : data.sortSettings[0];

    const sortSettingsParts = this.getSortSettingsHeaderParts(
      sortSettings,
      data.targetColumns,
      data.surveys
    );
    if (sortSettingsParts.length > 0) {
      rows.push(...sortSettingsParts);
    }
    if (data.filters.length > 0) {
      if (!cell) {
        rows.push(
          this.getColumnFiltersHeaderParts(
            data.filters,
            data.targetColumns,
            data.surveys
          )
        );
      } else {
        const currentFilter = this.getCurrentCellFilter(cell, data.filters);
        if (currentFilter) {
          rows.push(
            this.getColumnFiltersHeaderParts(
              [currentFilter],
              data.targetColumns,
              data.surveys
            )
          );
        }
      }
    }
    if (data.cellStyleStatus === CellStyleStatus.highlight) {
      rows.push(this.getHighlightHeaderParts(data.highlightValues, data.bins));
    }
    rows.push([]);

    return rows;
  }

  private getCurrentCellFilter(
    cell: CrossTabTableDataCell,
    filters: ColumnHeaderFilter[]
  ): ColumnHeaderFilter {
    return filters.find((filter: ColumnHeaderFilter) => {
      if (cell.type === 'insight') {
        return filter.columnId.startsWith('totals');
      } else {
        return filter.columnId.startsWith(cell.columnTarget?.id);
      }
    });
  }

  private getFooterRows(
    data: CrosstabTableXlsxData,
    cell?: CrossTabTableDataCell
  ): string[][] {
    const rows = [
      ...this.getSourceParts(data.surveys, cell?.surveyCode),
      ['Export date:', new Date().toLocaleDateString('en-GB')],
    ];
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus.startsWith('significanceTesting')
    ) {
      rows.push(['Colour Coding:', 'Green > 99% | Orange > 95% | Red > 90%']);
    }
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus === CellStyleStatus.heatmapQuartiles
    ) {
      rows.push([
        'Colour Coding:',
        HEATMAP_QUARTILES.map(
          (tile: HeatmapTile) => `${tile.color} - ${tile.label}`
        ).join(' | '),
      ]);
    }
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus === CellStyleStatus.heatmapQuintiles
    ) {
      rows.push([
        'Colour Coding:',
        HEATMAP_QUINTILES.map(
          (tile: HeatmapTile) => `${tile.color} - ${tile.label}`
        ).join(' | '),
      ]);
    }
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus === CellStyleStatus.heatmap
    ) {
      rows.push([
        'Colour Coding:',
        `Red - ${data.heatmapIndexPercentage}% under index | Green - ${data.heatmapIndexPercentage}% above index`,
      ]);
    }
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus === CellStyleStatus.zScoreHighlight
    ) {
      rows.push([
        'Z-score filtered highlight:',
        `Red < ${Z_SCORE_FILTERED_HIGHLIGHT_PROB} | Green > ${Z_SCORE_FILTERED_HIGHLIGHT_PROB}`,
      ]);
    }
    return rows;
  }

  private getSourceParts(surveys: Survey[], surveyCode?: string): string[][] {
    const sources = this.getSources(surveys, surveyCode);
    const sourceParts = [[]];
    sources.forEach((source: string, index: number) => {
      if (index === 0) {
        sourceParts.push(['Source:', source]);
      } else {
        sourceParts.push(['', source]);
      }
    });
    return sourceParts;
  }

  private getSources(surveys: Survey[], surveyCode?: string): string[] {
    if (surveyCode) {
      const survey = surveys.find(
        (surveyItem) => surveyItem.code === surveyCode
      );
      let code: string;
      let title: string;

      if (survey) {
        code = survey.code;
        title = survey.title;
        return [[code, title].join(' - ')];
      } else {
        return [surveyCode];
      }
    }

    return surveys.map(({ code, title }: Survey) => [code, title].join(' - '));
  }

  private getSortSettingsHeaderParts(
    sortSettings: SortSettings,
    targetColumns: TargetColumn[],
    surveys: Survey[]
  ): string[][] {
    if (sortSettings.columnId === '' || sortSettings.order.length === 0) {
      return [];
    }

    const targetColumn = targetColumns.find(
      (column: TargetColumn) => column.columnId === sortSettings.columnId
    );

    if (!targetColumn) {
      return [];
    }

    const surveyCount = surveys.length;
    const sortSurveyCode =
      sortSettings.survey || sortSettings.columnId.split('_')[1];
    const sortColumn = this.formatTitle(
      targetColumn.target,
      targetColumn.title
    );

    const sortOrder =
      sortSettings.order[0].toUpperCase() + sortSettings.order.slice(1);
    const sortDataItem = DATA_ITEMS_MAP[sortSettings.dataItem].displayName;

    const sortSettingsHeaderParts = [];
    if (surveyCount > 1) {
      sortSettingsHeaderParts.push(['Sort Survey Code:', sortSurveyCode]);
    }
    return [
      ...sortSettingsHeaderParts,
      ['Sort Column:', sortColumn],
      ['Sort Order:', sortOrder],
      ['Sort Data Item:', sortDataItem],
    ];
  }

  private getColumnFiltersHeaderParts(
    filters: ColumnHeaderFilter[],
    targetColumns: TargetColumn[],
    surveys: Survey[]
  ): string[] {
    const surveyCount = surveys.length;
    const filterParts = filters.map((columnFilter: ColumnHeaderFilter) => {
      const column = targetColumns.find(
        (targetColumn: TargetColumn) =>
          targetColumn.columnId === columnFilter.columnId
      );
      const columnTitle = this.formatTitle(column.target, column.title);
      const surveyCode =
        surveyCount > 1 ? ` (${columnFilter.columnId.split('_')[1]})` : '';
      const filterCount = columnFilter.filters.length;
      const filterConditions = columnFilter.filters.map(
        (filter: ColumnFilter, index: number) => {
          const dataItemName = DATA_ITEMS_MAP[filter.dataItem].displayName;
          const conditionalOperator = filter.conditionalOperator.toLowerCase();
          const value =
            conditionalOperator === 'is between'
              ? '(' + filter.value.join(', ') + ')'
              : filter.value[0];
          const filterOperator =
            index < filterCount - 1 ? ` ${filter.operator}` : '';
          return `${dataItemName} ${conditionalOperator} ${value}${filterOperator}`;
        }
      );

      return `${columnTitle}${surveyCode} - ${filterConditions.join(' ')}`;
    });
    return ['Filter(s):', filterParts.join(', ')];
  }

  private getHighlightHeaderParts(
    highlightValues: HighlightValues,
    bins: number[][]
  ): string[] {
    const dataItemName = DATA_ITEMS_MAP[highlightValues.dataItemId].displayName;
    const decimalPoints =
      DATA_ITEMS_MAP[highlightValues.dataItemId].decimalPoints;
    const conditions = bins
      .map((bin: number[]) => {
        return `${dataItemName} is between (${bin[0].toFixed(
          decimalPoints
        )},${bin[1].toFixed(decimalPoints)})`;
      })
      .join(', ');

    return ['Highlight Condition(s):', conditions];
  }

  private addHeaderOrFooter(rows: string[][]): CrosstabTableXlsxBuilder {
    this.worksheet
      .addRows(rows)
      .forEach((row: Row) => (row.getCell('A').font = { bold: true }));
    return this;
  }

  private addCombinedTableHeader(
    data: CrossTabTableData[]
  ): CrosstabTableXlsxBuilder {
    let cells: string[];
    let offset: number;
    let row: Row;

    const totalsColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'insight'
    );
    const targetColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'target'
    );
    const shouldAddSurveyCodeHeader = totalsColumnCells.length > 1;

    if (shouldAddSurveyCodeHeader) {
      cells = ['', ''];
      offset = cells.length;
      const surveyCodeCells = data[0].data.map(
        (cell: CrossTabTableDataCell) =>
          `${cell.surveyCode ? cell.surveyCode : ''}`
      );
      row = this.worksheet.addRow([...cells, ...surveyCodeCells]);
      for (let index = offset; index < row.cellCount; index++) {
        this.applyBorder(
          this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
        ); // !!! One based indexing !!!
      }
    }

    cells = ['', ''];
    offset = cells.length;

    cells.push(
      ...totalsColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );

    cells.push(
      ...targetColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );

    row = this.worksheet.addRow(cells);

    // The first two cells are empty, use the offset defined earlier to loop
    // through the remaining cells and apply the appropriate styles
    for (let index = offset; index < cells.length; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      ); // !!! One based indexing !!!
    }

    return this;
  }

  private addCombinedTableBody(
    data: CrosstabTableXlsxData
  ): CrosstabTableXlsxBuilder {
    const shouldApplyZScoreHighlight = this.shouldApplyZScoreHighlight(
      data.cellStyleStatus
    );
    data.data.forEach((row: CrossTabTableData, rowIndex: number) => {
      const start: number = this.worksheet.rowCount + 1;

      const insights: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'insight'
      );
      const targets: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'target'
      );

      const cells: CrossTabTableDataCell[] = [
        ...cloneDeep(insights),
        ...cloneDeep(targets),
      ];
      data.dataItems.forEach((dataItem: DataItem) => {
        this.addCombinedRow(data, row, rowIndex, dataItem, cells);
      });

      const end: number = this.worksheet.rowCount;

      this.applyCombinedOuterBorders(start, end);

      this.mergeTitleCells(start, end);

      if (!row.isTotalRow && shouldApplyZScoreHighlight) {
        this.applyCombinedZScoreHighlight(cells, start, end);
      }
    });

    this.fitToContents(this.worksheet.getColumn('A'));
    this.worksheet.getColumn('B').width = 20;

    return this;
  }

  private addSeparatedTableHeader(
    cell: CrossTabTableDataCell,
    dataItems: DataItem[],
    reportUnits: string,
    hasVolumetricCoding: boolean
  ): CrosstabTableXlsxBuilder {
    const emptyCells: string[] = [''];

    const offset: number = emptyCells.length;

    const dataItemCount = dataItems.length;
    const headerTitle = `${cell.surveyCode} - ${this.formatTitle(
      cell.columnTarget,
      cell.title
    )}`;

    let row: Row = this.worksheet.addRow([...emptyCells, headerTitle]);

    // The first cell is empty, use the offset defined earlier to loop through
    // the remaining cells and apply the appropriate styles
    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    this.worksheet.mergeCells(
      `${row.getCell(offset + 1).$col$row}:${
        row.getCell(offset + dataItemCount).$col$row
      }`
    );

    row = this.worksheet.addRow([
      ...emptyCells,
      ...dataItems.map((dataItem: DataItem, index: number) =>
        this.formatDataItemName(dataItem, reportUnits, hasVolumetricCoding)
      ),
    ]);

    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    return this;
  }

  private addSeparatedTableBody(
    data: CrosstabTableXlsxData,
    columnIndex: number
  ): CrosstabTableXlsxBuilder {
    const tableData = cloneDeep(data);
    const cellColors = tableData.cellColors;

    tableData.data.forEach((row: CrossTabTableData, rowIndex: number) => {
      const dataCell = row.data[columnIndex];
      if (dataCell.filteredOutCell) {
        return;
      }

      if (dataCell) {
        dataCell.color = cellColors[`${columnIndex}_${rowIndex}`];
      }

      this.addSeparatedRow(tableData, row, dataCell, columnIndex);
    });

    this.fitToContents(this.worksheet.getColumn('A'));

    return this;
  }

  private addCombinedRow(
    data: CrosstabTableXlsxData,
    rowData: CrossTabTableData,
    rowIndex: number,
    dataItem: DataItem,
    cells: CrossTabTableDataCell[]
  ): void {
    const titles: string[] = [
      this.formatTitle(rowData.data[0].rowTarget, rowData.title),
      this.formatDataItemName(
        dataItem,
        data.reportUnits,
        rowData.metadata?.hasVolumetricCoding
      ),
    ];
    const cellKey = dataItem.cellKey;
    const decimalPoints = dataItem.decimalPoints;
    const offset: number = titles.length;
    const isAffinityRow = cells[0].isAffinityRow;

    const excelRow: Row = this.worksheet.addRow([
      ...titles,
      ...cells.map((cell: CrossTabTableDataCell) => {
        if (
          cell.metadata?.isVolumetricCoding &&
          !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
        ) {
          return '';
        }
        return cell[cellKey] !== undefined && cell[cellKey] !== null
          ? parseFloat(cell[cellKey]?.toFixed(decimalPoints))
          : '';
      }),
    ]);

    // Style the first two cells (the title and subtitle)
    // tslint:disable-next-line:variable-name
    titles.forEach((_title: string, index: number) => {
      const cell: Cell = excelRow.getCell(index + 1);
      if (index === 0) {
        this.embolden(cell);
      }
      if (
        !rowData.isTotalRow &&
        index !== 0 &&
        this.shouldApplySort(data, dataItem)
      ) {
        this.embolden(
          this.applyFontColor(cell, { argb: this.colours.blue.fill })
        );
      }
      this.applyBorder(
        isAffinityRow
          ? this.applyFill(cell, { argb: this.colours.yellow.fill })
          : this.applyDefaultFill(cell)
      );
    });

    // Style the rest of the cells (the values themselves)
    for (let index = offset; index < offset + cells.length; index++) {
      const columnIndex = index - offset;
      const dataCell = cells[columnIndex];
      dataCell.color = data.cellColors[`${columnIndex}_${rowIndex}`];
      dataCell.surveyColor = data.surveyColors[dataCell.surveyCode];

      const excelCell: Cell = excelRow.getCell(index + 1); // !!! One based indexing !!!

      if (excelCell.type === ValueType.Number) {
        this.applyNumberFormat(excelCell, decimalPoints);
      }

      if (this.shouldApplyHighlight(data, dataItem, dataCell)) {
        this.embolden(excelCell);
      }

      if (this.shouldApplySort(data, dataItem, dataCell)) {
        this.embolden(
          this.applyFontColor(excelCell, { argb: this.colours.blue.fill })
        );
      }

      this.applyBorder(this.applyCellColor(dataCell, excelCell));
    }
  }

  private addSeparatedRow(
    data: CrosstabTableXlsxData,
    rowData: CrossTabTableData,
    dataCell: CrossTabTableDataCell,
    columnIndex: number
  ): void {
    if (!dataCell) {
      return;
    }
    const excelRow: Row = this.worksheet.addRow([
      this.formatTitle(dataCell?.rowTarget, rowData.title),
      ...data.dataItems.map((dataItem: DataItem) => {
        if (
          dataCell.metadata?.isVolumetricCoding &&
          !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
        ) {
          return '';
        }
        return dataCell &&
          dataCell[dataItem.cellKey] !== undefined &&
          dataCell[dataItem.cellKey] !== null
          ? parseFloat(
              dataCell[dataItem.cellKey]?.toFixed(dataItem.decimalPoints)
            )
          : '';
      }),
    ]);

    // Style the first cell (the title)
    const titleCell = excelRow.getCell(1);
    this.applyBorder(
      this.embolden(
        dataCell?.isAffinityRow
          ? this.applyFill(titleCell, {
              argb: this.colours.yellow.fill,
            })
          : this.applyDefaultFill(titleCell)
      )
    );

    // Style the rest of the cells (the values themselves)
    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1); // !!! One based indexing !!!

      if (cell.type === ValueType.Number) {
        this.applyNumberFormat(cell, data.dataItems[index - 1].decimalPoints);
      }

      if (
        this.shouldApplyHighlight(data, data.dataItems[index - 1], dataCell)
      ) {
        this.embolden(cell);
      }

      if (
        this.shouldApplySort(
          data,
          data.dataItems[index - 1],
          dataCell,
          columnIndex
        )
      ) {
        this.embolden(
          this.applyFontColor(cell, { argb: this.colours.blue.fill })
        );
      }

      this.applyBorder(this.applyCellColor(dataCell, cell, false));
    }

    if (
      !rowData.isTotalRow &&
      this.shouldApplyZScoreHighlight(data.cellStyleStatus)
    ) {
      this.applySeparatedZScoreHighLight(dataCell, excelRow);
    }
  }

  private addCombinedRankTableHeader(
    data: CrossTabTableData[],
    dataItems: DataItem[],
    reportUnits: string
  ): CrosstabTableXlsxBuilder {
    const emptyCells: string[] = [''];

    const offset: number = emptyCells.length;

    const dataItemCount = dataItems.length;

    const headerTitles = data[0].data.map((cell: CrossTabTableDataCell) =>
      this.formatTitle(cell.columnTarget, cell.title)
    );

    let row: Row = this.worksheet.addRow(
      [...emptyCells].concat(
        ...headerTitles.map((item: string) => Array(dataItemCount).fill(item))
      )
    );

    // The first cell is empty, use the offset defined earlier to loop through
    // the remaining cells and apply the appropriate styles
    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    // tslint:disable-next-line:variable-name
    headerTitles.forEach((_title, index) => {
      this.worksheet.mergeCells(
        `${row.getCell(offset + 1 + index * dataItemCount).$col$row}:${
          row.getCell(offset + dataItemCount + index * dataItemCount).$col$row
        }`
      );
    });

    row = this.worksheet.addRow(
      [...emptyCells].concat(
        ...headerTitles.map((title, index) => {
          const hasVolumetricCoding =
            data.filter(
              (crossTableData: CrossTabTableData) =>
                crossTableData.data[index].metadata?.isVolumetricCoding
            ).length > 0;

          return dataItems.map((dataItem: DataItem) =>
            this.formatDataItemName(dataItem, reportUnits, hasVolumetricCoding)
          );
        })
      )
    );

    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    return this;
  }

  private addCombinedRankTableBody(
    data: CrosstabTableXlsxData
  ): CrosstabTableXlsxBuilder {
    data.data.forEach((row: CrossTabTableData, rowIndex: number) => {
      this.addCombinedRankRow(data, row, rowIndex);
    });

    this.fitToContents(this.worksheet.getColumn('A'));

    return this;
  }

  private addCombinedRankRow(
    data: CrosstabTableXlsxData,
    rowData: CrossTabTableData,
    rowIndex: number
  ): void {
    const dataCells = rowData.data;
    const excelRow: Row = this.worksheet.addRow([
      this.formatTitle(rowData.data[0].rowTarget, rowData.title),
      ...dataCells
        .map((dataCell: CrossTabTableDataCell) =>
          data.dataItems.map((dataItem: DataItem) => {
            if (
              dataCell.metadata?.isVolumetricCoding &&
              !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
            ) {
              return '';
            }
            return dataCell[dataItem.cellKey] !== undefined &&
              dataCell[dataItem.cellKey] !== null
              ? parseFloat(
                  dataCell[dataItem.cellKey]?.toFixed(dataItem.decimalPoints)
                )
              : '';
          })
        )
        .reduce((acc, value) => acc.concat(value), []),
    ]);

    // Style the first cell (the title)
    const titleCell = excelRow.getCell(1);
    this.applyBorder(this.embolden(this.applyDefaultFill(titleCell)));

    const shouldApplyZScoreHighlight = this.shouldApplyZScoreHighlight(
      data.cellStyleStatus
    );
    // Style the rest of the cells (the values themselves)
    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1); // !!! One based indexing !!!
      const columnIndex = Math.floor((index - 1) / data.dataItems.length);
      const dataItemIndex = (index - 1) % data.dataItems.length;
      const dataCell = dataCells[columnIndex];
      dataCell.color = data.cellColors[`${columnIndex}_${rowIndex}`];

      if (cell.type === ValueType.Number) {
        this.applyNumberFormat(
          cell,
          data.dataItems[dataItemIndex].decimalPoints
        );
      }

      if (
        this.shouldApplyHighlight(data, data.dataItems[dataItemIndex], dataCell)
      ) {
        this.embolden(cell);
      }

      if (this.shouldApplySort(data, data.dataItems[dataItemIndex], dataCell)) {
        this.embolden(
          this.applyFontColor(cell, { argb: this.colours.blue.fill })
        );
      }

      this.applyBorder(this.applyCellColor(dataCell, cell, false));

      if (!rowData.isTotalRow && shouldApplyZScoreHighlight) {
        this.applyCombinedRankZScoreHighlight(dataCell, cell);
      }
    }
  }

  private applyCombinedRankZScoreHighlight(
    dataCell: CrossTabTableDataCell,
    cell: Cell
  ): void {
    const colour: string | null = this.getCellZScoreHighlightColor(dataCell);
    if (['red', 'green'].includes(colour)) {
      this.applyFill(cell, { argb: this.colours[colour].fill });
      this.applyBorder(cell, ['top', 'left', 'bottom', 'right']);
    }
  }

  private applySeparatedZScoreHighLight(
    dataCell: CrossTabTableDataCell,
    row: Row
  ): void {
    const colour: string | null = this.getCellZScoreHighlightColor(dataCell);

    if (['red', 'green'].includes(colour)) {
      for (let index = 1; index < row.cellCount; index++) {
        const cell: Cell = row.getCell(index + 1);
        this.applyFill(cell, { argb: this.colours[colour].fill });
        this.applyBorder(cell, ['top', 'left', 'bottom', 'right']);
      }
    }
  }

  private mergeTitleCells(start: number, end: number): void {
    const tl = `A${start}`;
    const br = `A${end}`;

    this.worksheet.mergeCells(`${tl}:${br}`);

    this.worksheet.getCell(tl).alignment = { vertical: 'top' };
  }

  private fitToContents(column: Column): void {
    column.width = Math.max(
      ...column.values
        .map((value: CellValue) => value.toString().length)
        .filter((value: CellValue) => typeof value === 'number')
    );
  }

  private applyBorder(
    cell: Cell,
    props: string[] = ['top', 'left', 'bottom', 'right'],
    color?: Partial<Color>
  ): Cell {
    const borderStyle: Partial<Border> = merge(
      { style: 'thin' },
      { color }
    ) as Partial<Border>;

    const borders: Partial<Borders> = {
      top: borderStyle,
      left: borderStyle,
      bottom: borderStyle,
      right: borderStyle,
    };

    cell.border = pick(borders, props);

    return cell;
  }

  private applyDefaultFill(cell: Cell): Cell {
    this.applyFill(cell, { argb: '00E6ECFF' });

    return cell;
  }

  private applyFill(cell: Cell, fgColor: Partial<Color>): Cell {
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor };

    return cell;
  }

  private applyFontColor(cell: Cell, color: Partial<Color>): Cell {
    cell.font = {
      ...cell.font,
      color,
    };

    return cell;
  }

  private embolden(cell: Cell): Cell {
    cell.font = {
      ...cell.font,
      bold: true,
    };

    return cell;
  }

  private formatTitle(target: Target, fallbackTitle: string): string {
    return target ? this.targetTitlePipe.transform(target) : fallbackTitle;
  }

  private formatDataItemName(
    dataItem: DataItem,
    reportUnits: string,
    hasVolumetricCoding: boolean
  ): string {
    if (hasVolumetricCoding && dataItem.id === DataItemType.audience) {
      return dataItem.volumetricDisplayName;
    }

    return (
      dataItem.displayName +
      (dataItem.id === DataItemType.audience ? reportUnits : '')
    );
  }

  private shouldApplySort(
    data: CrosstabTableXlsxData,
    dataItem: DataItem,
    dataCell?: CrossTabTableDataCell,
    columnIndex?: number
  ): boolean {
    if (dataCell !== undefined && !dataCell.rowTarget) {
      return false;
    }

    const sortIndex = columnIndex ?? 0;
    if (
      data.sortSettings.length < 1 ||
      !data.sortSettings[sortIndex] ||
      data.sortSettings[sortIndex].columnId === ''
    ) {
      return false;
    }

    return data.sortSettings[sortIndex].dataItem === dataItem.id;
  }

  private shouldApplyHighlight(
    data: CrosstabTableXlsxData,
    dataItem: DataItem,
    dataCell: CrossTabTableDataCell
  ): boolean {
    if (dataCell.isAffinityRow) {
      return false;
    }

    if (dataCell.isTotalsColumn) {
      return false;
    }

    if (!dataCell.rowTarget) {
      return false;
    }

    if (data.cellStyleStatus !== CellStyleStatus.highlight) {
      return false;
    }

    return data.highlightValues.dataItemId === dataItem.id;
  }

  private shouldApplyZScoreHighlight(
    cellStyleStatus: CellStyleStatus
  ): boolean {
    return cellStyleStatus === CellStyleStatus.zScoreHighlight;
  }

  private getCellZScoreHighlightColor(
    cell: CrossTabTableDataCell
  ): string | null {
    if (!('FILTER_ZSCORE' in cell)) {
      return null;
    }

    if (cell.isTotalsColumn || cell.isAffinityRow) {
      return null;
    }

    return cell.FILTER_ZSCORE > Z_SCORE_FILTERED_HIGHLIGHT_PROB
      ? 'green'
      : cell.FILTER_ZSCORE < Z_SCORE_FILTERED_HIGHLIGHT_PROB
      ? 'red'
      : null;
  }

  private applyCombinedOuterBorders(start: number, end: number): void {
    const rowCount = end - start + 1;
    this.worksheet
      .getRows(start, rowCount)
      .forEach((row: Row, index: number) => {
        row.eachCell((cell: Cell, colNumber: number) => {
          // ignore title cell
          if (colNumber === 1) {
            return;
          }
          const props: string[] = compact([
            index === 0 ? 'top' : undefined,
            'left',
            index === rowCount - 1 ? 'bottom' : undefined,
            'right',
          ]);
          this.applyBorder(cell, props);
        });
      });
  }

  private generateCombinedZScoreHighlight(
    cells: CrossTabTableDataCell[]
  ): string[] {
    return cells.map((cell: CrossTabTableDataCell) =>
      this.getCellZScoreHighlightColor(cell)
    );
  }

  private applyCombinedZScoreHighlight(
    cells: CrossTabTableDataCell[],
    start: number,
    end: number
  ): void {
    const highlight: string[] = this.generateCombinedZScoreHighlight(cells);
    const offset = 3; // Title, label, totals
    const rowCount = end - start + 1;
    this.worksheet
      .getRows(start, rowCount)
      .forEach((row: Row, index: number) => {
        row.eachCell((cell: Cell, colNumber: number) => {
          const props: string[] = compact([
            index === 0 ? 'top' : undefined,
            'left',
            index === rowCount - 1 ? 'bottom' : undefined,
            'right',
          ]);

          const colour: string = highlight[colNumber - offset];

          if (['red', 'green'].includes(colour)) {
            this.applyFill(cell, { argb: this.colours[colour].fill });
            this.applyBorder(cell, props);
          }
        });
      });
  }

  private applyNumberFormat(cell: Cell, decimalPoints: number): Cell {
    cell.numFmt = decimalPoints ? `0.${'0'.repeat(decimalPoints)}` : '#,##0';
    return cell;
  }

  private applyCellColor(
    dataCell: CrossTabTableDataCell,
    excelCell: Cell,
    fillSurveyColor: boolean = true
  ): Cell {
    let filledExcelCell =
      fillSurveyColor && dataCell && dataCell.surveyColor
        ? this.applyFill(excelCell, {
            argb: dataCell.surveyColor.replace('#', ''),
          })
        : excelCell;

    if (dataCell && dataCell.isAffinityRow) {
      filledExcelCell = this.applyFill(filledExcelCell, {
        argb: this.colours.yellow.fill,
      });
    }

    if (dataCell && dataCell.color !== '' && dataCell.color !== undefined) {
      filledExcelCell = this.applyFill(filledExcelCell, {
        argb: dataCell.color.replace('#', ''),
      });
    }

    return filledExcelCell;
  }
}
