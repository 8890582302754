<tup-mat-content-wrapper gap="0">
  <tup-mat-content-wrapper
    position="middle"
    gap="2"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap=".5rem"
    >
      <mat-card
        class="total-reports header-button"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <span>{{ documents?.dataSource.length || 0 }}</span
        >{{ documents?.dataSource.length === 1 ? "REPORT" : "REPORTS" }}
      </mat-card>

      <tup-document-filter
        data-tracking="crosstab-search"
        formFieldAppearance="outline"
        formFieldLabel="Search documents"
        [filterFor]="documents"
        fxFlex="1 1 auto"
      ></tup-document-filter>

      <button
        data-tracking="import-crosstab-btn"
        class="import-button header-button"
        mat-flat-button
        tupMatButton
        appearance="small"
        color="primary"
        (click)="fileInput.click($event)"
      >
        <mat-icon>file_upload</mat-icon>
        Import local file
      </button>

      <button
        data-tracking="create-crosstab-btn"
        class="create-button header-button"
        mat-flat-button
        tupMatButton
        appearance="small"
        color="primary"
        (click)="startupOptions()"
      >
        <mat-icon>add_circle_outline</mat-icon>
        Start crosstabing
      </button>

      <input
        type="file"
        accept=".json"
        hidden
        #fileInput
        onclick="this.value = null"
        (change)="import($event)"
      />
    </div>
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper
    position="last"
    gap="2"
    tupDocumentEventEmitter
    tupDocumentEventHandler
  >
    <mat-divider class="divider-bottom"></mat-divider>
    <tup-document-storage
      *ngIf="userEmail"
      [options]="{ types: [docOptionsType] }"
      class="document-storage-table document-storage-table__reports"
      #documents
    ></tup-document-storage>
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>

<div
  class="spinner-container-overlay"
  [ngClass]="{
    'spinner-container-overlay--white': isPageLoading
  }"
  *ngIf="isLoading || isPageLoading || !userEmail"
  data-qa="document-list-loading"
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

<ng-template #deleteDocDialogContent>
  <p>
    Are you sure you want to delete
    <b
      ><i>{{ documentName }}</i></b
    >
    ?
  </p>
  <p>
    This action cannot be undone. Your document will be permanently deleted.
  </p>
</ng-template>

<ng-template #deleteOtherDocDialogContent>
  <p>
    Please note that this crosstab report is owned by
    <b
      ><i>{{ documentOwnerName }}</i></b
    >
    and cannot be deleted.
  </p>
</ng-template>
