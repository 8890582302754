<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Sort options"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <div fxLayout="column">
        <h2 class="section-header">Sort options</h2>
        <div
          fxLayout="column"
          fxLayoutGap="10px"
          class="sort-options-container"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <mat-checkbox
              tupMatCheckbox
              appearance="small"
              color="primary"
              fxFlex
              [(ngModel)]="showTop"
              (change)="onShowTopOrBottomChange('top')"
              class="tiny-checkbox"
              >Show top</mat-checkbox
            >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
                class="input-form-field-without-label row-number-input"
              >
                <input
                  matInput
                  type="number"
                  min="0"
                  [(ngModel)]="sortSettings.topRows"
                />
              </mat-form-field>
              <span class="sort-options-label">Rows</span>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <mat-checkbox
              tupMatCheckbox
              appearance="small"
              color="primary"
              fxFlex
              [(ngModel)]="showBottom"
              (change)="onShowTopOrBottomChange('bottom')"
              class="tiny-checkbox"
              >Show bottom</mat-checkbox
            >

            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
                class="input-form-field-without-label row-number-input"
              >
                <input
                  matInput
                  type="number"
                  min="0"
                  [(ngModel)]="sortSettings.bottomRows"
                />
              </mat-form-field>
              <span class="sort-options-label">Rows</span>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column">
        <h2 class="section-header">Survey to use</h2>
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field-without-label"
        >
          <mat-select
            [(ngModel)]="sortSettings.survey"
            (ngModelChange)="onModelChange()"
          >
            <mat-option
              *ngFor="let survey of surveys"
              [value]="survey.code"
            >
              {{ survey.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column">
        <h2 class="section-header">Sort column</h2>
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field-without-label"
          data-qa="sort-column-select"
        >
          <mat-select
            [(ngModel)]="sortSettings.columnId"
            (ngModelChange)="onModelChange()"
          >
            <mat-option
              *ngFor="let column of columns"
              [value]="column.value"
              data-qa="sort-column-select-option"
            >
              {{ column.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <div
          fxLayout="column"
          fxLayoutGap="8px"
          fxFlex="50"
        >
          <h2 class="section-header">Sort data item</h2>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="8px"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
              class="input-form-field-without-label"
              data-qa="sort-data-item-select"
            >
              <mat-select [(ngModel)]="sortSettings.dataItem">
                <mat-option
                  *ngFor="let dataItem of dataItems"
                  [value]="dataItem.id"
                  data-qa="sort-data-item-select-option"
                >
                  <ng-container
                    *ngIf="
                      hasVolumetricCoding &&
                      dataItem.id == dataItemType.audience
                    "
                  >
                    {{ dataItem.volumetricDisplayName }}/{{
                      dataItem.displayName
                    }}
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !hasVolumetricCoding ||
                      dataItem.id != dataItemType.audience
                    "
                  >
                    {{ dataItem.displayName }}
                  </ng-container>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
              class="starting-at-row-container"
            >
              <span
                fxFlex
                fxGrow="1"
                class="sort-options-label"
                >Starting at row:</span
              >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
                class="input-form-field-without-label row-number-input"
              >
                <input
                  matInput
                  type="number"
                  min="1"
                  [(ngModel)]="sortSettings.startingRow"
                  (blur)="onStartingRowInputBlur()"
                  (keydown)="onStartingRowInputKeydown($event)"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          fxLayout="column"
          fxFlex="50"
        >
          <h2 class="section-header">Sort order</h2>
          <mat-radio-group
            fxLayout="column"
            fxLayoutGap="15px"
            [(ngModel)]="sortSettings.order"
            class="tiny-radio-group"
            data-qa="sort-order-radio-group"
          >
            <mat-radio-button value="asc"> Sort ascending </mat-radio-button>
            <mat-radio-button value="desc"> Sort descending </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
      data-qa="cancel-sort-button"
    >
      Cancel
    </button>
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onReloadDefaultsClick()"
    >
      Reload defaults
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onApplyButtonClick()"
      data-qa="apply-sort-button"
    >
      Apply
    </button>
  </mat-dialog-actions>
</div>
