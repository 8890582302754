import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  TitleLevelsDialogComponent,
  TitleLevelsDialogResult,
} from '../dialogs/title-levels-dialog/title-levels-dialog.component';
import { levelSeparator } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TitleLevelsService {
  private numberOfTitleLevels = 0;

  // tslint:disable-next-line:variable-name
  private _titleLevels: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);
  public titleLevels$: Observable<number[]> = this._titleLevels.asObservable();

  constructor(private dialog: MatDialog) {}

  public updateNumberOfTitleLevels(titles: string[]): void {
    this.numberOfTitleLevels = titles.reduce(
      (previousValue: number, currentValue: string) => {
        return Math.max(
          currentValue.split(levelSeparator).length,
          previousValue
        );
      },
      0
    );
  }

  public updateTitleLevels(titleLevels: number[]): void {
    this._titleLevels.next(titleLevels);
  }

  public openDialog(): Observable<TitleLevelsDialogResult> {
    return this.dialog
      .open(TitleLevelsDialogComponent, {
        data: {
          numberOfTitleLevels: this.numberOfTitleLevels,
          titleLevels: this._titleLevels.getValue(),
        },
      })
      .afterClosed()
      .pipe(
        filter(
          (dialogResult: TitleLevelsDialogResult) =>
            !!dialogResult?.titleLevels?.length
        )
      );
  }
}
