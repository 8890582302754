/**
 * Represents a legacy *.pry file
 *
 * TODO Find Types for unknowns (will need more example files)
 * TODO Implement logic to convert between *.pry file and SurveyTimeDocument (https://splashlight.atlassian.net/browse/TU-6676),
 */

export enum CodeSetName {
  Tables = 'Tables',
  Columns = 'Columns',
  Rows = 'Rows',
  SelectedTable = 'SelectedTable',
}

export interface CodeItemParent {
  Name: unknown; // Safe to assume string?
  Code: unknown; // String or number?
}

export interface CodeItemState {
  DisplayMode: string;
}

export interface CodeItemTitles {
  CustomLongTitle: string;
  CustomShortTitle: string;
  FriendlyName: string;
  LongTitle: string;
  ShortTitle: string;
}

export interface CodeItem {
  Code: string;
  Description: string;
  GroupName: string;
  NTileDesc: unknown; // Safe to assume string?
  NodeFullPath: string;
  NodeType: unknown; // String or number?
  Parent: CodeItemParent;
  ParentName: string;
  SerializedCode: unknown; // Safe to assume string?
  State: CodeItemState;
  SubGroupName: unknown; // Safe to assume string?
  SurveyCode: string;
  SurveyType: string | number;
  Titles: CodeItemTitles;
  Weights: string | number;
}

export interface CodeSet {
  CodeSetName: CodeSetName;
  CodeItem: CodeItem[];
}

export interface CodeSets {
  CodeSet: CodeSet[];
}

export interface PRYDataItem {
  Name: string;
  Title: string;
}

export interface HighlightGradient {
  Colours: string[] | string; // Array of strings in some places, comma separated values in others
  DataItem: string;
  DecimalCount: number;
}

export interface PPTGroups {
  Value: string; // Comma separated values
}

export interface Table {
  ChildNodes: unknown[];
  Code: string;
  CodeType: string;
  CodingManager: unknown; // Safe to assume string?
  CodingMode: string;
  ComplexCodeType: number;
  CustomLongTitle: string;
  CustomShortTitle: string;
  DisplayMode: string;
  GroupName: string;
  GroupNameToDisplay: string;
  IsLongTitleChanged: boolean;
  IsOwnTitleChanged: boolean;
  IsShortTitleChanged: boolean;
  LongTitle: string;
  NTileDesc: unknown; // Safe to assume string?
  NodeFullPath: string;
  NodeType: string; // String or number?
  OwnTitle: string;
  Parent: string;
  ShortTitle: string;
  SubGroupName: unknown; // Safe to assume string
  SurveyCode: string;
  SurveyType: string;
  Title: string;
}

export interface FilterRow extends Table {
  Weights: unknown[];
}

export interface Row extends FilterRow {
  uuId: string;
}

export interface Column extends FilterRow {
  uuId: string;
}

export interface SortInfo {
  ColIndex: unknown;
  ColumnTitle: unknown;
  DataItem: unknown;
  SortOrder: unknown;
  SurveyCode: unknown;
}

export interface PrySurvey {
  Calculation?: unknown;
  CanOverrideZeroWeightedRespondents?: boolean;
  Charset?: unknown; // Safe to assume string?
  DisplayName?: string;
  DisplayStudyCode: string;
  Error?: unknown;
  IsCalculation?: boolean;
  IsCalculationSurvey?: boolean;
  IsHubDefaultPopBaseForMultibasedStudy?: boolean;
  IsIgnoreZeroWeightedRespondents?: boolean;
  IsMB3Enabled: boolean;
  IsMappable: boolean;
  IsMultibased: boolean;
  LangCode: string;
  Language: string;
  MBBaseSurveyCode?: unknown; // Safe to assume string?
  MnemonicStatus?: number;
  OccurenceCount?: number;
  PartitionID?: number;
  Provider?: string;
  ProviderID?: number;
  SourceName?: string;
  StudyID?: number;
  StudyReleaseDate?: string;
  StudyType?: string;
  SurveyCode: string;
  SurveyColor?: string;
  SurveyID?: number;
  SurveyName: string;
  SurveyWeight?: string | number; // Are SurveyWeight and Weight the same thing?
  Weight?: number;
  Year?: number;
  defaults?: unknown;
  fields?: unknown;
}

export interface Run {
  ColourTiles: unknown;
  Columns: Column[];
  FilterConditions: unknown[];
  GroupCharts: unknown[];
  HighlightConditions: unknown;
  HighlightGradient: HighlightGradient;
  IsStabilityOn: boolean;
  Rows: Row[];
  SortInfo: SortInfo;
  Surveys: PrySurvey[];
  Table: Table;
  Tables: Table[];
  Weight: unknown[];
}

export interface Snapshot {
  Description: unknown; // Safe to assume string?
  DisplayName: string;
  DisplayVersion: string;
  FilterRows: FilterRow[];
  ID: string;
  Index: string | number;
  Info: string;
  Name: string;
  Run: Run[];
  TakenOn: string;
}

export interface Sort {
  SortIndex: unknown; // Safe to assume string?
  SortOnItem: unknown; // Safe to assume string?
  SortOrder: unknown; // Safe to assume string?
}

export interface SurveyInformation {
  Survey: PrySurvey;
}

export interface PRYFile {
  AppSourceName: string;
  AppSourceVersion: string;
  Calculations: unknown;
  CodeSets: CodeSets;
  Costs: unknown;
  DataItems: PRYDataItem[];
  Description: string;
  DisplayMode: string;
  FileCreationDate: string;
  FileVersion: string;
  FilterConditions: unknown;
  GroupCharts: unknown;
  HighlightConditions: unknown;
  HighlightGradient: HighlightGradient;
  IsMultiBased: string | boolean;
  Language: string;
  MBSurveyCode: unknown; // String or number?
  PPTGroups: PPTGroups;
  ProductCode: string;
  Snapshots: Snapshot[];
  Sort: Sort;
  SurveyInformation: SurveyInformation;
  UserID: string;
}
