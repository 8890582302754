<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="this.isLoadingNodes"
>
  <tup-mat-title-bar
    title="Visual code builder"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxFlex
      fxLayout="column grid"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="split-wrapper"
    >
      <ui-split
        unit="pixel"
        direction="horizontal"
        #split="uiSplit"
      >
        <ui-split-area
          [size]="480"
          [minSize]="50"
          [maxSize]="750"
        >
          <app-codebook-navigation
            #codebookNavigation
            fxFlex
            [inCodeBuilder]="true"
            [survey]="selectedSurvey"
          >
          </app-codebook-navigation>
        </ui-split-area>

        <ui-split-area>
          <tup-visual-code-builder
            #visualCodeBuilder
            [isLoadingSelectedNodes]="isLoadingSelectedNodes"
            [isLoadingFromDrag]="isLoadingFromDrag"
            [selectedNodes]="selectedNodes"
            [target]="target"
            [createTarget]="createTarget"
            [convertStatementsToTargets]="convertStatementsToTargets"
            [updateTitleAndCoding]="updateTitleAndCoding"
            [groupTargets]="groupTargets"
            [groupTargetsWithAutoOperator]="groupTargetsWithAutoOperator"
            [hasMoreThanMaxCombinedTargets]="hasMoreThanMaxCombinedTargets"
            [showMaxLimitAlert]="showMaxLimitAlert"
            (targetChange)="onTargetChange($event)"
            (dropNode)="onDropNode($event)"
            (unselectNodes)="onUnselectNodes()"
          ></tup-visual-code-builder>
        </ui-split-area>
      </ui-split>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onButtonClick(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick(true)"
      [disabled]="!targetUserTitle"
      data-qa="visual-code-builder-ok-button"
    >
      OK
    </button>
  </mat-dialog-actions>
</div>
