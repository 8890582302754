import { Injectable } from '@angular/core';
import {
  CellColor,
  CellColorId,
  CellStyleStatus,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItemId,
  DataItemType,
  HEATMAP_GREEN,
  HEATMAP_QUARTILES,
  HEATMAP_QUINTILES,
  HEATMAP_RED,
  HeatmapTile,
  HighlightColors,
  ReportPreference,
  StabilityLevelsColor,
  StabilityLevelsProbs,
} from '../models';
import { ReportPreferencesService } from './report-preferences.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HighlightValuesDialogComponent } from '../dialogs/highlight-values-dialog/highlight-values-dialog.component';
import { HeatmapDialogComponent } from '../dialogs/heatmap-dialog/heatmap-dialog.component';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';

@Injectable({
  providedIn: 'root',
})
export class HighlightCellsService {
  private chosenColor = HighlightColors[1];
  private colors: string[];
  private dataItemId: DataItemId;
  private bins: number[][] = [[]];

  private cellColors: Record<CellColorId, CellColor> = {};

  private preference: ReportPreference;

  constructor(
    private dialog: MatDialog,
    private reportPreferencesService: ReportPreferencesService
  ) {
    this.reportPreferencesService.preference$
      .pipe(isNotNullOrUndefined())
      .subscribe((preference: ReportPreference) => {
        this.preference = preference;
      });
  }

  public highlightColors(): MatDialogRef<HighlightValuesDialogComponent> {
    const highlightValues = this.preference.highlightValues;
    const options = {
      width: '600px',
      data: {
        chosenColor: highlightValues.chosenColor,
        dataItemId: highlightValues.dataItemId,
      },
    };
    return this.dialog.open(HighlightValuesDialogComponent, options);
  }

  public heatmap(): MatDialogRef<HeatmapDialogComponent> {
    const options = {
      width: '680px',
      data: {
        selectedHeatmapOption: this.preference.cellStyleStatus.startsWith(
          'heatmap'
        )
          ? this.preference.cellStyleStatus
          : '',
        value: this.preference.heatmapIndexPercentage,
      },
    };
    return this.dialog.open(HeatmapDialogComponent, options);
  }

  public updateCellColors(
    data: CrossTabTableData[]
  ): Record<CellColorId, CellColor> {
    this.initialiseCells(data);
    const cellStyleStatus = this.preference.cellStyleStatus;
    if (cellStyleStatus === CellStyleStatus.highlight) {
      const highlightValues = this.preference.highlightValues;
      this.chosenColor = highlightValues.chosenColor;
      this.colors = highlightValues.colors;
      this.dataItemId = highlightValues.dataItemId;
      this.divideDataIntoColors(
        data,
        this.dataItemId,
        this.colors.length,
        this.colors
      );
    }

    if (cellStyleStatus === CellStyleStatus.heatmapQuartiles) {
      this.divideDataIntoColors(
        data,
        DataItemType.index,
        HEATMAP_QUARTILES.length,
        HEATMAP_QUARTILES.map((tile: HeatmapTile) => tile.background)
      );
    }

    if (cellStyleStatus === CellStyleStatus.heatmapQuintiles) {
      this.divideDataIntoColors(
        data,
        DataItemType.index,
        HEATMAP_QUINTILES.length,
        HEATMAP_QUINTILES.map((tile: HeatmapTile) => tile.background)
      );
    }

    if (cellStyleStatus === CellStyleStatus.heatmap) {
      this.calculateHeatmapColors(data);
    }

    if (cellStyleStatus === CellStyleStatus.significanceTestingColumn) {
      this.significanceTestingColumn(data);
    }

    if (cellStyleStatus === CellStyleStatus.significanceTestingRow) {
      this.significanceTestingRow(data);
    }

    return this.cellColors;
  }

  public getBins(): number[][] {
    return this.bins;
  }

  private initialiseCells(data: CrossTabTableData[]): void {
    this.cellColors = {};
    data.forEach((row: CrossTabTableData) => {
      row.data.forEach((column: CrossTabTableDataCell, columnIndex: number) => {
        this.setCellColor(row.rowIndex, columnIndex, '');
      });
    });
  }

  private calculateHeatmapColors(data: CrossTabTableData[]) {
    const highIndex = this.preference.heatmapIndexPercentage + 100;
    const lowIndex = 100 - this.preference.heatmapIndexPercentage;
    data.forEach((row) => {
      row.data.forEach((column: CrossTabTableDataCell, columnIndex: number) => {
        this.setCellColor(
          row.rowIndex,
          columnIndex,
          row.title === 'Totals' || column.isTotalsColumn || row.isPlaceholder
            ? ''
            : column[DATA_ITEMS_MAP[DataItemType.index].cellKey] > highIndex
            ? HEATMAP_GREEN
            : column[DATA_ITEMS_MAP[DataItemType.index].cellKey] < lowIndex
            ? HEATMAP_RED
            : ''
        );
      });
    });
  }

  private divideDataIntoColors(
    data: CrossTabTableData[],
    dataItemId: DataItemId,
    numberOfSegments: number,
    colors: string[]
  ): void {
    this.bins = this.createBins(data, dataItemId, numberOfSegments);
    data.forEach((row) => {
      row.data.forEach((column: CrossTabTableDataCell, columnIndex: number) => {
        this.setCellColor(
          row.rowIndex,
          columnIndex,
          row.title === 'Totals' || column.isTotalsColumn || row.isPlaceholder
            ? ''
            : this.divideData(
                column[DATA_ITEMS_MAP[dataItemId].cellKey],
                this.bins,
                colors
              )
        );
      });
    });
  }

  private divideData(
    position: number,
    bins: number[][],
    colors: string[]
  ): string {
    for (let i = 0; i < bins.length; i++) {
      if (position <= bins[i][1] && position >= bins[i][0]) {
        return colors ? colors[i] : undefined;
      }
    }
  }

  private createBins(
    data: CrossTabTableData[],
    dataItemId: DataItemId,
    numberOfSegments: number
  ): number[][] {
    const { max, min } = this.getMaxMinValuesFromDataPoint(data, dataItemId);
    const binPercent = (max - min) / numberOfSegments;
    const binCollection = [[min, min + binPercent]];
    const lastSegment = numberOfSegments - 1;

    for (let i = 0; i < lastSegment; i++) {
      binCollection.push([
        binCollection[i][1],
        binCollection[i][1] + binPercent,
      ]);
    }

    binCollection[lastSegment][1] = binCollection[lastSegment][1] + 2;

    return binCollection;
  }

  private getMaxMinValuesFromDataPoint(
    data: CrossTabTableData[],
    dataItemId: DataItemId
  ): {
    max: number;
    min: number;
  } {
    let max = 0;
    let min;
    data.forEach((row: CrossTabTableData) => {
      if (row.title !== 'Totals') {
        max = Math.max(
          ...row.data
            .filter(
              (element) => !element.isTotalsColumn && !element.filteredOutCell
            )
            .map((element) => element[DATA_ITEMS_MAP[dataItemId].cellKey]),
          max
        );
        min = Math.min(
          ...row.data
            .filter(
              (element) => !element.isTotalsColumn && !element.filteredOutCell
            )
            .map((element) => element[DATA_ITEMS_MAP[dataItemId].cellKey]),
          min === undefined ? max : min
        );
      }
    });
    return { max, min };
  }

  private significanceTestingColumn(data: CrossTabTableData[]): void {
    if (data.length < 1) {
      return;
    }
    const totalsRow = data[0];
    const surveyTotalsData = this.getTotalsColumnData(totalsRow);
    data.forEach((row: CrossTabTableData, rowIndex: number) => {
      if (rowIndex === 0) {
        return;
      }
      const currentRowTotalsData = this.getTotalsColumnData(row);
      row.data.forEach((column: CrossTabTableDataCell, columnIndex: number) => {
        if (column.isTotalsColumn) {
          return;
        }
        const totalsColData = totalsRow.data[columnIndex];
        const notColumn = {
          projected:
            currentRowTotalsData[column.surveyCode].projected -
            column.projected,
          sample:
            currentRowTotalsData[column.surveyCode].sample - column.sample,
        };

        const notTotalsColData = {
          projected:
            surveyTotalsData[column.surveyCode].projected -
            totalsColData.projected,
          sample:
            surveyTotalsData[column.surveyCode].sample - totalsColData.sample,
        };

        const perCol = Number.isNaN(column.projected / totalsColData.projected)
          ? 0
          : Number((column.projected / totalsColData.projected).toFixed(6));
        const notPerCol = Number.isNaN(
          notColumn.projected / notTotalsColData.projected
        )
          ? 0
          : Number(
              (notColumn.projected / notTotalsColData.projected).toFixed(6)
            );

        let cellProb =
          (perCol * totalsColData.sample +
            notPerCol * notTotalsColData.sample) /
          (totalsColData.sample + notTotalsColData.sample);

        cellProb = Number.isNaN(cellProb) ? 0 : Number(cellProb.toFixed(8));

        let value1 = 1 / totalsColData.sample + 1 / notTotalsColData.sample;

        if (totalsColData.sample === 0) {
          value1 = 1 / notTotalsColData.sample;
        } else if (notTotalsColData.sample === 0) {
          value1 = 1 / totalsColData.sample;
        }

        value1 = Number.isNaN(value1) ? 0 : Number(value1.toFixed(8));

        let cellSD = Math.sqrt(cellProb * (1 - cellProb) * value1);

        cellSD = Number.isNaN(cellSD) ? 0 : Number(cellSD.toFixed(8));

        this.setCellColor(
          row.rowIndex,
          columnIndex,
          this.getStabilityLevelColors(Math.abs(perCol - notPerCol) / cellSD)
        );
      });
    });
  }

  private significanceTestingRow(data: CrossTabTableData[]): void {
    if (data.length < 1) {
      return;
    }
    const totalsRow = data[0];
    const surveyTotalsData = this.getTotalsColumnData(totalsRow);
    data.forEach((row: CrossTabTableData, rowIndex: number) => {
      if (rowIndex === 0) {
        return;
      }
      const currentRowTotalsData = this.getTotalsColumnData(row);
      row.data.forEach((column: CrossTabTableDataCell, columnIndex: number) => {
        if (column.isTotalsColumn) {
          return;
        }
        const totalsColData = totalsRow.data[columnIndex];
        const currentRowColData = currentRowTotalsData[column.surveyCode];
        const notColumn = {
          projected: totalsColData.projected - column.projected,
          sample: totalsColData.sample - column.sample,
        };

        const notTotalsRowData = {
          projected:
            surveyTotalsData[column.surveyCode].projected -
            currentRowColData.projected,
          sample:
            surveyTotalsData[column.surveyCode].sample -
            currentRowColData.sample,
        };

        const perRow = Number.isNaN(
          column.projected / currentRowColData.projected
        )
          ? 0
          : Number((column.projected / currentRowColData.projected).toFixed(6));

        const notPerRow = Number.isNaN(
          notColumn.projected / notTotalsRowData.projected
        )
          ? 0
          : Number(
              (notColumn.projected / notTotalsRowData.projected).toFixed(6)
            );

        let cellProb =
          (perRow * currentRowColData.sample +
            notPerRow * notTotalsRowData.sample) /
          (currentRowColData.sample + notTotalsRowData.sample);

        cellProb = Number.isNaN(cellProb) ? 0 : Number(cellProb.toFixed(8));

        let value1 = 1 / currentRowColData.sample + 1 / notTotalsRowData.sample;

        if (currentRowColData.sample === 0) {
          value1 = 1 / notTotalsRowData.sample;
        } else if (notTotalsRowData.sample === 0) {
          value1 = 1 / currentRowColData.sample;
        }

        let cellSD = Math.sqrt(cellProb * (1 - cellProb) * value1);

        cellSD = Number.isNaN(cellSD) ? 0 : Number(cellSD.toFixed(8));

        this.setCellColor(
          row.rowIndex,
          columnIndex,
          this.getStabilityLevelColors(Math.abs(perRow - notPerRow) / cellSD)
        );
      });
    });
  }

  private getTotalsColumnData(
    row: CrossTabTableData
  ): Record<string, CrossTabTableDataCell> {
    return row.data
      .filter((column: CrossTabTableDataCell) => column.isTotalsColumn)
      .reduce((prev, column: CrossTabTableDataCell) => {
        prev[column.surveyCode] = column;
        return prev;
      }, {});
  }

  private getStabilityLevelColors(zValue): string {
    if (Number.isNaN(zValue)) {
      zValue = 0;
    }

    if (
      zValue >= StabilityLevelsProbs.percent90 &&
      zValue < StabilityLevelsProbs.percent95
    ) {
      return StabilityLevelsColor.red;
    } else if (
      zValue >= StabilityLevelsProbs.percent95 &&
      zValue < StabilityLevelsProbs.percent99
    ) {
      return StabilityLevelsColor.orange;
    } else if (zValue >= StabilityLevelsProbs.percent99) {
      return StabilityLevelsColor.green;
    } else {
      return StabilityLevelsColor.white;
    }
  }

  private setCellColor(rowIndex, columnIndex, color: CellColor): void {
    this.cellColors[`${columnIndex}_${rowIndex}`] = color;
  }
}
