import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  CrossTabEditorComponent,
  DashboardComponent,
  LoginComponent,
} from '../pages';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { PMapsComponent } from '../pages/p-maps/p-maps.component';
import { CrossTabComponent } from '../pages/cross-tab/cross-tab.component';
import { CrossTabGraphComponent } from '../pages/cross-tab-graph/cross-tab-graph.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'doc/:documentId',
    component: CrossTabComponent,
    children: [
      { path: '', redirectTo: 'data', pathMatch: 'full' },
      {
        path: 'data',
        component: CrossTabEditorComponent,
      },
      {
        path: 'chart',
        component: CrossTabGraphComponent,
      },
      {
        path: 'pmaps',
        component: PMapsComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
