<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="{{
      chartSettingsMode === chartSettingsModeType.global
        ? 'Chart global filters'
        : 'Chart filters'
    }}"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="chart-filter"
    >
      <div
        fxLayout="column"
        fxFlex="100"
        fxFill
      >
        <div
          *ngFor="let filter of chartSettings.filters; let i = index"
          fxLayout="row"
          fxFlex="100"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          class="chart-filter-row"
        >
          <mat-label class="chart-filter-label">Filter {{ i + 1 }}</mat-label>
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label"
            *ngIf="chartSettingsMode !== chartSettingsModeType.global"
          >
            <mat-label *ngIf="filter.target === 'None'"
              >Filter target</mat-label
            >
            <mat-select [(ngModel)]="filter.target">
              <mat-option value="None"></mat-option>
              <mat-option
                *ngFor="let item of sortColumns"
                [value]="item.id"
                title="{{ item.title }}"
                >{{ item.title }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label chart-filter-data-item"
          >
            <mat-label *ngIf="filter.dataItem === dataItemType.none"
              >Data item</mat-label
            >
            <mat-select [(ngModel)]="filter.dataItem">
              <mat-option [value]="dataItemType.none"></mat-option>
              <mat-option
                *ngFor="let dataItem of chartDataItems"
                [value]="dataItem.value"
              >
                {{ dataItem.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label chart-filter-operator"
          >
            <mat-label *ngIf="filter.operator === chartFilterOperator.none"
              >Operator</mat-label
            >
            <mat-select
              [(ngModel)]="filter.operator"
              [disabled]="filter.dataItem === dataItemType.none"
            >
              <mat-option [value]="chartFilterOperator.none"></mat-option>
              <mat-option
                *ngFor="let filterOperator of filterOperators"
                [value]="filterOperator"
              >
                {{ filterOperator }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="5px"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
              class="input-form-field-without-label filter-value-field"
            >
              <input
                matInput
                type="number"
                min="0"
                [disabled]="
                  filter.dataItem === dataItemType.none ||
                  filter.operator === chartFilterOperator.none
                "
                [(ngModel)]="filter.value[0]"
                (change)="onFilterValueChange(filter)"
                (keydown)="onFilterValueInputKeydown($event)"
              />
            </mat-form-field>
            <ng-container
              *ngIf="filter.operator === chartFilterOperator.between"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
              >
                <span class="margin-adjustment">To</span>
                <mat-form-field
                  appearance="outline"
                  tupMatFormField
                  fxFlex
                  class="input-form-field-without-label filter-value-field"
                >
                  <input
                    matInput
                    type="number"
                    min="0"
                    [disabled]="
                      filter.dataItem === dataItemType.none ||
                      filter.operator === chartFilterOperator.none
                    "
                    [(ngModel)]="filter.value[1]"
                    (change)="onFilterValueChange(filter)"
                    (keydown)="onFilterValueInputKeydown($event)"
                  />
                </mat-form-field>
              </div>
            </ng-container>
          </div>

          <button
            mat-icon-button
            tupMatButton
            color="primary"
            [disabled]="
              filter.dataItem === dataItemType.none ||
              filter.operator === chartFilterOperator.none ||
              (chartSettingsMode !== chartSettingsModeType.global &&
                filter.target === 'None')
            "
            appearance="tiny"
            matTooltip="Reset filter"
            class="remove-filter-btn"
            (click)="onFilterCancelClick(filter)"
          >
            <mat-icon class="material-icons-outlined remove-filter-icon"
              >remove_circle_outline</mat-icon
            >
          </button>
        </div>

        <mat-hint
          class="chart-filter-hint"
          *ngIf="chartSettingsMode === chartSettingsModeType.global"
          >The global filter will override any individual chart
          filters.</mat-hint
        >
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
      data-qa="cancel-filter-button"
    >
      Cancel
    </button>
    <div>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        cdkFocusRegionstart
        (click)="onApplyButtonClick()"
        data-qa="apply-filter-button"
      >
        Apply
      </button>
    </div>
  </mat-dialog-actions>
</div>
