import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GraphViewComponent } from 'src/app/components';
import {
  ChartTargetMode,
  DocumentViewType,
  ExportFileType,
  Survey,
  SurveyTimeDocument,
  TARGET_MODES,
  TARGET_MODES_TRENDING_SURVEY,
} from 'src/app/models';
import {
  DocumentService,
  RequestLoadingService,
  TargetLoading,
} from 'src/app/services';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

@Component({
  selector: 'cross-tab-graph',
  templateUrl: './cross-tab-graph.component.html',
  styleUrls: ['./cross-tab-graph.component.scss'],
})
export class CrossTabGraphComponent implements OnInit, OnDestroy {
  @ViewChild(GraphViewComponent, { static: false })
  graphViewComponent: GraphViewComponent;

  private unsubscribe: Subject<void> = new Subject<void>();
  public readonly targetmodestrendingsurvey = TARGET_MODES_TRENDING_SURVEY;
  public readonly targetmodes = TARGET_MODES;
  public targetMode: ChartTargetMode = ChartTargetMode.single;
  public isFetchingCrosstabData = false;
  public isExporting = false;
  public surveys: Survey[];
  public isReadonly = true;
  public selectedSurvey: Survey;
  public canExportCharts = true;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;

  constructor(
    private requestLoadingService: RequestLoadingService,
    private router: Router,
    private documentService: DocumentService
  ) {
    this.isReadonly =
      this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit() {
    this.listenToLoadingState();
    this.listenToDocumentDataChanges();
    this.listenToActiveSurveyChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeLoadingSubscription();
  }

  public exportToPptx(): void {
    this.graphViewComponent.exportAs(ExportFileType.pptx);
  }

  public exportToSlides(): void {
    this.graphViewComponent.exportAs(ExportFileType.googleSlides);
  }

  public exportToXlsx(): void {
    this.graphViewComponent.exportAs(ExportFileType.xlsx);
  }

  public exportToSheets(): void {
    this.graphViewComponent.exportAs(ExportFileType.googleSheets);
  }

  public onChartExportEnabled(enabled: boolean): void {
    this.canExportCharts = enabled;
  }

  public onChartsViewTabChange(tab: MatTabChangeEvent): void {
    this.targetMode = this.targetmodestrendingsurvey[tab.index].value;
  }
  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'global' ||
            targetLoading.target === 'exporting'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isFetchingCrosstabData = targetLoading.isLoading;
      });
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) => targetLoading.target === 'crosstab'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isFetchingCrosstabData = targetLoading.isLoading;
      });
  }

  private unsubscribeLoadingSubscription(): void {
    this.isFetchingCrosstabData = false;
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.selectedSurvey = survey;
        this.surveys = this.documentService.getVisibleSurveys();
      });
  }
}
