import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import {
  ALL_RESPONDENTS_CODING,
  DocumentDataState,
  Target,
  TargetItem,
  TargetType,
} from 'src/app/models';
import { TargetCheckboxOption } from '../add-code-to-multiple-dialog/add-code-to-multiple-dialog.component';
type itemType = 'rows' | 'columns' | 'tables';

@Component({
  selector: 'manage-report-items-dialog',
  templateUrl: './manage-report-items-dialog.component.html',
  styleUrls: ['./manage-report-items-dialog.component.scss'],
})
export class ManageReportItemsDialogComponent implements OnInit {
  public tables: TargetCheckboxOption[];
  public columns: TargetCheckboxOption[];
  public rows: TargetCheckboxOption[];

  public columnsAllSelected: boolean = false;
  public rowsAllSelected: boolean = false;
  public tablesAllSelected: boolean = false;

  public dataToDelete: TargetItem[] = [];

  @ViewChild(SelectContainerComponent)
  selectContainer: SelectContainerComponent;

  constructor(
    public dialogRef: MatDialogRef<ManageReportItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentDataState
  ) {
    this.tables = this.transformData(
      data.tables.filter(
        (table: Target) => table.coding !== ALL_RESPONDENTS_CODING
      ),
      'tables'
    );
    this.columns = this.transformData(data.columns, 'columns');
    this.rows = this.transformData(data.rows, 'rows');
  }

  ngOnInit(): void {}

  private transformData(
    targetData: Target[],
    type: itemType
  ): TargetCheckboxOption[] {
    return targetData.map((target: Target, index: number) => ({
      targetItem: {
        type: TargetType[type],
        target,
        index,
      },
      selected: false,
    }));
  }

  public onButtonClick(buttonOption: boolean): void {
    if (buttonOption && this.dataToDelete.length > 0) {
      this.dialogRef.close(this.dataToDelete);
    } else {
      this.dialogRef.close(false);
    }
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public selectedAllTables($event): void {
    this.tables.map((item) => (item.selected = $event.checked));
  }

  public selectedAllRows($event): void {
    this.rows.map((item) => (item.selected = $event.checked));
  }

  public selectedAllColumns($event): void {
    this.columns.map((item) => (item.selected = $event.checked));
  }

  public selectedSome($event, data): void {
    switch (data) {
      case 'tables':
        if (!$event.checked && this.tablesAllSelected) {
          this.tablesAllSelected = false;
        }

        if ($event.checked && !this.tablesAllSelected) {
          this.tablesAllSelected = this.selectAllManually(this.tables);
        }

        break;

      case 'rows':
        if (!$event.checked && this.rowsAllSelected) {
          this.rowsAllSelected = false;
        }

        if ($event.checked && !this.rowsAllSelected) {
          this.rowsAllSelected = this.selectAllManually(this.rows);
        }

        break;

      case 'columns':
        if (!$event.checked && this.columnsAllSelected) {
          this.columnsAllSelected = false;
        }

        if ($event.checked && !this.columnsAllSelected) {
          this.columnsAllSelected = this.selectAllManually(this.columns);
        }

        break;
    }
  }

  public selectAllManually(data: TargetCheckboxOption[]) {
    return data.filter((item) => !item.selected).length === 0;
  }

  public deleteSelected(type: itemType): void {
    if (this.columnsAllSelected && this.columns.length > 0) {
      this.dataToDelete.push(
        ...this.columns.map((target) => target.targetItem)
      );
      this.columnsAllSelected = false;
      this.columns = [];
    } else if (this.rowsAllSelected && this.rows.length > 0) {
      this.dataToDelete.push(...this.rows.map((target) => target.targetItem));
      this.rowsAllSelected = false;
      this.rows = [];
    } else if (this.tablesAllSelected && this.tables.length > 0) {
      this.dataToDelete.push(...this.tables.map((target) => target.targetItem));
      this.tablesAllSelected = false;
      this.tables = [];
    } else {
      this[type].forEach((target: TargetCheckboxOption) => {
        if (target.selected) {
          this.dataToDelete.push(target.targetItem);
          this[type] = this[type].filter(
            (item) => item.targetItem.target.id !== target.targetItem.target.id
          );
        }
      });
    }
  }
}
