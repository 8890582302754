<tup-mat-card
  class="insight-chart"
  fxLayout="column"
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="2px"
    class="action-controls"
  >
    <div class="export-chart-checkbox">
      <mat-checkbox
        tupMatCheckbox
        matTooltip="Select to download"
        appearance="small"
        color="primary"
        [checked]="exportSelected"
        [(ngModel)]="exportSelected"
      ></mat-checkbox>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="end center"
      class="action-controls__settings"
    >
      <button
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        matTooltip="Chart filters"
        (click)="openChartFilter()"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
      <button
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        matTooltip="Export to PPT"
        (click)="exportSingle()"
      >
        <mat-icon class="tiny-icon">file_download</mat-icon>
      </button>
      <button
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        matTooltip="Show table"
        (click)="openTable()"
      >
        <mat-icon class="tiny-icon">table_chart</mat-icon>
      </button>
      <button
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        matTooltip="Edit note"
        (click)="toggleNote()"
      >
        <mat-icon class="tiny-icon">edit_note</mat-icon>
      </button>
      <button
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        matTooltip="Settings"
        (click)="openChartSettings()"
      >
        <mat-icon class="tiny-icon">settings</mat-icon>
      </button>
    </div>
  </div>

  <div
    fxFlex
    fxLayout="column"
    fxGrow="1"
    [class.has-filters]="filtersApplied"
  >
    <mat-chip-list
      class="chart-filters"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <ng-container
        *ngFor="let filter of localChartSettings.filters; let i = index"
      >
        <mat-chip
          [disableRipple]="false"
          [matTooltip]="
            filter | formatChartFilter: targets:localChartSettings:sortColumns
          "
          (removed)="onFilterRemove(filter)"
          (click)="openChartFilter()"
          *ngIf="
            filter.operator !== chartFilterOperator.none &&
            filter.dataItem !== dataItemType.none &&
            filter.target !== 'None'
          "
        >
          {{
            filter
              | formatChartFilter: targets:localChartSettings:sortColumns
              | truncate: 20
          }}
          <mat-icon
            matChipRemove
            matTooltipPosition="above"
            matTooltip="Remove filter"
          >
            cancel
          </mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <h3 class="target-title">
        {{
          localChartSettings.chartTitle
            | chartTitle
              : targetTitle
              : activeGraph.graphType
              : primaryDataItemSelection
              : secondaryChartType.graphType
              : secondaryDataItemSelection
        }}
      </h3>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <h5 class="group-name">
        {{ insightTitle }}
      </h5>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxFlex
      fxLayoutAlign="space-evenly start"
      fxLayoutAlign.lt-md="space-evenly center"
      class="chart-container-wrapper"
      [class.is-table-visible]="localChartSettings.showDataTable && !note"
    >
      <tup-table-chart
        *ngIf="
          localChartSettings.showDataTable && !note && tableData.length > 0
        "
        class="chart-data-table"
        [data]="tableData"
        [tableDataItemIds]="tableDataItemIds"
        [preSelectedTableDataItemIds]="preSelectedTableDataItemIds"
        [availableDataItems]="availableDataItems"
        [dataItemTooltip]="'Table data items'"
        [enableSort]="true"
        [handleSortExternally]="true"
        [(sortActive)]="sortActive"
        [(sortDirection)]="sortDirection"
        (sortChange)="onSortChange($event)"
        (dataItemsChange)="onDataItemsChange($event)"
      ></tup-table-chart>
      <div
        *ngIf="refreshChart"
        class="temp-chart-placeholder"
        [style.height]="tempChartPlaceholderHeight + 'px'"
      ></div>

      <div
        *ngIf="!refreshChart"
        #chartContainer
        class="chart-container"
        [class.is-note-visible]="note"
      >
        <tup-charts
          #chart
          *ngIf="!multipleDatasets"
          [class.single-combined-chart--no-table]="
            !localChartSettings.showDataTable
          "
          [type]="activeGraph.graphType"
          [data]="finalData"
          [scatterChartData]="secondaryDataItemValues"
          [percentBarChart]="activeGraph.percentBarChart"
          [waterfall]="activeGraph.waterfall"
          [areaChart]="activeGraph.areaChart || secondaryChartType.areaChart"
          [pyramidChart]="activeGraph.pyramidChart"
          [hideLegend]="!localChartSettings.showChartLegend"
          [secondaryChartType]="secondaryChartType.graphType"
          [secondaryChartData]="secondaryDataItemValues"
          [secondaryDataType]="secondaryDataItemSelection"
          [decimalPlaces]="localChartSettings.decimalPlaces"
          [showDataFlag]="localChartSettings.flagRowResps"
          [showDataLabel]="localChartSettings.showChartLabel"
          [aspectRatio]="activeGraph.aspectRatio"
          [maintainAspectRatio]="false"
          chartWidth="100%"
          chartHeight="100%"
          [legendPosition]="'top'"
          [axisLabelLength]="25"
          [legendLabelLength]="30"
          [dataItemSelection]="primaryDataItemSelection"
          [secondaryDataItemSelection]="secondaryDataItemSelection"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [x1AxisLabel]="x1AxisLabel"
          [y1AxisLabel]="y1AxisLabel"
        ></tup-charts>

        <ng-container *ngIf="multipleDatasets">
          <tup-charts
            #chart
            *ngIf="
              multipleDatasets &&
              !refreshChart &&
              !unsuitableMultipleCharts.includes(activeGraph.value)
            "
            [type]="activeGraph.graphType"
            [class.centeredCharts]="
              activeGraph.graphType === 'tupMultiSeriesDonut' ||
              activeGraph.graphType === 'tupRadar' ||
              activeGraph.graphType === 'tupFilledRadar' ||
              activeGraph.graphType === 'tupPolar'
            "
            [data]="finalData"
            [aspectRatio]="activeGraph.aspectRatio"
            [scatterChartData]="secondaryDataItemValues"
            [percentBarChart]="activeGraph.percentBarChart"
            [waterfall]="activeGraph.waterfall"
            [areaChart]="activeGraph.areaChart || secondaryChartType.areaChart"
            [hideLegend]="
              !localChartSettings.showChartLegend ||
              localChartSettings.showDataTable
            "
            [secondaryChartType]="secondaryChartType.graphType"
            [secondaryChartData]="secondaryDataItemValues"
            [secondaryDataType]="secondaryDataItemSelection"
            [decimalPlaces]="localChartSettings.decimalPlaces"
            [showDataFlag]="localChartSettings.flagRowResps"
            [showDataLabel]="localChartSettings.showChartLabel"
            [maintainAspectRatio]="false"
            chartWidth="100%"
            chartHeight="100%"
            [legendPosition]="'left'"
            [legendWidth]="legendWidth"
            [axisLabelLength]="25"
            [legendLabelLength]="targets.length > 15 ? (note ? 20 : 30) : 45"
            [dataItemSelection]="primaryDataItemSelection"
            [secondaryDataItemSelection]="secondaryDataItemSelection"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [x1AxisLabel]="x1AxisLabel"
            [y1AxisLabel]="y1AxisLabel"
          ></tup-charts>

          <div
            *ngIf="unsuitableMultipleCharts.includes(activeGraph.value)"
            fxLayout="col wrap"
            fxLayoutAlign="space-evenly start"
            class="scroll"
          >
            <div
              *ngFor="let dataset of datasetsForUnsuitableCharts; let i = index"
              class="multiple-combined-charts"
              [class.multiple-combined-charts--single-row]="
                localChartSettings.showDataTable &&
                chartsInSingleRow.includes(activeGraph.value)
              "
            >
              <h5>{{ chartData.targetTitles[i] }}</h5>
              <tup-charts
                #chart
                [class.single-row-chart]="
                  chartsInSingleRow.includes(activeGraph.value)
                "
                [type]="activeGraph.graphType"
                [data]="dataset"
                [pyramidChart]="activeGraph.pyramidChart"
                [hideLegend]="
                  !localChartSettings.showChartLegend ||
                  localChartSettings.showDataTable
                "
                [decimalPlaces]="localChartSettings.decimalPlaces"
                [showDataFlag]="true"
                [showDataLabel]="localChartSettings.showChartLabel"
                [aspectRatio]="activeGraph.aspectRatio"
                [maintainAspectRatio]="false"
                chartWidth="100%"
                chartHeight="100%"
                [legendPosition]="'left'"
                [axisLabelLength]="30"
                [legendLabelLength]="note ? 30 : 45"
                [dataItemSelection]="primaryDataItemSelection"
                [secondaryDataItemSelection]="secondaryDataItemSelection"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [x1AxisLabel]="x1AxisLabel"
                [y1AxisLabel]="y1AxisLabel"
              ></tup-charts>
            </div>
          </div>
        </ng-container>
      </div>

      <chart-note
        *ngIf="note"
        [isReadonly]="isReadonly"
        [associatedTarget]="associatedTarget"
        [associatedInsight]="insightTitle"
      ></chart-note>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="chart-data-flag"
    >
      <span *ngIf="localChartSettings.flagRowResps && showFlagDataItems"
        >* indicates Respondents <=
        {{ localChartSettings.flagRowRespsValue }}</span
      >
    </div>
  </div>
</tup-mat-card>
