import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { DisplayType, TargetItem } from '../models';
import { RenameDialogComponent, RenameDialogResult } from '../dialogs';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { MatDialog } from '@angular/material/dialog';
import { TargetTitlePipe } from '../pipes';

export interface AssignGroupNameActionContext {
  targetItems: TargetItem[];
}

@Injectable({
  providedIn: 'root',
})
export class AssignGroupNameAction extends ActionDefinition<AssignGroupNameActionContext> {
  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    super();
  }

  public invoke(context: AssignGroupNameActionContext): void {
    const groupName = this.targetTitlePipe.transform(
      context.targetItems[0].target,
      DisplayType.group
    );
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle: 'Assign group name',
          inputTitle: 'Group name',
          inputValue: groupName,
        },
        closeOnNavigation: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: RenameDialogResult) => {
        context.targetItems.forEach((targetItem: TargetItem) => {
          targetItem.target.groupName = result.name;
        });

        this.documentService.updateDocumentData(context.targetItems, true);
      });
  }
}
