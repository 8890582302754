import { Pipe, PipeTransform } from '@angular/core';
import uniqolor from 'uniqolor';
import { DEFAULT_SURVEY_COLOR, Survey } from '../models';

@Pipe({
  name: 'surveyBgColor',
})
export class SurveyBgColorPipe implements PipeTransform {
  public transform(selectedSurvey: string, surveyList: Survey[]): string {
    if (surveyList.length < 2) {
      return DEFAULT_SURVEY_COLOR;
    }
    const number = selectedSurvey.match(/\d+/g).join();
    const color = uniqolor(`${selectedSurvey}${number}`, {
      format: 'hex',
      saturation: [95, 100],
      lightness: [95, 100],
      differencePoint: parseInt(number, 10),
    }).color;

    return color;
  }
}
