import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { TargetItem } from '../models';

export interface DeleteTargetActionContext {
  targetItem: TargetItem;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteTargetAction extends ActionDefinition<DeleteTargetActionContext> {
  constructor(private documentService: DocumentService) {
    super();
  }

  public invoke(context: DeleteTargetActionContext): void {
    this.documentService.deleteDocumentData([context.targetItem], true);
  }
}
