<div>
  <cdk-virtual-scroll-viewport
    itemSize="320"
    [minBufferPx]="320 * 3"
    [maxBufferPx]="320 * 5"
    class="separated-rank-report-container"
  >
    <div
      class="single-report-container"
      *ngFor="let singleTargetTableData of separatedRankData; let iCol = index"
    >
      <app-single-target-table
        [singleTableData]="singleTargetTableData.tableData"
        [targetData]="singleTargetTableData.targetData"
        [displayedColumns]="displayedColumns"
        [dataItemMap]="dataItemMap"
        [decimalPoints]="decimalPoints"
        [reportUnits]="reportUnits"
        [stabilityFlagStatus]="stabilityFlagStatus"
        [cellStyleStatus]="cellStyleStatus"
        [highlightValues]="highlightValues"
        [zScoreHighlight]="zScoreHighlight"
        [cellColors]="cellColors"
        [sortSettings]="sortSettings[singleTargetTableData.columnId]"
        [heatmapIndexPercentage]="heatmapIndexPercentage"
        [iCol]="iCol"
        [columnHeaderFilters]="columnHeaderFilters"
        (sortChange)="onSortChange($event)"
        (filterChange)="onFilterChange($event)"
        (removeFilter)="onRemoveFilter($event)"
      ></app-single-target-table>
    </div>
  </cdk-virtual-scroll-viewport>

  <div
    class="spinner-container-overlay"
    *ngIf="isLoading"
    data-qa="rank-loading"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>
