import { Injectable } from '@angular/core';
import {
  CodingData,
  CodingDataMap,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItemType,
  Survey,
} from '../models';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CodingDataMapService {
  constructor(private decimalPipe: DecimalPipe) {}

  public formatCodingDataMap(
    activeSurvey: Survey,
    data: CrossTabTableData[]
  ): CodingDataMap {
    const map: CodingDataMap = {};
    data.forEach((row: CrossTabTableData) => {
      row.data.forEach((column: CrossTabTableDataCell) => {
        if (column.surveyCode !== activeSurvey.code) {
          return;
        }
        let targetId = null;
        if (row.isTotalRow && column.columnTarget) {
          targetId = column.columnTarget.id;
        }

        if (column.isTotalsColumn && column.rowTarget) {
          targetId = column.rowTarget.id;
        }

        if (targetId) {
          map[targetId] = this.formatCodingData(column);
        }
      });
    });
    return map;
  }

  private formatCodingData(cell: CrossTabTableDataCell): CodingData {
    return {
      resps: this.formatCodingDataValue(cell, 'sample', DataItemType.sample),
      population: this.formatCodingDataValue(
        cell,
        'projected',
        DataItemType.audience
      ),
    };
  }

  private formatCodingDataValue(
    cell: CrossTabTableDataCell,
    key: string,
    dataItem: DataItemType
  ): string {
    return cell[key] !== null && cell[key] !== undefined
      ? this.decimalPipe.transform(
          cell[key].toFixed(DATA_ITEMS_MAP[dataItem].decimalPoints),
          DATA_ITEMS_MAP[dataItem].digitsInfo
        )
      : '';
  }
}
