<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Filter"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      *ngIf="targetColumns.length > 0"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
        data-qa="filter-column-select"
      >
        <mat-label>Filter column</mat-label>
        <mat-select
          [(ngModel)]="columnId"
          (ngModelChange)="onColumnIdChange()"
        >
          <mat-option
            *ngFor="let targetColumn of targetColumns"
            [value]="targetColumn.value"
            data-qa="filter-column-select-option"
          >
            {{ targetColumn.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="10px"
    >
      <div
        fxLayout="column"
        class="filter-form-fields-container"
      >
        <div
          *ngFor="let filter of filters; let i = index"
          fxLayout="row"
          fxFlex="100"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <ng-container *ngIf="filters.length > 1">
            <mat-icon
              class="tiny-icon remove-filter-icon"
              [class.is-invisible]="i === 0"
              (click)="onFilterRemoveClick(i)"
              >cancel</mat-icon
            >

            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
              class="input-form-field-without-label filter-operator-field"
              [class.is-invisible]="i === 0"
            >
              <mat-select [(ngModel)]="filter.operator">
                <mat-option value="AND">AND</mat-option>
                <mat-option value="OR">OR</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label"
            data-qa="filter-data-item-select"
          >
            <mat-select [(ngModel)]="filter.dataItem">
              <mat-option
                *ngFor="let dataItem of dataItems"
                [value]="dataItem.id"
                data-qa="filter-data-item-select-option"
              >
                <ng-container
                  *ngIf="
                    hasVolumetricCoding && dataItem.id == dataItemType.audience
                  "
                >
                  {{ dataItem.volumetricDisplayName }}/{{
                    dataItem.displayName
                  }}
                </ng-container>
                <ng-container
                  *ngIf="
                    !hasVolumetricCoding || dataItem.id != dataItemType.audience
                  "
                >
                  {{ dataItem.displayName }}
                </ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label"
          >
            <mat-select [(ngModel)]="filter.conditionalOperator">
              <mat-option
                *ngFor="let conditionalOperator of conditionalOperators"
                [value]="conditionalOperator"
              >
                {{ conditionalOperator }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="5px"
            class="filter-value-field-container"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
              class="input-form-field-without-label filter-value-field"
            >
              <input
                matInput
                type="number"
                min="0"
                [(ngModel)]="filter.value[0]"
                (blur)="onFilterInputBlur(filter)"
                (keydown)="onFilterInputKeydown($event)"
              />
            </mat-form-field>
            <ng-container *ngIf="filter.conditionalOperator === 'Is Between'">
              <span>To</span>
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
                class="input-form-field-without-label filter-value-field"
              >
                <input
                  matInput
                  type="number"
                  min="0"
                  [(ngModel)]="filter.value[1]"
                  (blur)="onFilterInputBlur(filter)"
                  (keydown)="onFilterInputKeydown($event)"
                />
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <button
          mat-stroked-button
          color="primary"
          appearance="small"
          tupMatButton
          [matTooltip]="'Add more filter'"
          class="add-more-filter-button"
          (click)="onFilterAddClick()"
          [disabled]="filters.length === maxFilterNumber"
        >
          <mat-icon class="tiny-icon">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
      data-qa="cancel-filter-button"
    >
      Cancel
    </button>
    <div>
      <button
        *ngIf="shouldShowClearButton"
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        (click)="onClearButtonClick()"
        data-qa="clear-filter-button"
      >
        Clear
      </button>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        cdkFocusRegionstart
        (click)="onApplyButtonClick()"
        data-qa="apply-filter-button"
      >
        Apply
      </button>
    </div>
  </mat-dialog-actions>
</div>
