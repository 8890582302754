import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RenameDialogResult {
  name: string;
}

export interface RenameDialogDataModel {
  dialogTitle: string;
  inputTitle: string;
  inputValue: string;
}

@Component({
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss'],
})
export class RenameDialogComponent implements OnInit {
  public formControl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent, RenameDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: RenameDialogDataModel
  ) {}

  public ngOnInit(): void {
    this.formControl = new FormControl(this.data.inputValue);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public saveAs(): void {
    this.dialogRef.close({ name: this.formControl.value.trim() });
  }
}
