import { Injectable } from '@angular/core';
import {
  TupCsvBuilder,
  TupCsvExporterService,
} from '@telmar-global/tup-document-exporter';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private exporterService: TupCsvExporterService) {}

  public async saveAs(
    builder: TupCsvBuilder,
    filename?: string
  ): Promise<void> {
    await this.exporterService.saveAs(
      this.exporterService.build(builder),
      filename
    );
  }
}
