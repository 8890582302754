import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  CodingDataMap,
  CodingGridTableRow,
  DEFAULT_CODING_GRID_COLUMNS,
  READONLY_CODING_GRID_COLUMNS,
  SendCodingGridTableRow,
} from '../../models/coding-grid.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CtrlShiftKeyStates } from 'src/app/directives';
import { MatMenuTrigger } from '@angular/material/menu';
import { ALL_RESPONDENTS_CODING, TargetType } from 'src/app/models';
import {
  COMBINE_BUTTON_ACTION_ITEMS,
  CombineActionItem,
  MoveToActionItem,
  SEND_TO_ACTION_ITEMS,
} from '../../models/action.model';
import { CrosstabService } from '../../services';

type RowId = number;
type IsSelected = boolean;

@Component({
  selector: 'app-coding-grid-table',
  templateUrl: './coding-grid-table.component.html',
  styleUrls: ['./coding-grid-table.component.scss'],
})
export class CodingGridTableComponent implements OnChanges, OnInit, OnDestroy {
  public readonly targetType: typeof TargetType = TargetType;
  public displayedColumns: string[] = DEFAULT_CODING_GRID_COLUMNS;

  @Input() isReadonly = true;
  @Input() tableTargetType: TargetType;
  @Input() data: CodingGridTableRow[];
  @Input() isDeletable: boolean;
  @Input() isProgressing: boolean;

  public dataSource: MatTableDataSource<CodingGridTableRow> =
    new MatTableDataSource([]);

  private numberOfValidRows: number;
  private shiftPressed: boolean;
  private lastSelectedRowId: RowId;
  private rowIds: RowId[] = [];
  public selectedRowIds: Record<RowId, IsSelected> = {};
  public ALL_RESPONDENTS_CODING = ALL_RESPONDENTS_CODING;

  public selectedRows: CodingGridTableRow[] = [];

  public combineActionItems = COMBINE_BUTTON_ACTION_ITEMS;

  public contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('gridContextMenuTrigger') private gridContextMenu: MatMenuTrigger;

  @Output() selectedRowsChange = new EventEmitter<CodingGridTableRow[]>();
  @Output() clickedRow = new EventEmitter<CodingGridTableRow>();
  @Output() deleteClicked = new EventEmitter<CodingGridTableRow>();
  @Output() editClicked = new EventEmitter<CodingGridTableRow>();
  @Output() combineClicked = new EventEmitter<CombineActionItem>();
  @Output() separateCountClicked = new EventEmitter<CodingGridTableRow>();
  @Output() sendToClicked = new EventEmitter<SendCodingGridTableRow>();
  @Output() assignGroupNameClicked = new EventEmitter<CodingGridTableRow>();
  @Output() duplicateClicked = new EventEmitter<CodingGridTableRow>();
  @Output() nTileSettingsClicked = new EventEmitter<CodingGridTableRow>();

  public sendToActionItems = SEND_TO_ACTION_ITEMS;

  public codingDataMap: CodingDataMap;

  constructor(private crosstabService: CrosstabService) {}

  ngOnInit(): void {
    this.crosstabService.codingDataMap$.subscribe((map: CodingDataMap) => {
      this.codingDataMap = map;
    });

    this.displayedColumns = this.isReadonly
      ? READONLY_CODING_GRID_COLUMNS
      : DEFAULT_CODING_GRID_COLUMNS;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.populateTableData(this.data);
      this.updateRowIds(this.data);
      this.updateSelectedRowsAndIds();
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
  }

  public isAllSelected() {
    return (
      this.selectedRows.length &&
      this.selectedRows.length === this.numberOfValidRows
    );
  }

  public onAllRowsSelectedChange(event: MatCheckboxChange): void {
    this.setAllRowsSelected(event.checked);
    this.emitSelectedCodingGridTableRowChanged();
    this.lastSelectedRowId = null;
  }

  public onSingleRowSelectedChange(
    event: MatCheckboxChange,
    element: CodingGridTableRow
  ): void {
    const selectedRowId = element.id;

    if (this.shiftPressed && this.lastSelectedRowId) {
      const lastSelectedRowIndex = this.rowIds.indexOf(this.lastSelectedRowId);
      const selectedRowIndex = this.rowIds.indexOf(selectedRowId);

      let lowerIndex = selectedRowIndex;
      let upperIndex = lastSelectedRowIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedRowIndex;
        upperIndex = selectedRowIndex;
      }

      for (let i = lowerIndex; i <= upperIndex; i++) {
        const id = this.rowIds[i];

        this.selectedRowIds[id] = event.checked;
      }
    } else {
      this.selectedRowIds[selectedRowId] = event.checked;
    }

    this.lastSelectedRowId = selectedRowId;
    this.emitSelectedCodingGridTableRowChanged();
  }

  public onCtrlShift(states: CtrlShiftKeyStates): void {
    this.shiftPressed = states.shiftPressed;
  }

  public resetSelectedRowIds(): void {
    this.selectedRowIds = [];
    this.emitSelectedCodingGridTableRowChanged();
  }

  public setSelectedRows(rows: CodingGridTableRow[]): void {
    rows.forEach((row: CodingGridTableRow) => {
      this.selectedRowIds[row.id] = true;
    });

    this.emitSelectedCodingGridTableRowChanged();
  }

  public onRowClicked(row: CodingGridTableRow): void {
    if (row.isEmptyRow) {
      return;
    }
    this.clickedRow.emit(row);
  }

  public onContextMenuTrigger(
    event: MouseEvent,
    row: CodingGridTableRow
  ): void {
    event.preventDefault();
    if (row.isEmptyRow) {
      return;
    }
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.gridContextMenu.openMenu();

    this.setSelectedRows([row]);
  }

  public onDeleteClick(row?: CodingGridTableRow) {
    this.deleteClicked.emit(row);
  }

  public onEditClick(row?: CodingGridTableRow) {
    this.editClicked.emit(row);
  }

  public onCombineActionClicked(actionItem: CombineActionItem): void {
    this.combineClicked.emit(actionItem);
  }

  public onSeparateCountActionClicked(row?: CodingGridTableRow): void {
    this.separateCountClicked.emit(row);
  }

  public onSendToClick(
    actionItem: MoveToActionItem,
    row?: CodingGridTableRow
  ): void {
    this.sendToClicked.emit({
      actionItem,
      row,
    });
  }

  public onNTileSettingActionClicked(row?: CodingGridTableRow): void {
    this.nTileSettingsClicked.emit(row);
  }

  public onRenameGroupName(row?: CodingGridTableRow): void {
    this.assignGroupNameClicked.emit(row);
  }

  public onDuplicateClick(row?: CodingGridTableRow): void {
    this.duplicateClicked.emit(row);
  }

  private populateTableData(data: CodingGridTableRow[]): void {
    this.dataSource.data = data;

    this.numberOfValidRows = this.data.filter(
      (row: CodingGridTableRow) => !row.isEmptyRow
    ).length;
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
  }

  private setAllRowsSelected(isSelected: boolean): void {
    this.data.forEach((row: CodingGridTableRow) => {
      if (!row.isEmptyRow) {
        this.selectedRowIds[row.id] = isSelected;
      }
    });
  }

  private emitSelectedCodingGridTableRowChanged(): void {
    this.selectedRows = this.data.filter((row) => this.selectedRowIds[row.id]);
    this.selectedRowsChange.emit(this.selectedRows);
  }

  private updateRowIds(data: CodingGridTableRow[]): void {
    this.rowIds = data.filter((row) => !row.isEmptyRow).map((row) => row.id);
  }

  private updateSelectedRowsAndIds(): void {
    if (this.lastSelectedRowId) {
      const lastSelectedRow = this.rowIds.find(
        (rowId: number) => rowId === this.lastSelectedRowId
      );
      if (!lastSelectedRow) {
        this.lastSelectedRowId = null;
      }
    }
    if (this.selectedRows.length > 0) {
      this.selectedRows = this.selectedRows.filter((row) =>
        this.rowIds.includes(row.id)
      );
    }

    if (Object.keys(this.selectedRowIds).length > 0) {
      this.selectedRowIds = Object.keys(this.selectedRowIds).reduce(
        (acc, key: string) => ({
          ...acc,
          [key]: this.rowIds.includes(Number(key))
            ? this.selectedRowIds[key]
            : false,
        }),
        {}
      );
      setTimeout(() => {
        this.emitSelectedCodingGridTableRowChanged();
      }, 0);
    }
  }
}
