import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  DisplayType,
  Operator,
  Target,
  TargetItem,
  TargetType,
} from '../../models';
import {
  CountCodingDialogComponent,
  CountCodingModel,
} from '../count-coding-dialog/count-coding-dialog.component';
import { TargetService } from '../../services/target.service';
import { TitleModeService } from '../../services/title-mode.service';
import { first } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import { TitleLevelsService } from '../../services/title-levels.service';

export interface AddCodeToMultipleDialogDataModel {
  targetType: TargetType;
  selectedNodeTargets: Target[];
  selectableTargets: Target[];
}

export interface AddCodeToMultipleDialogResult {
  formattedTarget: Target;
  targetItems: TargetItem[];
  operator: Operator;
}

export interface TargetCheckboxOption {
  targetItem: TargetItem;
  selected: boolean;
}

@Component({
  templateUrl: './add-code-to-multiple-dialog.component.html',
  styleUrls: ['./add-code-to-multiple-dialog.component.scss'],
})
export class AddCodeToMultipleDialogComponent implements OnInit {
  public titleMode: DisplayType;
  public titleLevels: number[];
  public formattedSelectedNodesTarget: Target;
  public selectedAddMode: Operator = Operator.auto;
  private prevSelectedAddMode: Operator = Operator.auto;
  public addModeOptions: Operator[] = [
    Operator.auto,
    Operator.and,
    Operator.or,
    Operator.count,
  ];
  public selectAll = false;
  public selectedTargetType = '';

  public targetOptions: TargetCheckboxOption[] = [];
  public selectedFormattedTargetTitles: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddCodeToMultipleDialogDataModel,
    public dialogRef: MatDialogRef<AddCodeToMultipleDialogComponent>,
    private targetService: TargetService,
    private targetTitlePipe: TargetTitlePipe,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.titleModeService.titleMode$
      .pipe(first())
      .subscribe((titleMode) => (this.titleMode = titleMode));
    this.titleLevelsService.titleLevels$
      .pipe(first())
      .subscribe((titleLevels: number[]) => (this.titleLevels = titleLevels));
    this.formatSelectedTargetType();
    this.populateSelectableTargetOptions();
    this.updateSelectedNodesTargetTitle();
  }

  public onSelectedAddModeChange(): void {
    if (this.selectedAddMode === Operator.count) {
      this.showCountCodingDialog();
    } else {
      this.prevSelectedAddMode = this.selectedAddMode;
      this.updateSelectedNodesTargetTitle();
    }
  }

  public onSelectAllChange(): void {
    this.targetOptions.forEach((option: TargetCheckboxOption) => {
      option.selected = this.selectAll;
    });
    this.updateSelectedTargets();
  }

  public onSelectTargetOption(): void {
    this.updateSelectedTargets();
  }

  public onAddCodeClick(): void {
    const selectedTargetItems = this.getSelectedTargetItems();
    this.dialogRef.close(
      selectedTargetItems.length > 0
        ? {
            formattedTarget: this.formattedSelectedNodesTarget,
            targetItems: selectedTargetItems,
            operator: this.getNormalisedOperator(),
          }
        : null
    );
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  private formatSelectedTargetType(): void {
    this.selectedTargetType =
      'Selected ' +
      (this.data.targetType === TargetType.columns
        ? 'columns'
        : this.data.targetType === TargetType.rows
        ? 'rows'
        : 'tables');
  }

  private populateSelectableTargetOptions(): void {
    this.targetOptions = this.data.selectableTargets.map(
      (target: Target, index: number) => ({
        targetItem: {
          type: this.data.targetType,
          target,
          index,
        },
        selected: false,
      })
    );
  }

  private showCountCodingDialog(): void {
    this.dialog
      .open(CountCodingDialogComponent, { closeOnNavigation: true })
      .afterClosed()
      .pipe()
      .subscribe((result: CountCodingModel) => {
        if (result) {
          this.updateSelectedNodesTargetTitle(result);
          this.prevSelectedAddMode = this.selectedAddMode;
        } else {
          this.selectedAddMode = this.prevSelectedAddMode;
        }
      });
  }

  private updateSelectedNodesTargetTitle(countCoding?: CountCodingModel): void {
    const groupOperator = this.getNormalisedGroupOperator();

    let targets: Target[] = [];
    if (groupOperator === Operator.auto) {
      targets = this.targetService.groupTargetsWithAutoOperator(
        this.data.selectedNodeTargets
      );
    } else {
      targets = this.targetService.groupTargets(
        this.data.selectedNodeTargets,
        groupOperator
      );
    }

    let target = targets[0];
    if (countCoding) {
      target.countCoding = countCoding;
      target = this.targetService.groupTargets(targets, Operator.and, true)[0];
    }
    this.formattedSelectedNodesTarget = target;
    this.updateSelectedTargets();
  }

  private updateSelectedTargets(): void {
    const normalisedOperator = this.getNormalisedOperator();
    const selectedTargetItems = this.getSelectedTargetItems();
    this.selectAll = selectedTargetItems.length === this.targetOptions.length;
    this.selectedFormattedTargetTitles = selectedTargetItems.map(
      (targetItem: TargetItem) => {
        const selectedTitle = this.targetTitlePipe.transform(
          targetItem.target,
          targetItem.target.activeTitleMode || this.titleMode,
          targetItem.target.titleLevels
        );
        if (targetItem.target.activeTitleMode === DisplayType.ownTitle) {
          return selectedTitle;
        }
        const targetTitle = this.targetTitlePipe.transform(
          this.formattedSelectedNodesTarget,
          targetItem.target.activeTitleMode || this.titleMode,
          targetItem.target.titleLevels
        );
        const formattedTargetTitle =
          this.data.selectedNodeTargets.length > 1 &&
          (this.selectedAddMode === Operator.count ||
            this.selectedAddMode === Operator.auto)
            ? targetTitle
            : `(${targetTitle})`;
        return `${selectedTitle} ${normalisedOperator} ${formattedTargetTitle}`;
      }
    );
  }

  private getSelectedTargetItems(): TargetItem[] {
    return this.targetOptions
      .filter((option: TargetCheckboxOption) => option.selected)
      .map((option: TargetCheckboxOption) => option.targetItem);
  }

  private getNormalisedOperator(): Operator {
    return this.selectedAddMode === Operator.or ? Operator.or : Operator.and;
  }

  private getNormalisedGroupOperator(): Operator {
    return this.selectedAddMode === Operator.count
      ? Operator.plus
      : this.selectedAddMode;
  }
}
