<div
  fxFill
  fxLayout="column"
  *ngIf="selectedSurvey"
>
  <app-top-bar
    [ichabodsBody]="ichabodsBody"
    [selectViewType]="documentViewType.data"
    [showSurveyInfo]="true"
    [ichabodsSelectedTabIndex]="selectedTab"
    [isReadonly]="isReadonly"
    (exportToXlsx)="exportToXlsx()"
    (exportToSheets)="exportToSheets()"
    (exportToCsv)="exportToCsv()"
  ></app-top-bar>

  <div
    fxFlex
    fxLayout="row grid"
    class="split-wrapper"
  >
    <ui-split
      unit="pixel"
      direction="horizontal"
      [gutterSize]="1"
      [useTransition]="true"
      (dragEnd)="onDragEnd($event)"
    >
      <ui-split-area
        [size]="splitSizes.codebook"
        [maxSize]="700"
      >
        <div
          fxLayout="column"
          class="left-panel"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <mat-form-field
              appearance="outline"
              class="survey-selection__select select-small"
              tupMatFormField
            >
              <mat-select
                #surveySelect
                [(ngModel)]="selectedSurvey"
                (ngModelChange)="onSelectedSurveyChanged($event)"
              >
                <mat-select-trigger *ngIf="surveys[0].isTrendable">
                  <span
                    class="survey-selection__color-block survey-selection__color-block--small"
                    [ngStyle]="{
                      'background-color':
                        selectedSurvey.code | surveyBgColor: surveys
                    }"
                  ></span>
                  {{ selectedSurvey.title }}
                </mat-select-trigger>
                <div
                  *ngFor="let survey of surveys"
                  class="survey-selection__option-wrapper"
                  fxLayout="row"
                >
                  <mat-option
                    [value]="survey"
                    title="{{ survey.title }}"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    [disabled]="hiddenSurveys.has(survey)"
                  >
                    <span
                      *ngIf="surveys[0].isTrendable"
                      class="survey-selection__color-block"
                      [ngStyle]="{
                        'background-color': survey.code | surveyBgColor: surveys
                      }"
                    >
                      &nbsp;
                    </span>
                    <span class="survey-selection__option-text">
                      {{ survey.title }}
                    </span>
                  </mat-option>
                  <div
                    *ngIf="selectedSurvey !== survey"
                    class="survey-selection__icon-container"
                    (click)="toggleSurveyVisibility(survey)"
                    [matTooltip]="
                      hiddenSurveys.has(survey)
                        ? 'Display survey'
                        : 'Hide survey'
                    "
                  >
                    <mat-icon
                      class="material-icons-outlined tiny-icon survey-selection__icon"
                    >
                      {{
                        hiddenSurveys.has(survey)
                          ? "visibility_off"
                          : "visibility"
                      }}
                    </mat-icon>
                  </div>
                </div>

                <div
                  *ngIf="!isReadonly"
                  class="mat-option change-surveys-option"
                  (click)="surveySelect.close(); onChangeSurveysClicked()"
                >
                  <div
                    class="survey-select-control"
                    *ngIf="!surveys[0].isTrendable"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                  >
                    <span class="mat-option-text">Change surveys</span>
                    <mat-icon class="tiny-icon change-surveys-icon"
                      >swap_horiz
                    </mat-icon>
                  </div>

                  <div
                    class="survey-select-control"
                    *ngIf="surveys[0].isTrendable"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    [matTooltip]="
                      surveys.length === maxNumberTrendSurveys
                        ? 'Change trendable surveys'
                        : 'Add a trendable survey'
                    "
                  >
                    <span class="mat-option-text">Manage surveys</span>
                    <mat-icon class="tiny-icon change-surveys-icon">
                      settings
                    </mat-icon>
                  </div>
                </div>
              </mat-select>
            </mat-form-field>

            <button
              data-tracking="add-trending-btn"
              *ngIf="surveys[0].isTrendable && !isReadonly"
              mat-mini-fab
              color="primary"
              aria-label="Manage trending surveys"
              class="survey-selection__btn"
              (click)="onChangeSurveysClicked()"
            >
              <mat-icon
                [matTooltip]="
                  surveys.length === maxNumberTrendSurveys
                    ? 'Change trendable surveys'
                    : 'Add a trendable survey'
                "
              >
                trending_up
              </mat-icon>
            </button>
            <span
              [matTooltip]="
                visibleSurveys.length < 2
                  ? 'Need to have more than one visible survey to add trending calculation'
                  : 'Add trending calculation'
              "
            >
              <button
                data-tracking="add-trending-calc-btn"
                *ngIf="surveys.length > 1"
                mat-mini-fab
                color="primary"
                aria-label="Add trending calculation"
                data-qa="trending-calculation-btn"
                class="survey-selection__btn"
                (click)="onOpenTrendingCalculation()"
                [disabled]="visibleSurveys.length < 2"
              >
                <mat-icon> calculate </mat-icon>
              </button>
            </span>
          </div>

          <app-codebook-navigation
            fxFlex
            [isReadonly]="isReadonly"
            [survey]="selectedSurvey"
            (codebookContextMenu)="onCodebookContextMenu($event)"
          >
          </app-codebook-navigation>
        </div>
      </ui-split-area>
      <div class="codebook-split-button">
        <button
          *ngIf="showExpandBtn"
          mat-stroked-button
          tupMatButton
          appearance="nano"
          color="primary"
          matTooltip="Expand codebook"
          (click)="onExpandCodebook()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <ui-split-area [size]="splitSizes.table">
        <div fxFlex>
          <mat-tab-group
            #ichabodsBody
            tupMatIchabodsBody
            color="primary"
            class="ichabods-tab-group"
            [(selectedIndex)]="selectedTab"
            (selectedTabChange)="onTabChange($event)"
          >
            <mat-tab label="Reports">
              <app-crosstab-view
                [surveys]="surveys"
                (controlClick)="onControlClick($event)"
                (targetClick)="onTargetClick($event)"
                (dropNode)="onDropNode($event)"
                [isReadonly]="isReadonly"
                [isViewActive]="selectedTab === viewType.crossTab"
              ></app-crosstab-view>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <span data-tracking="coding-grid-btn">Coding grid</span>
              </ng-template>
              <app-coding-grid-view
                (targetClick)="onTargetClick($event)"
                [isReadonly]="isReadonly"
                (ntileClick)="onNtileClick($event)"
                (dropNode)="!isReadonly && onDropNode($event)"
              ></app-coding-grid-view>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ui-split-area>
    </ui-split>
  </div>
</div>

<div
  class="codebook-context-menu__trigger"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="codebookContextMenu"
  #codebookMenuTrigger="matMenuTrigger"
></div>
<mat-menu
  #codebookContextMenu="matMenu"
  data-qa="codebook-context-menu"
>
  <ng-template
    matMenuContent
    let-node="node"
    let-path="path"
    let-level="level"
    let-selectedNodes="selectedNodes"
    let-selectionTreeNode="selectionTreeNode"
  >
    <div
      *ngIf="
        selectedNodes && selectedNodes.length > 0;
        else codebookSingleSelection
      "
      class="contextual-menu"
      data-qa="codebook-context-menu"
    >
      <div
        class="mat-menu-title"
        mat-menu-item
      >
        <b>Selection</b>
      </div>
      <mat-divider></mat-divider>
      <button
        *ngIf="!node.shouldDisableAdding"
        mat-menu-item
        (click)="addToRows(selectedNodes)"
      >
        Add selected to Rows
      </button>
      <button
        *ngIf="!node.shouldDisableAdding"
        mat-menu-item
        (click)="addToColumns(selectedNodes)"
      >
        Add selected to Columns
      </button>
      <button
        *ngIf="!node.shouldDisableAdding"
        mat-menu-item
        (click)="addToTable(selectedNodes)"
      >
        Add selected to Table
      </button>
      <button
        *ngIf="!node.shouldDisableAdding"
        mat-menu-item
        [matMenuTriggerFor]="contextSubMenu"
        [matMenuTriggerData]="{ nodes: selectedNodes }"
      >
        Add code to multiple
      </button>
      <mat-divider *ngIf="!node.coding"></mat-divider>
      <button
        *ngIf="!node.coding"
        mat-menu-item
        (click)="expandAllCodebook(node)"
      >
        Expand <b>{{ node.description }}</b>
      </button>
      <mat-divider *ngIf="node.canRefresh"></mat-divider>
      <button
        *ngIf="node.canRefresh"
        mat-menu-item
        (click)="refreshCustomNodes(selectionTreeNode)"
      >
        Refresh
      </button>
      <mat-divider *ngIf="node.canEditTitle"></mat-divider>
      <button
        *ngIf="node.canEditTitle"
        mat-menu-item
        (click)="editCustomNodeTitle(selectionTreeNode)"
      >
        Edit Title
      </button>
    </div>
    <ng-template #codebookSingleSelection>
      <div
        class="contextual-menu"
        data-qa="codebook-context-menu"
      >
        <div
          class="mat-menu-title"
          mat-menu-item
        >
          <b>{{ node.description }}</b>
        </div>
        <mat-divider></mat-divider>
        <button
          *ngIf="!node.shouldDisableAdding"
          mat-menu-item
          (click)="addToRows(path)"
        >
          Add to Rows
        </button>
        <button
          *ngIf="!node.shouldDisableAdding"
          mat-menu-item
          (click)="addToColumns(path)"
        >
          Add to Columns
        </button>
        <button
          *ngIf="!node.shouldDisableAdding"
          mat-menu-item
          (click)="addToTable(path)"
        >
          Add to Table
        </button>
        <button
          *ngIf="!node.shouldDisableAdding"
          mat-menu-item
          [matMenuTriggerFor]="contextSubMenu"
          [matMenuTriggerData]="{ nodes: path }"
        >
          Add code to multiple
        </button>
        <mat-divider *ngIf="!node.coding"></mat-divider>
        <button
          *ngIf="!node.coding"
          mat-menu-item
          (click)="expandAllCodebook(node)"
        >
          Expand
        </button>
        <mat-divider *ngIf="node.canRefresh"></mat-divider>
        <button
          *ngIf="node.canRefresh"
          mat-menu-item
          (click)="refreshCustomNodes(selectionTreeNode)"
        >
          Refresh
        </button>
        <mat-divider *ngIf="node.canEditTitle"></mat-divider>
        <button
          *ngIf="node.canEditTitle"
          mat-menu-item
          (click)="editCustomNodeTitle(selectionTreeNode)"
        >
          Edit Title
        </button>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>

<mat-menu #contextSubMenu="matMenu">
  <ng-template
    matMenuContent
    let-nodes="nodes"
  >
    <div class="contextual-submenu">
      <button
        mat-menu-item
        (click)="addToMultiple(nodes, targetType.rows)"
      >
        Rows
      </button>
      <button
        mat-menu-item
        (click)="addToMultiple(nodes, targetType.columns)"
      >
        Columns
      </button>
      <button
        mat-menu-item
        (click)="addToMultiple(nodes, targetType.tables)"
      >
        Tables
      </button>
    </div>
  </ng-template>
</mat-menu>
