import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DocumentDataState,
  Target,
  TargetType,
  CodingGridTableRow,
  TargetItem,
  Operator,
  DisplayType,
  ALL_RESPONDENTS_CODING,
  TargetAction,
  DocumentDataChange,
  SendCodingGridTableRow,
  Survey,
  SWAP_ROWS_COLUMNS_WARNING,
} from '../../models';
import { DocumentService } from '../../services';
import { Subject } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DropDataContext } from '../../pages';
import { cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import { CodingGridTableComponent } from '../coding-grid-table/coding-grid-table.component';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import {
  COMBINE_BUTTON_ACTION_ITEMS,
  CombineActionItem,
  SEND_TO_ACTION_ITEMS,
} from '../../models/action.model';
import { SwapRowsColumnsAction } from '../../actions/SwapRowsColumnsAction';
import { AssignGroupNameAction } from '../../actions/AssignGroupNameAction';
import { SeparateCountAction } from '../../actions/SeparateCountAction';
import { DuplicateTargetsAction } from '../../actions/DuplicateTargetsAction';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'app-coding-grid-view',
  templateUrl: './coding-grid-view.component.html',
  styleUrls: ['./coding-grid-view.component.scss'],
})
export class CodingGridViewComponent implements OnInit, OnDestroy {
  public readonly emptyGridRowSize = 10;

  public readonly targetType: typeof TargetType = TargetType;
  public selectedTab = this.targetType.tables;

  public tablesTabLabel = '(0)';
  public columnsTabLabel = '(0)';
  public rowsTabLabel = '(0)';

  public tables: CodingGridTableRow[] = [];
  public columns: CodingGridTableRow[] = [];
  public rows: CodingGridTableRow[] = [];

  public selectedRows: CodingGridTableRow[] = [];

  private unsubscribe: Subject<void> = new Subject<void>();

  public isDeletable = false;
  public isProgressing = false;
  @Output() dropNode = new EventEmitter<DropDataContext<Operator>>();
  @Output() targetClick: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Input() isReadonly = true;
  @Output() ntileClick: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();

  @ViewChild('tableCodingGrid')
  private tableCodingGrid: CodingGridTableComponent;
  @ViewChild('columnCodingGrid')
  private columnCodingGrid: CodingGridTableComponent;
  @ViewChild('rowCodingGrid') private rowCodingGrid: CodingGridTableComponent;

  public combineActionItems = COMBINE_BUTTON_ACTION_ITEMS;
  public sendToActionItems = SEND_TO_ACTION_ITEMS;

  public swapActionWarning = '';

  constructor(
    private documentService: DocumentService,
    private userMessageService: TupUserMessageService,
    private targetTitlePipe: TargetTitlePipe,
    private injector: Injector,
    private swapRowsColumnsActionService: SwapRowsColumnsAction
  ) {}

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
    this.listenToActiveSurveyChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onTabChange(tab: MatTabChangeEvent) {
    this.selectedTab = tab.index;
    this.resetGrids();
  }

  public onOpenCodeBuilder(row?: CodingGridTableRow): void {
    const targetItem: TargetItem = row
      ? cloneDeep(row.targetItem)
      : cloneDeep(this.selectedRows[0].targetItem);
    this.targetClick.emit(targetItem);
  }

  public onOpenNTileSettings(row?: CodingGridTableRow): void {
    const targetItem: TargetItem = row
      ? cloneDeep(row.targetItem)
      : cloneDeep(this.selectedRows[0].targetItem);
    this.ntileClick.emit(targetItem);
  }

  public onSwapRowsAndColumns(): void {
    this.swapRowsColumnsActionService.invoke();
    this.resetGrids();
  }

  public onSelectedRowsChange(rows: CodingGridTableRow[]): void {
    this.selectedRows = rows;
    this.updateTabLabels();
    this.updateDeleteAbility();
  }

  public onClickRow(row: CodingGridTableRow): void {
    const targetItem: TargetItem = cloneDeep(row.targetItem);
    this.targetClick.emit(targetItem);
  }

  public onEditTablebase(targetItem: TargetItem): void {
    this.targetClick.emit(targetItem);
  }

  public onDeleteSelectedRows(row?: CodingGridTableRow): void {
    if (this.isAllSelected()) {
      this.showDeleteMessageBeforeDelete();
    } else {
      this.deleteSelectedRows(row);
    }
  }

  public onCombineActionClicked(actionItem: CombineActionItem): void {
    this.injector.get(actionItem.action).invoke({
      targetType: this.selectedTab,
      selectedTargets: this.selectedRows.map(
        (selectedRow: CodingGridTableRow) => ({
          ...cloneDeep(selectedRow.targetItem.target.targets[0]),
          activeTitleMode: undefined,
          titleLevels: undefined,
        })
      ),
      actionItem,
    });
    this.resetGrids();
  }

  public onSeparateCountActionClicked(row?: CodingGridTableRow): void {
    this.injector.get(SeparateCountAction).invoke({
      targetType: this.selectedTab,
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onSendTo(sendRow: SendCodingGridTableRow): void {
    this.injector.get(sendRow.actionItem.action).invoke({
      actionItem: sendRow.actionItem,
      ...(sendRow.row
        ? {
            targetItem: sendRow.row.targetItem,
          }
        : {
            targetItems: this.selectedRows.map(
              (row: CodingGridTableRow) => row.targetItem
            ),
          }),
    });
  }

  public onRenameGroupName(row?: CodingGridTableRow): void {
    this.injector.get(AssignGroupNameAction).invoke({
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onDuplicateSelectedRows(row?: CodingGridTableRow): void {
    this.injector.get(DuplicateTargetsAction).invoke({
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  private resetGrids(): void {
    this.tableCodingGrid.resetSelectedRowIds();
    this.columnCodingGrid.resetSelectedRowIds();
    this.rowCodingGrid.resetSelectedRowIds();
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: DocumentDataState) => {
        this.handleDocumentStateChange(state);
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.swapActionWarning =
          survey.provider === 'YouGov' ? `: ${SWAP_ROWS_COLUMNS_WARNING}` : '';
      });
  }

  private handleDocumentStateChange({
    columns,
    rows,
    tables,
    changes,
  }: DocumentDataState): void {
    this.tables = this.formatGridData(tables, TargetType.tables);
    this.columns = this.formatGridData(columns, TargetType.columns);
    this.rows = this.formatGridData(rows, TargetType.rows);

    this.updateTabLabels();

    if (changes && changes[0].action !== TargetAction.delete) {
      this.selectUpdatedDocumentRows(changes);
    }

    this.isProgressing = false;
  }

  private selectUpdatedDocumentRows(changes: DocumentDataChange[]): void {
    const changesTargetType = changes[0].items[0].type;
    const tabRows =
      changesTargetType === this.targetType.tables
        ? this.tables
        : changesTargetType === this.targetType.columns
        ? this.columns
        : this.rows;
    const addedRows = changes[0].items
      .map((item: TargetItem) => item.index)
      .map((index: number) => tabRows[index]);

    this.selectedTab = changesTargetType;
    setTimeout(() => this.updateSelectedTableRows(addedRows), 200);
  }

  private updateSelectedTableRows(addedRows: CodingGridTableRow[]): void {
    switch (this.selectedTab) {
      case TargetType.tables:
        this.tableCodingGrid.setSelectedRows(addedRows);
        break;
      case TargetType.rows:
        this.rowCodingGrid.setSelectedRows(addedRows);
        break;
      case TargetType.columns:
        this.columnCodingGrid.setSelectedRows(addedRows);
        break;
    }
  }

  private formatGridData(
    targets: Target[],
    type: TargetType
  ): CodingGridTableRow[] {
    return [
      ...targets.map(
        (target: Target, index: number): CodingGridTableRow => ({
          id: index + 1,
          title: this.targetTitlePipe.transform(target),
          code: target.coding,
          groupName: this.targetTitlePipe.transform(target, DisplayType.group),
          selected: false,
          isEmptyRow: false,
          targetId: target.id,
          targetItem: {
            type,
            target,
            index,
          },
        })
      ),
      ...this.createEmptyGridData(targets.length),
    ];
  }

  private updateTabLabels(): void {
    const selectedRowLength = this.selectedRows.length;
    this.tablesTabLabel = `(${
      this.selectedTab === TargetType.tables ? selectedRowLength : 0
    } / ${this.tables.length - this.emptyGridRowSize})`;
    this.columnsTabLabel = `(${
      this.selectedTab === TargetType.columns ? selectedRowLength : 0
    } / ${this.columns.length - this.emptyGridRowSize})`;
    this.rowsTabLabel = `(${
      this.selectedTab === TargetType.rows ? selectedRowLength : 0
    } / ${this.rows.length - this.emptyGridRowSize})`;
  }

  private createEmptyGridData(startIndex: number): CodingGridTableRow[] {
    return Array(this.emptyGridRowSize)
      .fill(0)
      .map((v, i) => ({
        id: startIndex + i + 1,
        title: '',
        code: '',
        resps: '-',
        population: '-',
        groupName: '',
        selected: false,
        isEmptyRow: true,
      }));
  }

  private isAllSelected(): boolean {
    let isAll = false;
    let activeRowsNumber: number;
    switch (this.selectedTab) {
      case TargetType.tables:
        activeRowsNumber = this.tables.length - this.emptyGridRowSize;
        break;
      case TargetType.rows:
        activeRowsNumber = this.rows.length - this.emptyGridRowSize;
        break;
      case TargetType.columns:
        activeRowsNumber = this.columns.length - this.emptyGridRowSize;
        break;
    }
    if (this.selectedRows.length === activeRowsNumber) {
      isAll = true;
    }
    return isAll;
  }

  private showDeleteMessageBeforeDelete(): void {
    this.userMessageService
      .openDialog(
        `You selected to clear your entire grid. This action cannot be undone. Are you sure you want to delete your entire grid? `,
        'Delete',
        {
          cancelText: 'Cancel',
          confirmText: 'Delete',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.deleteSelectedRows();
      });
  }

  private deleteSelectedRows(row?: CodingGridTableRow): void {
    this.isProgressing = true;
    const selectedTargetItems: TargetItem[] = row
      ? [row.targetItem]
      : // tslint:disable-next-line:no-shadowed-variable
        this.selectedRows.map((row: CodingGridTableRow) => row.targetItem);

    this.documentService.deleteDocumentData(selectedTargetItems, true);
    this.resetGrids();
  }

  private updateDeleteAbility(): void {
    this.isDeletable =
      !this.isAllRespondentsSelected() && this.selectedRows.length > 0;
  }

  private isAllRespondentsSelected(): boolean {
    let isSelected = false;
    this.selectedRows.forEach((row) => {
      if (
        row.targetItem.type === TargetType.tables &&
        row.targetItem.target.coding === ALL_RESPONDENTS_CODING
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  }
}
