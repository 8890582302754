<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Chart settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <h2
      class="mat-h4 group-name"
      *ngIf="groupName"
    >
      Group name: {{ groupName }}
    </h2>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        *ngIf="!isReadonly"
        class="input-form-field"
      >
        <mat-label>Chart title</mat-label>
        <input
          matInput
          [(ngModel)]="chartTitle"
        />
      </mat-form-field>

      <ng-template [ngIf]="isReadonly">
        <h3 fxFlex>{{ chartTitle }}</h3>
      </ng-template>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">Chart view set up</h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Primary data-item</mat-label>
        <mat-select
          (ngModelChange)="onPrimaryDataItemChange($event)"
          [(ngModel)]="chartSettings.primaryDataItem"
        >
          <mat-option
            *ngFor="let dataItem of chartDataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Primary chart type</mat-label>
        <mat-select
          [(ngModel)]="chartSettings.primaryChartType"
          (selectionChange)="onPrimaryChartTypeChange()"
        >
          <mat-option
            *ngFor="let chartType of chartTypes"
            [value]="chartType.value"
          >
            {{ chartType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Sort column</mat-label>
        <mat-select [(ngModel)]="chartSettings.sortColumn">
          <ng-container
            *ngIf="chartSettingsMode === chartSettingsModeType.global"
          >
            <mat-option value="Primary">Primary</mat-option>
            <mat-option value="Secondary">Secondary</mat-option>
          </ng-container>
          <ng-container
            *ngIf="chartSettingsMode !== chartSettingsModeType.global"
          >
            <mat-option
              *ngFor="let item of sortColumns"
              [value]="item.id"
              title="{{ item.title }}"
              >{{ item.title }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Column sort order</mat-label>
        <mat-select [(ngModel)]="chartSettings.columnSortOrder">
          <mat-option
            *ngFor="let columnSortOrder of columnSortOrders"
            [value]="columnSortOrder"
          >
            {{ columnSortOrder }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      [ngClass]="{ 'is-disabled': !isSuitableChartForMixing }"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Secondary data-item</mat-label>
        <mat-select
          (ngModelChange)="onSecondaryDataItemChange($event)"
          [(ngModel)]="chartSettings.secondaryDataItem"
        >
          <mat-option
            [value]="0"
            *ngIf="chartSettings.primaryChartType !== 'tupScatter'"
          >
            None
          </mat-option>
          <mat-option
            *ngFor="let dataItem of chartDataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        [ngClass]="{ 'is-disabled': isSecondaryChartTypeDisabled }"
        class="input-form-field"
      >
        <mat-label>Secondary chart type</mat-label>
        <mat-select [(ngModel)]="chartSettings.secondaryChartType">
          <mat-option
            *ngFor="let chartType of secondaryChartTypes"
            [value]="chartType.value"
          >
            {{ chartType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field appearance="outline" tupMatFormField fxFlex>
        <mat-label>No. of Char in Axis label</mat-label>
        <input
          matInput
          type="number"
          min="0"
          [(ngModel)]="chartSettings.numberOfChartInAxisLabel"
          (change)="onNumberOfChartInAxisLabelChange()"
          (keyup)="onNumberOfChartInAxisLabelChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" tupMatFormField fxFlex>
        <mat-label>Axis label angle</mat-label>
        <input
          matInput
          type="number"
          [max]="maxAxisLabelAngle"
          min="0"
          [(ngModel)]="chartSettings.axisLabelAngle"
          (change)="onAxisLabelAngleChange()"
          (keyup)="onAxisLabelAngleChange()"
        />
      </mat-form-field>
    </div>-->

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="chartSettings.showChartLabel"
      >
        Show data label
      </mat-checkbox>

      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="chartSettings.showAxisLabel"
      >
        Show axis label
      </mat-checkbox>

      <mat-checkbox
        *ngIf="
          chartTargetMode === chartTargetModeType.single ||
          !chartSettings.showDataTable
        "
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        [(ngModel)]="chartSettings.showChartLegend"
        class="tiny-checkbox margin-adjustment"
        disabled="{{ chartSettings.primaryChartType === 'tupWordcloud' }}"
      >
        Show legend
      </mat-checkbox>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        fxFlex="100"
        [ngClass]="{ 'is-hidden': !shouldShowFlagRowResp }"
      >
        <mat-checkbox
          tupMatCheckbox
          appearance="small"
          color="primary"
          fxFlex
          fxGrow="1"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="chartSettings.flagRowResps"
          >Flag row resps</mat-checkbox
        >

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field-without-label flag-row-resps-input"
        >
          <input
            matInput
            type="number"
            min="0"
            [disabled]="!chartSettings.flagRowResps"
            [(ngModel)]="chartSettings.flagRowRespsValue"
            (change)="onFlagRowRespsChange()"
            (keyup)="onFlagRowRespsChange()"
          />
        </mat-form-field>
      </div>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">
      Chart data preferences
    </h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
        class="input-form-field"
      >
        <mat-label>Top rows count</mat-label>
        <input
          matInput
          type="number"
          min="1"
          [max]="chartSettings.maxRowsCount"
          [(ngModel)]="chartSettings.topRowsCount"
          (change)="onTopRowsCountChange()"
          (keyup)="onTopRowsCountChange()"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
        class="input-form-field"
      >
        <mat-label>Decimal places</mat-label>
        <input
          matInput
          type="number"
          [max]="maxDecimalPlaces"
          min="0"
          [(ngModel)]="chartSettings.decimalPlaces"
          (change)="onDecimalPlacesChange()"
          (keyup)="onDecimalPlacesChange()"
        />
      </mat-form-field>
    </div>

    <div
      *ngIf="
        targets.length > 0 &&
        chartTargetMode !== chartTargetModeType.surveysGroup
      "
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <h3 class="setting-section-title margin-adjustment">Edit series color</h3>
      <div
        fxLayout="column"
        class="edit-color-container margin-adjustment"
      >
        <div
          *ngFor="let chartColor of chartColors; let i = index"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="edit-color-row"
        >
          <h4
            class="mat-h5 target-color-title-container"
            fxLayout="row"
          >
            <span class="target-color-title">
              {{
                chartSettings.dataViewMode === dataViewModeType.dynamic
                  ? "Current dynamic target"
                  : chartColor.title
              }}
            </span>
          </h4>
          <div
            fxLayout="row"
            fxLayoutGap="10px"
          >
            <button
              *ngFor="let color of chartColor.colors; let colorIndex = index"
              mat-mini-fab
              class="color-dot"
              [ngStyle]="{ 'background-color': color.color }"
              [ngClass]="{ 'color-dot-border': color.isSelected }"
              (click)="changeColor(i, colorIndex, color.color)"
              [disableRipple]="true"
            ></button>
            <button
              mat-mini-fab
              (click)="colorPickerDialog(i)"
              class="color-dot add-color-dot"
              [ngStyle]="{ 'background-color': 'white' }"
            >
              <mat-icon class="tiny-icon">add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</div>
