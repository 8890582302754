<div
  class="table-container"
  #tableContainer
>
  <div
    class="table-title-section"
    #tableTitleSection
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <b data-qa="combined-rank-survey-title">
        {{ activeSurvey.title }}
      </b>
      <mat-chip-list class="combined-rank-filters">
        <mat-chip
          *ngFor="let filter of columnHeaderFilters"
          [disableRipple]="false"
          [matTooltip]="filter.tooltip"
          (removed)="onColumnHeaderFilterRemove(filter)"
          (click)="onColumnHeaderFilterClick(filter)"
        >
          {{
            (filter.columnTarget
              | targetTitle
                : filter.columnTarget?.activeTitleMode
                : filter.columnTarget?.titleLevels) || "Totals"
          }}
          <mat-icon
            matChipRemove
            matTooltipPosition="above"
            matTooltip="Remove column filter"
          >
            cancel
          </mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <button
      data-tracking="combined-report-filter"
      mat-mini-fab
      color="primary"
      matTooltip="Combined rank filter"
      class="combined-rank-filter-button"
      (click)="onFilterClick()"
    >
      <mat-icon> filter_list</mat-icon>
    </button>
  </div>
  <div
    class="combined-rank-report"
    [style.height.px]="scrollViewportHeight"
  >
    <cdk-virtual-scroll-viewport
      [tvsItemSize]="33"
      headerEnabled="true"
      [headerHeight]="108"
      class="virtual-scroll-viewport"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        class="combined-rank-table"
      >
        <ng-container
          matColumnDef="insight"
          sticky="true"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header insight-header"
          >
            <div class="column-title-container">
              <div class="column-title">&nbsp;</div>
              <div class="column-title-data-items">&nbsp;</div>
            </div>
            <div class="column-total-title">
              <ng-container
                *ngTemplateOutlet="rowTitle; context: { element: totalRow }"
              ></ng-container>
            </div>
          </th>
          <td
            class="insight-cell"
            mat-cell
            *matCellDef="let element"
            [matTooltip]="
              element.data[0].rowTarget
                | targetTitle
                  : element.data[0].rowTarget?.activeTitleMode
                  : element.data[0].rowTarget?.titleLevels
            "
          >
            <ng-container
              *ngTemplateOutlet="rowTitle; context: { element: element }"
            ></ng-container>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let column of targetColumns; let iCol = index"
          matColumnDef="{{ column.name }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header"
          >
            <div class="column-title-container">
              <div
                class="column-title"
                [matTooltip]="
                  column.element.columnTarget || column.element
                    | targetTitle
                      : column.element.columnTarget?.activeTitleMode
                      : column.element.columnTarget?.titleLevels
                "
              >
                {{
                  column.element.columnTarget || column.element
                    | targetTitle
                      : column.element.columnTarget?.activeTitleMode
                      : column.element.columnTarget?.titleLevels
                    | truncate: 40
                }}
                <mat-icon
                  *ngIf="columnHeaderFiltersMap.has(column.columnId)"
                  class="column-title-filter-icon"
                  >filter_alt
                </mat-icon>
              </div>
              <div class="column-title-data-items">
                <ng-container
                  *ngTemplateOutlet="dataPoints; context: { column: column }"
                ></ng-container>
              </div>
            </div>
            <div class="column-total-value">
              <ng-container
                *ngTemplateOutlet="
                  columnValue;
                  context: { element: totalRow, columnIndex: iCol }
                "
              ></ng-container>
            </div>
          </th>
          <td
            class="target-cell"
            mat-cell
            *matCellDef="let element"
            [ngStyle]="{
              'background-color': cellColors[iCol + '_' + element.rowIndex]
            }"
            [ngClass]="{
              'z-score-green':
                zScoreHighlight &&
                !element.isTotalRow &&
                !element.data[iCol].isTotalsColumn &&
                element.data[iCol][
                  dataItemsMap[dataItemType.filterZScore].cellKey
                ] !== undefined &&
                element.data[iCol][
                  dataItemsMap[dataItemType.filterZScore].cellKey
                ].toFixed(
                  decimalPoints[
                    dataItemsMap[dataItemType.filterZScore].displayName
                  ]
                ) > zScoreHighlightProb,
              'z-score-red':
                zScoreHighlight &&
                !element.isTotalRow &&
                !element.data[iCol].isTotalsColumn &&
                element.data[iCol][
                  dataItemsMap[dataItemType.filterZScore].cellKey
                ] !== undefined &&
                element.data[iCol][
                  dataItemsMap[dataItemType.filterZScore].cellKey
                ].toFixed(
                  decimalPoints[
                    dataItemsMap[dataItemType.filterZScore].displayName
                  ]
                ) < zScoreHighlightProb
            }"
          >
            <ng-container
              *ngTemplateOutlet="
                columnValue;
                context: { element: element, columnIndex: iCol }
              "
            ></ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>

  <div
    class="spinner-container-overlay"
    *ngIf="isLoading"
    data-qa="rank-loading"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<ng-template
  #rowTitle
  let-element="element"
>
  <span class="insight-cell-content">
    {{
      element.data[0].rowTarget || element.data[0]
        | targetTitle
          : element.data[0].rowTarget?.activeTitleMode
          : element.data[0].rowTarget?.titleLevels
        | truncate: 30
    }}
  </span>
</ng-template>

<ng-template
  #dataPoints
  let-column="column"
>
  <ng-container *ngFor="let dataItem of dataItems">
    <div
      class="data-item-name"
      [ngClass]="dataItem.widthType"
      [class.is-hovering]="
        column.columnId + '_' + dataItem.id === hoveringColumnDataItem
      "
      (click)="onColumnDataItemClick(column, dataItem)"
      (mouseenter)="onColumnDataItemMouseEnter(column, dataItem)"
      (mouseleave)="onColumnDataItemMouseLeave()"
    >
      <span class="data-item-label">
        <ng-container *ngIf="dataItem.id === dataItemType.audience">
          {{
            columnIdVolumetricCodingSet.has(column.columnId)
              ? dataItem.volumetricDisplayName
              : dataItem.displayName
          }}{{
            !columnIdVolumetricCodingSet.has(column.columnId)
              ? (reportUnits | formatReportUnits)
              : ""
          }}
        </ng-container>
        <ng-container *ngIf="dataItem.id !== dataItemType.audience">
          {{ dataItem.displayName }}
        </ng-container>
      </span>
      <mat-icon
        class="column-sort-icon"
        [ngClass]="{
          'is-icon-active':
            column.columnId === sortSettings.columnId &&
            dataItem.id === sortSettings.dataItem
        }"
        >{{
          !(
            column.columnId === sortSettings.columnId &&
            dataItem.id === sortSettings.dataItem
          ) || sortSettings.order === sortDirectionType.asc
            ? "arrow_upward"
            : "arrow_downward"
        }}
      </mat-icon>
    </div>
  </ng-container>
</ng-template>

<ng-template
  #columnValue
  let-element="element"
  let-columnIndex="columnIndex"
>
  <div class="data-item-container">
    <ng-container *ngFor="let dataItem of dataItems; let i = index">
      <div
        class="data-item-value"
        [ngClass]="dataItem.widthType"
        [class.is-active-sort-data-item]="
          !element.isTotalRow &&
          isSortActive &&
          sortSettings.dataItem === dataItem.id
        "
        [class.is-highlighted-data-item]="
          !element.isTotalRow &&
          !element.data[columnIndex].isTotalsColumn &&
          cellStyleStatus === 'highlight' &&
          highlightValues.dataItemId === dataItem.id
        "
      >
        <ng-container
          *ngIf="
            !element.data[columnIndex].metadata?.isVolumetricCoding ||
            (element.data[columnIndex].metadata?.isVolumetricCoding &&
              volumetricDataItemIds.includes(dataItem.id))
          "
        >
          <span
            *ngIf="stabilityFlagStatus && dataItem.id === dataItemType.audience"
            class="stability-flag"
          >
            <span
              *ngIf="
                element.data[columnIndex].sample <=
                stabilityFlagStatus.firstLevel
              "
            >
              *
            </span>
            <span
              *ngIf="
                element.data[columnIndex].sample <=
                stabilityFlagStatus.secondLevel
              "
            >
              *
            </span>
          </span>
          {{
            element.data[columnIndex][dataItemsMap[dataItem.id].cellKey] !==
              null &&
            element.data[columnIndex][dataItemsMap[dataItem.id].cellKey] !==
              undefined
              ? (element.data[columnIndex][
                  dataItemsMap[dataItem.id].cellKey
                ].toFixed(decimalPoints[dataItem.displayName])
                | number: dataItem.digitsInfo)
              : ""
          }}&nbsp;
        </ng-container>

        <ng-container
          *ngIf="
            element.data[columnIndex].metadata?.isVolumetricCoding &&
            !volumetricDataItemIds.includes(dataItem.id)
          "
        >
          <span>-</span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
