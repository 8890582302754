import { Injectable } from '@angular/core';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { MAX_COMBINED_TARGETS, Target } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CombinedCodingThresholdService {
  constructor(private messageService: TupUserMessageService) {}

  public hasMoreThanMaxCombinedTargets(targets: Target[]): boolean {
    return targets.length > MAX_COMBINED_TARGETS;
  }

  public showMaxLimitAlert(): void {
    this.messageService.openMessageDialog(
      `You can only select a maximum of ${MAX_COMBINED_TARGETS} targets for combined coding`,
      'SurveyTime'
    );
  }
}
