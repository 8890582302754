import { cloneDeep } from 'lodash';

import {
  TupChartData,
  TupXlsxChartBuilder,
  CIRCULAR_CHART_TYPES,
  UNSUPPORTED_PPTX_CHART_TYPES,
} from '@telmar-global/tup-document-exporter';

import { SurveyTimeProps } from '../models';
import { provideColorsToCharts } from '../utils/colorHelper';
import { getExportProps } from '../utils/export-utils';

export class SurveyTimeXlsxChartsBuilder extends TupXlsxChartBuilder {
  private readonly badCharsReg = /[?*:\\/\[\]]/g;
  private readonly sheetNameLength = 27;

  public async init(props: SurveyTimeProps[]): Promise<TupXlsxChartBuilder> {
    const builder: TupXlsxChartBuilder = new TupXlsxChartBuilder();

    for (const {
      settings,
      primaryData,
      secondaryData,
      charts,
      title,
      subtitle,
      source,
    } of props) {
      const {
        primaryChartType,
        primaryChartData,
        secondaryChartType,
        chartOpts,
      } = getExportProps(settings, primaryData, secondaryData);

      if (
        UNSUPPORTED_PPTX_CHART_TYPES.includes(primaryChartType) ||
        secondaryChartType
      ) {
        for (const [index, chart] of charts.toArray().entries()) {
          builder.addWorksheet(
            this.formatTitle(title),
            this.formatTitle(subtitle),
            source,
            new Date()
          );

          await builder.addImage(
            [primaryChartData[index]],
            chart.toBase64Image()
          );
        }
      } else if (CIRCULAR_CHART_TYPES.includes(primaryChartType)) {
        primaryChartData.forEach((data: TupChartData, i: number) => {
          // tslint:disable-next-line:variable-name
          const _options: { [key: string]: any } = cloneDeep(chartOpts);

          const startColor: string = !_options.chartColors[i].startsWith('#')
            ? `#${_options.chartColors[i]}`
            : _options.chartColors[i];

          _options.chartColors = provideColorsToCharts(
            startColor,
            data.values.length
          );

          builder.addWorksheet(
            this.formatTitle(title),
            this.formatTitle(subtitle),
            source,
            new Date()
          );

          builder.addCoreChart(primaryChartType, [data], _options);
        });
      } else {
        builder.addWorksheet(
          this.formatTitle(title),
          this.formatTitle(subtitle),
          source,
          new Date()
        );

        builder.addCoreChart(primaryChartType, primaryChartData, chartOpts);
      }
    }

    return builder;
  }

  private formatTitle(title: string): string {
    return title
      .replace(this.badCharsReg, '')
      .substring(0, this.sheetNameLength);
  }
}
