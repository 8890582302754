import { cloneDeep } from 'lodash';
import { TupCsvBuilder } from '@telmar-global/tup-document-exporter';
import {
  ColumnFilter,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemType,
  SortSettings,
  StabilityLevels,
  Survey,
  Target,
  TargetColumn,
  VOLUMETRIC_DATA_ITEM_IDS,
} from '../models';
import { TargetTitlePipe } from '../pipes';

export interface CrosstabTableCsvData {
  documentName: string;
  targetColumns: TargetColumn[];
  data: CrossTabTableData[];
  dataItems: DataItem[];
  reportUnits: string;
  sortSettings: SortSettings;
  filters: ColumnHeaderFilter[];
  surveys: Survey[];
}

export class CrosstabTableCsvBuilder extends TupCsvBuilder {
  constructor(private targetTitlePipe: TargetTitlePipe) {
    super();
  }

  public addTableData(data: CrosstabTableCsvData): void {
    this.addHeaderOrFooter(this.getHeaderRows(data))
      .addTableHeader(data.data)
      .addTableBody(data)
      .addHeaderOrFooter(this.getFooterRows(data));
  }

  private getHeaderRows(
    data: CrosstabTableCsvData,
    cell?: CrossTabTableDataCell
  ): string[][] {
    const rows = [
      ['Survey Time Report:', data.documentName],
      ...this.getSourceParts(data.surveys, cell?.surveyCode),
    ];
    const sortSettingsParts = this.getSortSettingsHeaderParts(
      data.sortSettings,
      data.targetColumns,
      data.surveys
    );
    if (sortSettingsParts.length > 0) {
      rows.push(...sortSettingsParts);
    }
    if (data.filters.length > 0) {
      rows.push(
        this.getColumnFiltersHeaderParts(
          data.filters,
          data.targetColumns,
          data.surveys
        )
      );
    }

    return rows;
  }

  private getFooterRows(
    data: CrosstabTableCsvData,
    cell?: CrossTabTableDataCell
  ): string[][] {
    const rows = [
      ...this.getSourceParts(data.surveys, cell?.surveyCode),
      ['Export date:', new Date().toLocaleDateString('en-GB')],
    ];

    return rows;
  }

  private getSourceParts(surveys: Survey[], surveyCode?: string): string[][] {
    const sources = this.getSources(surveys, surveyCode);
    const sourceParts = [[]];
    sources.forEach((source: string, index: number) => {
      if (index === 0) {
        sourceParts.push(['Source:', source]);
      } else {
        sourceParts.push(['', source]);
      }
    });
    return sourceParts;
  }

  private getSources(surveys: Survey[], surveyCode?: string): string[] {
    if (surveyCode) {
      const { code, title } = surveys.find(
        (survey) => survey.code === surveyCode
      );
      return [[code, title].join(' - ')];
    }

    return surveys.map(({ code, title }: Survey) => [code, title].join(' - '));
  }

  private getSortSettingsHeaderParts(
    sortSettings: SortSettings,
    targetColumns: TargetColumn[],
    surveys: Survey[]
  ): string[][] {
    if (sortSettings.columnId === '') {
      return [];
    }

    const targetColumn = targetColumns.find(
      (column: TargetColumn) => column.columnId === sortSettings.columnId
    );

    const surveyCount = surveys.length;
    const sortSurveyCode =
      sortSettings.survey || sortSettings.columnId.split('_')[1];
    const sortColumn = this.formatTitle(
      targetColumn.target,
      targetColumn.title
    );
    const sortOrder =
      sortSettings.order[0].toUpperCase() + sortSettings.order.slice(1);
    const sortDataItem = DATA_ITEMS_MAP[sortSettings.dataItem].displayName;

    const sortSettingsHeaderParts = [];
    if (surveyCount > 1) {
      sortSettingsHeaderParts.push(['Sort Survey Code:', sortSurveyCode]);
    }
    return [
      ...sortSettingsHeaderParts,
      ['Sort Column:', sortColumn],
      ['Sort Order:', sortOrder],
      ['Sort Data Item:', sortDataItem],
    ];
  }

  private getColumnFiltersHeaderParts(
    filters: ColumnHeaderFilter[],
    targetColumns: TargetColumn[],
    surveys: Survey[]
  ): string[] {
    const surveyCount = surveys.length;
    const filterParts = filters.map((columnFilter: ColumnHeaderFilter) => {
      const column = targetColumns.find(
        (targetColumn: TargetColumn) =>
          targetColumn.columnId === columnFilter.columnId
      );
      const columnTitle = this.formatTitle(column.target, column.title);
      const surveyCode =
        surveyCount > 1 ? ` (${columnFilter.columnId.split('_')[1]})` : '';
      const filterCount = columnFilter.filters.length;
      const filterConditions = columnFilter.filters.map(
        (filter: ColumnFilter, index: number) => {
          const dataItemName = DATA_ITEMS_MAP[filter.dataItem].displayName;
          const conditionalOperator = filter.conditionalOperator.toLowerCase();
          const value =
            conditionalOperator === 'is between'
              ? '(' + filter.value.join(', ') + ')'
              : filter.value[0];
          const filterOperator =
            index < filterCount - 1 ? ` ${filter.operator}` : '';
          return `${dataItemName} ${conditionalOperator} ${value}${filterOperator}`;
        }
      );

      return `${columnTitle}${surveyCode} - ${filterConditions.join(' ')}`;
    });
    return ['Filter(s):', filterParts.join(', ')];
  }

  private addHeaderOrFooter(rows: string[][]): CrosstabTableCsvBuilder {
    rows.forEach((row: string[]) => this.addLine(row));
    this.addBlankLine();
    return this;
  }

  private addTableHeader(data: CrossTabTableData[]): CrosstabTableCsvBuilder {
    let cells: string[] = [];
    const totalsColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'insight'
    );
    const targetColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'target'
    );
    const shouldAddSurveyCodeHeader = totalsColumnCells.length > 1;
    if (shouldAddSurveyCodeHeader) {
      cells.push('', '');
      const surveyCodeCells = data[0].data.map(
        (cell: CrossTabTableDataCell) =>
          `${cell.surveyCode ? cell.surveyCode : ''}`
      );
      cells.push(...surveyCodeCells);
      this.addLine(cells);
    }

    cells = ['', ''];
    cells.push(
      ...totalsColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );

    cells.push(
      ...targetColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );
    this.addLine(cells);

    return this;
  }

  private addTableBody(data: CrosstabTableCsvData): CrosstabTableCsvBuilder {
    data.data.forEach((row: CrossTabTableData) => {
      const insights: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'insight'
      );
      const targets: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'target'
      );

      const cells: CrossTabTableDataCell[] = [
        ...cloneDeep(insights),
        ...cloneDeep(targets),
      ];
      data.dataItems.forEach((dataItem: DataItem) => {
        this.addCombinedRow(data, row, dataItem, cells);
      });

      this.addBlankLine();
    });

    return this;
  }

  private addCombinedRow(
    data: CrosstabTableCsvData,
    rowData: CrossTabTableData,
    dataItem: DataItem,
    dataCells: CrossTabTableDataCell[]
  ): void {
    const hasVolumetricCoding = rowData.metadata?.hasVolumetricCoding;
    const cells: string[] = [
      this.formatTitle(rowData.data[0].rowTarget, rowData.title),
      this.formatDataItemName(dataItem, data.reportUnits, hasVolumetricCoding),
    ];
    const cellKey = dataItem.cellKey;
    const decimalPoints = dataItem.decimalPoints;

    const targetValues = dataCells.map((cell: CrossTabTableDataCell) => {
      let value =
        cell[cellKey] !== undefined && cell[cellKey] !== null
          ? `${cell[cellKey]?.toFixed(decimalPoints)}`
          : '';

      if (
        cell.metadata?.isVolumetricCoding &&
        !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
      ) {
        value = '';
      }

      return value;
    });
    cells.push(...targetValues);

    this.addLine(cells);
  }

  private formatTitle(target: Target, fallbackTitle: string): string {
    return target ? this.targetTitlePipe.transform(target) : fallbackTitle;
  }

  private formatDataItemName(
    dataItem: DataItem,
    reportUnits: string,
    hasVolumetricCoding: boolean
  ): string {
    if (hasVolumetricCoding && dataItem.id === DataItemType.audience) {
      return dataItem.volumetricDisplayName;
    }
    return (
      dataItem.displayName +
      (dataItem.id === DataItemType.audience ? reportUnits : '')
    );
  }
}
