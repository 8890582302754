<div
  class="survey-info-dialog"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    [title]="
      type === SurveyInfoType.copyright ? 'Copyright' : 'Survey information'
    "
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    *ngIf="type === SurveyInfoType.copyright"
  >
    <mat-list *ngFor="let survey of surveys">
      <mat-list-item class="survey-info__item">
        <div>
          <div
            matListItemTitle
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <span
              class="survey-info__color-block"
              [ngStyle]="{
                'background-color': survey.code | surveyBgColor: surveys
              }"
            ></span>
            <span class="survey-info__title">{{ survey.title }}:</span>
          </div>

          <div
            matListItemLine
            class="survey-info__copyright"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
          >
            <span
              >Provided by: <b>{{ survey.provider }}.</b></span
            >
            <b>{{ survey.meta["copyright-info"] || defaultCopyRight }}</b>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-content
    fxLayout="column"
    *ngIf="type === SurveyInfoType.info"
    class="survey-info__info"
  >
    <mat-accordion class="survey-info__info-accordion">
      <mat-expansion-panel
        *ngFor="let survey of surveys; let i = index"
        hideToggle
        [expanded]="i === 0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="survey-info__info-title">
            {{ survey.title }}l
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="survey-info__info-content">
          {{ survey.meta["survey-info"] || defaultSurveyInfo }}
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
</div>
