import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { concatMap, filter, map, takeUntil, tap } from 'rxjs/operators';

import { UserContainer } from '@telmar-global/tup-auth';
import {
  Empty,
  TupDocument,
  TupDocumentService,
  TupDocumentTypeId,
  TupDocumentTypes,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

import { SaveAsDialogResult } from '../../dialogs';
import { DocumentService } from '../../services';
import { SurveyTimeDocument, ViewType } from '../../models';
import { DialogService } from '../../services/dialog.service';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

@Component({
  selector: 'save-menu',
  templateUrl: './save-menu.component.html',
})
export class SaveMenuComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();

  public containerName: string;
  public currentDocument: TupDocument<SurveyTimeDocument>;
  public currentContainer: UserContainer;

  constructor(
    private router: Router,
    private userContainerService: TupUserContainerService,
    private documentService: DocumentService,
    private tupDocumentService: TupDocumentService,
    private userMessageService: TupUserMessageService,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.userContainerService.container
      .pipe(
        takeUntil(this.unsubscribe),
        isNotNullOrUndefined(),
        map((userContainer: UserContainer) => userContainer.name)
      )
      .subscribe(
        (containerName: string) => (this.containerName = containerName)
      );

    this.documentService.currentDoc
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (doc: TupDocument<SurveyTimeDocument>) => (this.currentDocument = doc)
      );
  }

  /**
   * Notifies the Component to unsubscribe from any Observables.
   */
  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Saves the current run as a new document. Navigates to the correct location.
   */
  public saveAs(): void {
    let name: string;

    this.dialogService
      .saveAs()
      .afterClosed()
      .pipe(
        filter(
          (dialogResult: SaveAsDialogResult) =>
            dialogResult && dialogResult.name && dialogResult.name !== ''
        ),
        tap((dialogResult: SaveAsDialogResult) => (name = dialogResult.name)),
        concatMap((dialogResult: SaveAsDialogResult) => {
          this.currentContainer = dialogResult.container;

          this.currentDocument.metadata.name = dialogResult.name;
          this.currentDocument.metadata.description = dialogResult.description;
          this.currentDocument.metadata.tags = dialogResult.tags;
          this.currentDocument.metadata.type = dialogResult.isTemplateDocType
            ? TupDocumentTypes[TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE]
            : TupDocumentTypes[TupDocumentTypeId.SURVEYTIME_CAMPAIGN];
          const containerName = this.currentContainer.name;

          return this.tupDocumentService.create(
            containerName,
            this.currentDocument
          );
        })
      )
      .subscribe(
        (response: HttpResponse<Empty>) => {
          this.userContainerService.setContainer(this.currentContainer);
          this.documentService.resetDocumentChanges();
          this.userMessageService.showSnackBar(
            `Saved document as "${name}"`,
            'OK'
          );

          this.router.navigate(
            [`doc/${this.getDocumentIdFromResponseHeaders(response)}/data`],
            {
              queryParams: {
                tab: ViewType.crossTab,
              },
            }
          );
        },
        (error: HttpErrorResponse) => {
          this.userMessageService.showSnackBar(
            `Error saving document as "${name}": ${error}`,
            'OK'
          );
        }
      );
  }

  private getDocumentIdFromResponseHeaders(
    response: HttpResponse<Empty>
  ): string {
    return response.headers.get('Location').split('/').pop();
  }
}
