import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  CHART_SETTING_VERSION,
  ChartFilter,
  ChartFilterOperator,
  ChartSettings,
  ChartSettingsMode,
  ChartTargetMode,
  DATA_VIEW_MODES,
  DataViewMode,
  INITIAL_CHART_COUNT,
  MultipleSurveyChartData,
} from 'src/app/models/charts.model';
import {
  DocumentDataState,
  Operator,
  Target,
} from 'src/app/models/document.model';
import { CrossTabTableData } from 'src/app/models/crosstab.model';
import { CrosstabService } from 'src/app/services/crosstab.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DocumentService } from 'src/app/services/document.service';
import {
  ChartSettingsService,
  ChartsService,
  DataItemsService,
  PptxService,
  TargetService,
  XlsxChartsService,
} from '../../services';
import { SelectMenuOption } from 'src/app/models/application.model';

import {
  ChartStatus,
  DataItem,
  DataItemId,
  DataItemType,
  ExportFileType,
  Survey,
  SurveyTimeProps,
} from 'src/app/models';
import { first, takeUntil } from 'rxjs/operators';
import { ChartComponent } from '../chart/chart.component';
import { getSurveyTimeProps } from '../../utils/export-utils';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';
import { SurveyTimeXlsxChartsBuilder } from '../../builders/surveytime-xlsx-charts.builder';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { cloneDeep, groupBy } from 'lodash';
import { DisplayType } from '@telmar-global/tup-audience-groups';
import { TargetTitlePipe } from '../../pipes';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss'],
})
export class GraphViewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() isReadonly = true;
  @Input() targetMode;
  @Input() survey: Survey;
  @Input() surveys: Survey[];
  @Input() isLoading: boolean;
  @Output() chartExportEnabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public chartList = [];

  private allCombinedChartList = [];
  public combinedChartList = [];

  private allSingleChartList = [];
  public singleChartList = [];

  public insightsGroupChartList: MultipleSurveyChartData[];
  public surveysGroupChartList: MultipleSurveyChartData[];

  public chartTargetModeType: typeof ChartTargetMode = ChartTargetMode;
  public dataViewModeType: typeof DataViewMode = DataViewMode;

  public chartFilterOperator = ChartFilterOperator;
  public dataItemType = DataItemType;

  private defaultCrossTabData: CrossTabTableData[];
  public defaultTargets: Target[];
  public defaultTargetGroupNames: string[];
  public defaultTargetGroups: Record<string, Target[]>;
  private dynamicCrossTabData: CrossTabTableData[];
  public dynamicTargets: Target[];
  public customAudienceTargets: Target[];

  public selectedSurveysInSingleTarget: string[];
  public selectedSurveysInCombinedTargets: string[];
  public selectedTargetOption: string | Target = 'all';
  public selectedCustomAudiencesOption: string | Target = 'none';
  public selectedTargetGroupTarget: Record<string, Target | 'none'>;

  private customAudiencesLoaded = false;

  public chartSettings: ChartSettings;
  public dataItemKeys: Record<DataItemId, DataItem>;
  public targetColors: Record<string, string>;

  public selectedDataViewModeInSingleTarget: DataViewMode =
    DataViewMode.default;
  public selectedDataViewModeInCombinedTargets: DataViewMode =
    DataViewMode.default;
  public dataViewModes: SelectMenuOption<DataViewMode>[] = DATA_VIEW_MODES;
  public singleChartFullWidthStatus: ChartStatus = {};
  private unloadedChartList = [];
  public chartNumber = 0;

  public isInitialising = false;
  public isLoadingDynamicData = false;

  @ViewChild('chartScrollbar') chartScrollbar: ElementRef;
  @ViewChild('viewContainer') viewContainer: ElementRef;
  @ViewChild('chartSettingsRow') chartSettingsRow: ElementRef;
  @ViewChild('additionalChartSettingsRow')
  additionalChartSettingsRow: ElementRef;
  public chartContainerHeight = 0;
  public isAdditionalChartSettingsRowExpanded = false;

  private unsubscribe: Subject<void> = new Subject<void>();
  @ViewChildren(ChartComponent) charts: QueryList<ChartComponent>;

  constructor(
    private targetTitlePipe: TargetTitlePipe,
    private documentService: DocumentService,
    private chartSettingsService: ChartSettingsService,
    private crossTabService: CrosstabService,
    private chartsService: ChartsService,
    private dialogService: DialogService,
    private dataItemsService: DataItemsService,
    private pptxService: PptxService,
    private xlsxChartsService: XlsxChartsService,
    private messageService: TupUserMessageService,
    private targetService: TargetService
  ) {}

  ngOnInit(): void {
    this.scrollToTop();
    this.listenToDataItemsChanges();
    this.listenToCrossTabDataChanges();
    this.listenToDocumentStateChanges();
    this.prepareTrendingChartData();
    this.prepareViewData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('targetMode' in changes && !changes.targetMode.firstChange) {
      this.onTargetModeChanged();
      return;
    }

    if (
      'isLoading' in changes &&
      changes.isLoading.previousValue &&
      changes.isLoading.previousValue !== changes.isLoading.currentValue
    ) {
      this.refreshView();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateChartContainerHeight();
    }, 0);
  }

  @HostListener('window:resize')
  public onResize() {
    this.updateChartContainerHeight();
  }

  public loadMoreCharts(): void {
    if (!this.canLoadMoreCharts()) {
      return;
    }
    this.updateChartList();
  }

  // tslint:disable-next-line:no-shadowed-variable
  public onFilterRemove(filter: ChartFilter): void {
    filter.dataItem = DataItemType.none;
    filter.operator = ChartFilterOperator.none;
    filter.value[0] = 0;
    filter.value[1] = 0;
    this.chartSettingsService.saveChartSettings(this.chartSettings);
    this.refreshView();
  }

  public openChartFilter(): void {
    this.dialogService
      .chartFilters(ChartSettingsMode.global, this.chartSettings, '600px')
      .afterClosed()
      .subscribe((chartSettings: ChartSettings | null) => {
        if (chartSettings) {
          this.chartSettingsService.saveChartSettings(chartSettings);
          this.refreshView();
        }
      });
  }

  public openChartSettings(): void {
    this.dialogService
      .chartSettings(
        ChartSettingsMode.global,
        this.chartSettings,
        this.targetColors,
        this.getActiveChartTargets(),
        null,
        null,
        null,
        this.isReadonly
      )
      .afterClosed()
      .subscribe((settings: ChartSettings | null) => {
        if (settings) {
          this.chartSettingsService.saveChartSettings(settings);
          this.refreshView();
        }
      });
  }

  public onTargetModeChanged(): void {
    this.scrollToTop();
    if (
      this.targetMode === ChartTargetMode.single ||
      this.targetMode === ChartTargetMode.combined
    ) {
      this.prepareViewData();
    } else {
      this.initChartSettings();
      this.initialiseChartList();
    }
    setTimeout(() => {
      this.updateChartContainerHeight();
    }, 0);
  }

  public onDataViewModeInSingleTargetChanged(): void {
    if (this.selectedDataViewModeInSingleTarget !== DataViewMode.dynamic) {
      this.prepareViewData();
    } else {
      this.initialiseCustomAudiences();
    }
  }

  public onGroupTargetChanged(): void {
    this.prepareDynamicData();
  }

  public onSingleTargetChanged(): void {
    this.scrollToTop();
    this.prepareSingleChartList();
    this.initialiseChartList();
  }

  public onCustomAudienceTargetChanged(): void {
    this.scrollToTop();
    this.prepareDynamicData();
  }

  public onSelectedSurveysInCombinedTargetChanged(): void {
    this.scrollToTop();
    this.prepareCombinedChartList();
    this.initialiseChartList();
  }

  public refreshView(): void {
    // todo: to be improved, we need to sort out individual chart update issue in the lib
    this.ngOnDestroy();
    this.ngOnInit();
  }

  public refreshGlobalSettings(): void {
    this.initChartSettings();
  }

  public increaseWidthChartContainer($event, chart): void {
    this.singleChartFullWidthStatus[`${chart.target.id}_${chart.title}`] =
      $event;
  }

  public async exportAs(type: ExportFileType): Promise<void> {
    while (this.canLoadMoreCharts()) {
      this.updateChartList();
    }
    setTimeout(() => {
      const allChartProps = this.getSlideProps();
      let chartToExportProps = allChartProps.filter(
        (prop: SurveyTimeProps) => prop.selected
      );
      if (chartToExportProps.length === 0) {
        chartToExportProps = allChartProps;
      }
      this.exportCharts(type, chartToExportProps);
    }, 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private prepareViewData(): void {
    try {
      this.isInitialising = true;
      this.initChartSettings();
      this.prepareChartData();
      this.initialiseChartList();
      this.isInitialising = false;
      setTimeout(() => {
        this.updateChartContainerHeight();
      }, 0);
    } catch (error) {}
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.crossTabData$
      .pipe(takeUntil(this.unsubscribe), first())
      .subscribe((data: CrossTabTableData[]) => {
        this.defaultCrossTabData = data;
      });
  }

  private listenToDocumentStateChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe), first())
      .subscribe(({ columns }: DocumentDataState) => {
        this.defaultTargets = columns;
        // to ensure that when restoring doc, the selected target instance will get updated
        this.selectedTargetOption =
          this.selectedTargetOption !== 'all'
            ? this.defaultTargets.find(
                (target: Target) =>
                  target.id === (this.selectedTargetOption as Target).id
              )
            : this.selectedTargetOption;
        this.setupTargetGroups();
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private setupTargetGroups(): void {
    this.defaultTargetGroups = groupBy(this.defaultTargets, (target) =>
      this.targetTitlePipe.transform(target, DisplayType.group)
    );
    this.defaultTargetGroupNames = Object.keys(this.defaultTargetGroups);
    this.selectedTargetGroupTarget = this.defaultTargetGroupNames.reduce(
      (acc, groupName) => ({
        ...acc,
        [groupName]:
          !this.selectedTargetGroupTarget ||
          this.selectedTargetGroupTarget[groupName] === 'none'
            ? 'none'
            : this.defaultTargetGroups[groupName].find(
                (target: Target) =>
                  target.id ===
                  (this.selectedTargetGroupTarget[groupName] as Target)?.id
              ),
      }),
      {}
    );
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.chartDataItems$
      .pipe(takeUntil(this.unsubscribe), first())
      .subscribe((value: DataItem[]) => {
        this.dataItemKeys = value.reduce(
          (acc, item: DataItem) => ({
            ...acc,
            [item.id]: item,
          }),
          {}
        );
      });
  }

  private initChartSettings(): void {
    this.chartSettings = cloneDeep(
      this.chartSettingsService.getGlobalChartSettings(
        this.surveys,
        this.dataItemKeys,
        this.targetMode,
        this.getActiveChartTargets(),
        this.isDynamicDataMode() ? DataViewMode.dynamic : DataViewMode.default
      )
    );

    const primaryDataItemId = this.chartSettings.primaryDataItem;
    const secondaryDataItemId = this.chartSettings.secondaryDataItem;

    if (!this.dataItemKeys[primaryDataItemId]) {
      const firstAvailableDataItemId: DataItemId = parseInt(
        Object.keys(this.dataItemKeys)[0],
        10
      );
      this.chartSettings.primaryDataItem = firstAvailableDataItemId;
      this.chartSettings.extraTableSettings = [firstAvailableDataItemId];
    }

    if (!this.dataItemKeys[secondaryDataItemId]) {
      this.chartSettings.secondaryDataItem = 0;
    }
  }

  private prepareTrendingChartData(): void {
    const hasMultipleSurveys = this.surveys?.length > 1;
    if (hasMultipleSurveys) {
      const { insightsGroupedData, surveysGroupedData } =
        this.chartsService.getTrendingSurveyTargetChartList(
          this.defaultCrossTabData,
          this.defaultTargets
        );
      this.insightsGroupChartList = insightsGroupedData;
      this.surveysGroupChartList = surveysGroupedData;
    }
  }

  private prepareChartData(): void {
    let crosstabData = this.defaultCrossTabData;
    let targets = this.defaultTargets;
    if (this.isDynamicDataMode()) {
      crosstabData = this.dynamicCrossTabData;
      targets = this.dynamicTargets;
    }

    this.targetColors = this.chartSettingsService.getChartSeriesColours(
      targets,
      this.isDynamicDataMode() ? DataViewMode.dynamic : DataViewMode.default
    );

    this.allCombinedChartList =
      this.chartsService.getCombinedTargetsSingleSurveyChartList(
        crosstabData,
        targets
      );

    if (this.targetMode === ChartTargetMode.combined) {
      this.prepareCombinedChartList();
    } else {
      this.allSingleChartList = this.chartsService.getSingleChartList(
        this.allCombinedChartList,
        targets
      );
      this.allSingleChartList.forEach((chart, index) => {
        const localChartSettings = this.documentService.findChartSettings(
          this.targetMode,
          `${chart.target.id}_${CHART_SETTING_VERSION}`,
          `${chart.title}_${CHART_SETTING_VERSION}`,
          this.selectedDataViewModeInSingleTarget
        );
        this.singleChartFullWidthStatus[`${chart.target.id}_${chart.title}`] =
          localChartSettings
            ? localChartSettings.showDataTable
            : this.chartSettings.showDataTable;
      });
      this.prepareSingleChartList();
    }
  }

  private prepareSingleChartList(): void {
    this.selectedSurveysInSingleTarget =
      this.selectedSurveysInSingleTarget ??
      this.surveys.map((survey: Survey) => survey.code);

    const targetOption =
      this.selectedDataViewModeInSingleTarget === DataViewMode.default
        ? this.selectedTargetOption
        : this.selectedCustomAudiencesOption;
    if (this.selectedDataViewModeInSingleTarget === DataViewMode.default) {
      this.singleChartList =
        targetOption === 'all'
          ? this.allSingleChartList
          : this.allSingleChartList.filter(
              (chart) => chart.target.id === (targetOption as Target).id
            );
    } else {
      this.singleChartList = this.allSingleChartList;
    }

    this.singleChartList = this.singleChartList.filter((chart) =>
      this.selectedSurveysInSingleTarget.includes(chart.surveyCode[0])
    );
  }

  private prepareCombinedChartList(): void {
    this.selectedSurveysInCombinedTargets =
      this.selectedSurveysInCombinedTargets ??
      this.surveys.map((survey: Survey) => survey.code);
    this.combinedChartList = this.allCombinedChartList.filter((chart) =>
      this.selectedSurveysInCombinedTargets.includes(chart.surveyCodes[0])
    );
  }

  private initialiseChartList(): void {
    const chartListData = this.getChartListData();
    this.chartNumber = chartListData.length;
    const loadedChartCount =
      this.chartNumber < INITIAL_CHART_COUNT + 1
        ? this.chartNumber
        : INITIAL_CHART_COUNT;
    this.unloadedChartList = [...chartListData.slice(loadedChartCount)];
    this.chartList = chartListData.slice(0, loadedChartCount);
    setTimeout(() => {
      this.chartExportEnabled.emit(this.chartNumber > 0);
    }, 0);
  }

  private getChartListData(): any {
    let chartList;
    switch (this.targetMode) {
      case ChartTargetMode.single:
        chartList = this.singleChartList;
        break;
      case ChartTargetMode.combined:
        chartList = this.combinedChartList;
        break;
      case ChartTargetMode.surveysGroup:
        chartList = this.surveysGroupChartList;
        break;
      case ChartTargetMode.insightsGroup:
        chartList = this.insightsGroupChartList;
        break;
    }
    return chartList;
  }

  private canLoadMoreCharts(): boolean {
    return this.chartList.length < this.chartNumber;
  }

  private updateChartList() {
    const difference = this.chartNumber - this.chartList.length;
    const add =
      difference % 2 === 0 ? (difference > 2 ? 4 : 2) : difference >= 3 ? 3 : 1;
    this.chartList.push(...this.unloadedChartList.slice(0, add));
    this.unloadedChartList = [...this.unloadedChartList.slice(add)];
  }

  private scrollToTop(): void {
    this.chartScrollbar?.nativeElement.scrollTo(0, 0);
  }

  private updateChartContainerHeight(): void {
    if (
      !this.viewContainer ||
      !this.chartSettingsRow ||
      !this.additionalChartSettingsRow
    ) {
      return;
    }
    const additionalChartSettingsRowHeight =
      this.additionalChartSettingsRow.nativeElement.offsetHeight || 0;
    this.isAdditionalChartSettingsRowExpanded =
      additionalChartSettingsRowHeight > 47; // single row height
    const newHeight =
      this.viewContainer.nativeElement.offsetHeight -
      this.chartSettingsRow.nativeElement.offsetHeight -
      additionalChartSettingsRowHeight -
      40;
    if (this.chartContainerHeight !== newHeight) {
      this.chartContainerHeight = newHeight;
    }
  }

  private getSlideProps(): SurveyTimeProps[] {
    return this.charts.map((chart: ChartComponent) =>
      getSurveyTimeProps(chart, this.documentService.activeSurvey)
    );
  }

  private async exportCharts(
    type: ExportFileType,
    props: SurveyTimeProps[]
  ): Promise<void> {
    const documentName = this.documentService.document.metadata.name;
    const builder =
      type === ExportFileType.pptx || type === ExportFileType.googleSlides
        ? new SurveyTimePptxBuilder()
        : new SurveyTimeXlsxChartsBuilder();
    const initialisedBuilder = await builder.init(props);
    switch (type) {
      case ExportFileType.pptx:
        await this.pptxService.saveAs(
          initialisedBuilder as SurveyTimePptxBuilder,
          documentName
        );
        break;
      case ExportFileType.googleSlides:
        await this.pptxService.exportToSlides(
          initialisedBuilder as SurveyTimePptxBuilder,
          documentName
        );
        break;
      case ExportFileType.xlsx:
        await this.xlsxChartsService.saveAs(
          initialisedBuilder as SurveyTimeXlsxChartsBuilder,
          documentName
        );
        break;
      case ExportFileType.googleSheets:
        await this.xlsxChartsService.exportToSheets(
          initialisedBuilder as SurveyTimeXlsxChartsBuilder,
          documentName
        );
        break;
    }
  }

  private initialiseCustomAudiences(): void {
    if (this.customAudiencesLoaded) {
      this.prepareViewData();
      this.documentService.resetRestoreState();
      return;
    }
    this.isLoadingDynamicData = true;
    this.chartsService.getDynamicAudienceTargets(this.survey).subscribe(
      (customAudiences: Target[]) => {
        this.customAudienceTargets = customAudiences;
        this.selectedCustomAudiencesOption =
          customAudiences.length > 0
            ? this.customAudienceTargets[0]
            : this.selectedCustomAudiencesOption;
        this.customAudiencesLoaded = true;
        this.prepareDynamicData();
      },
      (error) => {
        this.messageService.showSnackBar(error, 'OK', 10000);
        this.isLoadingDynamicData = false;
      }
    );
  }

  private prepareDynamicData(): void {
    const formattedTargets = this.formatDynamicTargets();
    if (formattedTargets.length < 1) {
      this.setDynamicChartData([], []);
      return;
    }
    this.isLoadingDynamicData = true;
    this.documentService.fetchDynamicCrosstabData(formattedTargets).subscribe(
      (crossTabData: CrossTabTableData[]) => {
        this.setDynamicChartData(formattedTargets, crossTabData);
      },
      (error) => {
        this.setDynamicChartData([], []);
        this.messageService.showSnackBar(error, 'OK', 10000);
      }
    );
  }

  private formatDynamicTargets(): Target[] {
    const selectedTargets = Object.keys(this.selectedTargetGroupTarget)
      .map((key: string) => this.selectedTargetGroupTarget[key])
      .filter(
        (selectedGroupTarget: Target | 'none') => selectedGroupTarget !== 'none'
      );

    let firstDynamicTarget;
    if (this.selectedCustomAudiencesOption !== 'none') {
      firstDynamicTarget = cloneDeep(
        this.selectedCustomAudiencesOption as Target
      );
    } else {
      if (selectedTargets.length < 1) {
        return [];
      }
      firstDynamicTarget = cloneDeep(selectedTargets[0]);
      selectedTargets.shift();
    }
    firstDynamicTarget.activeTitleMode =
      this.documentService.getActiveTitleMode();

    selectedTargets.forEach((selectedTarget: Target) => {
      this.targetService.addChildTarget(
        firstDynamicTarget,
        selectedTarget,
        Operator.and
      );
    });
    return [firstDynamicTarget];
  }

  private setDynamicChartData(
    targets: Target[],
    crossTabTableData: CrossTabTableData[]
  ): void {
    this.dynamicTargets = targets;
    this.dynamicCrossTabData = crossTabTableData;
    this.prepareViewData();
    this.documentService.resetRestoreState();
    this.isLoadingDynamicData = false;
  }

  private getActiveChartTargets(): Target[] {
    return this.isDynamicDataMode() ? this.dynamicTargets : this.defaultTargets;
  }

  private isDynamicDataMode(): boolean {
    return (
      this.targetMode === ChartTargetMode.single &&
      this.selectedDataViewModeInSingleTarget === DataViewMode.dynamic
    );
  }
}
