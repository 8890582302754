<div class="dialog-content-container">
  <tup-mat-title-bar
    title="Manage report items"
    class="dialog-title"
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="row"
      class="manage-report-container"
      fxLayoutAlign="space-around center"
    >
      <div
        *ngIf="tables.length > 1"
        fxFlex="33"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="target-list-header"
        >
          <h2 class="target-list-title">Current tables</h2>
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="select-all-control"
            [(ngModel)]="tablesAllSelected"
            (change)="selectedAllTables($event)"
            >Select all</mat-checkbox
          >
        </div>
        <div class="target-list">
          <div class="scroll">
            <div
              *ngFor="let table of tables"
              fxLayout="column"
              class="selectable-target-list-item"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                fxFlex
                fxLayoutAlign="start center"
                [(ngModel)]="table.selected"
                (change)="selectedSome($event, 'tables')"
                class="tiny-checkbox"
                >{{
                  table.targetItem.target
                    | targetTitle
                      : table.targetItem.target.activeTitleMode
                      : table.targetItem.target.titleLevels
                }}</mat-checkbox
              >
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          class="delete-actions"
        >
          <button
            mat-flat-button
            color="primary"
            appearance="small"
            tupMatButton
            (click)="deleteSelected('tables')"
          >
            Delete selected tables
          </button>
        </div>
      </div>

      <div [fxFlex]="tables.length > 1 ? 33 : 50">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="target-list-header"
        >
          <h2 class="target-list-title">Current rows</h2>
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="select-all-control"
            [(ngModel)]="rowsAllSelected"
            (change)="selectedAllRows($event)"
            >Select all</mat-checkbox
          >
        </div>
        <div class="target-list">
          <div class="scroll">
            <div
              *ngFor="let row of rows"
              fxLayout="column"
              class="selectable-target-list-item"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                fxFlex
                fxLayoutAlign="start center"
                [(ngModel)]="row.selected"
                (change)="selectedSome($event, 'rows')"
                class="tiny-checkbox"
                >{{
                  row.targetItem.target
                    | targetTitle
                      : row.targetItem.target.activeTitleMode
                      : row.targetItem.target.titleLevels
                }}</mat-checkbox
              >
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          class="delete-actions"
        >
          <button
            mat-flat-button
            color="primary"
            appearance="small"
            tupMatButton
            (click)="deleteSelected('rows')"
          >
            Delete selected rows
          </button>
        </div>
      </div>
      <div [fxFlex]="tables.length > 1 ? 33 : 50">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="target-list-header"
        >
          <h2 class="target-list-title">Current columns</h2>
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="select-all-control"
            [(ngModel)]="columnsAllSelected"
            (change)="selectedAllColumns($event)"
            >Select all</mat-checkbox
          >
        </div>
        <div class="target-list">
          <div class="scroll">
            <div
              *ngFor="let column of columns"
              fxLayout="column"
              class="selectable-target-list-item"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                fxFlex
                fxLayoutAlign="start center"
                [(ngModel)]="column.selected"
                (change)="selectedSome($event, 'columns')"
                class="tiny-checkbox"
                >{{
                  column.targetItem.target
                    | targetTitle
                      : column.targetItem.target.activeTitleMode
                      : column.targetItem.target.titleLevels
                }}</mat-checkbox
              >
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          class="delete-actions"
        >
          <button
            mat-flat-button
            color="primary"
            appearance="small"
            tupMatButton
            (click)="deleteSelected('columns')"
          >
            Delete selected columns
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onButtonClick(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onButtonClick(true)"
    >
      View updated crosstab
    </button>
  </mat-dialog-actions>
</div>
