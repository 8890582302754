import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DocumentsDataSource,
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventType,
  TupDocumentStorageComponent,
} from '@telmar-global/tup-document-storage';
import { SurveyTimeDocument, ViewType } from 'src/app/models';

@Component({
  selector: 'app-my-report-list',
  templateUrl: './my-report-list.component.html',
  styleUrls: ['./my-report-list.component.scss'],
})
export class MyReportListComponent implements OnInit {
  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  public dataSource: DocumentsDataSource;

  @ViewChild('myReports') public myReports: TupDocumentStorageComponent;

  @Output()
  navigateToReportsTab: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  createReport: EventEmitter<null> = new EventEmitter<null>();

  public eventType: TupDocumentEventType = TupDocumentEventType.CLICK;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      this.openExisting(event.document);
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource = this.myReports?.dataSource;
  }

  public openExisting(doc: TupDocument<unknown>): void {
    const currentDoc = Object.assign(doc) as TupDocument<SurveyTimeDocument>;
    this.router.navigate([`doc/${currentDoc.metadata.id}/data`], {
      queryParams: {
        tab: ViewType.crossTab,
      },
    });
    return;
  }
}
