import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  DataItemType,
  ReportPreference,
  SortDirection,
  SortSettings,
  TargetItem,
} from '../models';
import { SortActionItem } from '../models/action.model';
import { CrosstabService, ReportPreferencesService } from '../services';
import { cloneDeep } from 'lodash';
import { first } from 'rxjs/operators';

export interface SortColumnTargetActionContext {
  targetItem: TargetItem;
  surveyCode: string;
  actionItem: SortActionItem;
}

@Injectable({
  providedIn: 'root',
})
export class SortColumnTargetAction extends ActionDefinition<SortColumnTargetActionContext> {
  constructor(
    private crossTabService: CrosstabService,
    private reportPreferencesService: ReportPreferencesService
  ) {
    super();
  }

  public invoke(context: SortColumnTargetActionContext): void {
    if (
      context.actionItem.direction === SortDirection.none ||
      context.actionItem.dataItem === DataItemType.none
    ) {
      this.reportPreferencesService.unsortColumn();
    } else {
      this.reportPreferencesService.preference$
        .pipe(first())
        .subscribe((preference: ReportPreference) => {
          const sortSettings = cloneDeep(
            preference.sortSettings
          ) as SortSettings;
          sortSettings.columnId = this.crossTabService.formatTargetColumnId(
            context.targetItem.target,
            context.surveyCode
          );
          sortSettings.order = context.actionItem.direction;
          sortSettings.dataItem = context.actionItem.dataItem;
          sortSettings.showTopOrBottomRows = '';
          sortSettings.startingRow = 1;
          this.reportPreferencesService.sortColumn(sortSettings);
        });
    }
  }
}
