import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes a zero based index and returns the title level label text:
 *
 * 0    => 'Parent'
 * 1    => '1st child'
 * 2    => '2nd child'
 * 3    => '3rd child'
 * n    => 'nth child'
 * last => 'Last child'
 */

@Pipe({
  name: 'titleLevel',
})
export class TitleLevelPipe implements PipeTransform {
  private pluralRules: Intl.PluralRules = new Intl.PluralRules('en', {
    type: 'ordinal',
  });

  private ordinals = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  };

  public transform(value: number, isFirst: boolean, isLast: boolean): string {
    return isFirst
      ? 'Parent'
      : isLast
      ? 'Last'
      : `${value}${this.ordinals[this.pluralRules.select(value)]} child`;
  }
}
