import { Injectable } from '@angular/core';

import {
  TupXlsxChartBuilder,
  TupXlsxChartExporterService,
} from '@telmar-global/tup-document-exporter';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { DialogService } from './dialog.service';
import { GoogleDriveFileInfo } from '@telmar-global/tup-google-api';
import { RequestLoadingService } from './request-loading.service';

@Injectable({
  providedIn: 'root',
})
export class XlsxChartsService {
  constructor(
    private exporterService: TupXlsxChartExporterService,
    private dialogService: DialogService,
    private messageService: TupUserMessageService,
    private requestLoadingService: RequestLoadingService
  ) {}

  public async saveAs(
    builder: TupXlsxChartBuilder,
    filename?: string
  ): Promise<void> {
    this.exporterService.saveAs(this.exporterService.build(builder), filename);
  }

  public async exportToSheets(
    builder: TupXlsxChartBuilder,
    filename?: string
  ): Promise<void> {
    this.requestLoadingService.setLoading({
      target: 'exporting',
      isLoading: true,
    });
    this.exporterService
      .exportToSheets(this.exporterService.build(builder), filename)
      .then((fileInfo: GoogleDriveFileInfo) => {
        this.dialogService.exportedGoogleFile(fileInfo.webViewLink);
      })
      .catch((error) => {
        console.error(error);
        this.alertExportError(error);
      })
      .finally(() => {
        this.requestLoadingService.setLoading({
          target: 'exporting',
          isLoading: false,
        });
      });
  }

  private alertExportError(error: string): void {
    this.messageService.showSnackBar(error, 'OK');
  }
}
