<tup-mat-content-wrapper
  class="table-container-quick-reports"
  tupDocumentEventEmitter
  tupDocumentEventHandler
>
  <div class="table-header quick-reports-table-header">
    MY LATEST QUICK REPORTS
  </div>
  <div
    [ngClass]="
      myQuickReports.dataSource.length === 0 &&
      !myQuickReports.nameFilterService.value
        ? 'hide'
        : 'show'
    "
  >
    <tup-document-filter
      data-tracking="quick-report-search"
      formFieldAppearance="outline"
      formFieldLabel="Search quick reports"
      [filterFor]="myQuickReports"
    ></tup-document-filter>

    <tup-document-storage
      [options]="{
        columns: [
          { header: 'My quick reports' },
          {},
          {},
          { header: 'Last modified' }
        ],
        types: ['14'],
        pageSize: 5
      }"
      class="dashboard-document-storage-table"
      #myQuickReports
    ></tup-document-storage>
    <div
      class="all-reports"
      (click)="navigateToReportsTab.emit(2)"
      *ngIf="myQuickReports.dataSource.length !== 0"
    >
      See all reports ({{ myQuickReports.dataSource.length }})
    </div>
  </div>
  <div
    class="error-message"
    *ngIf="
      myQuickReports.dataSource.length === 0 &&
      !myQuickReports.nameFilterService.value
    "
  >
    <h3>
      Get started:
      <span
        (click)="createQuickReport.emit()"
        class="create-report-link"
        >Create a quick report</span
      >
    </h3>
    <p>
      Start crafting your own personalized quick reports. Once available, this
      space will display a neatly organized table of your accessible quick
      reports, making data analysis and decision-making a breeze.
    </p>
  </div>
  <div
    class="no-search-results"
    *ngIf="
      myQuickReports.dataSource.length === 0 &&
      myQuickReports.nameFilterService.value
    "
  >
    <p>No search results.</p>
  </div>
</tup-mat-content-wrapper>
