<div class="table-container">
  <mat-progress-bar
    *ngIf="isProgressing"
    color="primary"
    mode="indeterminate"
  >
  </mat-progress-bar>
  <table
    ctrlShift
    (ctrlShift)="onCtrlShift($event)"
    mat-table
    [dataSource]="dataSource"
  >
    <ng-container
      matColumnDef="selected"
      sticky
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header column-header--checkbox"
      >
        <mat-checkbox
          *ngIf="!isReadonly"
          tupMatCheckbox
          appearance="small"
          color="primary"
          [checked]="isAllSelected()"
          (change)="onAllRowsSelectedChange($event)"
          [indeterminate]="selectedRows.length > 0 && !isAllSelected()"
        ></mat-checkbox>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <mat-checkbox
          *ngIf="!element.isEmptyRow && !isReadonly"
          tupMatCheckbox
          appearance="small"
          color="primary"
          [checked]="selectedRowIds[element.id]"
          (change)="onSingleRowSelectedChange($event, element)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container
      matColumnDef="settings"
      stickyEnd
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header column-header--settings"
      ></th>
      <td
        mat-cell
        class="column-cell--setting"
        *matCellDef="let element"
      >
        <button
          *ngIf="!element.isEmptyRow"
          [matMenuTriggerFor]="settingMenu"
          mat-icon-button
          [disabled]="selectedRows.length > 0"
          color="primary"
          class="column-cell--setting-btn"
        >
          <mat-icon matTooltip="Settings">more_vert</mat-icon>
        </button>
        <mat-menu #settingMenu="matMenu">
          <div data-qa="row-setting-menu">
            <button
              mat-menu-item
              (click)="onEditClick(element)"
            >
              <mat-icon>code</mat-icon>
              <span>Edit title and code</span>
            </button>
            <button
              mat-menu-item
              [matMenuTriggerFor]="sendToMenu"
              [matMenuTriggerData]="{ row: element }"
              [disabled]="
                element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
              "
              data-qa="send-to-action"
            >
              <mat-icon class="material-symbols-outlined">send</mat-icon>
              <span>Send to</span>
            </button>
            <button
              mat-menu-item
              [disabled]="
                element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
              "
              (click)="onNTileSettingActionClicked(element)"
              data-qa="ntile-settings-action"
            >
              <mat-icon class="material-icons-outlined">grid_on</mat-icon>
              <span>NTiles</span>
            </button>
            <button
              mat-menu-item
              [disabled]="
                element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
              "
              (click)="onSeparateCountActionClicked(element)"
              data-qa="separate-count-action"
            >
              <mat-icon class="material-icons-outlined">calculate</mat-icon>
              <span>Separate count</span>
            </button>
            <button
              mat-menu-item
              [disabled]="
                element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
              "
              (click)="onDuplicateClick(element)"
              data-qa="duplicate-action"
            >
              <mat-icon class="material-symbols-outlined"
                >content_copy</mat-icon
              >
              <span>Duplicate</span>
            </button>
            <button
              mat-menu-item
              [disabled]="
                element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
              "
              (click)="onRenameGroupName(element)"
              data-qa="group-name-action"
            >
              <mat-icon class="material-symbols-outlined">group_work</mat-icon>
              <span>Assign group name</span>
            </button>
            <button
              mat-menu-item
              [disabled]="
                element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
              "
              (click)="onDeleteClick(element)"
            >
              <mat-icon class="material-icons-outlined">delete</mat-icon>
              <span>Delete</span>
            </button>
          </div>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header column-header--id"
      >
        ID
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header"
      >
        Title
      </th>
      <td
        mat-cell
        [ngClass]="{
          'coding-grid-table__row': !isReadonly && !element.isEmptyRow
        }"
        (click)="!isReadonly && onRowClicked(element)"
        [matTooltipDisabled]="isReadonly || element.isEmptyRow"
        matTooltip="Edit title in visual code builder"
        *matCellDef="let element"
      >
        {{ element.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header"
      >
        Code
      </th>
      <td
        mat-cell
        [ngClass]="{
          'coding-grid-table__row': !isReadonly && !element.isEmptyRow
        }"
        (click)="!isReadonly && onRowClicked(element)"
        [matTooltipDisabled]="isReadonly || element.isEmptyRow"
        matTooltip="Edit code in Visual code builder"
        *matCellDef="let element"
      >
        {{ element.code }}
      </td>
    </ng-container>

    <ng-container matColumnDef="resps">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header"
      >
        Resps
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{
          element.targetId && codingDataMap[element.targetId]
            ? codingDataMap[element.targetId].resps
            : ""
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="population">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header"
      >
        Population
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{
          element.targetId && codingDataMap[element.targetId]
            ? codingDataMap[element.targetId].population
            : ""
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="column-header"
      >
        Group name
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="column-cell--group-name"
      >
        {{ element.groupName }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      (contextmenu)="!isReadonly && onContextMenuTrigger($event, row)"
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        'is-selected': selectedRowIds[row.id]
      }"
    ></tr>
  </table>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  #gridContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="gridContextMenu"
></div>
<mat-menu #gridContextMenu="matMenu">
  <div data-qa="grid-context-menu">
    <button
      mat-menu-item
      (click)="onEditClick()"
      [disabled]="selectedRows.length > 1"
    >
      <mat-icon>code</mat-icon>
      <span>Edit title and code</span>
    </button>
    <button
      mat-menu-item
      [matMenuTriggerFor]="sendToMenu"
      [disabled]="!isDeletable"
      data-qa="send-to-context-action"
    >
      <mat-icon class="material-symbols-outlined">send</mat-icon>
      <span>Send to</span>
    </button>
    <button
      mat-menu-item
      [disabled]="selectedRows.length !== 1 || !isDeletable"
      (click)="onNTileSettingActionClicked()"
      data-qa="ntile-settings-action"
    >
      <mat-icon class="material-icons-outlined">grid_on</mat-icon>
      <span>NTiles</span>
    </button>
    <button
      mat-menu-item
      [disabled]="selectedRows.length < 2"
      [matMenuTriggerFor]="combineMenu"
      data-qa="combine-rows-context-action"
    >
      <mat-icon class="material-symbols-outlined">join</mat-icon>
      <span>Combine</span>
    </button>
    <button
      mat-menu-item
      (click)="onSeparateCountActionClicked()"
      [disabled]="!isDeletable"
      data-qa="separate-count-context-action"
    >
      <mat-icon class="material-symbols-outlined">calculate</mat-icon>
      <span>Separate count</span>
    </button>
    <button
      mat-menu-item
      [disabled]="!isDeletable"
      data-qa="duplicate-context-action"
      (click)="onDuplicateClick()"
    >
      <mat-icon class="material-symbols-outlined">content_copy</mat-icon>
      <span>Duplicate</span>
    </button>
    <button
      mat-menu-item
      [disabled]="!isDeletable"
      data-qa="group-name-context-action"
      (click)="onRenameGroupName()"
    >
      <mat-icon class="material-symbols-outlined">group_work</mat-icon>
      <span>Assign group name</span>
    </button>
    <button
      mat-menu-item
      (click)="onDeleteClick()"
      [disabled]="!isDeletable"
    >
      <span
        [matTooltipDisabled]="!isDeletable"
        matTooltip="Delete selected {{ selectedRows.length }} row(s)"
      >
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span>Delete</span>
      </span>
    </button>
  </div>
</mat-menu>

<mat-menu #combineMenu="matMenu">
  <div data-qa="combine-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of combineActionItems"
      [matTooltip]="action.tooltip"
      matTooltipPosition="right"
      (click)="onCombineActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #sendToMenu="matMenu">
  <ng-template
    matMenuContent
    let-row="row"
  >
    <div data-qa="send-to-action-menu">
      <ng-container *ngFor="let sendToAction of sendToActionItems">
        <button
          *ngIf="sendToAction.currentTargetType !== tableTargetType"
          mat-menu-item
          (click)="onSendToClick(sendToAction, row)"
        >
          {{ sendToAction.name }}
        </button>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>
