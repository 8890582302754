import {
  Directive,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

export interface CtrlShiftKeyStates {
  ctrlPressed: boolean;
  shiftPressed: boolean;
}

@Directive({
  selector: '[ctrlShift]',
})
export class CtrlShiftDirective implements OnInit, OnDestroy {
  private ctrlPressed = false;
  private shiftPressed = false;

  @Output('ctrlShift') ctrlShiftEvent = new EventEmitter<CtrlShiftKeyStates>();

  @HostListener('window:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    this.doEmit(event);
  }

  @HostListener('window:keyup', ['$event'])
  keyEventUp(event: KeyboardEvent) {
    this.doEmit(event);
  }

  @HostListener('window:contextmenu', ['$event'])
  onRightClick(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const closest = target.closest('app-tree-view');
    if (!target.classList.contains('cdk-overlay-backdrop') && !closest) {
      return;
    }
    if (event.altKey) {
      // for debugging
      return;
    }
    event.preventDefault();
  }

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  doEmit(event: KeyboardEvent) {
    if (
      this.ctrlPressed !== event.ctrlKey ||
      this.shiftPressed !== event.shiftKey
    ) {
      this.ctrlShiftEvent.emit({
        ctrlPressed: event.ctrlKey,
        shiftPressed: event.shiftKey,
      });
    }
    this.ctrlPressed = event.ctrlKey;
    this.shiftPressed = event.shiftKey;
  }
}
