<div class="dialog-content-container">
  <tup-mat-title-bar
    title="Data items"
    class="dialog-title"
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <tup-mat-ichabods-tabs
      [ichabodsBody]="ichabodsBody"
    ></tup-mat-ichabods-tabs>

    <mat-tab-group
      fxFlex
      #ichabodsBody
      tupMatIchabodsBody
      color="primary"
      class="ichabods-tab-group"
    >
      <mat-tab label="Selected data items">
        <div
          fxLayout="column"
          class="data-item-list"
        >
          <div class="scroll">
            <div
              *ngFor="let item of dataItems"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [(ngModel)]="item.selected"
                [disabled]="item.unchangeable"
                class="available-checkboxes"
                (change)="checked($event)"
                *ngIf="item.available"
              >
                {{ item.displayName }}
              </mat-checkbox>

              <button
                *ngIf="
                  item.available &&
                  (item.id === dataItemType.audience ||
                    item.id === dataItemType.nTileEnd ||
                    item.id === dataItemType.nTileStart)
                "
                mat-icon-button
                color="primary"
                tupMatButton
                appearance="tiny"
              >
                <mat-icon
                  [matTooltip]="item.description"
                  class="material-icons-outlined"
                  >info</mat-icon
                >
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Available data items">
        <div
          fxLayout="row"
          class="survey-list-transfer"
        >
          <div
            fxLayout="column"
            [fxFlex]="50"
          >
            <h2 class="target-list-title">Available data items</h2>
            <div class="data-item-list">
              <div
                fxLayout="column"
                class="scroll"
              >
                <div
                  *ngFor="let item of dataItems; let i = index"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                >
                  <mat-checkbox
                    tupMatCheckbox
                    appearance="small"
                    color="primary"
                    [(ngModel)]="getItemAtIndex(i).available"
                    [disabled]="getItemAtIndex(i).unchangeable"
                    (change)="checked($event)"
                    class="available-checkboxes"
                  >
                    {{ getItemAtIndex(i).displayName }}
                  </mat-checkbox>

                  <button
                    *ngIf="
                      item.id === dataItemType.audience ||
                      item.id === dataItemType.nTileEnd ||
                      item.id === dataItemType.nTileStart
                    "
                    mat-icon-button
                    color="primary"
                    tupMatButton
                    appearance="tiny"
                  >
                    <mat-icon
                      [matTooltip]="item.description"
                      class="material-icons-outlined"
                      >info</mat-icon
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            fxLayout="column"
            [fxFlex]="50"
          >
            <h2 class="target-list-title">Selected data items</h2>
            <div class="data-item-list">
              <div
                fxLayout="column"
                class="scroll"
              >
                <table
                  class="selected-target-table"
                  cdkDropList
                  (cdkDropListDropped)="drop($event)"
                >
                  <tr
                    *ngFor="let item of dataItems"
                    cdkDrag
                    fxLayout="row"
                  >
                    <td
                      *ngIf="item.available"
                      class="selected-target-list-item"
                      fxFlex="100"
                    >
                      <span
                        fxLayout="column"
                        fxFlex="90"
                        >{{ item.displayName }}</span
                      >
                      <mat-icon
                        fxLayout="column"
                        cdkDragHandle
                        fxLayoutAlign="end"
                        >menu</mat-icon
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <h5
      class="error-message"
      *ngIf="disableButton"
    >
      You must select at least one data item for your crosstab
    </h5>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <div>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        (click)="onResetDataItems()"
        data-qa="reset-data-items-button"
      >
        Reset
      </button>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        [disabled]="disableButton"
        (click)="onApplyButtonClick()"
      >
        Apply
      </button>
    </div>
  </mat-dialog-actions>
</div>
