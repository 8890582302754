import { Injectable } from '@angular/core';
import { Statement } from '../models';
import { BehaviorSubject } from 'rxjs';

export enum LoadingSource {
  unknown,
  fromDrag,
  fromMenu,
  fromButton,
}

export class LoadingNode {
  inProgress: boolean;
  origin: LoadingSource;

  constructor(
    inProgress: boolean = false,
    origin: LoadingSource = LoadingSource.unknown
  ) {
    this.inProgress = inProgress;
    this.origin = origin;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CodebookSelectionService {
  private codebookSelectedNodes: Statement[] = [];

  private selectedNodesSubject = new BehaviorSubject<Statement[]>([]);
  public selectedNodes$ = this.selectedNodesSubject.asObservable();

  private loadingSelectedNodesSubject = new BehaviorSubject<LoadingNode>(
    new LoadingNode()
  );
  public loadingSelectedNodes$ =
    this.loadingSelectedNodesSubject.asObservable();

  public setLoadingChildrenNodes(
    isLoading: boolean,
    origin: LoadingSource = LoadingSource.unknown
  ): void {
    this.loadingSelectedNodesSubject.next(new LoadingNode(isLoading, origin));
  }

  public setSelectedNodes(selectedNodes: Statement[]): void {
    this.codebookSelectedNodes = selectedNodes;
    this.selectedNodesSubject.next(this.codebookSelectedNodes);
  }

  public unselectNodes(): void {
    this.setSelectedNodes([]);
  }

  public getSelectedNodes(): Statement[] {
    return this.codebookSelectedNodes;
  }
}
