import { Pipe, PipeTransform } from '@angular/core';
import {
  ChartFilter,
  ChartFilterOperator,
  ChartSettings,
  ChartTargetMode,
  DATA_ITEMS_MAP,
  DisplayType,
  Target,
} from '../models';
import { TargetTitlePipe } from './target-title.pipe';

@Pipe({
  name: 'formatChartFilter',
})
export class FormatChartFilterPipe implements PipeTransform {
  public transform(
    filter: ChartFilter,
    targets?: Target[],
    localChartSettings?: ChartSettings,
    sortColumns?: any[]
  ): string {
    const dataItemName = DATA_ITEMS_MAP[filter.dataItem].displayName;
    const conditionalOperator = filter.operator;
    const value =
      conditionalOperator === ChartFilterOperator.between
        ? '(' + filter.value.join(', ') + ')'
        : filter.value[0];

    const condition = `${dataItemName} ${conditionalOperator} ${value}`;
    let title = null;

    if (
      targets &&
      (localChartSettings.targetMode === ChartTargetMode.single ||
        localChartSettings.targetMode === ChartTargetMode.combined)
    ) {
      const target = targets.find(
        (target: Target) => target.id === filter.target
      );
      const targetTitlePipe = new TargetTitlePipe();
      title = targetTitlePipe.transform(target, DisplayType.shortTitle);
    }
    if (
      targets &&
      (localChartSettings.targetMode === ChartTargetMode.insightsGroup ||
        localChartSettings.targetMode === ChartTargetMode.surveysGroup)
    ) {
      title = sortColumns.find((column) => column.id === filter.target).title;
    }

    return title ? `${title}: ${condition}` : condition;
  }
}
