import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';

export interface TitleLevelsDialogData {
  numberOfTitleLevels: number;
  titleLevels: number[];
}
export interface TitleLevelsDialogResult {
  titleLevels: number[];
}

@Component({
  templateUrl: './title-levels-dialog.component.html',
  styleUrls: ['./title-levels-dialog.component.scss'],
})
export class TitleLevelsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('selectionList', { static: true })
  public selectionList: MatSelectionList;

  public indices: number[];

  constructor(
    private dialogRef: MatDialogRef<TitleLevelsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TitleLevelsDialogData
  ) {}

  public ngOnInit(): void {
    this.indices = Array.from(
      Array(this.data.numberOfTitleLevels),
      (_, index: number) => index
    );
  }

  public ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.selectionList.options.forEach(
        (listOption: MatListOption, index: number) => {
          listOption.selected = this.data.titleLevels.includes(index);
        }
      );

      this.selectionList.options.last.selected =
        this.data.titleLevels.includes(-1);
    });
  }

  public ok(listOptions: MatListOption[]): void {
    this.dialogRef.close({
      titleLevels: listOptions
        .map((listOption: MatListOption) => listOption.value)
        .sort(),
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
