import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CellColors,
  CellStyleStatus,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DataItem,
  DataItemCellKey,
  DEFAULT_SORT_OPTIONS,
  HighlightValues,
  ReportMode,
  ReportPreference,
  SortSettings,
  StabilityLevels,
  TargetColumn,
  TargetId,
} from 'src/app/models';
import { combineLatest, Subject } from 'rxjs';
import {
  CrosstabService,
  DataItemsService,
  DocumentService,
  HighlightCellsService,
  RequestLoadingService,
  TargetLoading,
  XlsxService,
} from '../../services';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';
import {
  CrosstabTableXlsxBuilder,
  CrosstabTableXlsxData,
} from '../../builders/crosstab-table-xlsx.builder';
import { TargetTitlePipe } from 'src/app/pipes';
import { cloneDeep, sortBy } from 'lodash';
import { CrosstabTableCsvBuilder } from 'src/app/builders/crosstab-table-csv.builder';
import { CsvService } from 'src/app/services/csv.service';
import { FormatReportUnitsPipe } from 'src/app/pipes/format-report-units.pipe';
import {
  SortChangeData,
  SortResult,
} from './single-target-table/single-target-table.component';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { ColumnHeaderFiltersService } from 'src/app/services/column-header-filters.service';
import { AddOrEditFilterAction } from 'src/app/actions/AddOrEditFilterAction';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'app-separated-rank-report-table',
  templateUrl: './separated-rank-report-table.component.html',
  styleUrls: ['./separated-rank-report-table.component.scss'],
})
export class SeparatedRankReportTableComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['title'];
  public dataItems: DataItem[] = [];
  public dataItemMap: Record<DataItemCellKey, DataItem> = {};
  public decimalPoints: Record<string, number>;
  public crossTabTableData: CrossTabTableData[];
  public separatedRankData: {
    columnId: string;
    targetData: CrossTabTableDataCell;
    tableData: CrossTabTableDataCell[];
    columnPosition: number;
  }[] = [];
  public isLoading = false;
  public cellColors: CellColors = {};
  public highlightValues: HighlightValues;
  public heatmapIndexPercentage: number;
  public cellStyleStatus: CellStyleStatus;
  public zScoreHighlight: boolean;
  public stabilityFlagStatus: StabilityLevels;
  public reportUnits: number;
  public sortResult: SortResult = {};

  private updateCellColorsState = new Subject<CrossTabTableData[]>();
  private updateCellColorsState$ = this.updateCellColorsState.asObservable();
  private unsubscribe: Subject<void> = new Subject<void>();
  public targetColumns: TargetColumn[] = [];

  public columnHeaderFilters: ColumnHeaderFilter[] = [];
  public sortSettings: Record<TargetId, SortSettings>;

  @Input() isViewActive: boolean;
  @Input() scrollViewportHeight = 0;

  constructor(
    private requestLoadingService: RequestLoadingService,
    private colorCellsService: HighlightCellsService,
    private documentService: DocumentService,
    private crossTabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private targetTitlePipe: TargetTitlePipe,
    private csvService: CsvService,
    private formatReportUnitsPipe: FormatReportUnitsPipe,
    private reportPreferencesService: ReportPreferencesService,
    private xlsxService: XlsxService,
    private injector: Injector,
    private columnHeaderFiltersService: ColumnHeaderFiltersService
  ) {}

  ngOnInit(): void {
    this.listenToLoadingState();
    this.listenToReportUnitsChanges();
    this.listenToReportModeChanges();
    this.listenToCrossTabDataChanges();
    this.listenToReportPreferencesChanges();
    this.listenToDataItemsChanges();
    this.listenToCellColorStateChanges();
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  public exportToCsv(): void {
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);

    const rawData = cloneDeep(this.getRawTableData());
    crossTabTableCsvBuilder.addTableData({
      documentName,
      targetColumns: this.crossTabService.getTargetColumns(rawData),
      data: rawData,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: { ...cloneDeep(DEFAULT_SORT_OPTIONS) },
      filters: [],
      surveys: [this.documentService.activeSurvey],
    });

    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheets(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  public onSortChange(sortChangeData: SortChangeData): void {
    this.sortResult[sortChangeData.targetId] = sortChangeData.sortedTargetData;
  }

  public onFilterChange(columnId: string): void {
    this.injector.get(AddOrEditFilterAction).invoke({
      surveyCode: this.documentService.activeSurvey.code,
      showColumnOptions: false,
      columnId,
      filterSingleColumn: true,
    });
  }

  public onRemoveFilter(columnId: string): void {
    this.reportPreferencesService.removeColumnHeaderFilters(columnId);
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private listenToReportUnitsChanges(): void {
    this.documentService.reportUnits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((units: number) => {
        this.reportUnits = units;
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        if (mode !== ReportMode.separatedRank) {
          this.unsubscribeAll();
        }
      });
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CrossTabTableData[]) => {
        this.setupTable(data);
      });
  }

  private listenToReportPreferencesChanges(): void {
    combineLatest([
      this.reportPreferencesService.preference$,
      this.documentService.surveyStabilityLevels$,
    ])
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(
        ([preference, surveyStabilityLevels]: [
          ReportPreference,
          StabilityLevels
        ]) => {
          this.stabilityFlagStatus = preference.stabilityFlagOn
            ? surveyStabilityLevels
            : null;
          this.heatmapIndexPercentage = preference.heatmapIndexPercentage;
          this.highlightValues = preference.highlightValues;
          this.cellStyleStatus = preference.cellStyleStatus;
          this.zScoreHighlight =
            preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;
          this.updateCellColors();

          this.columnHeaderFilters = preference.filters.map(
            (columnFilter: ColumnHeaderFilter) => ({
              ...columnFilter,
              filterConditions:
                this.getColumnHeaderFilterConditions(columnFilter),
            })
          );
          this.sortSettings = (
            preference.sortSettings as SortSettings[]
          ).reduce(
            (acc, sortSettings) => ({
              ...acc,
              [sortSettings.columnId]: sortSettings,
            }),
            {}
          );
        }
      );
  }

  private getColumnHeaderFilterConditions(
    columnHeaderFilter: ColumnHeaderFilter
  ): string {
    const column = this.targetColumns.find(
      (targetColumn: TargetColumn) =>
        targetColumn.columnId === columnHeaderFilter.columnId
    );
    return this.columnHeaderFiltersService.formatFilterConditions(
      columnHeaderFilter,
      column,
      true
    );
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.actualDataItems$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.updateTableDataPointsAndCell();
      });
  }

  private listenToCellColorStateChanges(): void {
    this.updateCellColorsState$
      .pipe(takeUntil(this.unsubscribe), debounceTime(200))
      .subscribe((data: CrossTabTableData[]) => {
        this.cellColors = this.colorCellsService.updateCellColors(data);
      });
  }

  private setupTable(data: CrossTabTableData[]): void {
    this.clearCurrentData();
    this.populateTableData(cloneDeep(data));
    this.addTableColumns();
    this.updateCellColors();
    this.targetColumns = this.crossTabService.getTargetColumns(cloneDeep(data));
  }

  private populateTableData(data: CrossTabTableData[]): void {
    const separatedRankDataSet = {};
    this.crossTabTableData = data.map((crossTabData: CrossTabTableData) => {
      return {
        ...crossTabData,
        data: crossTabData.data.filter(
          (column: CrossTabTableDataCell) =>
            column.surveyCode === this.documentService.activeSurvey.code
        ),
      };
    });

    const currentColumns = this.crossTabService.getTargetColumns(
      cloneDeep(this.crossTabTableData)
    );

    currentColumns.forEach((column) => {
      separatedRankDataSet[column.columnId] = {
        columnId: column.columnId,
        targetData: column.element,
        tableData: [],
        columnPosition: column.element.columnPosition,
      };

      // tslint:disable-next-line:no-shadowed-variable
      this.crossTabTableData.forEach((data: CrossTabTableData) => {
        let tableData: CrossTabTableDataCell;
        if (column.columnId.startsWith('totals')) {
          tableData = data.data.find(
            (columnData) => columnData.type === 'insight'
          );
        } else {
          tableData = data.data.find(
            (columnData) =>
              columnData.title === column.title && columnData.type === 'target'
          );
        }
        separatedRankDataSet[column.columnId].tableData.push({
          ...tableData,
          title: data.title,
        });
      });
    });

    this.separatedRankData = sortBy(separatedRankDataSet, ['columnPosition']);
  }

  private addTableColumns(): void {
    if (this.dataItems.length > 0) {
      const dataItems = this.dataItems.map(
        (data: DataItem) => `${data.cellKey}#${data.id}`
      );
      this.displayedColumns.push(...dataItems);

      this.dataItems.forEach((dataItem: DataItem) => {
        this.dataItemMap[`${dataItem.cellKey}#${dataItem.id}`] = dataItem;
      });

      this.updateTableDataPointsAndCell();
    }
  }

  private clearCurrentData(): void {
    this.displayedColumns = ['title'];
    this.crossTabTableData = [];
    this.separatedRankData = [];
    this.dataItemMap = {};
  }

  private updateTableDataPointsAndCell(): void {
    this.dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.separatedRank
    );

    this.decimalPoints = this.dataItems.reduce(
      (prev: Record<string, number>, dataItem: DataItem) => ({
        ...prev,
        [dataItem.displayName]: dataItem.decimalPoints,
      }),
      {}
    );
  }

  private updateCellColors(): void {
    if (this.cellStyleStatus !== CellStyleStatus.none) {
      this.updateCellColorsState.next(this.crossTabTableData);
    }
  }

  private sortData(
    data: CrossTabTableData[],
    sortResult: SortResult
  ): CrossTabTableData[] {
    const sortedData: CrossTabTableData[] = cloneDeep(data);
    sortedData.forEach((insightData, insightIndex) => {
      // first row is total and will stay at the top
      if (insightIndex > 0) {
        // tslint:disable-next-line:no-shadowed-variable
        insightData.data = insightData.data.map((insightData, index) => {
          const columnId = this.crossTabService.formatTargetColumnId(
            insightData.columnTarget,
            insightData.surveyCode
          );
          let newData = { ...insightData };
          if (sortResult[columnId]) {
            newData = sortResult[columnId].sortedData[insightIndex - 1];
          }
          return newData;
        });
      }
    });

    return sortedData;
  }

  private getSortSettings(): SortSettings[] {
    const crossTabTableData = cloneDeep(this.crossTabTableData);
    const sortSettings: SortSettings[] = [];
    crossTabTableData[0].data.forEach((insightData, targetIndex) => {
      const columnId = this.crossTabService.formatTargetColumnId(
        insightData.columnTarget,
        insightData.surveyCode
      );
      if (this.sortResult[columnId]) {
        sortSettings.push(this.sortResult[columnId].sortSettings);
      } else {
        sortSettings.push({ ...cloneDeep(DEFAULT_SORT_OPTIONS) });
      }
    });
    return sortSettings;
  }

  private getXlsxExportData(): [string, CrosstabTableXlsxBuilder] {
    const rawData = cloneDeep(this.crossTabTableData);
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');

    const crossTabTableData: CrosstabTableXlsxData = {
      documentName,
      targetColumns: this.crossTabService.getTargetColumns(rawData),
      data: this.sortData(rawData, this.sortResult),
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: this.getSortSettings(),
      filters: this.columnHeaderFilters,
      surveys: [this.documentService.activeSurvey],
      surveyColors: this.crossTabService.getSurveyColors(
        rawData,
        this.documentService.surveys
      ),
      cellStyleStatus: this.cellStyleStatus,
      highlightValues: this.highlightValues,
      bins: this.colorCellsService.getBins(),
      cellColors: this.colorCellsService.updateCellColors(
        this.sortData(rawData, this.sortResult)
      ),
      heatmapIndexPercentage: this.heatmapIndexPercentage,
    };
    const entireTableRawData = cloneDeep(this.getRawTableData());
    const crossTabTableDataForEntireTable = {
      ...crossTabTableData,
      data: entireTableRawData,
      cellColors: this.colorCellsService.updateCellColors(entireTableRawData),
      filters: [],
      sortSettings: [{ ...cloneDeep(DEFAULT_SORT_OPTIONS) }],
    };
    crossTabTableBuilder.addSeparateColumnTableSheets(crossTabTableData);
    crossTabTableBuilder.addEntireTableSheet(crossTabTableDataForEntireTable);
    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private getRawTableData(): CrossTabTableData[] {
    let rawData;
    this.crossTabService.crossTabData$
      .pipe(first())
      .subscribe((data: CrossTabTableData[]) => {
        rawData = data.map((crossTabData: CrossTabTableData) => {
          return {
            ...crossTabData,
            data: crossTabData.data.filter(
              (column: CrossTabTableDataCell) =>
                column.surveyCode === this.documentService.activeSurvey.code
            ),
          };
        });
      });
    return rawData;
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
