<app-main>
  <tup-mat-content-wrapper gap="0">
    <tup-mat-content-wrapper
      class="wrapper"
      position="first"
      gap="32"
    >
      <div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div
            class="greeting-message"
            data-qa="greeting-message"
          >
            Hello {{ userName }}
          </div>

          <div class="ichabods-tabs">
            <tup-mat-ichabods-tabs
              #ichabodsTab
              #tabs
              fxFlex
              [ichabodsBody]="ichabodsBody"
            ></tup-mat-ichabods-tabs>
          </div>
        </div>
        <mat-divider></mat-divider>
        <mat-tab-group
          #ichabodsBody
          tupMatIchabodsBody
          color="primary"
          class="ichabods-tab-group"
          [selectedIndex]="selectedIndex"
          (selectedIndexChange)="changeSelectedIndex($event)"
        >
          <div>
            <mat-tab>
              <ng-template mat-tab-label
                ><span
                  (click)="setMyDrive()"
                  class="tab-nav"
                  data-tracking="dashboard-tab"
                  >Dashboard</span
                ></ng-template
              >
              <ng-template matTabContent>
                <app-dashboard-tab
                  (changeSelectedIndex)="changeSelectedIndex($event)"
                ></app-dashboard-tab>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label
                ><span
                  class="tab-nav"
                  data-tracking="my-reports-tab"
                  data-qa="my-reports-tab"
                  >My reports</span
                ></ng-template
              >
              <ng-template matTabContent>
                <app-dashboard-reports></app-dashboard-reports>
              </ng-template>
            </mat-tab>
          </div>
          <div>
            <mat-tab>
              <ng-template mat-tab-label
                ><span
                  class="tab-nav"
                  data-tracking="my-quick-reports-tab"
                  data-qa="my-quick-reports-tab"
                  >My quick reports</span
                ></ng-template
              >
              <ng-template matTabContent>
                <app-dashboard-quick-reports></app-dashboard-quick-reports>
              </ng-template>
            </mat-tab>
          </div>
        </mat-tab-group>
      </div>
    </tup-mat-content-wrapper>
    <div
      class="spinner-container-overlay"
      *ngIf="isLoading"
    >
      <div class="center-spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </tup-mat-content-wrapper>
</app-main>
