<tup-mat-content-wrapper gap="0">
  <tup-mat-content-wrapper
    position="middle"
    gap="0"
  >
    <div
      class="top-buttons-container"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        fxLayout="row"
        fxLayoutAlign=" center"
        fxLayoutGap="0.5rem"
      >
        <mat-card
          class="total-reports header-button"
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="navigateToReportsTab(1)"
        >
          <span>{{ myReports?.dataSource?.length }}</span
          >{{ myReports?.dataSource?.length === 1 ? "REPORT" : "REPORTS" }}
        </mat-card>

        <mat-card
          class="total-reports header-button"
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="navigateToReportsTab(2)"
        >
          <span class="quick-reports-number">{{
            myReports?.dataSource?.length &&
              myQuickReports?.quickReportDataSources?.length
          }}</span
          >{{
            myReports?.dataSource?.length &&
            myQuickReports?.quickReportDataSources?.length === 1
              ? "QUICK REPORT"
              : "QUICK REPORTS"
          }}
        </mat-card>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="0.5rem"
      >
        <button
          (click)="createReport()"
          data-tracking="create-crosstab-btn"
          class="create-button header-button"
          mat-flat-button
          tupMatButton
          appearance="small"
          color="primary"
        >
          <mat-icon>add_circle_outline</mat-icon>
          Start crosstabing
        </button>

        <button
          (click)="createQuickReport()"
          data-tracking="create-crosstab-btn"
          class="create-button header-button"
          mat-flat-button
          tupMatButton
          appearance="small"
          color="primary"
        >
          <mat-icon class="bar-chart-icon tiny-icon">bar_chart</mat-icon>
          Create quick report
        </button>
      </div>
    </div>
  </tup-mat-content-wrapper>
  <mat-divider class="divider-bottom"></mat-divider>
  <tup-mat-content-wrapper
    position="middle"
    gap="0"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="images-container"
      *ngIf="
        myReports?.dataSource?.length ||
        myQuickReports?.quickReportDataSources?.length
      "
    >
      <div
        class="chart-container"
        *ngFor="let quickLink of sortedQuickLinks | slice: 0:5; let i = index"
      >
        <div class="container-title">
          <h3>{{ quickLink.metadata.name }}</h3>
          <p>{{ quickLink.metadata.description }}</p>
        </div>
        <div>
          <img
            class="chart"
            src="../../../assets/images - containers on dashboard/{{ i }}.png"
          />
        </div>
        <button
          class="report-btn"
          (click)="openExisting(quickLink)"
          [ngClass]="
            quickLink.metadata.type.id ===
            TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
              ? 'quick-report-btn'
              : 'report-btn'
          "
        >
          {{
            quickLink.metadata.type.id ===
            TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
              ? "Latest quick report"
              : "Latest report"
          }}
        </button>
      </div>
    </div>
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper
    position="middle"
    gap="0"
  >
    <div
      fxLayoutGap="32"
      fxLayout="row"
    >
      <app-my-report-list
        fxFlex
        #myReports
        (navigateToReportsTab)="navigateToReportsTab(1)"
        (createQuickReport)="createReport()"
      >
      </app-my-report-list>

      <app-quick-report-list
        fxFlex
        #myQuickReports
        (navigateToReportsTab)="navigateToReportsTab(2)"
        (createQuickReport)="createQuickReport()"
      ></app-quick-report-list>
    </div>
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>
