import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-exported-google-file-dialog',
  templateUrl: './exported-google-file-dialog.component.html',
  styleUrls: ['./exported-google-file-dialog.component.scss'],
})
export class ExportedGoogleFileDialogComponent implements OnInit {
  public exportedFileLink: string;

  constructor(
    public dialogRef: MatDialogRef<ExportedGoogleFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(Window) private window: Window
  ) {
    this.exportedFileLink = data;
  }

  ngOnInit(): void {}

  onButtonClick(): void {
    if (this.exportedFileLink) {
      this.window.open(this.exportedFileLink, '_blank');
    }

    this.close();
  }

  private close(): void {
    this.dialogRef.close();
  }
}
