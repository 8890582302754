import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { ColorPickerService } from '../../services/color-picker.service';
import { CHART_COLORS } from '../../models';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { PMapsSettings } from '../../models/p-maps.model';

@Component({
  templateUrl: './p-maps-settings-dialog.component.html',
  styleUrls: ['./p-maps-settings-dialog.component.scss'],
})
export class PMapsSettingsDialogComponent implements OnInit, OnDestroy {
  public settings: PMapsSettings;
  public readonly placeholderColor = '#f65354';
  public defaultColors: string[] = CHART_COLORS.slice(0, 6);

  constructor(
    public dialogRef: MatDialogRef<PMapsSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PMapsSettings,
    private colorPicker: ColorPickerService
  ) {
    this.settings = cloneDeep(data);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public changeColor(index: number, color: string): void {
    this.settings.data[index].color = color;
  }

  public colorPickerDialog(index: number): void {
    this.colorPicker
      .chartColor({
        target: this.settings.data[index].title,
        colors: undefined,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((color: string) => {
        this.changeColor(index, color.toUpperCase());
      });
  }

  public onButtonClick(): void {
    this.dialogRef.close(this.settings);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }
}
