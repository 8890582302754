import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Statement } from 'src/app/models/codebook.model';
import {
  ContextMenuData,
  SelectionTreeFlatNode,
} from 'src/app/components/tree-view/tree.models';
import { CodebookNavigationComponent } from 'src/app/components/codebook-navigation/codebook-navigation.component';
import {
  CodebookSelectionService,
  DocumentService,
  LoadingSource,
  TargetService,
  TrendingCalculationService,
} from '../../services';
import {
  CODEBOOK_INITIAL_SIZE_IN_PIXEL,
  CODEBOOK_MIN_SIZE_IN_PIXEL,
  DocumentDataState,
  DocumentViewType,
  EMPTY_TARGET_ITEM_INDEX,
  MAX_NUMBER_TREND_SURVEYS,
  Survey,
  SurveyTimeDocument,
  Target,
  TargetItem,
  TargetType,
  ViewType,
  Operator,
} from '../../models';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { VisualCodeBuilderDialogComponent } from '../../dialogs';
import { SeparateOperatorAction } from '../../actions/SeparateOperatorAction';
import { delay, first, takeUntil } from 'rxjs/operators';
import { TupDocument } from '@telmar-global/tup-document-storage';
import {
  ChangeSurveysResult,
  DocumentAudienceGroup,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { AddToMultipleAction } from '../../actions/AddToMultipleAction';
import { getAreaSize, IAreaSize } from 'ngx-split';
import { CrosstabViewComponent } from '../../components';
import { cloneDeep, omit } from 'lodash';
import { CombinedCodingThresholdService } from 'src/app/services/combined-coding-threshold.service';
import {
  NTileDialogResult,
  NtileDialogComponent,
} from 'src/app/dialogs/ntile-dialog/ntile-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

export interface DropDataContext<T> {
  selectedNodes: Statement[];
  selectedTargets?: Target[];
  context: T;
  handleDropNode: (context: T, selectedNodes: Statement[]) => void;
}

@Component({
  selector: 'cross-tab-editor',
  templateUrl: './cross-tab-editor.component.html',
  styleUrls: ['./cross-tab-editor.component.scss'],
})
export class CrossTabEditorComponent implements OnInit, OnDestroy {
  public showExpandBtn = false;
  public splitSizes: Record<string, IAreaSize> = {
    codebook: CODEBOOK_INITIAL_SIZE_IN_PIXEL,
    table: '*',
  };
  public targetType: typeof TargetType = TargetType;
  public viewType: typeof ViewType = ViewType;
  public isReadonly = true;
  public selectedTab: ViewType = this.viewType.crossTab;
  public surveys: Survey[];
  public visibleSurveys: Survey[];
  public selectedSurvey: Survey;
  public hiddenSurveys: Set<Survey> = new Set<Survey>();
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;

  // contextual menu (contextMenuPosition is shared by both menu)
  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('codebookMenuTrigger') private codebookContextMenu: MatMenuTrigger;
  @ViewChild(CodebookNavigationComponent)
  private codebook: CodebookNavigationComponent;
  public maxNumberTrendSurveys = MAX_NUMBER_TREND_SURVEYS;

  @ViewChild(CrosstabViewComponent, { static: false })
  crosstabViewComponent: CrosstabViewComponent;

  private visualCodeBuilderDialog: MatDialogRef<VisualCodeBuilderDialogComponent>;
  private nTileDialog: MatDialogRef<NtileDialogComponent>;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private targetService: TargetService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private combinedCodingThresholdService: CombinedCodingThresholdService,
    private codebookSelectionService: CodebookSelectionService,
    private separateOperatorAction: SeparateOperatorAction,
    private dialog: DialogService,
    private audienceGroupsService: TupAudienceGroupsService,
    private trendingCalculationService: TrendingCalculationService,
    private addToMultipleAction: AddToMultipleAction,
    private messageService: TupUserMessageService
  ) {
    this.isReadonly =
      this.router.getCurrentNavigation().extras?.state?.isReadonly;
    this.selectedTab = this.activatedRoute.snapshot.queryParams?.tab;
  }

  ngOnInit() {
    this.listenToUrlQueryChanges();
    this.listenToDocumentDataChanges();
    this.listenToSelectedNodes();
    this.listenToActiveSurveyChanges();
  }

  public onDragEnd(event): void {
    this.splitSizes.codebook = getAreaSize(event.sizes[0]);
    this.splitSizes.table = getAreaSize(event.sizes[1]);

    this.showExpandBtn = event.sizes[0] < CODEBOOK_MIN_SIZE_IN_PIXEL;
  }

  public onExpandCodebook(): void {
    this.splitSizes.codebook = CODEBOOK_INITIAL_SIZE_IN_PIXEL;
    this.splitSizes.table = '*';
    this.showExpandBtn = false;
  }

  public onTabChange(tab: MatTabChangeEvent): void {
    this.selectedTab = tab.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        tab: this.selectedTab,
      },
    });
  }

  public onDropNode(event: DropDataContext<any>) {
    const selectedNodes = event.selectedNodes;
    if (selectedNodes) {
      this.codebookSelectionService.setLoadingChildrenNodes(
        true,
        LoadingSource.fromDrag
      );
      this.codebook.loadChildren(selectedNodes).then((result) => {
        this.codebookSelectionService.setLoadingChildrenNodes(false);
        this.codebookSelectionService.setSelectedNodes(result);
        event.handleDropNode(event.context, result);
      });
    }
  }

  public onCodebookContextMenu(data: ContextMenuData): void {
    this.contextMenuPosition.x = data.event.clientX + 'px';
    this.contextMenuPosition.y = data.event.clientY + 'px';
    this.codebookContextMenu.menuData = {
      node: data.node,
      path: data.path,
      level: data.level,
      selectedNodes: data.selectedNodes,
      selectionTreeNode: data.selectionTreeNode,
    };
    this.codebookContextMenu.openMenu();
  }

  ngOnDestroy(): void {
    this.unsubscribeLoadingSubscription();
  }

  public onControlClick(type: TargetType): void {
    const selectedNodes = this.codebook.getSelectedNodes();
    if (!selectedNodes || selectedNodes.length < 1) {
      return;
    }

    this.addSelectedNodesToDocument(
      selectedNodes,
      type,
      LoadingSource.fromButton
    );
  }

  public onTargetClick(targetClick: TargetItem): void {
    this.closeVisualCodeBuilderDialog();
    this.visualCodeBuilderDialog = this.dialog.visualCodeBuilder(
      this.documentService,
      this.targetService,
      this.combinedCodingThresholdService,
      targetClick.target,
      this.documentService.activeSurvey
    );
    this.visualCodeBuilderDialog.componentInstance.dropNode.subscribe(
      (dropData) => {
        this.onDropNode(dropData);
      }
    );
    this.visualCodeBuilderDialog
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: Target) => {
        this.visualCodeBuilderDialog.componentInstance.dropNode.unsubscribe();
        if (targetClick.index !== EMPTY_TARGET_ITEM_INDEX) {
          this.documentService.updateDocumentData([
            {
              ...targetClick,
              target: result,
            },
          ]);
        } else {
          this.documentService.addDocumentData(
            [result],
            targetClick.type,
            true
          );
        }
      });
  }

  public onNtileClick(targetClick: TargetItem): void {
    this.nTileDialog = this.dialog.nTileSettings(
      targetClick.target,
      targetClick.type
    );

    this.nTileDialog
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: NTileDialogResult) => {
        let targets = this.targetService.createNTileTargets(
          result.target,
          result.nTileBuckets,
          result.nTileFunction
        );
        const createdTargets: Target[] = [];
        targets.forEach((target) => {
          const createdTarget = this.targetService.groupTargets(
            [target],
            Operator.ntiles,
            true
          );
          createdTargets.push(...createdTarget);
        });

        this.documentService.addDocumentData(
          createdTargets,
          result.targetType,
          true
        );
      });
  }

  public onOpenTrendingCalculation(): void {
    const previousTrendingCalculations = cloneDeep(
      this.trendingCalculationService.getTrendingCalculations()
    );
    this.dialog
      .trendingCalc(this.visibleSurveys, this.trendingCalculationService)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.documentService.updateCrossTabData();
        } else {
          this.trendingCalculationService.reset(previousTrendingCalculations);
        }
      });
  }

  private listenToSelectedNodes(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        if (selectedNodes.length < 1) {
          this.codebook?.unselectAllNodes();
        }
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.surveys = doc.content.surveys;
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
        if (readonly) {
          this.splitSizes.codebook = 0;
          this.showExpandBtn = true;
        }
      });
    this.documentService.restoreDocumentState$
      .pipe(takeUntil(this.unsubscribe), delay(0))
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.updateSurveys();
      });
  }

  private updateSurveys(): void {
    this.surveys = this.documentService.surveys;
    this.selectedSurvey = this.documentService.activeSurvey;
    this.visibleSurveys = this.documentService.getVisibleSurveys();
  }

  private listenToUrlQueryChanges(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((query) => {
        this.selectedTab = query.tab;
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.selectedSurvey = survey;
        this.visibleSurveys = this.documentService.getVisibleSurveys();
        this.hiddenSurveys = this.documentService.getHiddenSurveys();
      });
  }

  public onChangeSurveysClicked(): void {
    this.audienceGroupsService
      .surveySelector(
        this.documentService.surveys,
        null,
        null,
        null,
        MAX_NUMBER_TREND_SURVEYS
      )
      .pipe(isNotNullOrUndefined())
      .subscribe((result: ChangeSurveysResult) => {
        const selectedSurvey = this.removeSurveysSearchInfo(result.surveys);
        this.documentService.setTempDocumentState();
        this.resetHiddenSurveys();
        this.updateTrendingCalculation(result.clearExisting, selectedSurvey);
        this.documentService.initDocument(
          selectedSurvey,
          !result.clearExisting,
          true,
          true,
          true
        );
        this.surveys = this.documentService.surveys;
      });
  }

  public onSelectedSurveyChanged(survey: Survey): void {
    this.documentService.activeSurvey = survey;
  }

  public toggleSurveyVisibility(survey: Survey): void {
    if (!this.hiddenSurveys.has(survey)) {
      this.hiddenSurveys.add(survey);
      this.trendingCalculationService.removeInvalidCalculation(
        this.surveys,
        survey
      );
    } else {
      this.hiddenSurveys.delete(survey);
    }
    this.notifyHiddenSurveysChanged(true);
    this.visibleSurveys = this.documentService.getVisibleSurveys();
  }

  public addToRows(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.rows,
      LoadingSource.fromMenu
    );
  }

  public addToColumns(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.columns,
      LoadingSource.fromMenu
    );
  }

  public addToTable(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.tables,
      LoadingSource.fromMenu
    );
  }

  public expandAllCodebook(node: Statement) {
    this.codebook.codebookFetchAndExpandNodes([node]);
  }

  public refreshCustomNodes(node: SelectionTreeFlatNode): void {
    this.codebook.onCodebookLoadData(node);
  }

  public editCustomNodeTitle(node: SelectionTreeFlatNode): void {
    this.audienceGroupsService
      .editOwnCodesTargetTitle(
        node.data.customData.document,
        node.data.customData.index
      )
      .subscribe((document: TupDocument<DocumentAudienceGroup>) => {
        this.messageService.showSnackBar(
          'Custom audiences/media updated successfully',
          'OK',
          10000
        );
        const audienceGroupItem =
          document.content.targets[node.data.customData.index];
        const paths = node.data.path.split('|');
        paths.pop();
        this.codebook.updateCustomAudienceOrMediaNode(node, {
          description: audienceGroupItem.title,
          path: paths.join('|') + '|' + audienceGroupItem.title,
          customData: {
            index: node.data.customData.index,
            target: audienceGroupItem.options.target,
            document,
          },
        });
      });
  }
  public addToMultiple(nodes: Statement[], type: TargetType): void {
    this.codebookSelectionService.setLoadingChildrenNodes(
      true,
      LoadingSource.fromMenu
    );
    this.codebook.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.documentService.documentState$
        .pipe(first())
        .subscribe(({ columns, rows, tables }: DocumentDataState) => {
          this.addToMultipleAction.invoke({
            targetType: type,
            selectedTargets:
              this.targetService.convertStatementsToTargets(result),
            selectableTargets:
              type === TargetType.tables
                ? tables
                : type === TargetType.columns
                ? columns
                : rows,
          });
        });
    });
  }

  private notifyHiddenSurveysChanged(
    shouldRefreshCrossTab: boolean = true
  ): void {
    this.documentService.setHiddenSurveys(
      this.hiddenSurveys,
      shouldRefreshCrossTab
    );
  }

  private removeSurveysSearchInfo(surveys: Survey[]): Survey[] {
    return surveys.map((survey: Survey) => omit(survey, 'occurrenceCount'));
  }

  private updateTrendingCalculation(
    clearExisting: boolean,
    surveys: Survey[]
  ): void {
    if (clearExisting) {
      this.trendingCalculationService.reset();
    } else {
      this.trendingCalculationService.removeInvalidCalculation(surveys);
    }
  }

  private resetHiddenSurveys(): void {
    this.hiddenSurveys.clear();
    this.notifyHiddenSurveysChanged(false);
  }

  private unsubscribeLoadingSubscription(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private addSelectedNodesToDocument(
    nodes: Statement[],
    type: TargetType,
    origin: LoadingSource
  ): void {
    this.codebookSelectionService.setLoadingChildrenNodes(true, origin);
    this.codebook.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.separateOperatorAction.invoke({
        targetType: type,
        selectedTargets: this.targetService.convertStatementsToTargets(result),
      });
    });
  }

  private closeVisualCodeBuilderDialog(): void {
    this.visualCodeBuilderDialog?.close();
  }

  public exportToXlsx(): void {
    this.crosstabViewComponent.exportToXlsx();
  }

  public exportToSheets(): void {
    this.crosstabViewComponent.exportToSheets();
  }

  public exportToCsv(): void {
    this.crosstabViewComponent.exportToCsv();
  }
}
