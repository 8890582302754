import { Statement } from 'src/app/models/codebook.model';

/** Flat to-do item node with expandable and level information */
export class SelectionTreeFlatNode {
  coding: string;
  description: string;
  validWeights: number[];
  answer: string;
  question: string;
  level: number;
  expandable: boolean;
  isLoading: boolean = false;
  data: Statement;
}

export class ContextMenuData {
  event: any;
  node: Statement;
  path: Statement[];
  level: number;
  selectedNodes: Statement[];
  selectionTreeNode: SelectionTreeFlatNode;
}

export class TreeNodeSelection {
  nbChildren: number = 0;
  nbSelectedChildren: number = 0;
}

export class StatementFactory {
  static cloneData(
    data: Statement,
    ignoreChildren: boolean = false,
    selected?: boolean
  ): Statement {
    const copy: Statement = {
      ...data,
      description: data.description,
      path: data.path,
      coding: data.coding,
      validWeights: data.validWeights,
      children: [],
    };
    if (selected !== undefined) {
      copy.selected = selected;
    }
    if (!ignoreChildren) {
      data.children?.forEach((child) => {
        copy.children.push(
          StatementFactory.cloneData(child, false, copy.selected)
        );
      });
    }

    return copy;
  }

  static getNodeFromTree(node: Statement, tree: Statement[]): Statement {
    let matchedNode = null;
    for (const branch of tree) {
      if (node.path.startsWith(branch.path)) {
        if (node.path === branch.path) {
          matchedNode = branch;
          break;
        }
        matchedNode = StatementFactory.getNodeFromTree(node, branch.children);
      }
    }

    return matchedNode;
  }

  static getMissingNodes(
    nodes: Statement[],
    missing?: Statement[]
  ): Statement[] {
    if (!missing) {
      missing = [];
    }
    nodes.forEach((node) => {
      if (node.children.length === 0 && !node.coding) {
        missing.push(node);
      } else {
        StatementFactory.getMissingNodes(node.children, missing);
      }
    });
    return missing;
  }

  static getSelectedNodes(nodes: Statement[], selectedNodes?: Statement[]) {
    if (!selectedNodes) {
      selectedNodes = [];
    }
    nodes.forEach((node) => {
      if (node.children.length === 0) {
        // we are on a leaf
        if (node.selected) {
          selectedNodes.push(StatementFactory.cloneData(node, true));
        }
      } else {
        // check children
        let selectedChildren: Statement[] = [];
        StatementFactory.getSelectedNodes(node.children, selectedChildren);
        if (selectedChildren.length > 0) {
          let clone = StatementFactory.cloneData(node, true);
          clone.children = selectedChildren;
          selectedNodes.push(clone);
        }
      }
    });
    return selectedNodes;
  }

  static selectChildren(node: Statement) {
    if (node.children.length === 0) {
      node.selected = true;
    } else {
      node.selected = undefined;
      node.children.forEach((child) => {
        StatementFactory.selectChildren(child);
      });
    }
  }

  static unselectChildren(node: Statement) {
    if (node.children.length === 0) {
      node.selected = false;
    } else {
      node.selected = undefined;
      node.children.forEach((child) => {
        StatementFactory.unselectChildren(child);
      });
    }
  }

  static atLeastOneSelectedDescendant(data: Statement): boolean {
    if (data.children.length === 0) {
      return data.selected === true;
    }
    for (let child of data.children) {
      if (StatementFactory.atLeastOneSelectedDescendant(child)) {
        return true;
      }
    }
    return false;
  }

  static descendantsAllSelected(data: Statement): boolean {
    if (data.children.length === 0) {
      return data.selected === true;
    }
    for (let child of data.children) {
      if (!StatementFactory.descendantsAllSelected(child)) {
        return false;
      }
    }
    return true;
  }

  static descendantsPartiallySelected(
    data: Statement,
    selection?: TreeNodeSelection
  ): TreeNodeSelection {
    if (!selection) {
      selection = new TreeNodeSelection();
    }
    if (data.children.length === 0) {
      selection.nbChildren++;
      if (data.selected === true) {
        selection.nbSelectedChildren++;
      }
    }
    for (let child of data.children) {
      StatementFactory.descendantsPartiallySelected(child, selection);
      if (
        selection.nbSelectedChildren > 0 &&
        selection.nbChildren !== selection.nbSelectedChildren
      ) {
        break;
      }
    }
    return selection;
  }

  static getPath(node: Statement, nodes: Statement[]): Statement[] {
    for (let child of nodes) {
      if (child === node) {
        // found the node
        return [StatementFactory.cloneData(node, false)];
      }
      const path: Statement[] = StatementFactory.getPath(node, child.children);
      if (path.length > 0) {
        // found on this branch
        return [
          { description: child.description, path: child.path, children: path },
        ];
      }
    }
    return [];
  }

  static getParent(path: string): string {
    const paths: string[] = path.split('|');
    paths.pop();
    return paths.join('|');
  }
}
