import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { ReportPreference, TargetItem } from '../models';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { MatDialog } from '@angular/material/dialog';
import {
  SortColumnDialogComponent,
  SortColumnDialogDataModel,
} from '../dialogs/sort-column-dialog/sort-column-dialog.component';
import { cloneDeep } from 'lodash';
import { CrosstabService, ReportPreferencesService } from '../services';
import { first } from 'rxjs/operators';

export interface SortColumnActionContext {
  targetItem: TargetItem;
  surveyCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class SortColumnAction extends ActionDefinition<SortColumnActionContext> {
  constructor(
    private dialog: MatDialog,
    private crossTabService: CrosstabService,
    private reportPreferencesService: ReportPreferencesService
  ) {
    super();
  }

  public invoke(context: SortColumnActionContext): void {
    let sortSettings;
    this.reportPreferencesService.preference$
      .pipe(first())
      .subscribe((preference: ReportPreference) => {
        sortSettings = cloneDeep(preference.sortSettings);
      });

    sortSettings.columnId = `${context.targetItem.target?.id || 'totals'}`;
    sortSettings.survey = context.surveyCode;

    this.dialog
      .open(SortColumnDialogComponent, {
        data: {
          sortSettings,
          crossTabService: this.crossTabService,
        },
        closeOnNavigation: true,
        width: '800px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data: SortColumnDialogDataModel) => {
        data.sortSettings.columnId = `${data.sortSettings.columnId}_${data.sortSettings.survey}`;
        this.reportPreferencesService.sortColumn(data.sortSettings);
      });
  }
}
