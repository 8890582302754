import { DisplayType, Operator, TargetType } from './document.model';
import { ActionDefinition } from '../actions/ActionDefinition';
import { Type } from '@angular/core';
import { GenericOperatorAction } from '../actions/GenericOperatorAction';
import { CountOperatorAction } from '../actions/CountOperatorAction';
import { SolusOperatorAction } from '../actions/SolusOperatorAction';
import { DeleteTargetAction } from '../actions/DeleteTargetAction';
import { RenameTargetAction } from '../actions/RenameTargetAction';
import { ChangeTargetTitleModeAction } from '../actions/ChangeTargetTitleModeAction';
import { MoveToTargetAction } from '../actions/MoveToTargetAction';
import { AddToMultipleAction } from '../actions/AddToMultipleAction';
import { SortDirection } from './crosstab.model';
import { DataItem, DataItemType } from './data-item.model';
import { SortColumnTargetAction } from '../actions/SortColumnTargetAction';
import { DeleteSeveralTargetsAction } from '../actions/DeleteSeveralTargetsAction';
import { AddOrEditFilterAction } from '../actions/AddOrEditFilterAction';
import { RemoveFilterAction } from '../actions/RemoveFilterAction';
import { SortColumnAction } from '../actions/SortColumnAction';
import { ToggleFreezeTotalsAction } from '../actions/ToggleFreezeTotalsAction';
import { DuplicateTargetsAction } from '../actions/DuplicateTargetsAction';
import { SwapRowsColumnsAction } from '../actions/SwapRowsColumnsAction';
import { AutoOperatorAction } from '../actions/AutoOperatorAction';
import { NTileSettingsTargetAction } from '../actions/NTileSettingsAction';

export interface ActionItem {
  name: Operator | string;
  action?: Type<ActionDefinition<unknown>>;
}

export interface DropActionItem extends ActionItem {
  operator?: Operator;
  groupOperator?: Operator;
  shouldGroupTarget?: boolean;
}

export const DEFAULT_BASE_TABLE_ACTION_ITEMS: DropActionItem[] = [
  {
    name: Operator.auto,
    groupOperator: Operator.auto,
    operator: Operator.and,
    action: AutoOperatorAction,
  },
  {
    name: Operator.and,
    groupOperator: Operator.or,
    operator: Operator.and,
    action: GenericOperatorAction,
  },
  {
    name: Operator.or,
    groupOperator: Operator.or,
    operator: Operator.or,
    action: GenericOperatorAction,
  },
  {
    name: Operator.andNot,
    groupOperator: Operator.or,
    operator: Operator.andNot,
    action: GenericOperatorAction,
  },
  {
    name: Operator.count,
    groupOperator: Operator.plus,
    operator: Operator.and,
    action: CountOperatorAction,
  },
  {
    name: 'Code builder',
  },
];

export const DEFAULT_TABLE_COLUMN_ACTION_ITEMS: DropActionItem[] = [
  ...DEFAULT_BASE_TABLE_ACTION_ITEMS,
  {
    name: 'Add to multiple columns',
    action: AddToMultipleAction,
  },
];

export const DEFAULT_TABLE_ROW_ACTION_ITEMS: DropActionItem[] = [
  ...DEFAULT_BASE_TABLE_ACTION_ITEMS,
  {
    name: 'Add to multiple rows',
    action: AddToMultipleAction,
  },
];

export type ContextMenuActionItem =
  | ColumnRowActionItem
  | TitleModeActionItem
  | MoveToActionItem
  | DividerItem
  | SortActionItem
  | FreezeTotalsActionItem;

export interface ColumnRowActionItem extends ActionItem {
  actions?: (TitleModeActionItem | MoveToActionItem | SortActionItem)[];
}

export interface TitleModeActionItem extends ActionItem {
  displayType?: DisplayType;
  isActive?: boolean;
}

export interface MoveToActionItem extends ActionItem {
  currentTargetType?: TargetType;
  moveTo?: TargetType;
}

export interface FreezeTotalsActionItem extends ActionItem {
  type: TargetType;
}

export interface DividerItem extends ActionItem {
  isDivider: boolean;
}

export const DIVIDER = '-';

export const SWAP_ROWS_COLUMNS_ACTION_NAME = 'Swap rows/columns';

export const NTILE_SETTINGS = 'NTiles';

export interface SortActionItem extends ActionItem {
  dataItem: DataItemType;
  direction: SortDirection;
}

const FREEZE_ROW_TOTALS: ContextMenuActionItem[] = [
  {
    name: 'Freeze totals row',
    action: ToggleFreezeTotalsAction,
    type: TargetType.rows,
  },
  {
    name: 'Unfreeze totals row',
    action: ToggleFreezeTotalsAction,
    type: TargetType.rows,
  },
];

const FREEZE_COLUMN_TOTALS: ContextMenuActionItem[] = [
  {
    name: 'Freeze totals column',
    action: ToggleFreezeTotalsAction,
    type: TargetType.columns,
  },
  {
    name: 'Unfreeze totals column',
    action: ToggleFreezeTotalsAction,
    type: TargetType.columns,
  },
];

const TITLE_ACTIONS: ContextMenuActionItem[] = [
  {
    name: 'Title',
    actions: [
      {
        name: 'Short title',
        action: ChangeTargetTitleModeAction,
        displayType: DisplayType.shortTitle,
      },
      {
        name: 'Long title',
        action: ChangeTargetTitleModeAction,
        displayType: DisplayType.title,
      },
      {
        name: 'User title',
        action: ChangeTargetTitleModeAction,
        displayType: DisplayType.ownTitle,
      },
      {
        name: 'Data context',
        action: ChangeTargetTitleModeAction,
        displayType: DisplayType.levels,
      },
    ],
  },
];

export const DEFAULT_ROW_TITLE_ACTION_ITEMS: ContextMenuActionItem[] = [
  {
    name: 'Delete row',
    action: DeleteTargetAction,
  },
  {
    name: 'Delete multiple items',
    action: DeleteSeveralTargetsAction,
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Duplicate row',
    action: DuplicateTargetsAction,
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Rename',
    action: RenameTargetAction,
  },
  ...TITLE_ACTIONS,
  {
    name: DIVIDER,
    isDivider: true,
  },
  ...FREEZE_ROW_TOTALS,
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Move to',
    actions: [
      {
        name: 'Columns',
        action: MoveToTargetAction,
        moveTo: TargetType.columns,
      },
      {
        name: 'Tables',
        action: MoveToTargetAction,
        moveTo: TargetType.tables,
      },
    ],
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: NTILE_SETTINGS,
    action: NTileSettingsTargetAction,
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: SWAP_ROWS_COLUMNS_ACTION_NAME,
    action: SwapRowsColumnsAction,
  },
];

export const DEFAULT_ROW_TOTALS_ACTION_ITEMS: ContextMenuActionItem[] = [
  ...FREEZE_ROW_TOTALS,
];

export const DEFAULT_SORT_ACTION_ITEM: SortActionItem = {
  name: 'Un-Sort',
  action: SortColumnTargetAction,
  dataItem: DataItemType.none,
  direction: SortDirection.none,
};

export const DEFAULT_COLUMN_TOTALS_ACTION_ITEMS: ContextMenuActionItem[] = [
  {
    name: DIVIDER,
    isDivider: true,
  },
  ...FREEZE_COLUMN_TOTALS,
];

export const DEFAULT_COLUMN_TITLE_ACTION_ITEMS: ContextMenuActionItem[] = [
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Delete column',
    action: DeleteTargetAction,
  },
  {
    name: 'Delete multiple items',
    action: DeleteSeveralTargetsAction,
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Duplicate column',
    action: DuplicateTargetsAction,
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Rename',
    action: RenameTargetAction,
  },
  ...TITLE_ACTIONS,
  {
    name: DIVIDER,
    isDivider: true,
  },
  ...FREEZE_COLUMN_TOTALS,
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: 'Move To',
    actions: [
      {
        name: 'Rows',
        action: MoveToTargetAction,
        moveTo: TargetType.rows,
      },
      {
        name: 'Tables',
        action: MoveToTargetAction,
        moveTo: TargetType.tables,
      },
    ],
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: NTILE_SETTINGS,
    action: NTileSettingsTargetAction,
  },
  {
    name: DIVIDER,
    isDivider: true,
  },
  {
    name: SWAP_ROWS_COLUMNS_ACTION_NAME,
    action: SwapRowsColumnsAction,
  },
];

export const getColumnActionItems = (
  isTotalsColumn: boolean,
  dataItems: DataItem[],
  hasVolumetricCoding: boolean
): ContextMenuActionItem[] => {
  return [
    {
      name: 'Sort asc',
      actions: [
        ...dataItems.map((item: DataItem) => {
          let name = item.displayName;
          if (hasVolumetricCoding && item.id === DataItemType.audience) {
            name = `${item.volumetricDisplayName}/${item.displayName}`;
          }
          return {
            name,
            action: SortColumnTargetAction,
            dataItem: item.id,
            direction: SortDirection.asc,
          };
        }),
      ],
    },
    {
      name: 'Sort desc',
      actions: [
        ...dataItems.map((item: DataItem) => {
          let name = item.displayName;
          if (hasVolumetricCoding && item.id === DataItemType.audience) {
            name = `${item.volumetricDisplayName}/${item.displayName}`;
          }
          return {
            name,
            action: SortColumnTargetAction,
            dataItem: item.id,
            direction: SortDirection.desc,
          };
        }),
      ],
    },
    DEFAULT_SORT_ACTION_ITEM,
    ...(isTotalsColumn
      ? DEFAULT_COLUMN_TOTALS_ACTION_ITEMS
      : DEFAULT_COLUMN_TITLE_ACTION_ITEMS),
  ];
};

export type ColumnHeaderMenuActionItem =
  | ActionItem
  | SortActionItem
  | DividerItem;

export const DEFAULT_COLUMN_HEADER_ACTION_ITEMS: ColumnHeaderMenuActionItem[] =
  [
    {
      name: 'Add filter',
      action: AddOrEditFilterAction,
    },
    {
      name: 'Edit filter',
      action: AddOrEditFilterAction,
    },
    {
      name: 'Remove filter',
      action: RemoveFilterAction,
    },
    {
      name: DIVIDER,
      isDivider: true,
    },
    {
      name: 'Sort column',
      action: SortColumnAction,
    },
    {
      name: 'Reset sorting',
      action: SortColumnTargetAction,
      dataItem: DataItemType.none,
      direction: SortDirection.none,
    },
  ];

export interface CombineActionItem extends ActionItem {
  operator?: Operator;
  groupOperator?: Operator;
  shouldGroupTarget?: boolean;
  tooltip?: string;
}

export const COMBINE_BUTTON_ACTION_ITEMS: CombineActionItem[] = [
  {
    name: Operator.any,
    operator: Operator.or,
    shouldGroupTarget: true,
    groupOperator: Operator.or,
    action: GenericOperatorAction,
    tooltip:
      'Will combine all selected codes with OR, and enclose codes in brackets.',
  },
  {
    name: Operator.all,
    operator: Operator.and,
    shouldGroupTarget: true,
    groupOperator: Operator.and,
    action: GenericOperatorAction,
    tooltip:
      'Will combine all selected codes with AND, and enclose codes in brackets.',
  },
  {
    name: Operator.auto,
    operator: Operator.auto,
    shouldGroupTarget: true,
    groupOperator: Operator.auto,
    action: AutoOperatorAction,
    tooltip:
      'Will combine selections under a node with OR & across nodes with AND, and enclose codes in brackets.',
  },
  {
    name: Operator.or,
    shouldGroupTarget: true,
    groupOperator: Operator.or,
    action: GenericOperatorAction,
    tooltip: 'Add single OR statement.',
  },
  {
    name: Operator.and,
    shouldGroupTarget: true,
    groupOperator: Operator.and,
    action: GenericOperatorAction,
    tooltip: 'Add single AND statement.',
  },
  {
    name: Operator.andNot,
    shouldGroupTarget: false,
    operator: Operator.andNot,
    action: GenericOperatorAction,
  },
  {
    name: Operator.sum,
    operator: Operator.plus,
    shouldGroupTarget: true,
    groupOperator: Operator.plus,
    action: GenericOperatorAction,
    tooltip: 'Join codes using + operator',
  },
  {
    name: Operator.count,
    shouldGroupTarget: true,
    groupOperator: Operator.plus,
    action: CountOperatorAction,
    tooltip:
      'Combines all chosen codes with a (+), and allows for qualifiers to be added. ' +
      'Usually used with Volume coding and attitude statements.',
  },
  {
    name: Operator.vind,
    shouldGroupTarget: false,
    operator: Operator.vind,
    action: GenericOperatorAction,
    tooltip: 'Index comparison',
  },
  {
    name: Operator.vmul,
    shouldGroupTarget: true,
    groupOperator: Operator.vmul,
    action: GenericOperatorAction,
    tooltip: '',
  },
  {
    name: Operator.solus,
    shouldGroupTarget: true,
    groupOperator: Operator.plus,
    operator: Operator.solus,
    action: SolusOperatorAction,
    tooltip: 'Calculate the Exclusive Reach',
  },
  {
    name: Operator.vdiv,
    shouldGroupTarget: true,
    groupOperator: Operator.vdiv,
    action: GenericOperatorAction,
    tooltip: 'Divides two codes',
  },
  {
    name: 'DIVIDE',
    shouldGroupTarget: true,
    groupOperator: Operator.divide,
    action: GenericOperatorAction,
    tooltip: '',
  },
  {
    name: Operator.greaterThan,
    shouldGroupTarget: true,
    groupOperator: Operator.greaterThan,
    action: GenericOperatorAction,
    tooltip: '',
  },
];

export const SEND_TO_ACTION_ITEMS: MoveToActionItem[] = [
  {
    name: 'Tables',
    currentTargetType: TargetType.tables,
    action: MoveToTargetAction,
    moveTo: TargetType.tables,
  },
  {
    name: 'Columns',
    currentTargetType: TargetType.columns,
    action: MoveToTargetAction,
    moveTo: TargetType.columns,
  },
  {
    name: 'Rows',
    currentTargetType: TargetType.rows,
    action: MoveToTargetAction,
    moveTo: TargetType.rows,
  },
];
