import { Injectable } from '@angular/core';
import {
  DisplayType,
  levelSeparator,
  Operator,
  SURVEYTIME_TARGET_VERSION,
  Target,
} from '../models';
import { Statement } from '../models';
import { NTileBucketElement } from '../models/n-tiles.model';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

class TargetFactory {
  public static create(options?: Partial<Target>): Target {
    return {
      id: uuidv4(),
      fileVersion: SURVEYTIME_TARGET_VERSION,
      title: '',
      coding: '',
      validWeights: [],
      operator: Operator.and,
      created: Date.now(),
      audience: 0,
      resps: 0,
      percent: 0,
      targets: [],
      ...options,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  public convertStatementsToTargets(statements: Statement[]): Target[] {
    return this.findRecursiveTargets(statements, '');
  }

  public createTarget(options?: Partial<Target>): Target {
    return this.updateTitleAndCoding(TargetFactory.create(options));
  }

  public shallowCopyTarget(target: Target): Target {
    return {
      ...target,
      id: uuidv4(),
    };
  }

  public addChildTarget(
    parentTarget: Target,
    childTarget: Target,
    operator: Operator = Operator.and
  ): void {
    if (parentTarget.targets.length > 1 && operator !== Operator.and) {
      const clonedParentTarget = cloneDeep(parentTarget);
      parentTarget.targets = [clonedParentTarget];
    }
    if (parentTarget.targets.length > 0) {
      parentTarget.targets[parentTarget.targets.length - 1].operator = operator;
    }
    parentTarget.targets.push(childTarget);
    this.updateTitleAndCoding(parentTarget);
  }

  public updateTitleAndCoding(target: Target): Target {
    target.title = this.formatDisplayByType(target, DisplayType.title);
    target.ownTitle =
      target.activeTitleMode !== DisplayType.ownTitle
        ? this.formatDisplayByType(target, DisplayType.ownTitle)
        : target.ownTitle;
    target.coding = this.formatDisplayByType(target, DisplayType.coding);

    return target;
  }

  public formatDisplayByType(target: Target, type: DisplayType): string {
    const childrenTargets = target.targets;
    const numberOfChildrenTargets = childrenTargets.length;

    if (numberOfChildrenTargets < 1) {
      return target[type];
    }

    return childrenTargets
      .map((childTarget: Target, index: number) => {
        const shouldGroupTarget =
          childTarget.targets.length > 1 ||
          (type !== DisplayType.coding &&
            childTarget.countCoding &&
            childTarget.titlePrefix);
        const shouldShowCountCoding =
          childTarget.countCoding &&
          (type === DisplayType.coding || !childTarget.titlePrefix);
        const shouldShowTitlePrefix =
          childTarget.countCoding &&
          type !== DisplayType.coding &&
          childTarget.titlePrefix;
        const additionalCoding = shouldShowCountCoding
          ? ` ${childTarget.countCoding.operator} ${childTarget.countCoding.value}`
          : '';
        return `${shouldShowTitlePrefix ? childTarget.titlePrefix : ''}${
          shouldShowCountCoding ? '(' : ''
        }${shouldGroupTarget ? '(' : ''}${childTarget[type]}${
          shouldGroupTarget ? ')' : ''
        }${additionalCoding}${shouldShowCountCoding ? ')' : ''}${
          index + 1 < numberOfChildrenTargets ? ` ${childTarget.operator}` : ''
        }`;
      })
      .join(' ');
  }

  public groupTargetsWithAutoOperator(selectedTargets: Target[]): Target[] {
    let targets: Target[] = [];
    const autoTargets: { [key: string]: Target[] } = selectedTargets.reduce(
      (acc, target) => {
        const categoryTitle = target.title.replace(target.ownTitle, '');
        if (categoryTitle in acc) {
          acc[categoryTitle].push(target);
        } else {
          acc[categoryTitle] = [target];
        }
        return acc;
      },
      {}
    );

    targets = Object.keys(autoTargets).map((categoryTitle) => {
      return this.groupTargets(
        autoTargets[categoryTitle],
        Operator.or,
        true
      )[0];
    });
    targets = this.groupTargets(targets, Operator.and, true);
    if (Object.keys(autoTargets).length > 1)
      targets = this.groupTargets(targets, Operator.and, true); // To form a group around AND

    return targets;
  }

  public groupTargets(
    targets: Target[],
    operator: Operator,
    forceGrouping = false
  ): Target[] {
    const hasMultipleSelectedTargets = targets.length > 1;
    if (hasMultipleSelectedTargets || forceGrouping) {
      return [
        this.createTarget({
          targets: this.formatTargets(targets, operator),
        }),
      ];
    } else {
      return targets;
    }
  }

  public separateTargets(targets: Target[]): Target[] {
    return targets.map((target: Target) => {
      // should create a new group for target with count coding on the second level
      const isTargetAlreadyGrouped =
        target.targets.length > 0 && !target.targets[0].countCoding;
      if (isTargetAlreadyGrouped) {
        return target;
      }
      const ownTitle =
        target.activeTitleMode === DisplayType.ownTitle
          ? target.ownTitle
          : null;
      const groupTarget = this.createTarget({
        validWeights: target.validWeights,
        targets: [target],
      });
      if (ownTitle) {
        groupTarget.activeTitleMode = DisplayType.ownTitle;
      }
      return groupTarget;
    });
  }

  public createNTileTargets(
    target: Target,
    nTileBuckets: NTileBucketElement[],
    nTileFunction: string
  ): Target[] {
    return nTileBuckets.map((nTile: NTileBucketElement) => {
      const shallowCopy = this.shallowCopyTarget(target);
      shallowCopy.targets[0].nTilesCoding = true;
      shallowCopy.targets[0].titlePrefix = Operator.ntiles;
      shallowCopy.coding = `${nTileFunction}(${nTile['start']}, ${nTile['end']}, ${shallowCopy.coding})`;
      shallowCopy.ownTitle = `${nTileFunction}(${nTile['start']}, ${nTile['end']}, ${shallowCopy.ownTitle})`;
      shallowCopy.title = `${shallowCopy.title} : ${nTile['start']}%, ${nTile['end']}%`;
      return shallowCopy;
    });
  }

  private findRecursiveTargets(
    statements: Statement[],
    parentTitle: string
  ): Target[] {
    const targets: Target[] = [];
    const currentTitlePrefix = parentTitle
      ? `${parentTitle} ${levelSeparator} `
      : '';

    statements.forEach((node: Statement) => {
      const title = `${currentTitlePrefix}${node.description}`;
      if (node.children.length > 0) {
        targets.push(...this.findRecursiveTargets(node.children, title));
      } else {
        const target = this.convertStatementToTarget(title, node);
        if (target) {
          targets.push(target);
        }
      }
    });

    return targets;
  }

  private convertStatementToTarget(
    title: string,
    node: Statement
  ): Target | null {
    return node.customData
      ? this.convertCustomStatementToTarget(node)
      : !node.isCustomCoding || node.coding
      ? this.createTarget({
          title,
          ownTitle: node.description,
          coding: node.coding,
          validWeights: node.validWeights,
        })
      : null;
  }

  private convertCustomStatementToTarget(node: Statement): Target | null {
    if (!node.customData) {
      return null;
    }
    let target = node.customData.target as Target;
    const isNodeRenamed = target.title !== node.description;
    if (isNodeRenamed) {
      target.activeTitleMode = DisplayType.ownTitle;
      target.ownTitle = node.description;
    }
    // this could also ungroup target with count coding
    const shouldUngroupTarget = target.targets.length < 2;
    if (shouldUngroupTarget) {
      target.targets[0].activeTitleMode = target.activeTitleMode;
      target.targets[0].ownTitle = target.ownTitle;
      target = target.targets[0];
    }
    return target;
  }

  private formatTargets(
    targets: Target[],
    operator: Operator = Operator.or
  ): Target[] {
    const targetCount = targets.length;
    return targets.map((target: Target, index: number) => ({
      ...target,
      operator: index < targetCount - 1 ? operator : Operator.and,
    }));
  }
}
