export interface NTileBucketElement {
  start: number;
  end: number;
}

export const NTILE_FUNCTION_WITH_ZERO_EXCLUDED = 'NTileZ';

export const NTILE_FUNCTION_WITH_ZERO_INCLUDED = 'NTile';

export const NTILE_START_ADI_OPTION = 'NTILE_START';

export const NTILE_END_ADI_OPTION = 'NTILE_END';

export const DEFAULT_NTILES = 'Quartiles';

export enum NTileBoundaryTypes {
  start = 'start',
  end = 'end',
}

export enum NTiles {
  Tertiles = 3,
  Quartiles = 4,
  Quintiles = 5,
  Deciles = 10,
  Percentiles = 100,
}
