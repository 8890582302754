import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService, TargetService } from '../services';
import { Operator, Target, TargetItem } from '../models';
import { MatDialog } from '@angular/material/dialog';
import {
  NTileDialogResult,
  NtileDialogComponent,
} from '../dialogs/ntile-dialog/ntile-dialog.component';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';

export interface NTileSettingsTargetActionContext {
  targetItem: TargetItem;
}

@Injectable({
  providedIn: 'root',
})
export class NTileSettingsTargetAction extends ActionDefinition<NTileSettingsTargetActionContext> {
  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private targetService: TargetService
  ) {
    super();
  }

  public invoke(context: NTileSettingsTargetActionContext): void {
    const target = context.targetItem.target;
    const type = context.targetItem.type;

    this.dialog
      .open(NtileDialogComponent, {
        data: {
          target,
          type,
          targetService: this.targetService,
        },
        width: '900px',
        closeOnNavigation: true,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: NTileDialogResult) => {
        let targets = this.targetService.createNTileTargets(
          result.target,
          result.nTileBuckets,
          result.nTileFunction
        );

        const createdTargets: Target[] = [];
        targets.forEach((target) => {
          const createdTarget = this.targetService.groupTargets(
            [target],
            Operator.ntiles,
            true
          );
          createdTargets.push(...createdTarget);
        });

        this.documentService.addDocumentData(
          createdTargets,
          result.targetType,
          true
        );
      });
  }
}
