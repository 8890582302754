import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService, TargetService } from '../services';
import { Target, TargetItem } from '../models';

export interface DuplicateTargetsActionContextWithSingleTarget {
  targetItem: TargetItem;
}

export interface DuplicateTargetsActionContextWithMultipleTargets {
  targetItems: TargetItem[];
}

export type DuplicateTargetsActionContext =
  | DuplicateTargetsActionContextWithSingleTarget
  | DuplicateTargetsActionContextWithMultipleTargets;

@Injectable({
  providedIn: 'root',
})
export class DuplicateTargetsAction extends ActionDefinition<DuplicateTargetsActionContext> {
  constructor(
    private documentService: DocumentService,
    private targetService: TargetService
  ) {
    super();
  }

  public invoke(context: DuplicateTargetsActionContext): void {
    const targetItems = this.isDuplicateTargetsActionContextWithSingleTarget(
      context
    )
      ? [context.targetItem]
      : context.targetItems;
    const targetType = targetItems[0].type;
    const targets = this.createDuplicatedTargets(targetItems);
    this.documentService.addDocumentData(targets, targetType, true);
  }

  private isDuplicateTargetsActionContextWithSingleTarget(
    context: DuplicateTargetsActionContext
  ): context is DuplicateTargetsActionContextWithSingleTarget {
    return 'targetItem' in context;
  }

  private createDuplicatedTargets(targetItems: TargetItem[]): Target[] {
    return targetItems.map((targetItem: TargetItem) =>
      this.targetService.shallowCopyTarget(targetItem.target)
    );
  }
}
