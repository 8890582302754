import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  DEFAULT_CROSSTAB_NAME,
  DEFAULT_QUICK_REPORT_NAME,
  MAX_NUMBER_TREND_SURVEYS,
  Survey,
  SurveyTimeDocument,
  ViewType,
} from '../models';
import { TupAudienceGroupsService } from '@telmar-global/tup-audience-groups';
import { DocumentService } from './document.service';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentService,
} from '@telmar-global/tup-document-storage';
import { Router } from '@angular/router';
import { omit, uniqBy } from 'lodash';
import { TupUserPreferenceStorageService } from '@telmar-global/tup-user-preference-storage';
import { TupLoggerService } from '@telmar-global/tup-logger-angular';
import { RequestLoadingService } from './request-loading.service';

@Injectable({
  providedIn: 'root',
})
export class CreateNewDocumentService {
  constructor(
    private audienceGroupsService: TupAudienceGroupsService,
    private documentService: DocumentService,
    private authService: TupAuthService,
    private tupDocumentService: TupDocumentService,
    private router: Router,
    private loggerService: TupLoggerService,
    private requestLoadingService: RequestLoadingService,
    private userPreferenceService: TupUserPreferenceStorageService
  ) {}

  public create(isTemplateDocType: boolean): void {
    const user = this.authService.user;
    const containerName = user.attributes.email;
    this.audienceGroupsService
      .surveySelector([], null, null, null, MAX_NUMBER_TREND_SURVEYS)
      .pipe(isNotNullOrUndefined())
      .subscribe((surveys: Survey[]) => {
        this.requestLoadingService.setLoading({
          target: 'global',
          isLoading: true,
        });
        this.createNewDocument(surveys, containerName, isTemplateDocType);
      });
  }

  private async createNewDocument(
    surveys: Survey[],
    containerName: string,
    isTemplateDocType = false
  ): Promise<void> {
    const crossTabName = isTemplateDocType
      ? DEFAULT_QUICK_REPORT_NAME
      : DEFAULT_CROSSTAB_NAME;

    const docId = await this.documentService
      .createDocument(containerName, crossTabName, undefined, isTemplateDocType)
      .toPromise();

    const document = (await this.tupDocumentService
      .get(containerName, docId)
      .toPromise()) as TupDocument<SurveyTimeDocument>;

    const selectedSurveys = this.removeSurveysSearchInfo(surveys);

    this.documentService.setCurrentDocument(document);
    this.documentService.createInitialTable();
    this.documentService.setDocumentSurveys(selectedSurveys, false);
    this.documentService.setDefaultReportPreferences();
    this.documentService.setActiveDocumentId(docId);
    this.setListRecentSurveys(selectedSurveys);

    this.goToDocument(docId, selectedSurveys);
  }

  private removeSurveysSearchInfo(surveys: Survey[]): Survey[] {
    return surveys.map((survey: Survey) => omit(survey, 'occurrenceCount'));
  }

  public setListRecentSurveys(surveys: Survey[]) {
    this.userPreferenceService
      .hasItem(environment.userPreferences.lastTenSurveysUsed)
      .pipe(first())
      .subscribe(
        async (userPrefExists: boolean) => {
          if (userPrefExists) {
            this.userPreferenceService
              .getItem<Survey[]>(environment.userPreferences.lastTenSurveysUsed)
              .pipe(first())
              .subscribe(
                (surveyList: Survey[]) => {
                  surveyList.forEach((savedSurvey) => {
                    if (
                      surveys.filter(
                        (survey) =>
                          survey.code === savedSurvey.code &&
                          survey.authorizationGroup ===
                            savedSurvey.authorizationGroup
                      ).length !== 0
                    ) {
                      surveyList.splice(surveyList.indexOf(savedSurvey), 1);
                    }
                  });
                  let surveysToSave = uniqBy(
                    surveys.concat(surveyList),
                    'code'
                  ).map((survey) => omit(survey, 'occurrenceCount'));

                  if (surveysToSave.length > 10) {
                    surveysToSave = surveysToSave.slice(0, 10);
                  }
                  this.userPreferenceService
                    .setItem<Survey[]>(
                      environment.userPreferences.lastTenSurveysUsed,
                      surveysToSave
                    )
                    .pipe(first())
                    .subscribe(
                      () =>
                        this.loggerService.info(
                          'lastTenSurveysUsed User preference stored successfully'
                        ),
                      (error: any) =>
                        this.loggerService.error(
                          'Error storing user preference',
                          error
                        )
                    );
                },
                (error: any) =>
                  this.loggerService.error(
                    'Error retrieving user preference',
                    error
                  )
              );
          } else {
            if (surveys.length > 10) {
              surveys = surveys.slice(0, 10);
            }

            surveys = this.removeSurveysSearchInfo(surveys);
            this.userPreferenceService
              .setItem<Survey[]>(
                environment.userPreferences.lastTenSurveysUsed,
                surveys
              )
              .pipe(first())
              .subscribe(
                () =>
                  this.loggerService.info(
                    'lastTenSurveysUsed User preference stored successfully'
                  ),
                (error: any) =>
                  this.loggerService.error(
                    'Error storing user preference',
                    error
                  )
              );
          }
        },
        (error: any) =>
          this.loggerService.error('Error setting user preference', error)
      );
  }

  private goToDocument(docId: string, selectedSurveys: Survey[]): void {
    this.documentService.documentUpdated$
      .pipe(isNotNullOrUndefined(), first())
      .subscribe(() => {
        if (docId && selectedSurveys.length) {
          this.router.navigate([`doc/${docId}/data`], {
            queryParams: {
              tab: ViewType.crossTab,
            },
            state: { hasDocument: true, surveys: selectedSurveys },
          });
        }
      });
  }
}
