import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChartColorDialogComponent } from '../dialogs/chart-color-dialog/chart-color-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ColorPickerService {
  constructor(private dialog: MatDialog) {}

  chartColor(dialogData): MatDialogRef<ChartColorDialogComponent> {
    const options = {
      width: '320px',
      panelClass: 'custom-container',
      data: {
        target: dialogData.target,
        colors: dialogData.colors,
      },
    };
    return this.dialog.open(ChartColorDialogComponent, options);
  }
}
