import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { CrosstabService, ReportPreferencesService } from '../services';
import { TargetItem } from '../models';

export interface RemoveFilterActionContext {
  targetItem: TargetItem;
  surveyCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class RemoveFilterAction extends ActionDefinition<RemoveFilterActionContext> {
  constructor(
    private crossTabService: CrosstabService,
    private reportPreferencesService: ReportPreferencesService
  ) {
    super();
  }

  public invoke(context: RemoveFilterActionContext): void {
    const columnId = this.crossTabService.formatTargetColumnId(
      context.targetItem.target,
      context.surveyCode
    );
    this.reportPreferencesService.removeColumnHeaderFilters(columnId);
  }
}
