<div
  fxFlex
  fxLayout="row"
  fxLayoutGap="-10px"
  class="surveys-info-wrapper"
>
  <button
    *ngIf="hasSurveyInfo"
    fxFlex="0 0 auto"
    mat-icon-button
    color="primary"
    tupMatButton
    appearance="tiny"
    (click)="onOpenSurveyInfo()"
    onclick="this.blur()"
  >
    <mat-icon
      matTooltip="Survey info"
      class="material-icons-outlined"
      >info</mat-icon
    >
  </button>

  <button
    fxFlex="0 0 auto"
    mat-icon-button
    color="primary"
    tupMatButton
    appearance="tiny"
    (click)="onOpenCopyright()"
    onclick="this.blur()"
  >
    <mat-icon
      matTooltip="Survey copyright"
      class="material-icons-outlined"
      >copyright</mat-icon
    >
  </button>
</div>
