<div
  class="card-container"
  #tableCardContainer
>
  <div class="table-container">
    <div [style.height.px]="scrollViewportHeight">
      <cdk-virtual-scroll-viewport
        [tvsItemSize]="tvsItemSize"
        headerEnabled="true"
        [headerHeight]="
          freezeTotals.row ? headerHeight + tvsItemSize : headerHeight
        "
        class="virtual-scroll-viewport"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          class="crossTab-table"
        >
          <ng-container
            matColumnDef="position"
            sticky="true"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="position-header sticky-cell--position"
            >
              <div class="title-position">&nbsp;</div>
              <div class="title-container">
                <span class="title-label">&nbsp;</span>
              </div>
              <div
                *ngIf="freezeTotals.row"
                class="frozen-total-row-cell"
                fxLayout="column"
                fxLayoutAlign="center start"
                (contextmenu)="onContextMenu($event, targetType.rows, 0)"
                [style.height.px]="frozenTotalsRowHeight"
              >
                <ng-container
                  *ngTemplateOutlet="
                    rowPosition;
                    context: { element: totalRow }
                  "
                ></ng-container>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (contextmenu)="
                onContextMenu($event, targetType.rows, element.rowIndex)
              "
              class="sticky-cell--position"
            >
              <ng-container
                *ngTemplateOutlet="rowPosition; context: { element: element }"
              ></ng-container>
            </td>
          </ng-container>

          <ng-container
            matColumnDef="insight"
            sticky="true"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="title-header sticky-cell--title"
            >
              <div class="title-position table-info">
                {{
                  "Rows: " +
                    this.tableInfo.rows.toString() +
                    "&nbsp;&nbsp;&nbsp;&nbsp;" +
                    "Columns: " +
                    this.tableInfo.columns.toString() +
                    "&nbsp;&nbsp;&nbsp;&nbsp;"
                }}
                {{
                  this.tableInfo.filtered !== -1
                    ? "Filtered: " + this.tableInfo.filtered.toString()
                    : ""
                }}
              </div>
              <div class="title-container">
                <span class="title-label">&nbsp;</span>
              </div>
              <div
                *ngIf="freezeTotals.row"
                class="frozen-total-row-cell"
                fxLayout="column"
                fxLayoutAlign="center start"
                (contextmenu)="onContextMenu($event, targetType.rows, 0)"
                [style.height.px]="frozenTotalsRowHeight"
              >
                <ng-container
                  *ngTemplateOutlet="rowTitle; context: { element: totalRow }"
                ></ng-container>
              </div>
            </th>

            <ng-template [ngIf]="isReadonly">
              <td
                mat-cell
                *matCellDef="let element"
                class="sticky-cell--title"
                [ngClass]="{
                  'code-builder-trigger__readonly': !element.isTotalRow
                }"
              >
                <ng-container
                  *ngTemplateOutlet="rowTitle; context: { element: element }"
                ></ng-container>
              </td>
            </ng-template>

            <ng-template [ngIf]="!isReadonly">
              <td
                mat-cell
                *matCellDef="let element"
                (click)="onClickRowTitle(element.rowIndex)"
                [dndDropzone]
                [dndDisableDropIf]="element.isTotalRow"
                dndDragoverClass="drag-over-row-title"
                (dragenter)="
                  onDropzoneEnter(
                    dropZoneMenuTrigger,
                    targetType.rows,
                    element.rowIndex
                  )
                "
                (dragleave)="onDropzoneLeave(targetType.rows, element.rowIndex)"
                (dndDrop)="onDrop(targetType.rows, element.rowIndex)"
                (contextmenu)="
                  onContextMenu($event, targetType.rows, element.rowIndex)
                "
                class="sticky-cell--title"
                [ngClass]="{
                  'code-builder-trigger': !element.isTotalRow
                }"
                (mouseenter)="!element.isTotalRow && codeTooltip.show()"
                (mouseleave)="!element.isTotalRow && codeTooltip.hide()"
              >
                <mat-icon
                  matTooltipPosition="below"
                  #codeTooltip="matTooltip"
                  matTooltip="Edit coding"
                  class="code-builder-trigger__icon"
                >
                  code
                </mat-icon>

                <span
                  class="title-menu-trigger"
                  [matMenuTriggerFor]="dropzoneMenu"
                  #dropZoneMenuTrigger="matMenuTrigger"
                ></span>
                <ng-container
                  *ngTemplateOutlet="rowTitle; context: { element: element }"
                ></ng-container>
              </td>
            </ng-template>
          </ng-container>

          <ng-container
            matColumnDef="dataPoints"
            sticky="true"
          >
            <th
              mat-header-cell
              *matHeaderCellDef="let element"
              class="data-points-header sticky-cell--data-points"
            >
              <div class="title-position">&nbsp;</div>
              <div class="title-container">
                <span class="title-label">&nbsp;</span>
              </div>
              <div
                *ngIf="freezeTotals.row"
                class="frozen-total-row-cell"
              >
                <ng-container
                  *ngTemplateOutlet="
                    rowDataPoints;
                    context: { element: totalRow }
                  "
                ></ng-container>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="sticky-cell--data-points"
            >
              <ng-container
                *ngTemplateOutlet="rowDataPoints; context: { element: element }"
              ></ng-container>
            </td>
          </ng-container>

          <ng-container
            *ngFor="let column of targetColumns; let iCol = index"
            matColumnDef="{{ column.name }}"
            [sticky]="isStickyTotalsColumn(iCol)"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="target-column-header"
            >
              <div
                class="target-position-title-container"
                (click)="onClickColumnTitle(iCol)"
                [dndDropzone]
                [dndDisableDropIf]="iCol === 0"
                dndDragoverClass="drag-over-column-title"
                (dragenter)="
                  onDropzoneEnter(dropZoneMenuTrigger, targetType.columns, iCol)
                "
                (dragleave)="onDropzoneLeave(targetType.columns, iCol)"
                (dndDrop)="onDrop(targetType.columns, iCol)"
              >
                <div
                  class="title-position"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <span>{{ column.element.columnPosition }}</span>
                  <div
                    fxLayout="row"
                    class="column-header-icons-container"
                  >
                    <mat-icon
                      class="column-header-icon column-header-menu-icon"
                      matTooltip="Sort and filter"
                      matTooltipPosition="above"
                      (click)="
                        onColumnMenuClick(
                          $event,
                          iCol,
                          column.element.surveyCode
                        )
                      "
                      >more_vert</mat-icon
                    >
                    <mat-icon
                      *ngIf="column.columnId === sortSettings.columnId"
                      class="column-header-icon"
                      >{{
                        sortSettings.order === sortDirectionType.asc
                          ? "arrow_upward"
                          : "arrow_downward"
                      }}</mat-icon
                    >
                    <mat-icon
                      *ngIf="
                        columnHeaderFilterIds.indexOf(column.columnId) !== -1
                      "
                      class="column-header-icon column-header-filter-icon"
                      (click)="
                        onColumnFilterClick(
                          $event,
                          iCol,
                          column.element.surveyCode
                        )
                      "
                      >filter_alt</mat-icon
                    >
                  </div>
                </div>
                <span
                  class="title-menu-trigger title-menu-trigger--column"
                  [matMenuTriggerFor]="dropzoneMenu"
                  #dropZoneMenuTrigger="matMenuTrigger"
                ></span>
                <div
                  class="title-container"
                  (contextmenu)="
                    onContextMenu(
                      $event,
                      targetType.columns,
                      iCol,
                      column.element.surveyCode
                    )
                  "
                >
                  <ng-template [ngIf]="!isReadonly">
                    <span
                      class="title-label"
                      (mouseenter)="
                        column.element.columnPosition !== 0 &&
                          codeTooltip.show()
                      "
                      (mouseleave)="
                        column.element.columnPosition !== 0 &&
                          codeTooltip.hide()
                      "
                      [ngClass]="{
                        'code-builder-trigger':
                          column.element.columnPosition !== 0
                      }"
                    >
                      <mat-icon
                        matTooltipPosition="below"
                        #codeTooltip="matTooltip"
                        matTooltip="Edit coding"
                        class="code-builder-trigger__icon"
                      >
                        code
                      </mat-icon>
                      <ng-container
                        *ngTemplateOutlet="
                          columnTitle;
                          context: { column: column }
                        "
                      ></ng-container>
                    </span>
                  </ng-template>

                  <ng-template [ngIf]="isReadonly">
                    <span
                      class="title-label"
                      [ngClass]="{
                        'code-builder-trigger__readonly':
                          column.element.columnPosition !== 0
                      }"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          columnTitle;
                          context: { column: column }
                        "
                      ></ng-container>
                    </span>
                  </ng-template>
                </div>
              </div>
              <div
                *ngIf="freezeTotals.row"
                class="frozen-total-row-cell"
              >
                <ng-container
                  *ngTemplateOutlet="
                    rowColumnTarget;
                    context: { element: totalRow, columnIndex: iCol }
                  "
                ></ng-container>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngStyle]="{
                'background-color':
                  cellColors[iCol + '_' + element.rowIndex] ||
                  surveyColors[column.element.surveyCode]
              }"
              [class.has-cell-color]="
                !!cellColors[iCol + '_' + element.rowIndex]
              "
              class="row-cell"
              [ngClass]="{
                'z-score-green':
                  !element.isTotalRow &&
                  !element.data[iCol].isTotalsColumn &&
                  zScoreHighlight &&
                  element.data[iCol][
                    dataItemsMap[dataItemType.filterZScore].cellKey
                  ] !== undefined &&
                  element.data[iCol][
                    dataItemsMap[dataItemType.filterZScore].cellKey
                  ].toFixed(
                    decimalPoints[
                      dataItemsMap[dataItemType.filterZScore].displayName
                    ]
                  ) > zScoreHighlightProb,
                'z-score-red':
                  !element.isTotalRow &&
                  !element.data[iCol].isTotalsColumn &&
                  zScoreHighlight &&
                  element.data[iCol][
                    dataItemsMap[dataItemType.filterZScore].cellKey
                  ] !== undefined &&
                  element.data[iCol][
                    dataItemsMap[dataItemType.filterZScore].cellKey
                  ].toFixed(
                    decimalPoints[
                      dataItemsMap[dataItemType.filterZScore].displayName
                    ]
                  ) < zScoreHighlightProb
              }"
            >
              <ng-container
                *ngTemplateOutlet="
                  rowColumnTarget;
                  context: { element: element, columnIndex: iCol }
                "
              ></ng-container>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.is-affinity-target-row]="
              !isLoading &&
              affinityRow &&
              row.data[0].rowTarget &&
              row.data[0].rowTarget.id === affinityRow.id
            "
          ></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

  <div
    class="spinner-container-overlay"
    *ngIf="isLoading"
    data-qa="crosstab-loading"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<ng-template
  #columnTitle
  let-column="column"
>
  <span
    *ngIf="column.element.surveyCode && surveys.length > 1"
    class="title-label--survey-code"
  >
    {{ column.element.surveyCode }}
  </span>
  <span
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
    fxGrow="1"
    [title]="
      column.element.columnTarget || column.element
        | targetTitle
          : column.element.columnTarget?.activeTitleMode
          : column.element.columnTarget?.titleLevels
    "
  >
    {{
      column.element.columnTarget || column.element
        | targetTitle
          : column.element.columnTarget?.activeTitleMode
          : column.element.columnTarget?.titleLevels
        | truncate: 40
    }}
  </span>
</ng-template>

<ng-template
  #rowPosition
  let-element="element"
>
  <span>{{ element.position }}</span>
</ng-template>

<ng-template
  #rowTitle
  let-element="element"
>
  <span
    class="insight-label"
    [title]="
      element.data[0].rowTarget || element.data[0]
        | targetTitle
          : element.data[0].rowTarget?.activeTitleMode
          : element.data[0].rowTarget?.titleLevels
    "
  >
    {{
      element.data[0].rowTarget || element.data[0]
        | targetTitle
          : element.data[0].rowTarget?.activeTitleMode
          : element.data[0].rowTarget?.titleLevels
        | truncate: 80
    }}
  </span>
</ng-template>

<ng-template
  #rowDataPoints
  let-element="element"
>
  <ng-container *ngIf="!element.isPlaceholder">
    <p
      class="cell-line"
      *ngFor="let dataItem of dataItems"
      [class.is-active-sort-data-item]="
        !element.isTotalRow &&
        isSortActive &&
        dataItem.id === sortSettings.dataItem
      "
    >
      <ng-container *ngIf="!element.metadata?.hasVolumetricCoding">
        <p>
          {{ dataItem.displayName
          }}{{
            dataItem.id === dataItemType.audience
              ? (reportUnits | formatReportUnits)
              : ""
          }}
        </p>
      </ng-container>
      <ng-container *ngIf="element.metadata?.hasVolumetricCoding">
        <p>
          {{
            dataItem.id === dataItemType.audience
              ? dataItem.volumetricDisplayName
              : dataItem.displayName
          }}
        </p>
      </ng-container>
    </p></ng-container
  >

  <ng-container *ngIf="element.isPlaceholder">
    <p
      class="cell-line"
      *ngFor="let dataItem of dataItems"
    >
      -
    </p>
  </ng-container>
</ng-template>

<ng-template
  #rowColumnTarget
  let-element="element"
  let-columnIndex="columnIndex"
>
  <div
    class="table-cell"
    *ngIf="element.isPlaceholder"
  >
    <p class="cell-line">-</p>
    <p class="cell-line">-</p>
    <p class="cell-line">-</p>
    <p class="cell-line">-</p>
    <p class="cell-line">-</p>
  </div>
  <div
    class="table-cell"
    *ngIf="!element.isPlaceholder"
  >
    <span *ngFor="let dataItem of dataItems; let i = index">
      <span
        *ngIf="dataItem.id === dataItemType.audience"
        fxLayout="row"
        fxLayoutAlign="end"
      >
        <span
          fxLayout="row"
          *ngIf="stabilityFlagStatus"
        >
          <p
            class="cell-line"
            *ngIf="
              element.data[columnIndex].sample <= stabilityFlagStatus.firstLevel
            "
          >
            *
          </p>
          <p
            class="cell-line"
            *ngIf="
              element.data[columnIndex].sample <=
              stabilityFlagStatus.secondLevel
            "
          >
            *
          </p>
        </span>
        <p
          class="cell-line"
          [class.is-active-sort-data-item]="
            !element.isTotalRow &&
            isSortActive &&
            sortSettings.dataItem === dataItemType.audience
          "
          [class.is-highlighted-data-item]="
            !element.isTotalRow &&
            !element.data[columnIndex].isTotalsColumn &&
            !element.data[columnIndex].isAffinityRow &&
            cellStyleStatus === 'highlight' &&
            highlightValues.dataItemId === dataItem.id
          "
        >
          {{
            element.data[columnIndex].projected !== null &&
            element.data[columnIndex].projected !== undefined
              ? (element.data[columnIndex].projected.toFixed(
                  decimalPoints[dataItem.displayName]
                ) | number: dataItem.digitsInfo)
              : ""
          }}&nbsp;
        </p>
      </span>
      <ng-container
        *ngIf="element.data[columnIndex].metadata?.isVolumetricCoding"
      >
        <p
          *ngIf="
            volumetricDataItemIds.includes(dataItem.id) &&
            dataItem.id !== dataItemType.audience
          "
          class="cell-line"
          [class.is-active-sort-data-item]="
            !element.isTotalRow &&
            isSortActive &&
            sortSettings.dataItem === dataItem.id
          "
          [class.is-highlighted-data-item]="
            !element.isTotalRow &&
            !element.data[columnIndex].isTotalsColumn &&
            !element.data[columnIndex].isAffinityRow &&
            cellStyleStatus === 'highlight' &&
            highlightValues.dataItemId === dataItem.id
          "
        >
          {{
            element.data[columnIndex][dataItemsMap[dataItem.id].cellKey] !==
              null &&
            element.data[columnIndex][dataItemsMap[dataItem.id].cellKey] !==
              undefined
              ? (element.data[columnIndex][
                  dataItemsMap[dataItem.id].cellKey
                ].toFixed(decimalPoints[dataItem.displayName])
                | number: dataItem.digitsInfo)
              : ""
          }}&nbsp;
        </p>
        <p
          class="cell-line"
          *ngIf="
            dataItem.id !== dataItemType.sample &&
            dataItem.id !== dataItemType.audience
          "
        >
          -
        </p>
      </ng-container>
      <ng-container
        *ngIf="!element.data[columnIndex].metadata?.isVolumetricCoding"
      >
        <p
          *ngIf="dataItem.name !== 'Audience'"
          class="cell-line"
          [class.is-active-sort-data-item]="
            !element.isTotalRow &&
            isSortActive &&
            sortSettings.dataItem === dataItem.id
          "
          [class.is-highlighted-data-item]="
            !element.isTotalRow &&
            !element.data[columnIndex].isTotalsColumn &&
            !element.data[columnIndex].isAffinityRow &&
            cellStyleStatus === 'highlight' &&
            highlightValues.dataItemId === dataItem.id
          "
        >
          {{
            element.data[columnIndex][dataItemsMap[dataItem.id].cellKey] !==
              null &&
            element.data[columnIndex][dataItemsMap[dataItem.id].cellKey] !==
              undefined
              ? (element.data[columnIndex][
                  dataItemsMap[dataItem.id].cellKey
                ].toFixed(decimalPoints[dataItem.displayName])
                | number: dataItem.digitsInfo)
              : ""
          }}&nbsp;
        </p>
      </ng-container>
    </span>
  </div>
</ng-template>
