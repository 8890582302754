<div class="cross-tab-content">
  <tup-mat-rightsidebar
    fxFlex
    [closeButton]="true"
    [(opened)]="rightSidebarOpened"
  >
    <tup-mat-rightsidebar-menuitem
      menuName="Crosstab setup"
      matIconName="table_view"
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-title-mode"
          mat-button
          tupSearchableItem
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="titleModeMenu"
          *ngIf="
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
        >
          Title mode
        </button>
        <mat-menu
          #titleModeMenu="matMenu"
          class="mat-menus-rhs"
        >
          <div
            fxLayout="column"
            fxLayoutGap="5px"
            class="menu-content-popup"
          >
            <mat-radio-group
              [(ngModel)]="titleMode"
              fxLayout="column"
              fxLayoutGap="5px"
              (ngModelChange)="selectTitleMode()"
            >
              <mat-radio-button
                *ngFor="let mode of titleModes"
                [value]="mode.displayType"
                (click)="clickTitleMode(mode.displayType)"
                >{{ mode.name }}</mat-radio-button
              >
            </mat-radio-group>
            <button
              mat-flat-button
              color="primary"
              tupMatButton
              class="menu-flat-button"
              (click)="resetTitleMode()"
            >
              Reset
            </button>
          </div>
        </mat-menu>
        <button
          data-tracking="menu-data-item"
          mat-button
          tupSearchableItem
          (click)="openDataItemsDialog()"
        >
          Data items
        </button>
        <button
          data-tracking="menu-manage-report-item"
          mat-button
          tupSearchableItem
          *ngIf="!isReadonly"
          (click)="openManageReportItemsDialog()"
        >
          Manage report Items
        </button>
        <button
          data-tracking="menu-data-context"
          mat-button
          tupSearchableItem
          *ngIf="
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          (click)="openTitleLevelsDialog()"
        >
          Data context
        </button>
        <button
          data-tracking="menu-swap"
          mat-button
          tupSearchableItem
          *ngIf="
            activeReportMode !== reportMode.affinity &&
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          (click)="swapRowsAndColumns()"
          [matTooltip]="swapActionWarning"
        >
          Swap rows/columns
        </button>
      </div>
    </tup-mat-rightsidebar-menuitem>

    <tup-mat-rightsidebar-menuitem
      menuName="Crosstab formatting"
      matIconName="backup_table"
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-highlight"
          mat-button
          tupSearchableItem
          (click)="openHighlightDialog()"
        >
          Highlight values
        </button>
        <button
          data-tracking="menu-heatmap"
          mat-button
          tupSearchableItem
          (click)="openHeatmapDialog()"
        >
          Heatmap
        </button>
        <button
          *ngIf="selectedSurvey?.provider === 'YouGov'"
          mat-button
          tupSearchableItem="Z-score filtered highlight"
          (click)="toggleZScoreHighlight()"
          data-tracking="menu-zscore-filtered-highlight"
        >
          Z-score filtered highlight
          <mat-icon class="formatting-switch-flag-icon">{{
            zScoreHighlight ? "toggle_on" : "toggle_off"
          }}</mat-icon>
        </button>
        <span
          class="stability-flag"
          matTooltip="The stability flag cannot be turned off, please contact your Telmar representative for more details."
        >
          <button
            mat-button
            tupSearchableItem="Stability flag"
            [disabled]="true"
            (click)="showStabilityFlag()"
          >
            Stability flag
            <mat-icon class="formatting-switch-flag-icon">{{
              stabilityFlagOn ? "toggle_on" : "toggle_off"
            }}</mat-icon>
          </button>
        </span>
        <button
          *ngIf="
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          mat-button
          tupSearchableItem
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="significanceTestingMenu"
          data-tracking="menu-significance"
        >
          Significance testing
        </button>
        <mat-menu
          #significanceTestingMenu="matMenu"
          class="mat-menus-rhs"
        >
          <div
            fxLayout="column"
            fxLayoutGap="5px"
            class="menu-content-popup"
          >
            <mat-radio-group
              [(ngModel)]="significanceTestingVar"
              fxLayout="column"
              fxLayoutGap="5px"
              (ngModelChange)="turnOnSignificanceTesting()"
              data-qa="significance-testing-radio-group"
            >
              <mat-radio-button [value]="'column'">Column</mat-radio-button>
              <mat-radio-button [value]="'row'">Rows</mat-radio-button>
            </mat-radio-group>
          </div>
        </mat-menu>
      </div>
    </tup-mat-rightsidebar-menuitem>

    <tup-mat-rightsidebar-menuitem
      menuName="Custom audiences/media"
      matIconName="bookmark"
      *ngIf="!isReadonly"
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-save-audience"
          mat-button
          tupSearchableItem
          (click)="saveOwnCodes(saveOwnCodesType.audience)"
        >
          Save audiences
        </button>
        <button
          mat-button
          tupSearchableItem
          (click)="saveOwnCodes(saveOwnCodesType.media)"
          [disabled]="true"
        >
          Save media
        </button>
      </div>
    </tup-mat-rightsidebar-menuitem>
    <app-main>
      <router-outlet></router-outlet>
    </app-main>
  </tup-mat-rightsidebar>
</div>

<div
  class="spinner-container-overlay"
  *ngIf="isLoading"
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
