<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="end start"
>
  <div
    class="action-controls__spinner-container-overlay"
    *ngIf="isLoadingSelectedNodes && isLoadingFromButtons"
  >
    <div class="action-controls__center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>

  <div
    data-tracking="codebook-unselect-all"
    fxLayout="column"
  >
    <button
      [disabled]="selectedNodes.length === 0"
      fxFlex="0 0 auto"
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="tiny"
      (click)="onUnselectAll()"
      class="control-button control-button--small-icon"
    >
      <mat-icon
        matTooltip="Unselect all"
        class="material-icons-outlined"
      >
        remove_done
      </mat-icon>
    </button>
  </div>

  <div
    data-tracking="codebook-add-table"
    fxLayout="column"
  >
    <ng-container
      *ngTemplateOutlet="
        addButtonTemplate;
        context: {
          type: 0,
          tooltip: 'Add to tables',
          separatedTooltip: 'Add one code to one table',
          separateCountTooltip: 'Add one code with count to one table',
          combinedTooltip: 'Combined codes to one table'
        }
      "
    ></ng-container>
  </div>

  <div
    data-tracking="codebook-add-col"
    fxLayout="column"
  >
    <ng-container
      *ngTemplateOutlet="
        addButtonTemplate;
        context: {
          type: 1,
          tooltip: 'Add to columns(targets)',
          separatedTooltip: 'Add one code to one column',
          separateCountTooltip: 'Add one code with count to one column',
          combinedTooltip: 'Combined codes to one column'
        }
      "
    ></ng-container>
  </div>

  <div
    data-tracking="codebook-add-row"
    fxLayout="column"
  >
    <ng-container
      *ngTemplateOutlet="
        addButtonTemplate;
        context: {
          type: 2,
          tooltip: 'Add to rows(insights)',
          separatedTooltip: 'Add one code to one row',
          separateCountTooltip: 'Add one code with count to one row',
          combinedTooltip: 'Combined codes to one row'
        }
      "
    ></ng-container>
  </div>

  <div
    data-tracking="codebook-more"
    fxLayout="column"
    class="control-button-column--last"
  >
    <button
      [disabled]="selectedNodes.length === 0"
      fxFlex="0 0 auto"
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="tiny"
      class="control-button"
      [matMenuTriggerFor]="moreSettings"
    >
      <mat-icon
        matTooltip="More settings"
        class="material-icons-outlined"
      >
        more_vert
      </mat-icon>
    </button>

    <mat-menu #moreSettings="matMenu">
      <button
        mat-menu-item
        matTooltip="Add all selected nodes to multiple rows/columns/tables"
        [matMenuTriggerFor]="addToMultipleMenu"
      >
        <mat-icon class="material-symbols-outlined">share</mat-icon>
        <span>Add to multiple</span>
      </button>

      <button
        mat-menu-item
        (click)="expandAllClicked()"
        matTooltip="Expand all selected nodes"
      >
        <mat-icon class="material-symbols-outlined">open_in_full</mat-icon>
        <span>Expand all children</span>
      </button>
    </mat-menu>
    <mat-menu #addToMultipleMenu="matMenu">
      <button
        mat-menu-item
        (click)="addToMultiple(targetType.rows)"
      >
        <mat-icon class="material-symbols-outlined">table_rows</mat-icon>
        <span>Add to multiple rows(insights)</span>
      </button>
      <button
        mat-menu-item
        (click)="addToMultiple(targetType.columns)"
      >
        <mat-icon class="material-symbols-outlined">view_column</mat-icon>
        <span>Add to multiple columns(targets)</span>
      </button>
      <button
        mat-menu-item
        (click)="addToMultiple(targetType.tables)"
      >
        <mat-icon class="material-symbols-outlined">backup_table</mat-icon>
        <span>Add to multiple tables</span>
      </button>
    </mat-menu>
  </div>
</div>

<ng-template
  #addButtonTemplate
  let-type="type"
  let-tooltip="tooltip"
  let-separatedTooltip="separatedTooltip"
  let-combinedTooltip="combinedTooltip"
  let-separateCountTooltip="separateCountTooltip"
>
  <button
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    [disabled]="selectedNodes.length === 0"
    class="control-button"
    [matMenuTriggerFor]="addSettings"
  >
    <div [matTooltip]="tooltip">
      <span class="control-button__plus">+</span>
      <ng-container [ngSwitch]="type">
        <svg
          *ngSwitchCase="0"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 4.5H14.5M4.5 0.5V14.5M1.5 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H1.5C0.947716 14.5 0.5 14.0523 0.5 13.5V1.5C0.5 0.947716 0.947715 0.5 1.5 0.5Z"
            stroke="#0054FF"
          />
        </svg>
        <svg
          *ngSwitchCase="1"
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 3.57628e-07L8.5 15M12.5 4.76837e-07L12.5 15M4.5 1.19209e-07L4.5 15M0.500001 0L0.5 15"
            stroke="#0054FF"
          />
        </svg>
        <svg
          *ngSwitchCase="2"
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 4.5H15M0 0.5H15M0 8.5H15M0 12.5H15"
            stroke="#0054FF"
          />
        </svg>
      </ng-container>
    </div>
  </button>

  <mat-menu #addSettings="matMenu">
    <button
      mat-menu-item
      [matTooltip]="separatedTooltip"
      (click)="clickSeparateButton(type)"
    >
      <mat-icon class="material-icons-outlined">
        keyboard_double_arrow_right
      </mat-icon>
      <span>Separate</span>
    </button>

    <button
      mat-menu-item
      [matTooltip]="combinedTooltip"
      [matMenuTriggerFor]="addAsCombined"
    >
      <mat-icon class="material-symbols-outlined">join</mat-icon>
      <span>Combined</span>
    </button>

    <button
      mat-menu-item
      [matTooltip]="separateCountTooltip"
      (click)="clickSeparateCountButton(type)"
    >
      <mat-icon class="material-icons-outlined"> calculate </mat-icon>
      <span>Separate count</span>
    </button>
  </mat-menu>

  <mat-menu #addAsCombined="matMenu">
    <button
      mat-menu-item
      *ngFor="let item of actionItems"
      [matTooltip]="item.tooltip"
      (click)="onCombineActionClicked(item, type)"
    >
      {{ item.name }}
    </button>
  </mat-menu>
</ng-template>
