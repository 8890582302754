import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopBarService {
  private rightSidebarOpenedState: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public rightSidebarOpenedState$ = this.rightSidebarOpenedState.asObservable();

  constructor() {}

  public setRightSidebarOpenedState(opened: boolean): void {
    this.rightSidebarOpenedState.next(opened);
  }
}
