import { Injectable } from '@angular/core';

import fxp from 'fast-xml-parser';
import { Observable, Subscriber } from 'rxjs';

import { TupLoggerService } from '@telmar-global/tup-logger-angular';

import { SurveyTimeDocument } from '../models';

/**
 * Handles all things *.pry related
 */
@Injectable({
  providedIn: 'root',
})
export class PRYFileService {
  private readonly exts: string[] = ['pry'];

  constructor(private loggerService: TupLoggerService) {}

  /**
   * Validates a File extension
   *
   * @param file The File
   * @returns Whether or not the File extension is valid
   */
  public validate(file: File): Observable<File> {
    return new Observable((subscriber: Subscriber<File>) => {
      const ext: string = file.name.split('.').pop();

      if (!this.exts.includes(ext)) {
        subscriber.error(
          new Error(
            `Invalid file extension (.${ext}). Valid extensions are: ${this.exts
              .map((ext: string) => `.${ext}`)
              .join(', ')}.`
          )
        );
      }

      subscriber.next(file);
      subscriber.complete();
    });
  }

  /**
   * Reads a File
   *
   * TODO: Inject a FileReader factory? Unit tests seem a bit fragile.
   *
   * @param file The File
   * @returns The contents of the File as a string
   */
  public read(file: File): Observable<string> {
    const fileReader = new FileReader();

    return new Observable((subscriber: Subscriber<string>) => {
      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        this.loggerService.info(`Reading file '${file.name}'`, file);

        subscriber.next(event.target.result.toString());
        subscriber.complete();
      };

      fileReader.onerror = (error: ProgressEvent<FileReader>): void => {
        subscriber.error(error);
      };

      fileReader.readAsText(file);
    });
  }

  /**
   * Parses an xml string into a JSON Object
   *
   * @param xml the xml string
   * @returns the JSON Object
   */
  public parse(xml: string): Observable<any> {
    // DataItems and Snapshots tagValues are stringified JSON
    const jpaths: string[] = ['PRYFile.DataItems', 'PRYFile.Snapshots'];

    const parser = new fxp.XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: '',
      allowBooleanAttributes: true,
      tagValueProcessor: (tagName: string, tagValue: string, jpath: string) =>
        jpaths.includes(jpath) ? JSON.parse(tagValue) : tagValue,
    });

    return new Observable((subscriber: Subscriber<string>) => {
      try {
        // Throws an fxp.ValidationError if the xml is invalid
        const json: any = parser.parse(xml);

        this.loggerService.info('Parsing xml', { xml, json });

        subscriber.next(json);
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
      }
    });
  }

  /**
   * Transforms a JSON Object into a SurveyTimeDocument
   *
   * TODO: Convert JSON Object into a SurveyTimeDocument (https://splashlight.atlassian.net/browse/TU-6676)
   *
   * @param json The JSON Object
   * @returns a SurveyTimeDocument
   */
  public transform(json: any): Observable<SurveyTimeDocument> {
    return new Observable((subscriber: Subscriber<SurveyTimeDocument>) => {
      try {
        throw new Error('Method not implemented.');

        // const document: SurveyTimeDocument = new SurveyTimeDocument('');

        // this.loggerService.info('Transforming JSON', { json, document });

        // subscriber.next(document);
        // subscriber.complete();
      } catch (error) {
        subscriber.error(error);
      }
    });
  }
}
