import { Injectable } from '@angular/core';
import { DisplayType } from '../models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleModeService {
  // tslint:disable-next-line:variable-name
  private _titleMode: BehaviorSubject<DisplayType> =
    new BehaviorSubject<DisplayType>(DisplayType.title);
  public titleMode$: Observable<DisplayType> = this._titleMode.asObservable();

  public updateTitleMode(newTitleMode: DisplayType): void {
    this._titleMode.next(newTitleMode);
  }
}
