import { Pipe, PipeTransform } from '@angular/core';

import { compact } from 'lodash';

@Pipe({
  name: 'chartTitle',
})
export class ChartTitlePipe implements PipeTransform {
  public transform(
    chartTitle: string,
    targetTitle: string,
    primaryChartType: string | boolean,
    primaryDataItemSelection: string,
    secondaryChartType: string | boolean,
    secondaryDataItemSelection: string
  ): string {
    const titleParts: string[] = [
      targetTitle,
      primaryDataItemSelection,
      primaryChartType === 'tupScatter' || secondaryChartType
        ? secondaryDataItemSelection
        : undefined,
    ];

    return chartTitle ? chartTitle : compact(titleParts).join(' - ');
  }
}
