import { Component, Input, OnInit } from '@angular/core';

import { TupDocument } from '@telmar-global/tup-document-storage';

import { SurveyTimeDocument, Survey } from '../../models';

/**
 * Displays an Array of Surveys as a comma separated list of codes.
 *
 * Hovering over a survey code displays the survey title as a tooltip.
 */
@Component({
  templateUrl: './surveys-table-cell.component.html',
})
export class SurveysTableCellComponent implements OnInit {
  @Input() public document: TupDocument<SurveyTimeDocument>;

  public surveys: Survey[];

  public ngOnInit(): void {
    this.surveys = this.document?.content?.surveys || [];
  }
}
