<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="document-controls-container"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="edit-name"
  >
    <div
      fxFlex
      class="edit-name__copy"
      *ngIf="isReadonly"
    >
      <span>{{ docName }}</span>
    </div>

    <app-edit-name
      fxFlex
      inputId="0"
      *ngIf="!isReadonly"
    ></app-edit-name>

    <app-surveys-info
      *ngIf="showSurveyInfo"
      [surveys]="surveys"
    ></app-surveys-info>
  </div>

  <div
    fxLayout="row"
    fxFlex
    fxLayoutAlign="end center"
  >
    <div
      fxLayout="row"
      fxFlex
      fxLayoutAlign="start center"
    >
      <tup-mat-ichabods-tabs
        #ichabodsTabs
        *ngIf="ichabodsBody"
        [ichabodsBody]="ichabodsBody"
        class="ichabods-tabs"
      ></tup-mat-ichabods-tabs>
    </div>
    <div
      class="toolbar-buttons"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div
          *ngIf="!isReadonly"
          fxLayout="row"
          fxLayoutGap="7px"
          fxLayoutAlign="start center"
          class="document-state-container"
        >
          <ng-container *ngIf="!isSaving">
            <span data-qa="document-saved">Saved</span>
          </ng-container>
          <ng-container *ngIf="isSaving">
            <span data-qa="document-saving"
              >{{ isUndoing ? "Undoing last edit" : "Saving" }}...</span
            >
            <mat-icon>sync</mat-icon>
          </ng-container>
        </div>

        <mat-chip-list
          *ngIf="isReadonly"
          class="readonly"
        >
          <mat-chip
            [matTooltip]="
              'This document is owned by ' +
              docOwnerName +
              ' and has been granted view-only access to you. Any modifications will not be saved. To save the changes, please create a copy of the document.'
            "
          >
            <mat-icon class="tiny-icon"> lock </mat-icon>
            View-only</mat-chip
          >
        </mat-chip-list>
      </div>
      <button
        *ngIf="!isReadonly"
        data-tracking="document-undo-btn"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Undo"
        (click)="undo()"
        [disabled]="!canUndo"
        data-qa="document-undo-button"
      >
        <mat-icon>undo</mat-icon>
      </button>
      <button
        data-tracking="document-download-btn"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Download"
        [matMenuTriggerFor]="exportMenuInDataView"
        *ngIf="selectViewType === documentViewType.data"
      >
        <mat-icon>file_download</mat-icon>
      </button>
      <mat-menu #exportMenuInDataView="matMenu">
        <button
          mat-menu-item
          (click)="onExportToCsv()"
        >
          CSV
        </button>
        <button
          mat-menu-item
          (click)="onExportToXlsx()"
        >
          XLSX
        </button>
        <button
          mat-menu-item
          (click)="onExportToSheets()"
        >
          Google Sheets
        </button>
      </mat-menu>
      <save-menu></save-menu>
      <button
        data-tracking="download-charts"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Download all or selected charts"
        [matMenuTriggerFor]="exportMenuInGraphView"
        *ngIf="selectViewType === documentViewType.graph"
        [disabled]="!canExportCharts"
      >
        <mat-icon>file_download</mat-icon>
      </button>
      <mat-menu
        #exportMenuInGraphView="matMenu"
        class="tup-mat-menu-panel-small"
      >
        <button
          mat-menu-item
          (click)="onExportToPptx()"
        >
          PPT
        </button>
        <button
          mat-menu-item
          (click)="onExportToXlsx()"
        >
          XLSX
        </button>
        <button
          mat-menu-item
          (click)="onExportToSlides()"
        >
          Google Slides
        </button>
        <button
          mat-menu-item
          (click)="onExportToSheets()"
        >
          Google Sheets
        </button>
      </mat-menu>

      <button
        data-tracking="download-pmaps"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Download"
        [matMenuTriggerFor]="exportMenuInPMapsView"
        *ngIf="selectViewType === documentViewType.pmaps"
      >
        <mat-icon>file_download</mat-icon>
      </button>
      <mat-menu #exportMenuInPMapsView="matMenu">
        <button
          mat-menu-item
          (click)="onExportToPNG()"
        >
          PNG
        </button>
        <button
          mat-menu-item
          (click)="onExportToJPEG()"
        >
          JPEG
        </button>
        <button
          mat-menu-item
          (click)="onExportToPDF()"
        >
          PDF
        </button>
        <button
          mat-menu-item
          (click)="onExportToSVG()"
        >
          SVG
        </button>
      </mat-menu>

      <mat-button-toggle-group
        [(ngModel)]="selectViewType"
        (ngModelChange)="onToggleViewChanged()"
      >
        <mat-button-toggle
          data-tracking="data-view"
          [disableRipple]="true"
          [value]="documentViewType.data"
        >
          Data
        </mat-button-toggle>
        <mat-button-toggle
          data-tracking="charts-view"
          [disableRipple]="true"
          [value]="documentViewType.graph"
        >
          Charts
        </mat-button-toggle>
        <mat-button-toggle
          *ngIf="selectViewType === documentViewType.pmaps"
          data-tracking="pmaps-view"
          [disableRipple]="true"
          [value]="documentViewType.pmaps"
        >
          PMaps
        </mat-button-toggle>
      </mat-button-toggle-group>
      <tup-mat-rightsidebar-button
        *ngIf="
          selectViewType === documentViewType.data &&
          !(isReadonly && selectedTab === viewType.codingGrid)
        "
        (click)="onOpenRightSidebarClick()"
      ></tup-mat-rightsidebar-button>
    </div>
  </div>
</div>
