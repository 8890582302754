import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  ColumnFiltersDialogComponent,
  ColumnFiltersDialogResultDataModel,
} from '../dialogs';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { MatDialog } from '@angular/material/dialog';
import { TargetItem } from '../models';
import { CrosstabService, ReportPreferencesService } from '../services';

export interface AddOrEditFilterActionContext {
  targetItem?: TargetItem;
  columnId?: string;
  surveyCode: string;
  showColumnOptions?: boolean;
  filterSingleColumn?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AddOrEditFilterAction extends ActionDefinition<AddOrEditFilterActionContext> {
  constructor(
    private dialog: MatDialog,
    private crossTabService: CrosstabService,
    private reportPreferencesService: ReportPreferencesService
  ) {
    super();
  }

  public invoke(context: AddOrEditFilterActionContext): void {
    this.dialog
      .open(ColumnFiltersDialogComponent, {
        data: {
          columnId: this.getColumnId(context),
          surveyCode: context.surveyCode,
          showColumnOptions: context.showColumnOptions || false,
        },
        closeOnNavigation: true,
        width: '800px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data: ColumnFiltersDialogResultDataModel) => {
        this.reportPreferencesService.addOrUpdateColumnHeaderFilters(
          data.columnId,
          this.crossTabService.getTableColumnTarget(data.columnId),
          data.filters,
          context.filterSingleColumn
        );
      });
  }

  private getColumnId(context: AddOrEditFilterActionContext): string {
    return (
      context.columnId ||
      (context.targetItem
        ? this.crossTabService.formatTargetColumnId(
            context.targetItem.target,
            context.surveyCode
          )
        : '')
    );
  }
}
