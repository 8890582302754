<div
  fxFill
  class="codebook-navigation"
>
  <ng-container *ngTemplateOutlet="codebookContent"> </ng-container>
</div>

<ng-template #codebookContent>
  <div
    fxFill
    fxLayout="column"
  >
    <div fxLayout="row">
      <div
        [fxFlex]="isReadonly ? 100 : 35"
        [ngClass]="{
          'cat-select--full-width': inCodeBuilder
        }"
      >
        <mat-form-field
          appearance="outline"
          class="select-small codebook-category"
          tupMatFormField
        >
          <mat-select
            class="category-text"
            [(ngModel)]="categoryFilter"
            multiple
            (openedChange)="openedChange($event)"
          >
            <mat-option
              #matOption
              *ngFor="let cat of categories; let index = index"
              [id]="'mat-option-cat-' + index"
              [value]="cat.value"
              (click)="selectionChanged(matOption)"
            >
              {{ cat.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxFlex="65"
        *ngIf="!inCodeBuilder && !isReadonly"
      >
        <app-action-controls
          (addToMultipleClick)="onAddToMultipleClick($event)"
          (separateClick)="onSeparateClick($event)"
          (combineActionClick)="onCombineActionClick($event)"
          (separateCountClick)="onSeparateCountClick($event)"
          (expandAllClick)="onExpandAllClick()"
        ></app-action-controls>
      </div>

      <div *ngIf="inCodeBuilder">
        <button
          [disabled]="selectedNodes.length === 0"
          fxFlex="0 0 auto"
          mat-stroked-button
          color="primary"
          tupMatButton
          appearance="tiny"
          (click)="onUnselectAll()"
          class="control-button control-button--small-icon"
        >
          <mat-icon
            matTooltip="Unselect all"
            class="material-icons-outlined"
          >
            remove_done
          </mat-icon>
        </button>
      </div>
    </div>

    <app-tree-view
      #codebook
      [isReadonly]="isReadonly"
      [categoryFilter]="categoryFilter"
      [data]="codebookData"
      [validWeights]="validWeights"
      [showValidWeightTooltip]="showValidWeightTooltip"
      [weightDescriptions]="weightDescriptions"
      [searching]="searching"
      (loadData)="onCodebookLoadData($event)"
      (treeContextMenu)="onCodebookTreeContextMenu($event)"
      fxFlex
    ></app-tree-view>

    <div
      class="filter-search-bar"
      fxLayout="column"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        class="input-small"
        fxFlex
      >
        <input
          class="text-field"
          placeholder="Filter"
          matInput
          [(ngModel)]="filterText"
          (keydown.enter)="onFilter()"
        />
        <button
          matSuffix
          mat-icon-button
          (click)="onFilter()"
        >
          <mat-icon>filter_list</mat-icon>
        </button>
      </mat-form-field>
      <div
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          class="input-small"
          fxFlex
        >
          <input
            class="text-field"
            placeholder="Search"
            matInput
            [(ngModel)]="searchText"
            (keydown.enter)="onSearch()"
          />
          <button
            matSuffix
            mat-icon-button
            (click)="onSearch()"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <button
          class="reset"
          mat-stroked-button
          tupMatButton
          appearance="nano"
          color="primary"
          (click)="onReset()"
        >
          <mat-icon>autorenew</mat-icon>
        </button>
      </div>
    </div>

    <mat-expansion-panel
      class="advanced-search-options"
      #panel
      displayMode="flat"
      togglePosition="before"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <button
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="nano"
          >
            <mat-icon>{{
              panel.expanded ? "keyboard_arrow_down" : "keyboard_arrow_up"
            }}</mat-icon>
          </button>
          <div>Advanced search options</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div
          class="filter-search-options"
          fxLayout="row"
          fxLayoutGap="10px"
        >
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            [(ngModel)]="searchSelected"
            color="primary"
            fxFlex
            >Only search selected</mat-checkbox
          >
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            [(ngModel)]="searchAgain"
            color="primary"
            fxFlex
            >Search again</mat-checkbox
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start center"
        >
          <label class="bold-label">Search mode</label>
          <mat-form-field
            fxFlex
            appearance="outline"
            tupMatFormField
            class="select-small"
          >
            <mat-select
              class="search-options-text"
              [(ngModel)]="searchMode"
            >
              <mat-option
                #matOption
                *ngFor="let option of searchOptions"
                [value]="option.value"
              >
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <div
            class="options-field"
            fxFlex
            fxLayout="column"
          >
            <mat-label class="bold-label">Tree search option</mat-label>
            <mat-checkbox
              tupMatCheckbox
              appearance="small"
              [(ngModel)]="searchOwnCodes"
              color="primary"
              fxFlex
              >Search own codes</mat-checkbox
            >
          </div>
          <div
            class="options-field"
            fxFlex
            fxLayout="column"
            style="margin-left: 20px"
          >
            <mat-label class="bold-label">Search for</mat-label>
            <mat-radio-group
              class="tup-mat-radio-small"
              [(ngModel)]="searchForTitle"
              fxFlex
              fxLayout="row wrap"
              fxLayoutGap="10px grid"
            >
              <mat-radio-button [value]="true">Title</mat-radio-button>
              <mat-radio-button [value]="false">Code</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>
