import {
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CodebookSelectionService,
  CrosstabService,
  DataItemsService,
  DialogService,
  DocumentService,
  HighlightCellsService,
  LoadingNode,
  LoadingSource,
  TargetService,
  TitleLevelsService,
  TitleModeService,
} from '../../services';
import {
  CellStyleStatus,
  ColumnFilterClick,
  DATA_ITEMS_MAP,
  DataItem,
  DEFAULT_WEIGHT_INDEX,
  DisplayType,
  DocumentDataState,
  DropzoneMenuType,
  HEATMAP_QUARTILES,
  HEATMAP_QUINTILES,
  MenuEventTrigger,
  MenuEventType,
  Operator,
  ReportMode,
  ReportPreference,
  Statement,
  Survey,
  SurveyMetaDataWeights,
  SWAP_ROWS_COLUMNS_WARNING,
  Target,
  TargetItem,
  TargetType,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
} from '../../models';
import { Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { DropDataContext } from '../../pages';
import {
  ColumnHeaderMenuActionItem,
  ColumnRowActionItem,
  ContextMenuActionItem,
  DEFAULT_BASE_TABLE_ACTION_ITEMS,
  DEFAULT_COLUMN_HEADER_ACTION_ITEMS,
  DEFAULT_ROW_TITLE_ACTION_ITEMS,
  DEFAULT_ROW_TOTALS_ACTION_ITEMS,
  DEFAULT_TABLE_COLUMN_ACTION_ITEMS,
  DEFAULT_TABLE_ROW_ACTION_ITEMS,
  DropActionItem,
  getColumnActionItems,
  SWAP_ROWS_COLUMNS_ACTION_NAME,
  TitleModeActionItem,
} from 'src/app/models/action.model';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { CrosstabTableComponent } from '../crosstab-table/crosstab-table.component';
import { SeparatedRankReportTableComponent } from '../separated-rank-report-table/separated-rank-report-table.component';
import { CombinedRankReportTableComponent } from '../combined-rank-report-table/combined-rank-report-table.component';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { MatSelectChange } from '@angular/material/select';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'app-crosstab-view',
  templateUrl: './crosstab-view.component.html',
  styleUrls: ['./crosstab-view.component.scss'],
})
export class CrosstabViewComponent implements OnInit, OnDestroy {
  public readonly HEATMAP_QUARTILES = HEATMAP_QUARTILES;
  public readonly HEATMAP_QUINTILES = HEATMAP_QUINTILES;
  public readonly SWAP_ROWS_COLUMNS_ACTION_NAME = SWAP_ROWS_COLUMNS_ACTION_NAME;
  public readonly menuItemDragoverClass = 'drag-over-menu-button';
  public readonly zScoreHighlightProb = Z_SCORE_FILTERED_HIGHLIGHT_PROB;

  public targetType: typeof TargetType = TargetType;
  public menuEventType: typeof MenuEventType = MenuEventType;
  public menuType: typeof DropzoneMenuType = DropzoneMenuType;
  public operator: typeof Operator = Operator;
  public reportMode: typeof ReportMode = ReportMode;

  @Input() surveys: Survey[];
  @Input() isReadonly = true;
  @Output() controlClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() targetClick: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();

  public columns: Target[] = [];
  public rows: Target[] = [];

  private unsubscribe: Subject<void> = new Subject<void>();

  public isLoadingSelectedNodes = false;
  public isLoadingFromButtons = false;
  public canUseAffinityReport = false;
  public affinityReportTooltip = 'Affinity report';

  @ViewChild('virtualButton') public virtualButton;

  private dropzoneEnterCount = 0;
  private isOverActionMenu = false;
  private isDropzoneMenuOpen = false;
  private activeMenuTrigger: MatMenuTrigger;
  private menuTarget: EventTarget;

  public dropzoneMenuType: DropzoneMenuType = DropzoneMenuType.button;
  private dropzoneTargetType: TargetType = TargetType.columns;
  public dropzoneActionItems: DropActionItem[] = [];
  private dropzoneTargetItem: TargetItem;
  public tableStyleStatus: CellStyleStatus;
  public zScoreHighlight: boolean;
  public significanceTestingOn: null | 'rows' | 'columns' = null;
  public highlightColors: string[];
  public heatmapIndexPercentage: number;
  public chosenDataItem: string;

  public weightDescriptions: SurveyMetaDataWeights[] = [];
  public filteredWeightDescriptions: SurveyMetaDataWeights[] = [];
  public currentWeightIndex = 1;

  @Output() dropNode = new EventEmitter<
    DropDataContext<DropActionItem | Operator>
  >();

  @Input() isViewActive: boolean;

  public menuPosition = { x: 0, y: 0 };
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  private menuTargetItem: TargetItem;
  private menuTargetSurveyCode?: string;

  private titleMode: DisplayType;

  public activeReportMode: ReportMode = ReportMode.crossTab;
  public tabReportMode: ReportMode = ReportMode.crossTab;
  public previousActiveReportMode: ReportMode = ReportMode.crossTab;

  public swapActionWarning = '';

  @ViewChild('reportTable') reportTable:
    | CrosstabTableComponent
    | SeparatedRankReportTableComponent
    | CombinedRankReportTableComponent;

  constructor(
    private documentService: DocumentService,
    private crossTabService: CrosstabService,
    private codebookSelectionService: CodebookSelectionService,
    private targetService: TargetService,
    private injector: Injector,
    private dialog: DialogService,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private colorCellsService: HighlightCellsService,
    private dataItemsService: DataItemsService,
    private reportPreferencesService: ReportPreferencesService
  ) {}

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
    this.listenToActiveSurveyChanges();
    this.listenToSelectedNodesChanges();
    this.listenToTitleModeUpdates();
    this.listenToReportPreferencesChanges();
    this.listenToReportModeChanges();
    this.listenToSurveyDataWeightsChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoadingSelectedNodes = false;
  }

  public onTargetClick(targetClick: TargetItem): void {
    if (this.isLoadingSelectedNodes) {
      return;
    }
    this.targetClick.emit(targetClick);
  }

  public onEditTablebase(targetItem: TargetItem): void {
    this.targetClick.emit(targetItem);
  }

  public openHighlightSettings(): void {
    this.colorCellsService
      .highlightColors()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data) => {
        this.reportPreferencesService.updateHighlightValues({
          ...data,
        });
      });
  }

  public openHeatmapSettings(): void {
    this.colorCellsService
      .heatmap()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data) => {
        this.reportPreferencesService.updateHeatmapPercentageValue(
          data.selectedHeatmapOption,
          data.value
        );
      });
  }

  public onWeightDescriptionChange(item: MatSelectChange) {
    this.currentWeightIndex = item.value;

    if (this.documentService.document?.content) {
      this.documentService.document.content.weight = this.currentWeightIndex;
      this.documentService.notifyCrosstabDataAboutToChange();
      this.documentService.updateCrossTabData();
    }
  }

  public isWeightSelectable(weight: SurveyMetaDataWeights): boolean {
    return !!this.filteredWeightDescriptions.find(
      (filteredWeight) => filteredWeight === weight
    );
  }

  public onDropzoneEnter(
    trigger: MatMenuTrigger,
    menuType: DropzoneMenuType,
    targetType: TargetType,
    targetItem?: TargetItem
  ): void {
    this.dropzoneMenuType = menuType;
    this.dropzoneTargetType = targetType;
    this.dropzoneTargetItem = targetItem;

    this.dropzoneEnterCount++;
    setTimeout(() => {
      if (this.dropzoneEnterCount === 0 || this.activeMenuTrigger === trigger) {
        return;
      }
      if (
        this.activeMenuTrigger &&
        (this.isDropzoneMenuOpen || this.dropzoneEnterCount > 1)
      ) {
        this.closeActionMenu();
      }
      this.activeMenuTrigger = trigger;
      this.setDropzoneMenuItems(targetType);
      this.isDropzoneMenuOpen = true;
      trigger.openMenu();
      // need to focus on something else, otherwise the first item of the menu gets focused for some reason
      this.virtualButton.nativeElement.focus();
    }, 300);
  }

  public onDropzoneLeave(): void {
    this.dropzoneEnterCount--;
    setTimeout(() => {
      this.closeActionMenu();
    }, 200);
  }

  public onTableDropzoneDrop(targetItem: TargetItem): void {
    this.dropzoneTargetItem = targetItem;
    this.onDrop(DEFAULT_BASE_TABLE_ACTION_ITEMS[0]);
  }

  public onActionMenuEnter(event: DragEvent): void {
    this.menuTarget = event.currentTarget;
    this.isOverActionMenu = true;
  }

  public onActionMenuLeave(event: DragEvent): void {
    const target = event.currentTarget;
    // note: dndDragoverClass doesn't always get removed after leaving the target
    if (target && target instanceof Element) {
      target.classList.remove(this.menuItemDragoverClass);
    }
    if (this.menuTarget === target) {
      this.isOverActionMenu = false;
      this.closeActionMenu();
    }
  }

  public onDrop(actionItem: DropActionItem): void {
    if (this.isLoadingSelectedNodes) {
      return;
    }
    this.dropNode.emit({
      selectedNodes: this.codebookSelectionService.getSelectedNodes(),
      context: actionItem,
      handleDropNode: (action: DropActionItem, selectedNodes: Statement[]) =>
        this.handleDropNode(action, selectedNodes),
    });
    this.unsetDraggingState();
  }

  public handleDropNode(
    actionItem: DropActionItem,
    selectedNodes: Statement[]
  ): void {
    if (actionItem.name === 'Code builder') {
      this.targetClick.emit(this.dropzoneTargetItem);
    } else {
      this.injector.get(actionItem.action).invoke({
        targetType: this.dropzoneTargetType,
        selectedTargets:
          this.targetService.convertStatementsToTargets(selectedNodes),
        selectableTargets:
          this.dropzoneTargetType === TargetType.columns
            ? this.columns
            : this.rows,
        targetItem:
          this.dropzoneMenuType === DropzoneMenuType.table
            ? this.dropzoneTargetItem
            : undefined,
        actionItem,
      });
    }
  }

  public onMenuTrigger(
    contextMenuEvent: MenuEventTrigger,
    type: MenuEventType
  ): void {
    const event = contextMenuEvent.event;
    this.menuPosition.x = event.clientX;
    this.menuPosition.y = event.clientY;
    this.menuTargetItem = contextMenuEvent.targetItem;
    this.menuTargetSurveyCode = contextMenuEvent.surveyCode;

    const items =
      type === MenuEventType.context
        ? this.getContextMenuItems(this.menuTargetItem)
        : this.getColumnHeaderMenuItems(this.menuTargetItem);
    this.showMenu(items);
  }

  public onColumnFilterClick({
    targetItem,
    surveyCode,
  }: ColumnFilterClick): void {
    this.menuTargetItem = targetItem;
    this.menuTargetSurveyCode = surveyCode;
    this.onMenuItemClick(DEFAULT_COLUMN_HEADER_ACTION_ITEMS[0]);
  }

  @HostListener('document:mousedown', ['$event'])
  public closeContextMenuOnMousedown(event): void {
    if (
      !event.target ||
      !event.target.classList.contains('context-menu-item')
    ) {
      this.matMenuTrigger?.closeMenu();
    }
  }

  public onMenuItemClick(
    actionItem: ContextMenuActionItem | ColumnHeaderMenuActionItem
  ): void {
    if (!('action' in actionItem)) {
      return;
    }

    this.injector.get(actionItem.action).invoke({
      targetItem: this.menuTargetItem,
      actionItem,
      surveyCode: this.menuTargetSurveyCode,
    });
  }

  public onChangeReport(): void {
    if (this.tabReportMode === ReportMode.affinity) {
      this.openAffinityReport();
    }

    if (this.tabReportMode === ReportMode.crossTab) {
      this.openCrosstabReport();
    }

    if (this.tabReportMode === ReportMode.separatedRank) {
      this.openSeparatedRankReport();
    }

    if (this.tabReportMode === ReportMode.combinedRank) {
      this.openCombinedRankReport();
    }
  }

  public clearStyles(): void {
    this.reportPreferencesService.updateCellStatus(CellStyleStatus.none);
  }

  private openSeparatedRankReport(): void {
    this.reportPreferencesService.updateReportMode(ReportMode.separatedRank);
    this.activeReportMode = ReportMode.separatedRank;
    this.updateAffinityReportTooltip();
  }

  private openCombinedRankReport(): void {
    this.reportPreferencesService.updateReportMode(ReportMode.combinedRank);
    this.activeReportMode = ReportMode.combinedRank;
    this.updateAffinityReportTooltip();
  }

  private openAffinityReport(): void {
    this.dialog
      .affinityReport()
      .afterClosed()
      .subscribe((row: Target) => {
        if (row) {
          this.reportPreferencesService.updateReportMode(
            ReportMode.affinity,
            row
          );
        } else {
          this.tabReportMode = this.activeReportMode;
        }
      });
  }

  private openCrosstabReport(): void {
    this.reportPreferencesService.updateReportMode(ReportMode.crossTab);
    this.activeReportMode = ReportMode.crossTab;
    this.updateAffinityReportTooltip();
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.columns = columns;
        this.rows = rows;

        const titles: string[] = this.getTitles([
          ...this.columns,
          ...this.rows,
        ]);

        this.titleLevelsService.updateNumberOfTitleLevels(titles);
        this.canUseAffinityReport = rows.length > 0 && columns.length > 0;
        this.updateAffinityReportTooltip();
        this.updateCompatibleWeights();
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.swapActionWarning =
          survey.provider === 'YouGov' ? SWAP_ROWS_COLUMNS_WARNING : '';
      });
  }

  private updateCompatibleWeights() {
    const validWeights = this.documentService.getValidWeightsFromDocument();
    if (validWeights.length) {
      this.filteredWeightDescriptions = this.weightDescriptions.filter(
        (weight) => validWeights.includes(weight.index)
      );
      this.currentWeightIndex = this.documentService.getCompatibleWeight(
        validWeights,
        this.currentWeightIndex
      );
    } else {
      this.filteredWeightDescriptions = this.weightDescriptions;
      this.currentWeightIndex = DEFAULT_WEIGHT_INDEX;
    }
  }

  private listenToSurveyDataWeightsChanges(): void {
    this.documentService.surveyDataWeights$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((weights: SurveyMetaDataWeights[]) => {
        this.weightDescriptions = weights;
        this.filteredWeightDescriptions = weights;

        if (this.documentService.document?.content && weights.length) {
          const existingWeight = weights.find(
            (weight) =>
              weight.index === this.documentService.document.content.weight
          );

          this.currentWeightIndex = existingWeight
            ? existingWeight.index
            : DEFAULT_WEIGHT_INDEX;
        }
        this.updateCompatibleWeights();
      });
  }

  private listenToReportPreferencesChanges(): void {
    this.reportPreferencesService.preference$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((preference: ReportPreference) => {
        this.chosenDataItem =
          DATA_ITEMS_MAP[preference.highlightValues.dataItemId].displayName;
        this.highlightColors = preference.highlightValues.colors;

        this.tableStyleStatus = preference.cellStyleStatus;
        if (this.tableStyleStatus === CellStyleStatus.significanceTestingRow) {
          this.significanceTestingOn = 'rows';
        } else if (
          this.tableStyleStatus === CellStyleStatus.significanceTestingColumn
        ) {
          this.significanceTestingOn = 'columns';
        } else {
          this.significanceTestingOn = null;
        }
        this.zScoreHighlight =
          preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;

        this.heatmapIndexPercentage = preference.heatmapIndexPercentage;
      });
  }

  /**
   * Returns an Array of leaf node Target titles
   */
  private getTitles(targets: Target[], titles: string[] = []): string[] {
    return targets.reduce((previousValue: string[], currentValue: Target) => {
      if (currentValue.targets?.length) {
        return this.getTitles(currentValue.targets, previousValue);
      } else {
        previousValue.push(currentValue.title);

        return previousValue;
      }
    }, titles);
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        this.activeReportMode = mode;
        this.tabReportMode = mode;
        this.updateAffinityReportTooltip();
      });
  }

  private updateAffinityReportTooltip(): void {
    if (!this.canUseAffinityReport) {
      this.affinityReportTooltip =
        'You need at least one row and one column to create an affinity report.';
    } else if (this.activeReportMode === ReportMode.affinity) {
      this.affinityReportTooltip = 'The affinity score is applied.';
    } else {
      this.affinityReportTooltip = 'Affinity report';
    }
  }

  private listenToSelectedNodesChanges(): void {
    this.codebookSelectionService.loadingSelectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isLoading: LoadingNode) => {
        this.isLoadingSelectedNodes = isLoading.inProgress;
        this.isLoadingFromButtons =
          isLoading.origin === LoadingSource.fromButton;
      });
  }

  private listenToTitleModeUpdates(): void {
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((titleMode) => (this.titleMode = titleMode));
  }

  private setDropzoneMenuItems(targetType: TargetType): void {
    this.dropzoneActionItems =
      targetType === TargetType.columns
        ? DEFAULT_TABLE_COLUMN_ACTION_ITEMS
        : DEFAULT_TABLE_ROW_ACTION_ITEMS;
  }

  private closeActionMenu(): void {
    if (this.isDropzoneMenuOpen && !this.isOverActionMenu) {
      this.isDropzoneMenuOpen = false;
      this.activeMenuTrigger.closeMenu();
      this.activeMenuTrigger = null;
    }
  }

  private unsetDraggingState(): void {
    this.isOverActionMenu = false;
    this.dropzoneEnterCount = 0;
    this.closeActionMenu();
  }

  private getContextMenuItems(targetItem: TargetItem): ContextMenuActionItem[] {
    let items;
    if (targetItem.type === TargetType.rows) {
      items = targetItem.target
        ? DEFAULT_ROW_TITLE_ACTION_ITEMS
        : DEFAULT_ROW_TOTALS_ACTION_ITEMS;
    } else {
      const isTotalsColumn = !targetItem.target;
      const dataItems = this.getColumnDataItems(isTotalsColumn);
      const hasVolumetricCoding = this.handleVolumetricCoding(
        targetItem.target?.id || 'totals'
      );
      items = getColumnActionItems(
        isTotalsColumn,
        dataItems,
        hasVolumetricCoding
      );
    }
    items = this.updateActiveTitleForContextMenuItems(items);
    items = this.updateSwapActionForContextMenuItems(items);
    items = this.updateFreezeTotalsForContextMenuItems(targetItem, items);

    return items;
  }

  private handleVolumetricCoding(columnId: string): boolean {
    const columnIdVolumetricCodingSet =
      this.crossTabService.getColumnIdVolumetricCodingMap();

    return columnIdVolumetricCodingSet.has(
      `${columnId}_${this.menuTargetSurveyCode}`
    );
  }

  private getColumnHeaderMenuItems(
    targetItem: TargetItem
  ): ColumnHeaderMenuActionItem[] {
    const columnId = this.crossTabService.formatTargetColumnId(
      targetItem.target,
      this.menuTargetSurveyCode
    );
    const filtersApplied =
      this.reportPreferencesService.getColumnHeaderFilters(columnId).length > 0;
    const items = !filtersApplied
      ? [
          ...DEFAULT_COLUMN_HEADER_ACTION_ITEMS.filter(
            (actionItem: ColumnHeaderMenuActionItem) =>
              actionItem.name !== 'Remove filter' &&
              actionItem.name !== 'Edit filter'
          ),
        ]
      : [
          ...DEFAULT_COLUMN_HEADER_ACTION_ITEMS.filter(
            (actionItem: ColumnHeaderMenuActionItem) =>
              actionItem.name !== 'Add filter'
          ),
        ];
    return this.reportPreferencesService.getSortColumnId()
      ? items
      : items.filter(
          (item: ColumnHeaderMenuActionItem) => item.name !== 'Reset sorting'
        );
  }

  private getColumnDataItems(isTotalsColumn: boolean): DataItem[] {
    return this.dataItemsService.getActiveDataItems(
      this.activeReportMode,
      isTotalsColumn
    );
  }

  private updateActiveTitleForContextMenuItems(
    items: ContextMenuActionItem[]
  ): ContextMenuActionItem[] {
    const activeTarget = this.menuTargetItem.target;
    if (!activeTarget) {
      return items;
    }
    const menuItems = cloneDeep(items);
    const activeTitleMode = activeTarget.activeTitleMode || this.titleMode;
    const titlesAction = menuItems.find(
      (item: ContextMenuActionItem) => item.name === 'Title'
    );
    const titleModeAction = (titlesAction as ColumnRowActionItem).actions.find(
      (item: TitleModeActionItem) => item.displayType === activeTitleMode
    );
    (titleModeAction as TitleModeActionItem).isActive = true;

    return menuItems;
  }

  private updateSwapActionForContextMenuItems(
    items: ContextMenuActionItem[]
  ): ContextMenuActionItem[] {
    const activeTarget = this.menuTargetItem.target;
    if (!activeTarget || this.activeReportMode === ReportMode.crossTab) {
      return items;
    }
    const menuItems = cloneDeep(items);
    return menuItems.filter(
      (item: ContextMenuActionItem) =>
        item.name !== SWAP_ROWS_COLUMNS_ACTION_NAME
    );
  }

  private updateFreezeTotalsForContextMenuItems(
    targetItem: TargetItem,
    items: ContextMenuActionItem[]
  ): ContextMenuActionItem[] {
    const freezeTotals = this.reportPreferencesService.getFreezeTotals();
    const isFreezeTotalsActive =
      targetItem.type === TargetType.rows
        ? freezeTotals.row
        : freezeTotals.column;
    const UnwantedActionName = isFreezeTotalsActive
      ? 'Freeze totals'
      : 'Unfreeze totals';
    return items.filter(
      (item: ContextMenuActionItem) => !item.name.startsWith(UnwantedActionName)
    );
  }

  private showMenu(items: ContextMenuActionItem[]): void {
    this.matMenuTrigger.menuData = { items };
    this.matMenuTrigger?.closeMenu();
    this.matMenuTrigger.openMenu();
    setTimeout(() => {
      this.virtualButton.nativeElement.focus();
    }, 0);
  }

  public exportToCsv(): void {
    this.reportTable.exportToCsv();
  }

  public exportToXlsx(): void {
    this.reportTable.exportToXlsx();
  }

  public exportToSheets(): void {
    this.reportTable.exportToSheets();
  }
}
