import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep, isEmpty } from 'lodash';
import { Note } from 'src/app/models/document.model';
import { DocumentService } from 'src/app/services/document.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'chart-note',
  templateUrl: './chart-note.component.html',
  styleUrls: ['./chart-note.component.scss'],
})
export class ChartNoteComponent implements OnInit {
  public title = '';
  public description = '';
  public note: Note;

  @Input() associatedTarget;
  @Input() associatedInsight;
  @Input() isReadonly = true;

  private updateNote = new BehaviorSubject<Note>(null);
  private updateNote$ = this.updateNote.asObservable();

  constructor(private documentService: DocumentService) {}

  ngOnInit(): void {
    this.note = this.documentService.createNote(
      this.associatedTarget,
      this.associatedInsight
    );

    const existingNote = this.documentService.findNote(this.note)[0];

    if (!isEmpty(existingNote)) {
      this.title = existingNote.title;
      this.description = existingNote.description;
    }

    this.listenToNoteChanges();
  }

  public saveNote() {
    this.note.title = this.title;
    this.note.description = this.description;
    this.updateNote.next(cloneDeep(this.note));
  }

  private listenToNoteChanges(): void {
    this.updateNote$
      .pipe(debounceTime(1000), isNotNullOrUndefined())
      .subscribe((note: Note) => {
        this.documentService.addChartNote(note);
      });
  }
}
