import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { cloneDeep } from 'lodash';
import {
  Survey,
  TrendingCalculationItem,
  TrendingCalculationType,
} from 'src/app/models';
import { TrendingCalculationService } from 'src/app/services';

export interface TrendingCalculationData {
  surveys: Survey[];
  trendingCalculationService: TrendingCalculationService;
}

@Component({
  templateUrl: './trending-calculation-dialog.component.html',
  styleUrls: ['./trending-calculation-dialog.component.scss'],
})
export class TrendingCalculationDialogComponent implements OnInit {
  public surveys: Survey[];
  public trendingCalculationList: TrendingCalculationItem[];
  public selectedDiffSurveys: Survey[] = [];
  public selectedDividendSurvey: Survey;
  public calculationPreview: string;
  public selectedSurveyList: Survey[] = [];
  public unableToAdd: boolean = true;
  public disableTooltipMessage: string = 'Add calculation';

  private selectedTab: number = 0;
  private trendingCalculationService: TrendingCalculationService;

  constructor(
    private dialogRef: MatDialogRef<TrendingCalculationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TrendingCalculationData
  ) {
    this.surveys = data.surveys;
    this.trendingCalculationService = data.trendingCalculationService;
    this.trendingCalculationList =
      this.trendingCalculationService.getTrendingCalculations();
  }

  public ngOnInit(): void {
    this.setDefaults();

    this.trendingCalculationService.trendingCalculation$.subscribe(
      (trendingCalculationList: TrendingCalculationItem[]) => {
        this.trendingCalculationList = trendingCalculationList;
      }
    );
  }

  public onDeleteCalculation(calculation: TrendingCalculationItem): void {
    this.trendingCalculationService.removeTrendingCalculation(calculation);
    this.updatePreview();
  }

  public onDiffSurveysChange(): void {
    this.updatePreview();
  }

  public onDividendSurveyChange(): void {
    this.updatePreview();
  }

  public onSurveyChecked(event: MatCheckboxChange, survey: Survey): void {
    if (event.checked) {
      this.selectedSurveyList.push(survey);
    } else {
      const index = this.selectedSurveyList.indexOf(survey);
      if (index > -1) {
        this.selectedSurveyList.splice(index, 1);
      }
    }
    this.updatePreview();
  }

  public tabClick(tab: MatTabChangeEvent): void {
    this.selectedTab = tab.index;
    this.reset();
    this.updatePreview();
  }

  public onAdd(): void {
    let selectedCalcSurveyList: Survey[] = [];
    switch (this.selectedTab) {
      case TrendingCalculationType.diff:
        selectedCalcSurveyList = cloneDeep(this.selectedDiffSurveys);
        break;
      case TrendingCalculationType.percentDiff:
        selectedCalcSurveyList = cloneDeep(this.selectedDiffSurveys);
        selectedCalcSurveyList.push(this.selectedDividendSurvey);
        break;
      case TrendingCalculationType.average:
      case TrendingCalculationType.sum:
        selectedCalcSurveyList = cloneDeep(this.selectedSurveyList);
        break;
    }

    this.trendingCalculationService.addTrendingCalculation({
      coding: this.calculationPreview,
      surveys: selectedCalcSurveyList,
    });

    this.unableToAdd = true;
  }

  public ok(): void {
    this.dialogRef.close({ result: 'apply' });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  private setDefaults(): void {
    this.selectedDiffSurveys[0] = this.surveys[0];
    this.selectedDiffSurveys[1] = this.surveys[1];
    this.selectedDividendSurvey = this.surveys[1];

    this.updatePreview();
  }

  private reset(): void {
    this.calculationPreview = null;
    this.selectedSurveyList = [];
  }

  private updatePreview(): void {
    let selectedCodes = [];
    switch (this.selectedTab) {
      case TrendingCalculationType.diff:
        this.calculationPreview = `DIF(${this.selectedDiffSurveys[0].code}, ${this.selectedDiffSurveys[1].code})`;
        break;
      case TrendingCalculationType.percentDiff:
        this.calculationPreview = `PER(${this.selectedDiffSurveys[0].code}, ${this.selectedDiffSurveys[1].code}): ${this.selectedDividendSurvey.code}`;
        break;
      case TrendingCalculationType.average:
        selectedCodes = this.selectedSurveyList.map((survey) => survey.code);
        this.calculationPreview = `AVG(${selectedCodes.join(',')})`;
        break;
      case TrendingCalculationType.sum:
        selectedCodes = this.selectedSurveyList.map((survey) => survey.code);
        this.calculationPreview = `SUM(${selectedCodes.join(',')})`;
        break;
    }

    this.validateCoding();
  }

  private validateCoding(): void {
    const currentCalculations =
      this.trendingCalculationService.getTrendingCalculations();

    switch (this.selectedTab) {
      case TrendingCalculationType.diff:
      case TrendingCalculationType.percentDiff:
        if (
          currentCalculations.filter(
            (calculation: TrendingCalculationItem) =>
              calculation.coding === this.calculationPreview
          ).length > 0
        ) {
          this.unableToAdd = true;
          this.disableTooltipMessage =
            'You have already added this to your calculation';
        } else {
          this.unableToAdd = false;
          this.disableTooltipMessage = 'Add calculation';
        }
        break;
      case TrendingCalculationType.average:
        this.checkSurveys(currentCalculations, TrendingCalculationType.average);
        break;
      case TrendingCalculationType.sum:
        this.checkSurveys(currentCalculations, TrendingCalculationType.sum);
        break;
    }
  }

  private checkSurveys(
    currentCalculations: TrendingCalculationItem[],
    type: TrendingCalculationType
  ): void {
    const selectedCodesString = this.selectedSurveyList
      .map((survey) => survey.code)
      .sort()
      .toString();
    const match =
      type === TrendingCalculationType.average ? /AVG|\(|\)/gi : /SUM\(|\)/gi;

    if (this.selectedSurveyList.length < 2) {
      this.unableToAdd = true;
      this.disableTooltipMessage = 'Select at least two surveys';
    } else if (
      currentCalculations.filter((calculation: TrendingCalculationItem) => {
        const codesString = calculation.coding
          .replace(match, '')
          .split(',')
          .sort()
          .toString();
        return selectedCodesString === codesString;
      }).length > 0
    ) {
      this.unableToAdd = true;
      this.disableTooltipMessage =
        'You have already added this to your calculation';
    } else {
      this.unableToAdd = false;
      this.disableTooltipMessage = 'Add calculation';
    }
  }
}
