import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentDataState, Target } from '../../models';
import { DocumentService } from '../../services/document.service';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './affinity-report-dialog.component.html',
  styleUrls: ['./affinity-report-dialog.component.scss'],
})
export class AffinityReportDialogComponent implements OnInit {
  public rows: Target[];
  public selectedRow: Target;

  constructor(
    public dialogRef: MatDialogRef<AffinityReportDialogComponent, Target>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentService: DocumentService
  ) {}

  public ngOnInit(): void {
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ rows }: DocumentDataState) => {
        this.rows = rows;
        this.selectedRow = this.rows[0];
      });
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public continue(): void {
    this.dialogRef.close(this.selectedRow);
  }
}
