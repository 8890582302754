import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { FreezeTotalsActionItem } from '../models/action.model';
import { ReportPreferencesService } from '../services/report-preferences.service';

export interface ToggleFreezeTotalsActionContext {
  actionItem: FreezeTotalsActionItem;
}

@Injectable({
  providedIn: 'root',
})
export class ToggleFreezeTotalsAction extends ActionDefinition<ToggleFreezeTotalsActionContext> {
  constructor(private reportPreferencesService: ReportPreferencesService) {
    super();
  }

  public invoke(context: ToggleFreezeTotalsActionContext): void {
    this.reportPreferencesService.toggleFreezeTotals(context.actionItem.type);
  }
}
