<tup-mat-content-wrapper
  class="table-container-reports"
  tupDocumentEventEmitter
  tupDocumentEventHandler
>
  <div class="table-header reports-table-header">MY LATEST REPORTS</div>
  <div
    [ngClass]="
      myReports.dataSource.length === 0 && !myReports.nameFilterService.value
        ? 'hide'
        : 'show'
    "
  >
    <tup-document-filter
      data-tracking="report-search"
      formFieldAppearance="outline"
      formFieldLabel="Search reports"
      [filterFor]="myReports"
    ></tup-document-filter>
    <tup-document-storage
      [options]="{
        columns: [
          { header: 'My reports' },
          {},
          {},
          { header: 'Last modified' }
        ],
        types: ['8'],
        pageSize: 5
      }"
      class="dashboard-document-storage-table"
      #myReports
    ></tup-document-storage>
    <div
      *ngIf="myReports.dataSource.length !== 0"
      class="all-reports"
      (click)="navigateToReportsTab.emit(1)"
    >
      See all reports ({{ myReports?.dataSource?.length }})
    </div>
  </div>
  <div
    class="error-message"
    *ngIf="
      myReports.dataSource.length === 0 && !myReports.nameFilterService.value
    "
  >
    <h3>
      Get started:
      <span
        (click)="(createReport.emit)"
        class="create-report-link"
        >Start crosstabing</span
      >
    </h3>
    <p>
      As you generate new reports, they will be conveniently displayed here,
      providing you with quick access to your most recent data analysis. Embark
      on your analytical adventure and transform this space into a valuable
      resource for informed decision-making.
    </p>
  </div>
  <div
    class="no-search-results"
    *ngIf="
      myReports.dataSource.length === 0 && myReports.nameFilterService.value
    "
  >
    <p>No search results.</p>
  </div>
</tup-mat-content-wrapper>
