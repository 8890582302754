import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services';
import { ALL_RESPONDENTS_CODING, Target } from '../../models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tablebase-info-popper',
  templateUrl: './tablebase-info-popper.component.html',
  styleUrls: ['./tablebase-info-popper.component.scss'],
})
export class TablebaseInfoPopperComponent implements OnInit, OnDestroy {
  @Input() popperAppendTo: string;

  public shouldShowTablebaseInfo: boolean;
  public tablebaseTitle: string;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private documentService: DocumentService) {}

  ngOnInit(): void {
    this.listenToActiveTablebaseChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenToActiveTablebaseChanges(): void {
    this.documentService.activeTablebase$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((target: Target) => {
        if (!target) {
          return;
        }
        this.shouldShowTablebaseInfo = target.coding !== ALL_RESPONDENTS_CODING;
        this.tablebaseTitle = target.title;
      });
  }
}
