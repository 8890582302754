import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule, routes } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './modules/app-material.module';
import { MainComponent } from './components/main/main.component';
import { TupAnalyticsModule } from '@telmar-global/tup-analytics';
import { TupMaterialModule } from '@telmar-global/tup-material';
import { TupAuthModule } from '@telmar-global/tup-auth';
import { TupUserPreferenceStorageModule } from '@telmar-global/tup-user-preference-storage';
import { TupChartsModule } from '@telmar-global/tup-charts';
import { environment } from 'src/environments/environment';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  CrossTabEditorComponent,
  DashboardComponent,
  LoginComponent,
} from './pages';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TupUserMessageModule } from '@telmar-global/tup-user-message';
import { StateService } from './services/state.service';
import { DialogService } from './services/dialog.service';
import { CodebookNavigationComponent } from './components/codebook-navigation/codebook-navigation.component';
import { ActionControlsComponent } from './components/codebook-navigation/action-controls/action-controls.component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { CtrlShiftDirective } from './directives/ctrl-shift.directive';
import { CrosstabTableComponent } from './components/crosstab-table/crosstab-table.component';
import { CrosstabViewComponent } from './components/crosstab-view/crosstab-view.component';
import { CrosstabService } from './services/crosstab.service';
import { DocumentService } from './services/document.service';
import { CodingGridViewComponent } from './components/coding-grid-view/coding-grid-view.component';
import { CodingGridTableComponent } from './components/coding-grid-table/coding-grid-table.component';
import { RequestLoadingService } from './services/request-loading.service';
import { TupDocumentStorageModule } from '@telmar-global/tup-document-storage';
import { VisualCodeBuilderDialogComponent } from './dialogs/visual-code-builder-dialog/visual-code-builder-dialog.component';
import { DndModule } from 'ngx-drag-drop';
import { NgxSplitModule } from 'ngx-split';
import { CodebookSelectionService } from './services/codebook-selection.service';
import { TargetService } from './services/target.service';
import { CountCodingDialogComponent } from './dialogs/count-coding-dialog/count-coding-dialog.component';
import { ChartNoteComponent } from './components/chart-note/chart-note.component';
import { GraphViewComponent } from './components/graph-view/graph-view.component';
import { ChartComponent } from './components/chart/chart.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { ColorBlockModule } from 'ngx-color/block';
import { ChartColorDialogComponent } from './dialogs/chart-color-dialog/chart-color-dialog.component';
import { ChartSettingsDialogComponent } from './dialogs/chart-settings-dialog/chart-settings-dialog.component';
import { ChartSettingsService } from './services/chart-settings.service';
import { TupLoggerModule } from '@telmar-global/tup-logger-angular';
import { SaveMenuComponent } from './components';
import {
  AddCodeToMultipleDialogComponent,
  AffinityReportDialogComponent,
  ColumnFiltersDialogComponent,
  MappingOptionsDialogComponent,
  RenameDialogComponent,
  SaveAsDialogComponent,
  TitleLevelsDialogComponent,
} from './dialogs';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EditNameComponent } from './components/edit-name/edit-name.component';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { HighlightValuesDialogComponent } from './dialogs/highlight-values-dialog/highlight-values-dialog.component';
import { NgxPopperModule } from 'ngx-popper';
import { SurveysTableCellComponent } from './components/surveys-table-cell/surveys-table-cell.component';
import { ManageReportItemsDialogComponent } from './dialogs/manage-report-items-dialog/manage-report-items-dialog.component';
import { DataItemsDialogComponent } from './dialogs/data-items-dialog/data-items-dialog.component';
import { TupAudienceGroupsModule } from '@telmar-global/tup-audience-groups';
import { SortColumnDialogComponent } from './dialogs/sort-column-dialog/sort-column-dialog.component';
import { TablebaseSelectComponent } from './components/tablebase-select/tablebase-select.component';
import { TablebaseInfoPopperComponent } from './components/tablebase-info-popper/tablebase-info-popper.component';
import {
  SurveyBgColorPipe,
  ChartTitlePipe,
  TargetTitlePipe,
  TitleLevelPipe,
  TruncatePipe,
  FormatChartFilterPipe,
} from './pipes';
import { InfoPopperComponent } from './components/info-popper/info-popper.component';
import { FormatReportUnitsPipe } from './pipes/format-report-units.pipe';
import { SurveysInfoComponent } from './components/survey-info/surveys-info.component';
import { SurveysInfoDialogComponent } from './components/surveys-info-dialog/surveys-info-dialog.component';
import { SeparatedRankReportTableComponent } from './components/separated-rank-report-table/separated-rank-report-table.component';
import { TupDocumentExporterModule } from '@telmar-global/tup-document-exporter';
import { TupGoogleApiModule } from '@telmar-global/tup-google-api';
import { ExportedGoogleFileDialogComponent } from './dialogs/exported-google-file-dialog/exported-google-file-dialog.component';
import { TrendingCalculationDialogComponent } from './dialogs/trending-calculation-dialog/trending-calculation-dialog.component';
import { DecimalPipe } from '@angular/common';
import { SingleTargetTableComponent } from './components/separated-rank-report-table/single-target-table/single-target-table.component';
import { CombinedRankReportTableComponent } from './components/combined-rank-report-table/combined-rank-report-table.component';
import { HeatmapDialogComponent } from './dialogs/heatmap-dialog/heatmap-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { ChartFiltersDialogComponent } from './dialogs/chart-filters-dialog/chart-filters-dialog.component';
import { DashboardReportsComponent } from './components/dashboard-reports/dashboard-reports.component';
import { DashboardQuickReportsComponent } from './components/dashboard-quick-reports/dashboard-quick-reports.component';
import { DashboardTabComponent } from './components/dashboard-tab/dashboard-tab.component';
import { PMapsComponent } from './pages/p-maps/p-maps.component';
import { QuickReportListComponent } from './components/dashboard-tab/quick-report-list/quick-report-list.component';
import { TupLargeListSelectorModule } from '@telmar-global/tup-large-list-selector';
import { CrossTabComponent } from './pages/cross-tab/cross-tab.component';
import { CrossTabGraphComponent } from './pages/cross-tab-graph/cross-tab-graph.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TopBarService } from './services';
import { PMapsSettingsDialogComponent } from './dialogs/p-maps-settings-dialog/p-maps-settings-dialog.component';
import { NtileDialogComponent } from './dialogs/ntile-dialog/ntile-dialog.component';
import { MyReportListComponent } from './components/dashboard-tab/my-report-list/my-report-list.component';
import { PMapsDataDialogComponent } from './dialogs/p-maps-data-dialog/p-maps-data-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    CrossTabEditorComponent,
    CrossTabComponent,
    CrossTabGraphComponent,
    DashboardComponent,
    CodebookNavigationComponent,
    ActionControlsComponent,
    TreeViewComponent,
    CtrlShiftDirective,
    CrosstabViewComponent,
    CrosstabTableComponent,
    CodingGridViewComponent,
    CodingGridTableComponent,
    VisualCodeBuilderDialogComponent,
    CountCodingDialogComponent,
    ChartNoteComponent,
    GraphViewComponent,
    ChartComponent,
    ChartColorDialogComponent,
    ChartSettingsDialogComponent,
    ChartFiltersDialogComponent,
    SaveMenuComponent,
    SaveAsDialogComponent,
    PageNotFoundComponent,
    SurveysInfoDialogComponent,
    EditNameComponent,
    AddCodeToMultipleDialogComponent,
    HighlightValuesDialogComponent,
    RenameDialogComponent,
    TruncatePipe,
    SurveysTableCellComponent,
    ManageReportItemsDialogComponent,
    QuickReportListComponent,
    DataItemsDialogComponent,
    ColumnFiltersDialogComponent,
    ChartFiltersDialogComponent,
    SortColumnDialogComponent,
    TablebaseSelectComponent,
    TablebaseInfoPopperComponent,
    TitleLevelsDialogComponent,
    TrendingCalculationDialogComponent,
    SurveysInfoComponent,
    TargetTitlePipe,
    TopBarComponent,
    SurveyBgColorPipe,
    TitleLevelPipe,
    FormatReportUnitsPipe,
    AffinityReportDialogComponent,
    InfoPopperComponent,
    ChartTitlePipe,
    FormatChartFilterPipe,
    SeparatedRankReportTableComponent,
    SingleTargetTableComponent,
    ExportedGoogleFileDialogComponent,
    CombinedRankReportTableComponent,
    HeatmapDialogComponent,
    DashboardReportsComponent,
    DashboardQuickReportsComponent,
    DashboardTabComponent,
    PMapsComponent,
    MappingOptionsDialogComponent,
    PMapsSettingsDialogComponent,
    NtileDialogComponent,
    MyReportListComponent,
    PMapsDataDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    TupAnalyticsModule.forRoot(environment.analytics),
    TupAuthModule.forRoot(environment, routes),
    TupDocumentStorageModule.forRoot(environment),
    TupLoggerModule.forRoot(environment),
    TupUserPreferenceStorageModule.forRoot(environment),
    LayoutModule,
    FormsModule,
    ScrollingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TupMaterialModule,
    TupUserMessageModule,
    DragToSelectModule.forRoot(),
    DndModule,
    TupChartsModule,
    MatIconModule,
    MatChipsModule,
    ColorBlockModule,
    InfiniteScrollModule,
    TableVirtualScrollModule,
    NgxPopperModule.forRoot({}),
    NgxSplitModule.forRoot(),
    TupAudienceGroupsModule.forRoot(environment),
    TupDocumentExporterModule.forRoot(environment),
    TupGoogleApiModule.forRoot(environment.googleApi),
    MatSliderModule,
    TupLargeListSelectorModule,
  ],
  entryComponents: [],
  providers: [
    StateService,
    DialogService,
    CrosstabService,
    DocumentService,
    TopBarService,
    RequestLoadingService,
    CodebookSelectionService,
    TargetService,
    ChartSettingsService,
    { provide: Window, useValue: window },
    TargetTitlePipe,
    SurveyBgColorPipe,
    ChartTitlePipe,
    FormatChartFilterPipe,
    FormatReportUnitsPipe,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
