import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatReportUnits',
})
export class FormatReportUnitsPipe implements PipeTransform {
  public transform(units: number): string {
    return units === 1 ? '' : `(${units.toString().replace('1', '')})`;
  }
}
