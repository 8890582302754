<div
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <button
    data-tracking="document-save-as-btn"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    matTooltip="Save as"
    (click)="saveAs()"
  >
    <mat-icon>cloud_done</mat-icon>
  </button>
</div>
