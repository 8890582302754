import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import { Target, TargetType, TargetItem, Operator } from '../models';
import { CountCodingDialogComponent, CountCodingModel } from '../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';

interface SeparateCountActionContextWithTargetItems {
  targetType: TargetType;
  targetItems: TargetItem[];
}

interface SeparateCountActionContextWithSelectedTargets {
  targetType: TargetType;
  selectedTargets: Target[];
}

export type SeparateCountActionContext =
  | SeparateCountActionContextWithTargetItems
  | SeparateCountActionContextWithSelectedTargets;

@Injectable({
  providedIn: 'root',
})
export class SeparateCountAction extends ActionDefinition<SeparateCountActionContext> {
  constructor(
    private documentService: DocumentService,
    private targetService: TargetService,
    private codebookSelectionService: CodebookSelectionService,
    private dialog: MatDialog
  ) {
    super();
  }

  public invoke(context: SeparateCountActionContext): void {
    this.dialog
      .open(CountCodingDialogComponent, { closeOnNavigation: true })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((countCoding: CountCodingModel) => {
        if (this.isSeparateCountActionContextWithTargetItems(context)) {
          this.handleUpdateTargetItems(context, countCoding);
        } else {
          this.handleAddSelectedTargets(context, countCoding);
        }
      });
  }

  private isSeparateCountActionContextWithTargetItems(
    context: SeparateCountActionContext
  ): context is SeparateCountActionContextWithTargetItems {
    return 'targetItems' in context;
  }

  private handleUpdateTargetItems(
    context: SeparateCountActionContextWithTargetItems,
    countCoding: CountCodingModel
  ): void {
    const selectedTargetItems = context.targetItems;
    selectedTargetItems.forEach((targetItem: TargetItem) => {
      targetItem.target.countCoding = countCoding;
      targetItem.target = this.targetService.groupTargets(
        [targetItem.target],
        Operator.and,
        true
      )[0];
    });
    this.documentService.updateDocumentData(selectedTargetItems, true);
  }

  private handleAddSelectedTargets(
    context: SeparateCountActionContextWithSelectedTargets,
    countCoding: CountCodingModel
  ): void {
    const targets = [];
    this.targetService
      .separateTargets(context.selectedTargets)
      .forEach((target: Target) => {
        target.countCoding = countCoding;
        targets.push(
          this.targetService.groupTargets([target], Operator.and, true)[0]
        );
      });
    this.documentService.addDocumentData(targets, context.targetType, true);
    this.codebookSelectionService.unselectNodes();
  }
}
