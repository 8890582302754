import { QueryList } from '@angular/core';

import { TupChartsComponent } from '@telmar-global/tup-charts';
import {
  TupChartData,
  TupChartOptions,
  TupChartType,
} from '@telmar-global/tup-document-exporter';

import { ChartSettings } from './charts.model';

export enum ExportFileType {
  pptx,
  xlsx,
  googleSheets,
  googleSlides,
}

/**
 * Missing models (－‸ლ).
 */
export interface Data {
  labels: string[];
  datasets: Dataset[];
}

export interface Dataset {
  name: string;
  label: string;
  data: number[];
  dataFlag: unknown[];
  backgroundColor: string[];
  // Flesh this out if we ever need to use any of the other properties
  [key: string]: unknown;
}

/**
 * Narrows down the type of secondary data.
 */
export const isData = (data: number[][] | Data): data is Data => {
  return (
    (data as Data).labels !== undefined && (data as Data).datasets !== undefined
  );
};

export interface SurveyTimeProps {
  settings: ChartSettings;
  primaryData: Data;
  secondaryData: number[][] | Data;
  charts: QueryList<TupChartsComponent>;
  title: string;
  subtitle: string;
  source: string;
  selected: boolean;
}

export interface ExportProps {
  primaryChartType: TupChartType;
  primaryChartData: TupChartData[];
  secondaryChartType?: TupChartType;
  secondaryChartData?: TupChartData[];
  chartColors: string[];
  chartOpts: TupChartOptions;
}
