import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DEFAULT_SURVEY_COPYRIGHT,
  DEFAULT_SURVEY_INFO,
  Survey,
  SurveyInfoType,
} from 'src/app/models';

@Component({
  selector: 'app-surveys-info-dialog',
  templateUrl: './surveys-info-dialog.component.html',
  styleUrls: ['./surveys-info-dialog.component.scss'],
})
export class SurveysInfoDialogComponent {
  public type: SurveyInfoType = SurveyInfoType.copyright;
  public SurveyInfoType = SurveyInfoType;
  public surveys: Survey[] = [];
  public defaultCopyRight = DEFAULT_SURVEY_COPYRIGHT;
  public defaultSurveyInfo = DEFAULT_SURVEY_INFO;

  constructor(
    public dialogRef: MatDialogRef<SurveysInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
    this.surveys = data.surveys;
  }

  public close(): void {
    this.dialogRef.close(null);
  }
}
