import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SurveysInfoDialogComponent } from '../components/surveys-info-dialog/surveys-info-dialog.component';
import {
  VisualCodeBuilderDialogComponent,
  ChartSettingsDialogComponent,
  SaveAsDialogComponent,
  AffinityReportDialogComponent,
  ChartFiltersDialogComponent,
  MappingOptionsDialogComponent,
  MappingOptions,
  NtileDialogComponent,
} from '../dialogs';
import { DataItemsDialogComponent } from '../dialogs/data-items-dialog/data-items-dialog.component';
import { ManageReportItemsDialogComponent } from '../dialogs/manage-report-items-dialog/manage-report-items-dialog.component';
import {
  ChartSettings,
  ChartSettingsMode,
  DataItem,
  DocumentDataState,
  Target,
  TargetType,
} from '../models';
import { Survey, SurveyInfoType } from '../models/survey.model';
import { ExportedGoogleFileDialogComponent } from '../dialogs/exported-google-file-dialog/exported-google-file-dialog.component';
import { TrendingCalculationDialogComponent } from '../dialogs/trending-calculation-dialog/trending-calculation-dialog.component';
import { TrendingCalculationService } from './trending-calculation.service';
import { DocumentService } from './document.service';
import { TargetService } from './target.service';
import { CombinedCodingThresholdService } from './combined-coding-threshold.service';
import { PMapsSettingsDialogComponent } from '../dialogs/p-maps-settings-dialog/p-maps-settings-dialog.component';
import { PMapsDataDialogComponent } from '../dialogs/p-maps-data-dialog/p-maps-data-dialog.component';
import { PMapsSeriesDataItem, PMapsSettings } from '../models/p-maps.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public chartSettings(
    chartSettingsMode: ChartSettingsMode,
    config: ChartSettings,
    targetColors: Record<string, string>,
    targets: Target[] = [],
    chartData?: any,
    groupName?: string,
    chartTitle?: string,
    isReadonly?: boolean
  ): MatDialogRef<ChartSettingsDialogComponent, ChartSettings | null> {
    return this.dialog.open(ChartSettingsDialogComponent, {
      data: {
        chartSettingsMode,
        config,
        targetColors,
        targets,
        chartData,
        groupName,
        chartTitle,
        isReadonly,
      },
    });
  }

  public chartFilters(
    chartSettingsMode: ChartSettingsMode,
    chartSettings: ChartSettings,
    width: string,
    targets?: Target[],
    chartData?: any
  ): MatDialogRef<ChartFiltersDialogComponent, ChartSettings | null> {
    return this.dialog.open(ChartFiltersDialogComponent, {
      data: {
        chartSettingsMode,
        chartSettings,
        targets,
        chartData,
      },
      width,
      closeOnNavigation: true,
      autoFocus: false,
    });
  }

  public manageReportItems(
    data: DocumentDataState
  ): MatDialogRef<ManageReportItemsDialogComponent> {
    return this.dialog.open(ManageReportItemsDialogComponent, {
      data,
      width: '1000px',
      closeOnNavigation: true,
      autoFocus: false,
    });
  }

  public dataItems(
    dataItems: DataItem[]
  ): MatDialogRef<DataItemsDialogComponent> {
    return this.dialog.open(DataItemsDialogComponent, {
      data: dataItems,
      width: '1000px',
    });
  }

  public visualCodeBuilder(
    documentService: DocumentService,
    targetService: TargetService,
    combinedCodingThresholdService: CombinedCodingThresholdService,
    target: Target,
    survey?: Survey
  ): MatDialogRef<VisualCodeBuilderDialogComponent, any> {
    return this.dialog.open(VisualCodeBuilderDialogComponent, {
      data: {
        target,
        survey,
        documentService,
        targetService,
        combinedCodingThresholdService,
      },
      width: '1100px',
      closeOnNavigation: true,
      disableClose: true,
    });
  }

  public nTileSettings(
    target: Target,
    type: TargetType
  ): MatDialogRef<NtileDialogComponent, any> {
    return this.dialog.open(NtileDialogComponent, {
      data: {
        target,
        type,
      },
      width: '900px',
      closeOnNavigation: true,
    });
  }

  public saveAs(): MatDialogRef<SaveAsDialogComponent> {
    return this.dialog.open(SaveAsDialogComponent, {
      width: '850px',
      closeOnNavigation: true,
    });
  }

  public affinityReport(): MatDialogRef<AffinityReportDialogComponent> {
    return this.dialog.open(AffinityReportDialogComponent, {
      closeOnNavigation: true,
      width: '600px',
    });
  }

  public surveysInfo(
    type: SurveyInfoType,
    surveys: Survey[]
  ): MatDialogRef<SurveysInfoDialogComponent> {
    return this.dialog.open(SurveysInfoDialogComponent, {
      data: {
        type,
        surveys,
      },
      closeOnNavigation: true,
      width: type === SurveyInfoType.copyright ? '600px' : '80vw',
    });
  }

  public exportedGoogleFile(
    webViewLink: string
  ): MatDialogRef<ExportedGoogleFileDialogComponent> {
    const options = {
      width: '400px',
      data: webViewLink,
    };
    return this.dialog.open(ExportedGoogleFileDialogComponent, options);
  }

  public trendingCalc(
    surveys: Survey[],
    trendingCalculationService: TrendingCalculationService
  ): MatDialogRef<TrendingCalculationDialogComponent> {
    const options = {
      width: '80vw',
      maxWidth: '1200px',
      data: {
        surveys,
        trendingCalculationService,
      },
    };
    return this.dialog.open(TrendingCalculationDialogComponent, options);
  }

  public mappingOptions(
    mapping: MappingOptions
  ): MatDialogRef<MappingOptionsDialogComponent> {
    const options = {
      width: '550px',
      data: mapping,
    };
    return this.dialog.open(MappingOptionsDialogComponent, options);
  }

  public pMapsSettings(
    settings: PMapsSettings
  ): MatDialogRef<PMapsSettingsDialogComponent> {
    const options = {
      width: '700px',
      data: settings,
    };
    return this.dialog.open(PMapsSettingsDialogComponent, options);
  }

  public changePMapsData(
    data: PMapsSeriesDataItem[]
  ): MatDialogRef<PMapsDataDialogComponent> {
    const options = {
      width: '900px',
      data,
    };
    return this.dialog.open(PMapsDataDialogComponent, options);
  }
}
