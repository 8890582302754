import { flatten } from 'lodash';

import {
  TupChartData,
  TupChartType,
  TupComboChartData,
} from '@telmar-global/tup-document-exporter';

import {
  isData,
  ChartSettings,
  Data,
  Dataset,
  DataItemId,
  ExportProps,
  Survey,
  SurveyTimeProps,
  DATA_ITEMS_MAP,
  Y_AXIS_LABEL_CHART_TYPES,
  X_AXIS_LABEL_CHART_TYPES,
} from '../models';
import { ChartComponent } from '../components';
import { ChartTitlePipe } from '../pipes';

export const getSurveyTimeProps = (
  chart: ChartComponent,
  survey: Survey
): SurveyTimeProps => {
  const pipe: ChartTitlePipe = new ChartTitlePipe();

  const chartTitle = pipe.transform(
    chart.localChartSettings.chartTitle,
    chart.targetTitle,
    chart.activeGraph.graphType,
    chart.primaryDataItemSelection,
    chart.secondaryChartType.graphType,
    chart.secondaryDataItemSelection
  );

  const chartSubtitle: string = chart.chartData.title;

  const { code, title } = survey;
  const chartSource: string = [code, title].join(' - ');

  return {
    settings: chart.localChartSettings,
    primaryData: chart.finalData,
    secondaryData: chart.secondaryDataItemValues,
    charts: chart.charts,
    title: chartTitle,
    subtitle: chartSubtitle,
    source: chartSource,
    selected: chart.exportSelected,
  };
};

/**
 * Converts Chart.js settings and data into PptxGenJS core chart data that can be consumed by the export library.
 */
export const getExportProps = (
  settings: ChartSettings,
  primaryData: Data,
  secondaryData: number[][] | Data
): ExportProps => {
  const primaryChartType: TupChartType =
    TupChartType[settings.primaryChartType];

  const primaryChartData: TupChartData[] = getCoreChartData(primaryData);

  const secondaryChartType: TupChartType =
    settings.secondaryChartType !== 'None'
      ? TupChartType[settings.secondaryChartType]
      : undefined;

  const secondaryChartData: TupChartData[] = secondaryData
    ? getCoreChartData(formatSecondaryData(primaryData, secondaryData))
    : undefined;

  if (primaryChartType === TupChartType.tupScatter) {
    primaryChartData.push(...secondaryChartData);
  }

  const chartColors: string[] = getChartColors(primaryData);

  const chartOpts: { [key: string]: any } = getChartOpts(
    chartColors,
    settings,
    secondaryChartType
  );

  return {
    primaryChartType,
    primaryChartData,
    secondaryChartType,
    secondaryChartData,
    chartColors,
    chartOpts,
  };
};

/**
 * Converts secondary data into a consistent format.
 *
 * Secondary data (ChartComponent.secondaryDataValues) does not have a type.
 *
 * Sometimes it's in the same format as the primary data, other times, it's a 2D Array of numbers ¯\_(ツ)_/¯.
 */
export const formatSecondaryData = (
  primaryData: Data,
  secondaryData: number[][] | Data
): Data => {
  const callbackFn = (dataset: Dataset, index: number): Dataset =>
    ({
      label: dataset.label,
      data: flatten(secondaryData[index]),
    } as Dataset);

  return isData(secondaryData)
    ? secondaryData
    : {
        labels: primaryData.labels,
        datasets: primaryData.datasets.map(callbackFn),
      };
};

/**
 * Converts Chart.js data into PptxGenJS core chart data that can be consumed by the export library.
 *
 * @param data The Chart.js data.
 */
export const getCoreChartData = (data: Data): TupChartData[] => {
  return data.datasets.map((dataset: Dataset) => ({
    name: dataset.label,
    labels: data.labels,
    values: dataset.data,
  }));
};

/**
 * Converts PptxGenJS core chart data into combo chart data (the core chart and combo chart function signatures are slightly different)
 *
 * @param settings The chart settings
 * @param primaryChartData The primary data
 * @param secondaryChartData The secondary data
 * @param chartColors An Array of chart colours
 */
export const getComboChartData = (
  settings: ChartSettings,
  primaryChartData: TupChartData[],
  secondaryChartData: TupChartData[],
  chartColors: string[]
): TupComboChartData[] => {
  return [
    {
      type: TupChartType[settings.primaryChartType],
      data: primaryChartData,
      options: {
        chartColors,
      },
    },
    {
      type: TupChartType[settings.secondaryChartType],
      data: secondaryChartData,
      options: {
        chartColors,
        secondaryCatAxis: true,
        secondaryValAxis: true,
      },
    },
  ];
};

/**
 * Extracts chartColors PptxGenJS IChartOpt from Chart.js settings.
 *
 * @param data The data.
 */
export const getChartColors = (data: Data): string[] => {
  const chartColors: string[] =
    data.datasets.length === 1
      ? data.datasets[0].backgroundColor
      : data.datasets.map((dataset: Dataset) => dataset.backgroundColor[0]);

  return chartColors.map((chartColor: string) =>
    chartColor.toUpperCase().replace('#', '')
  );
};

/**
 * Extracts PptxGenJS IChartOpts from Chart.js settings.
 *
 * @param secondaryChartType The secondary chart type.
 * @param settings The chart settings.
 */
export const getChartOpts = (
  chartColors: string[],
  settings: ChartSettings,
  secondaryChartType: TupChartType
): { [key: string]: any } => {
  return {
    chartColors,
    showLabel: settings.showChartLabel,
    showValue: settings.showChartLabel,
    showDataTable: settings.showDataTable,
    showLegend: settings.showChartLegend,
    ...getAxisTitles(null, settings),
    ...getAxisTitles(secondaryChartType, settings),
    dataLabelFormatCode:
      '#,###' +
      (settings.decimalPlaces > 0
        ? '.' + '#'.repeat(settings.decimalPlaces)
        : ''),
  };
};

/**
 * Extracts catAxisTitle / valAxisTitle PptxGenJS IChartOpts from Chart.js settings.
 *
 * @param secondaryChartType The secondary chart type.
 * @param settings The chart settings.
 */
export const getAxisTitles = (
  secondaryChartType: TupChartType | null,
  settings: ChartSettings
): any => {
  // Combo charts
  if (secondaryChartType) {
    return {
      valAxes: [
        {
          valAxisTitle: getAxisTitle(settings.primaryDataItem, settings),
        },
        {
          valAxisTitle: getAxisTitle(settings.secondaryDataItem, settings),
          valAxisTitleRotate: 90,
        },
      ],
    };
    // Core charts
  } else {
    return X_AXIS_LABEL_CHART_TYPES.includes(settings.primaryChartType) ||
      Y_AXIS_LABEL_CHART_TYPES.includes(settings.primaryChartType)
      ? {
          showValAxisTitle: true,
          valAxisTitle: getAxisTitle(settings.primaryDataItem, settings),
        }
      : {
          catAxisTitle: getAxisTitle(settings.primaryDataItem, settings),
          valAxisTitle: getAxisTitle(settings.secondaryDataItem, settings),
        };
  }
};

/**
 * Extracts the axis title (DataItem displayName) according to the DataItemId.
 *
 * If showAxisLabel is false, returns a space (not an empty string!).
 *
 * @param dataItemId The DataItemId
 * @returns The axis title (DataItem displayName)
 */
export const getAxisTitle = (
  dataItemId: DataItemId,
  settings: ChartSettings
): string => {
  return settings.showAxisLabel ? DATA_ITEMS_MAP[dataItemId].displayName : ' ';
};
