import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import {
  BasicAccountDetails,
  BasicUserDetails,
  TupAnalyticsService,
} from '@telmar-global/tup-analytics';
import { AuthEvent, TupAuthService } from '@telmar-global/tup-auth';
import {
  UserContainer,
  UserDetails,
} from '@telmar-global/tup-auth/lib/tup-auth.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private authService: TupAuthService,
    private analyticsService: TupAnalyticsService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.analyticsService.sendPageviews(router);
    this.authService.authEvents.subscribe((e: AuthEvent) => {
      if (AuthEvent.AuthEventUserDataChanged === e) {
        if (this.authService.user) {
          this.trackUserLogin(this.authService.user);
        } else {
          this.analyticsService.logout();
        }
      }
    });
  }

  private trackUserLogin(user: UserDetails): void {
    const UUID = user.uuid;
    const email = user.attributes.email;
    const defaultContainer = user.containers.find(
      (container: UserContainer) => container.default
    );
    const businessName = defaultContainer
      ? defaultContainer.label
      : user.containers[0].label;
    const businessId = defaultContainer
      ? defaultContainer.name
      : user.containers[0].name;
    const loginUser: BasicUserDetails = {
      id: UUID,
      attributes: {
        email,
      },
    };
    const accountDetails: BasicAccountDetails = {
      id: businessId,
      name: businessName,
    };
    this.analyticsService.login(loginUser, accountDetails);
  }

  public ngOnInit(): void {
    // Close any dialogs when a NavigationStart RouterEvent occurs
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter((event: RouterEvent) => event instanceof NavigationStart)
      )
      .subscribe(() => this.dialog.closeAll());
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
