import { Injectable } from '@angular/core';

import {
  TupXlsxBuilder,
  TupXlsxExporterService,
} from '@telmar-global/tup-document-exporter';
import { DialogService } from './dialog.service';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { GoogleDriveFileInfo } from '@telmar-global/tup-google-api';
import { RequestLoadingService } from './request-loading.service';

@Injectable({
  providedIn: 'root',
})
export class XlsxService {
  constructor(
    private exporterService: TupXlsxExporterService,
    private dialogService: DialogService,
    private messageService: TupUserMessageService,
    private requestLoadingService: RequestLoadingService
  ) {}

  public async saveAs(
    builder: TupXlsxBuilder,
    filename?: string
  ): Promise<void> {
    await this.exporterService.saveAs(
      this.exporterService.build(builder),
      filename
    );
  }

  public async exportToSheets(
    builder: TupXlsxBuilder,
    filename?: string
  ): Promise<void> {
    this.requestLoadingService.setLoading({
      target: 'global',
      isLoading: true,
    });
    this.exporterService
      .exportToSheets(this.exporterService.build(builder), filename)
      .then((fileInfo: GoogleDriveFileInfo) => {
        this.dialogService.exportedGoogleFile(fileInfo.webViewLink);
      })
      .catch((error) => {
        console.error(error);
        this.alertExportError(error);
      })
      .finally(() => {
        this.requestLoadingService.setLoading({
          target: 'global',
          isLoading: false,
        });
      });
  }

  private alertExportError(error: string): void {
    this.messageService.showSnackBar(error, 'OK');
  }
}
