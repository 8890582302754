import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { DataItemsService } from '../../services/data-items.service';
import { DataItem, DataItemType } from '../../models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'data-items-dialog',
  templateUrl: './data-items-dialog.component.html',
  styleUrls: ['./data-items-dialog.component.scss'],
})
export class DataItemsDialogComponent implements OnInit {
  public dataItems: DataItem[];
  public dataItemType: typeof DataItemType = DataItemType;
  public disableButton = false;

  constructor(
    public dialogRef: MatDialogRef<DataItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: TupUserMessageService,
    private dataItemsService: DataItemsService
  ) {
    this.dataItems = this.data;
  }

  ngOnInit(): void {}

  public onApplyButtonClick(): void {
    if (this.dataItems.length < 1) {
      this.messageService.openMessageDialog(
        'Please select at least one data item to prepare report, or the program cannot proceed.',
        'Alert'
      );
      return;
    }
    this.showWarningBeforeClosing(this.dataItems);
  }

  public onResetDataItems(): void {
    this.showWarningBeforeClosing(this.dataItemsService.getDefaultDataItems());
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.dataItems, event.previousIndex, event.currentIndex);
  }

  public checked($event): void {
    if (!$event.checked) {
      this.disableButton =
        this.dataItems.filter((item) => item.selected).length === 0 ||
        this.dataItems.filter((item) => item.available).length === 0;
    } else {
      this.disableButton = false;
    }
  }

  public getItemAtIndex(index: number): DataItem {
    return this.dataItems.filter(
      (item: DataItem) => item.originalIndex === index
    )[0];
  }

  private showWarningBeforeClosing(dataItems: DataItem[]): void {
    this.messageService
      .openMessageDialog(
        'Please note all filtering/sorting applied to this report mode will be reset.',
        'Alert'
      )
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close(dataItems);
      });
  }
}
