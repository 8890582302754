<h1 mat-dialog-title>{{ data.dialogTitle }}</h1>

<div mat-dialog-content>
  <mat-form-field
    appearance="outline"
    tupMatFormField
    fxFlex="100"
  >
    <mat-label>{{ data.inputTitle }}</mat-label>
    <input
      type="text"
      required
      matInput
      [formControl]="formControl"
      data-qa="rename-value-input"
    />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    cdkFocusRegionstart
    (click)="saveAs()"
    [disabled]="formControl.invalid"
    data-qa="rename-save-button"
  >
    Save
  </button>
</div>
