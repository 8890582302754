import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  Empty,
  TupDocument,
  TupDocumentService,
  TupDocumentTypeId,
  TupDocumentTypes,
} from '@telmar-global/tup-document-storage';
import { HttpResponse } from '@angular/common/http';
import { SurveyTimeDocument } from '../models';
import { merge, pick } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(
    private authService: TupAuthService,
    private tupDocumentService: TupDocumentService
  ) {}

  public importJSON(
    file: File,
    userEmail,
    docTypeId:
      | TupDocumentTypeId.SURVEYTIME_CAMPAIGN
      | TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
  ): Observable<HttpResponse<Empty>> {
    return new Observable((subscriber) => {
      try {
        this.read(file).subscribe((result) => {
          const docJSON = JSON.parse(result);
          const doc: TupDocument<SurveyTimeDocument> = merge(
            {},
            pick(docJSON, [
              'metadata.type',
              'metadata.status',
              'content',
              'metadata.name',
            ]),
            {
              metadata: {
                name: `${docJSON.metadata.name} (imported)`,
                type: TupDocumentTypes[docTypeId],
                status: docJSON.metadata.status,
              },
            }
          );

          const userContainer = this.authService.user.containers.find(
            (container: UserContainer) => container.name === userEmail
          );

          this.tupDocumentService.create(userContainer.name, doc).subscribe(
            (res) => {
              subscriber.next(res);
              subscriber.complete();
            },
            (error) => subscriber.error(error)
          );
        });
      } catch (error) {
        subscriber.error(error);
      }
    });
  }

  private read(file: File): Observable<string> {
    const fileReader = new FileReader();

    return new Observable((subscriber: Subscriber<string>) => {
      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        subscriber.next(event.target.result.toString());
        subscriber.complete();
      };

      fileReader.onerror = (error: ProgressEvent<FileReader>): void => {
        subscriber.error(error);
      };

      fileReader.readAsText(file);
    });
  }
}
