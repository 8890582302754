import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep, isEqual } from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CellColor,
  CellColorId,
  CellStyleStatus,
  ColumnHeaderFilter,
  ColumnFilter,
  CrossTabTableDataCell,
  DataItem,
  DataItemCellKey,
  DataItemType,
  DEFAULT_SORT_OPTIONS,
  SortDirection,
  SortSettings,
  StabilityLevels,
  TargetId,
  DATA_ITEMS_MAP,
  ReportPreference,
  VOLUMETRIC_DATA_ITEM_IDS,
  HighlightValues,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
} from 'src/app/models';
import { ReportPreferencesService } from '../../../services';
import { isNotNullOrUndefined } from '../../../utils/pipeable-operators';

export interface SortedTargetData {
  sortSettings: SortSettings;
  sortedData: CrossTabTableDataCell[];
  sortState: Sort;
}

export type SortResult = Record<TargetId, SortedTargetData>;

export interface SortChangeData {
  targetId: TargetId;
  sortedTargetData: SortedTargetData;
}

@Component({
  selector: 'app-single-target-table',
  templateUrl: './single-target-table.component.html',
  styleUrls: ['./single-target-table.component.scss'],
})
export class SingleTargetTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  public readonly zScoreHighlightProb = Z_SCORE_FILTERED_HIGHLIGHT_PROB;
  @Input() singleTableData: CrossTabTableDataCell[];
  @Input() displayedColumns: string[] = [];
  @Input() dataItemMap: Record<DataItemCellKey, DataItem> = {};
  @Input() decimalPoints: Record<string, number>;
  @Input() reportUnits: number;
  @Input() stabilityFlagStatus: StabilityLevels;
  @Input() cellStyleStatus: CellStyleStatus;
  @Input() highlightValues: HighlightValues;
  @Input() zScoreHighlight: boolean;
  @Input() heatmapIndexPercentage: number;
  @Input() cellColors: Record<CellColorId, CellColor> = {};
  @Input() iCol: number;
  @Input() targetData: any;
  @Input() columnHeaderFilters: ColumnHeaderFilter[] = [];
  @Input() sortSettings: SortSettings;
  @Output() sortChange = new EventEmitter<SortChangeData>();
  @Output() filterChange = new EventEmitter<string>();
  @Output() removeFilter = new EventEmitter<string>();

  public tableTargetData: any;
  private unsubscribe: Subject<void> = new Subject<void>();
  public surveyCode = '';
  private columnId = '';
  public dataSource: MatTableDataSource<CrossTabTableDataCell> =
    new TableVirtualScrollDataSource([]);
  public dataItemType: typeof DataItemType = DataItemType;
  public displayedTotals: string[] = [];
  public columnHeaderFilter: ColumnHeaderFilter;
  public columnFiltersMap: Set<DataItemType> = new Set();
  public hasVolumetricCoding: boolean;
  public volumetricDataItemIds: number[] = VOLUMETRIC_DATA_ITEM_IDS;

  constructor(private reportPreferencesService: ReportPreferencesService) {}

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.listenToColumnFiltersChanges();
  }

  ngOnChanges(): void {
    this.setupTable();

    if (this.sortSettings) {
      this.setPreviousSorting(this.sortSettings);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onSortChange(sortState: Sort) {
    const isDataItemMapReady = Object.keys(this.dataItemMap).length > 0;
    if (!isDataItemMapReady) {
      return;
    }
    const sortSettings: SortSettings = {
      ...DEFAULT_SORT_OPTIONS,
      columnId: this.columnId,
      order: sortState.direction
        ? SortDirection[sortState.direction]
        : SortDirection.none,
      survey: this.surveyCode,
      dataItem: sortState.direction
        ? this.dataItemMap[sortState.active]?.id
        : DataItemType.none,
    };

    const sortedData = this.dataSource.sortData(
      this.dataSource.data,
      this.sort
    );

    const sortChangeData: SortChangeData = {
      targetId: this.columnId,
      sortedTargetData: {
        sortSettings,
        sortedData,
        sortState,
      },
    };

    this.sortChange.emit(sortChangeData);

    const shouldUpdateState = !isEqual(sortSettings, this.sortSettings);
    if (shouldUpdateState) {
      this.reportPreferencesService.sortSeparateColumn(
        this.columnId,
        sortSettings
      );
    }
  }

  public openFilter(): void {
    this.filterChange.emit(this.columnId);
  }

  public onColumnHeaderFilterRemove(): void {
    this.removeFilter.emit(this.columnId);
  }

  public onColumnHeaderFilterClick(): void {
    this.filterChange.emit(this.columnId);
  }

  private listenToColumnFiltersChanges(): void {
    this.reportPreferencesService.preference$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((preference: ReportPreference) => {
        const currentTableHeaderFilter = preference.filters.find(
          (columnHeaderFilter: ColumnHeaderFilter) =>
            columnHeaderFilter.columnId === this.columnId
        );

        if (currentTableHeaderFilter) {
          this.columnFiltersMap = new Set(
            currentTableHeaderFilter.filters.map(
              (columnFilter: ColumnFilter) => columnFilter.dataItem
            )
          );
        }
      });
  }

  private setPreviousSorting(sortSettings: SortSettings): void {
    const sortState = {
      direction: sortSettings.order,
      active: `${DATA_ITEMS_MAP[sortSettings.dataItem].cellKey}#${
        sortSettings.dataItem
      }`,
    };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  private setupTable(): void {
    this.tableTargetData = cloneDeep(this.targetData);
    this.surveyCode = this.tableTargetData.surveyCode;

    this.columnId = `${this.tableTargetData.columnTarget?.id || 'totals'}_${
      this.surveyCode
    }`;

    this.columnHeaderFilter = this.columnHeaderFilters.find(
      (filter: ColumnHeaderFilter) => filter.columnId === this.columnId
    );

    this.displayedTotals = this.displayedColumns.map(
      (columnName) => `${columnName}&totals`
    );

    const tableDataWithoutTotals = this.getTableDataWithoutTotals();
    this.dataSource = new TableVirtualScrollDataSource(tableDataWithoutTotals);
    this.dataSource.sort = this.sort;

    this.hasVolumetricCoding =
      tableDataWithoutTotals.filter(
        (crossTabTableDataCell: CrossTabTableDataCell) =>
          crossTabTableDataCell.metadata?.isVolumetricCoding
      ).length > 0;

    this.setupSortData();
  }

  private getTableDataWithoutTotals(): CrossTabTableDataCell[] {
    const tableData = cloneDeep(this.singleTableData);

    tableData.shift();
    return tableData.filter((tableRow: CrossTabTableDataCell) => {
      return Object.keys(tableRow).length > 1;
    });
  }

  private setupSortData(): void {
    this.dataSource.sortingDataAccessor = (
      rowData: any,
      sortHeaderId: string
    ) => {
      return rowData[sortHeaderId.split('#')[0]];
    };
  }
}
