import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  TupDocument,
  TupDocumentEventType,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { QuickReportListComponent } from './quick-report-list/quick-report-list.component';
import { CreateNewDocumentService } from 'src/app/services';
import { combineLatest } from 'rxjs';
import { MyReportListComponent } from './my-report-list/my-report-list.component';
import { map } from 'rxjs/operators';
import { SurveyTimeDocument, ViewType } from 'src/app/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-tab',
  templateUrl: './dashboard-tab.component.html',
  styleUrls: ['./dashboard-tab.component.scss'],
})
export class DashboardTabComponent implements AfterViewInit {
  @ViewChild('myQuickReports')
  public myQuickReports: QuickReportListComponent;

  @ViewChild('myReports')
  public myReports: MyReportListComponent;

  @Output()
  changeSelectedIndex: EventEmitter<number> = new EventEmitter<number>();

  public eventType: TupDocumentEventType = TupDocumentEventType.CLICK;

  public sortedQuickLinks;
  public TupDocumentTypeId = TupDocumentTypeId;

  constructor(
    private createNewDocumentService: CreateNewDocumentService,
    private router: Router,
    private cdref: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.mergeReports(this.myReports, this.myQuickReports);
    this.cdref.detectChanges();
  }

  public openExisting(doc: TupDocument<unknown>): void {
    const currentDoc = Object.assign(doc) as TupDocument<SurveyTimeDocument>;
    if (
      doc.metadata.type.id === TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
    ) {
      this.router.navigate([`doc/${currentDoc.metadata.id}/chart`]);
    } else {
      this.router.navigate([`doc/${currentDoc.metadata.id}/data`], {
        queryParams: {
          tab: ViewType.crossTab,
        },
      });
    }
    return;
  }

  navigateToReportsTab(selectedIndex: number) {
    this.changeSelectedIndex.emit(selectedIndex);
  }

  public createReport(): void {
    const isTemplateDocType = false;
    this.createNewDocumentService.create(isTemplateDocType);
  }

  public createQuickReport(): void {
    const isTemplateDocType = true;
    this.createNewDocumentService.create(isTemplateDocType);
  }

  public mergeReports(reports, quickReports) {
    const reports$ = reports.dataSource.documents;
    const quickReports$ = quickReports.quickReportDataSources.documents;

    combineLatest([reports$, quickReports$])
      .pipe(
        map(([repo, quickRepo]) => {
          const mergedReports = [...(repo as any), ...(quickRepo as any)];
          return mergedReports.sort((a, b) => {
            const reportDateModified = a.metadata.modified;
            const quickReportDateModified = b.metadata.modified;
            return (
              new Date(quickReportDateModified).valueOf() -
              new Date(reportDateModified).valueOf()
            );
          });
        })
      )
      .subscribe((sortedQuickLinks) => {
        this.sortedQuickLinks = sortedQuickLinks;
      });
  }
}
