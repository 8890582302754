import { Component, Inject, OnInit } from '@angular/core';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { UserAction } from '@telmar-global/tup-auth';
import { environment } from 'src/environments/environment';
import { StateService } from '../../services';
import { version } from '../../../../package.json';
import { ActivatedRoute } from '@angular/router';
import { SideNavRoute } from '@telmar-global/tup-auth/lib/tup-side-nav/tup-side-nav.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public sidebarInitialState: boolean;
  public sidebarOpened: boolean;

  public docRoutesConfig: SideNavRoute[];
  public dashboardRouteConfig: SideNavRoute;

  version: string = version;

  constructor(
    private stateService: StateService,
    private analyticsService: TupAnalyticsService,
    private activatedRoute: ActivatedRoute,
    @Inject(Window) private window: Window
  ) {
    this.buildNavConfig();
  }

  ngOnInit() {
    this.sidebarOpened = this.sidebarInitialState =
      this.stateService.state$.getValue().isSidebarOpen;
  }

  onLogoClick() {
    this.goToPlatformHomepage();
  }

  onToggleSidePanel(value: boolean) {
    this.stateService.state$.next({
      isSidebarOpen: value,
    });
    this.sidebarOpened = value;
  }

  onUserAction(event: UserAction) {
    if (
      event === UserAction.changePassword ||
      event === UserAction.changePasswordFailed
    ) {
      this.analyticsService.e('user engagement', event);
    }
  }

  private goToPlatformHomepage(): void {
    this.analyticsService.e('Platform', 'platform');
    this.window.open(environment.appOptions.platformHomepage, '_self');
  }

  private buildNavConfig(): void {
    this.dashboardRouteConfig = {
      url: '/dashboard',
      attributeData: 'dashboard-btn',
      tooltip: 'Dashboard',
      matIcon: 'bar_chart',
      label: 'My SurveyTime dashboard',
    };

    const param = this.activatedRoute.snapshot.params.documentId;
    this.docRoutesConfig = [
      {
        url: `/doc/${param}/data`,
        queryParams: {
          tab: 0,
        },
        attributeData: `data-btn`,
        label: 'Data',
        tooltip: 'Data',
        matIcon: `table_chart`,
        children: [
          {
            url: `/doc/${param}/data`,
            label: 'Report',
            tooltip: 'Report',
            attributeData: 'report-btn',
            queryParams: { tab: 0 },
          },
          {
            url: `/doc/${param}/data`,
            label: 'Coding grid',
            tooltip: 'Coding grid',
            attributeData: 'coding-grid-btn',
            queryParams: { tab: 1 },
          },
        ],
      },
      {
        url: `/doc/${param}/chart`,
        attributeData: `visualisation-btn`,
        tooltip: 'Visualisation',
        matIcon: `pie_chart`,
        label: 'Visualisation',
        children: [
          {
            url: `/doc/${param}/chart`,
            attributeData: 'chart-btn',
            tooltip: 'Chart',
            label: 'Chart',
          },
          {
            url: `/doc/${param}/pmaps`,
            attributeData: 'pmaps-btn',
            tooltip: 'PMaps',
            label: 'PMaps',
          },
        ],
      },
    ];
  }
}
