<div
  class="view-container"
  fxFlex
  fxLayout="row"
>
  <div
    fxFill
    fxLayout="column"
    class="grid-views-container"
  >
    <div
      class="data-controls-container"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <mat-button-toggle-group
          class="view-toggle-group-control"
          [(ngModel)]="selectedTab"
        >
          <mat-button-toggle
            data-tracking="coding-grid-tables-toggle"
            [disableRipple]="true"
            matTooltip="Tables"
            data-qa="Tables"
            [value]="targetType.tables"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 4.5H14.5M4.5 0.5V14.5M1.5 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H1.5C0.947716 14.5 0.5 14.0523 0.5 13.5V1.5C0.5 0.947716 0.947715 0.5 1.5 0.5Z"
                stroke="#0054FF"
              />
            </svg>
            <span class="view-toggle-group-control__text">{{
              tablesTabLabel
            }}</span>
          </mat-button-toggle>

          <mat-button-toggle
            data-tracking="coding-grid-columns-toggle"
            [disableRipple]="true"
            matTooltip="Columns (Targets)"
            data-qa="Columns"
            [value]="targetType.columns"
          >
            <svg
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 3.57628e-07L8.5 15M12.5 4.76837e-07L12.5 15M4.5 1.19209e-07L4.5 15M0.500001 0L0.5 15"
                stroke="#0054FF"
              />
            </svg>
            <span class="view-toggle-group-control__text">{{
              columnsTabLabel
            }}</span>
          </mat-button-toggle>
          <mat-button-toggle
            data-tracking="coding-grid-rows-toggle"
            [disableRipple]="true"
            matTooltip="Rows (Insights)"
            data-qa="Rows"
            [value]="targetType.rows"
          >
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4.5H15M0 0.5H15M0 8.5H15M0 12.5H15"
                stroke="#0054FF"
              />
            </svg>
            <span class="view-toggle-group-control__text">{{
              rowsTabLabel
            }}</span>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <div
          *ngIf="!isReadonly"
          fxFlex
          class="grid-views-control-buttons"
          fxLayout="row"
          fxLayoutGap="10px"
        >
          <button
            data-tracking="coding-grid-code-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            (click)="onOpenCodeBuilder()"
            [disabled]="selectedRows.length !== 1"
          >
            <mat-icon matTooltip="Visual code builder">code</mat-icon>
          </button>

          <button
            data-tracking="coding-grid-ntile-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            (click)="onOpenNTileSettings()"
            [disabled]="selectedRows.length !== 1 || !isDeletable"
          >
            <mat-icon matTooltip="Ntiles">grid_on</mat-icon>
          </button>

          <button
            data-tracking="coding-grid-swap-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            (click)="onSwapRowsAndColumns()"
            [disabled]="
              rows.length === emptyGridRowSize &&
              columns.length === emptyGridRowSize
            "
            data-qa="swap-top-menu-action"
            [matTooltip]="'Swap rows/columns' + swapActionWarning"
          >
            <mat-icon>sync_alt</mat-icon>
          </button>

          <button
            data-tracking="coding-grid-duplicate-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            [disabled]="!isDeletable"
            data-qa="duplicate-top-menu-action"
            (click)="onDuplicateSelectedRows()"
          >
            <mat-icon
              matTooltip="Duplicate selected items"
              class="material-symbols-outlined"
            >
              content_copy
            </mat-icon>
          </button>

          <button
            data-tracking="coding-grid-sent-to-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            [matMenuTriggerFor]="sendToMenu"
            [disabled]="!isDeletable"
            data-qa="send-to-top-menu-action"
          >
            <mat-icon
              matTooltip="Send to"
              class="material-symbols-outlined"
            >
              send
            </mat-icon>
          </button>

          <button
            data-tracking="coding-grid-combine-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            [matMenuTriggerFor]="combineMenu"
            [disabled]="selectedRows.length < 2"
            data-qa="combine-top-menu-action"
          >
            <mat-icon
              matTooltip="Combine selected items"
              class="material-symbols-outlined"
            >
              join
            </mat-icon>
          </button>

          <button
            data-tracking="coding-grid-count-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            [disabled]="!isDeletable"
            (click)="onSeparateCountActionClicked()"
            data-qa="separate-count-top-menu-action"
          >
            <mat-icon
              matTooltip="Separate count"
              class="material-symbols-outlined"
            >
              calculate
            </mat-icon>
          </button>

          <button
            data-tracking="coding-grid-group-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            [disabled]="!isDeletable"
            data-qa="group-name-top-menu-action"
            (click)="onRenameGroupName()"
          >
            <mat-icon
              matTooltip="Assign group name"
              class="material-symbols-outlined"
            >
              group_work
            </mat-icon>
          </button>

          <button
            data-tracking="coding-grid-delete-btn"
            onclick="this.blur()"
            fxFlex="0 0 auto"
            mat-stroked-button
            color="primary"
            tupMatButton
            appearance="tiny"
            class="control-button"
            (click)="onDeleteSelectedRows()"
            [disabled]="!isDeletable"
          >
            <mat-icon
              matTooltip="Delete selected items"
              class="material-icons-outlined"
              >delete</mat-icon
            >
          </button>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <app-tablebase-info-popper
          [popperAppendTo]="'.grid-views-container'"
        ></app-tablebase-info-popper>

        <app-tablebase-select
          [isReadonly]="isReadonly"
          (tablebaseEdit)="onEditTablebase($event)"
        ></app-tablebase-select>
      </div>
    </div>

    <mat-tab-group
      tupMatIchabodsBody
      color="primary"
      [(selectedIndex)]="selectedTab"
      (selectedTabChange)="onTabChange($event)"
      class="ichabods-tab-group"
    >
      <mat-tab>
        <app-coding-grid-table
          #tableCodingGrid
          fxFlex
          [isReadonly]="isReadonly"
          [tableTargetType]="targetType.tables"
          [data]="tables"
          [isDeletable]="isDeletable"
          [isProgressing]="isProgressing"
          (editClicked)="onOpenCodeBuilder($event)"
          (selectedRowsChange)="onSelectedRowsChange($event)"
          (deleteClicked)="onDeleteSelectedRows($event)"
          (combineClicked)="onCombineActionClicked($event)"
          (separateCountClicked)="onSeparateCountActionClicked($event)"
          (clickedRow)="onClickRow($event)"
          (sendToClicked)="onSendTo($event)"
          (assignGroupNameClicked)="onRenameGroupName($event)"
          (duplicateClicked)="onDuplicateSelectedRows($event)"
          (nTileSettingsClicked)="onOpenNTileSettings($event)"
        ></app-coding-grid-table>
      </mat-tab>
      <mat-tab>
        <app-coding-grid-table
          #columnCodingGrid
          fxFlex
          [isReadonly]="isReadonly"
          [tableTargetType]="targetType.columns"
          [data]="columns"
          [isDeletable]="isDeletable"
          [isProgressing]="isProgressing"
          (editClicked)="onOpenCodeBuilder($event)"
          (selectedRowsChange)="onSelectedRowsChange($event)"
          (deleteClicked)="onDeleteSelectedRows($event)"
          (combineClicked)="onCombineActionClicked($event)"
          (separateCountClicked)="onSeparateCountActionClicked($event)"
          (clickedRow)="onClickRow($event)"
          (sendToClicked)="onSendTo($event)"
          (assignGroupNameClicked)="onRenameGroupName($event)"
          (duplicateClicked)="onDuplicateSelectedRows($event)"
          (nTileSettingsClicked)="onOpenNTileSettings($event)"
        ></app-coding-grid-table>
      </mat-tab>
      <mat-tab>
        <app-coding-grid-table
          #rowCodingGrid
          fxFlex
          [isReadonly]="isReadonly"
          [tableTargetType]="targetType.rows"
          [data]="rows"
          [isDeletable]="isDeletable"
          [isProgressing]="isProgressing"
          (editClicked)="onOpenCodeBuilder($event)"
          (selectedRowsChange)="onSelectedRowsChange($event)"
          (deleteClicked)="onDeleteSelectedRows($event)"
          (combineClicked)="onCombineActionClicked($event)"
          (separateCountClicked)="onSeparateCountActionClicked($event)"
          (clickedRow)="onClickRow($event)"
          (sendToClicked)="onSendTo($event)"
          (assignGroupNameClicked)="onRenameGroupName($event)"
          (duplicateClicked)="onDuplicateSelectedRows($event)"
          (nTileSettingsClicked)="onOpenNTileSettings($event)"
        ></app-coding-grid-table>
      </mat-tab>
      <mat-tab> </mat-tab>
    </mat-tab-group>
  </div>
</div>

<mat-menu #combineMenu="matMenu">
  <div data-qa="combine-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of combineActionItems"
      [matTooltip]="action.tooltip"
      matTooltipPosition="right"
      (click)="onCombineActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #sendToMenu="matMenu">
  <div data-qa="send-to-action-menu">
    <ng-container *ngFor="let sendToAction of sendToActionItems">
      <button
        *ngIf="sendToAction.currentTargetType !== selectedTab"
        mat-menu-item
        (click)="onSendTo({ actionItem: sendToAction })"
      >
        {{ sendToAction.name }}
      </button>
    </ng-container>
  </div>
</mat-menu>
