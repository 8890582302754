import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { TargetItem } from '../models';
import { TitleModeActionItem } from '../models/action.model';

export interface ChangeTargetTitleModeActionContext {
  targetItem: TargetItem;
  actionItem: TitleModeActionItem;
}

@Injectable({
  providedIn: 'root',
})
export class ChangeTargetTitleModeAction extends ActionDefinition<ChangeTargetTitleModeActionContext> {
  constructor(private documentService: DocumentService) {
    super();
  }

  public invoke(context: ChangeTargetTitleModeActionContext): void {
    const target = context.targetItem.target;
    target.activeTitleMode = context.actionItem.displayType;
    this.documentService.updateDocumentData([context.targetItem], false);
  }
}
