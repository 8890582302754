import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import { Operator, Target, TargetItem, TargetType } from '../models';
import { CountCodingModel } from '../dialogs';
import { DropActionItem } from '../models/action.model';
import { CombinedCodingThresholdService } from '../services/combined-coding-threshold.service';

export interface GenericOperatorActionContext {
  targetType: TargetType;
  targetItem?: TargetItem;
  selectedTargets: Target[];
  actionItem: DropActionItem;
  countCoding?: CountCodingModel;
}

@Injectable({
  providedIn: 'root',
})
export class GenericOperatorAction extends ActionDefinition<GenericOperatorActionContext> {
  constructor(
    protected documentService: DocumentService,
    protected targetService: TargetService,
    protected codebookSelectionService: CodebookSelectionService,
    protected combinedCodingThresholdService: CombinedCodingThresholdService
  ) {
    super();
  }

  public invoke(context: GenericOperatorActionContext): void {
    if (
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        context.selectedTargets
      )
    ) {
      this.combinedCodingThresholdService.showMaxLimitAlert();
      return;
    }

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(
        context.selectedTargets
      )
    ) {
      return;
    }

    if (context.targetItem) {
      this.addToExistingTarget(context);
    } else {
      this.addNewTarget(context);
    }
    this.codebookSelectionService.unselectNodes();
  }

  protected addToExistingTarget(context: GenericOperatorActionContext): void {
    const targets = this.targetService.groupTargets(
      context.selectedTargets,
      context.actionItem.groupOperator
    );
    let childTarget = targets[0];
    if (context.countCoding) {
      childTarget.countCoding = context.countCoding;
      childTarget = this.targetService.groupTargets(
        targets,
        Operator.and,
        true
      )[0];
    }
    this.targetService.addChildTarget(
      context.targetItem.target,
      childTarget,
      context.actionItem.operator
    );
    this.documentService.updateDocumentData([context.targetItem], true);
  }

  protected addNewTarget(context: GenericOperatorActionContext): void {
    let targets;
    if (context.actionItem.shouldGroupTarget) {
      targets = this.targetService.groupTargets(
        context.selectedTargets,
        context.actionItem.groupOperator
      );
      if (context.countCoding) {
        targets[0].countCoding = context.countCoding;
        targets = this.targetService.groupTargets(targets, Operator.and, true);
      }
      targets = this.targetService.groupTargets(targets, Operator.and, true);
    } else {
      targets = this.targetService.groupTargets(
        context.selectedTargets,
        context.actionItem.operator,
        true
      );
    }
    this.documentService.addDocumentData(targets, context.targetType, true);
  }
}
