import { ReportMode } from './crosstab.model';
import { NTILE_START_ADI_OPTION, NTILE_END_ADI_OPTION } from './n-tiles.model';

export enum DataItemType {
  none = 0,
  audience = 101,
  sample = 102,
  percentage = 103,
  percentRow = 104,
  index = 105,
  sigma = 106,
  sigmaRank = 107,
  sigmaTol = 108,
  sigmaTolRank = 109,
  correlationPP = 110,
  correlationPL = 111,
  wtdIndex = 112,
  filterProjected = 113,
  filterSample = 114,
  filterPercentage = 115,
  filterPercentRow = 116,
  filterIndex = 117,
  filterBasePercent = 118,
  zScore = 119,
  filterMarketSize = 120,
  filterZScore = 121,
  filterColSample = 122,
  filterRowSample = 123,
  percentColRank = 124,
  percentRowRank = 125,
  audienceRank = 126,
  indexRank = 127,
  percentTable = 128,
  meanStdDev = 129,
  filterCode = 130,
  coverage = 131,
  composition = 132,
  affinityScore = 98,
  affinityRank = 99,
  nTileStart = 97,
  nTileEnd = 96,
}

export enum DataItemDataType {
  default,
  adi,
  filter,
  rank,
}

export interface DataItem {
  id: DataItemType;
  displayName: DataItemDisplayName;
  name: string;
  type: DataItemDataType;
  dependsOnCellKey?: string;
  cellKey?: string;
  digitsInfo?: string;
  adi?: string;
  decimalPoints: number;
  available?: boolean;
  selected?: boolean;
  originalIndex?: number;
  widthType: 'sm' | 'md' | 'lg' | 'xl' | '';
  volumetricDisplayName?: string;
  description?: string;
  unchangeable?: boolean;
}

export type DataItemId = number;

export type DataItemDisplayName = string;

export type DataItemCellKey = string;

export const DATA_ITEMS_MAP: Record<DataItemId, DataItem> = {
  [DataItemType.none]: {
    name: 'None',
    displayName: 'None',
    id: DataItemType.none,
    type: DataItemDataType.default,
    decimalPoints: 0,
    widthType: '',
  },
  [DataItemType.audience]: {
    name: 'Audience',
    displayName: 'Audience',
    id: DataItemType.audience,
    type: DataItemDataType.default,
    decimalPoints: 2,
    cellKey: 'projected',
    digitsInfo: '1.2-2',
    widthType: 'md',
    volumetricDisplayName: 'Value',
    description: "For Volumetric coding, it means 'Value' data item",
  },
  [DataItemType.sample]: {
    name: 'Sample',
    displayName: 'Resps',
    id: DataItemType.sample,
    type: DataItemDataType.default,
    decimalPoints: 0,
    cellKey: 'sample',
    digitsInfo: '1.0-0',
    widthType: 'sm',
  },
  [DataItemType.percentage]: {
    name: 'Percentage',
    displayName: '%Col',
    id: DataItemType.percentage,
    type: DataItemDataType.default,
    decimalPoints: 2,
    cellKey: 'column',
    widthType: 'sm',
  },
  [DataItemType.percentRow]: {
    name: 'PercentRow',
    displayName: '%Row',
    id: DataItemType.percentRow,
    type: DataItemDataType.default,
    decimalPoints: 2,
    cellKey: 'row',
    widthType: 'sm',
  },
  [DataItemType.index]: {
    name: 'Index',
    displayName: 'Index',
    id: DataItemType.index,
    type: DataItemDataType.default,
    decimalPoints: 0,
    cellKey: 'index',
    widthType: 'sm',
  },
  [DataItemType.sigma]: {
    name: 'Sigma',
    displayName: '2-Sigma %',
    id: DataItemType.sigma,
    type: DataItemDataType.adi,
    adi: 'SIGMA',
    cellKey: 'SIGMA',
    decimalPoints: 1,
    widthType: 'md',
  },
  [DataItemType.sigmaRank]: {
    name: 'SigmaRank',
    displayName: '2-Sigma % rank',
    id: DataItemType.sigmaRank,
    type: DataItemDataType.adi,
    adi: 'SIGMA_RANK',
    cellKey: 'SIGMA_RANK',
    decimalPoints: 0,
    widthType: 'xl',
  },
  [DataItemType.sigmaTol]: {
    name: 'SigmaTol',
    displayName: '2-Sigma tolerence',
    id: DataItemType.sigmaTol,
    type: DataItemDataType.adi,
    adi: 'SIGMA_TOL',
    cellKey: 'SIGMA_TOL',
    decimalPoints: 1,
    widthType: 'xl',
  },
  [DataItemType.sigmaTolRank]: {
    name: 'SigmaTolRank',
    displayName: '2-Sigma tol rank',
    id: DataItemType.sigmaTolRank,
    type: DataItemDataType.adi,
    adi: 'SIGMA_TOL_RANK',
    cellKey: 'SIGMA_TOL_RANK',
    decimalPoints: 0,
    widthType: 'xl',
  },
  [DataItemType.correlationPP]: {
    name: 'CorrelationPP',
    displayName: 'Correlation (PP)',
    id: DataItemType.correlationPP,
    type: DataItemDataType.adi,
    adi: 'CORRELATION_PP',
    cellKey: 'CORRELATION_PP',
    decimalPoints: 3,
    widthType: 'lg',
  },
  [DataItemType.correlationPL]: {
    name: 'CorrelationPL',
    displayName: 'Correlation (PL)',
    id: DataItemType.correlationPL,
    type: DataItemDataType.adi,
    adi: 'CORRELATION_PL',
    cellKey: 'CORRELATION_PL',
    decimalPoints: 3,
    widthType: 'lg',
  },
  [DataItemType.wtdIndex]: {
    name: 'WtdIndex',
    displayName: 'Wtd index',
    id: DataItemType.wtdIndex,
    type: DataItemDataType.adi,
    adi: 'WTD_INDEX',
    cellKey: 'WTD_INDEX',
    decimalPoints: 0,
    widthType: 'md',
  },
  [DataItemType.filterProjected]: {
    name: 'FilterProjected',
    displayName: 'Audience - Filtered',
    id: DataItemType.filterProjected,
    type: DataItemDataType.filter,
    adi: 'FILTER_PROJECTION',
    cellKey: 'FILTER_PROJECTION',
    decimalPoints: 0,
    digitsInfo: '1.0-0',
    widthType: 'xl',
  },
  [DataItemType.filterSample]: {
    name: 'FilterSample',
    displayName: 'Resps - Filtered',
    id: DataItemType.filterSample,
    type: DataItemDataType.filter,
    adi: 'FILTER_SAMPLE',
    cellKey: 'FILTER_SAMPLE',
    decimalPoints: 0,
    digitsInfo: '1.0-0',
    widthType: 'lg',
  },
  [DataItemType.filterPercentage]: {
    name: 'FilterPercentage',
    displayName: '%Col - Filtered',
    id: DataItemType.filterPercentage,
    type: DataItemDataType.filter,
    adi: 'FILTER_PERCENTAGE_COLUMN_PROJECTION',
    cellKey: 'FILTER_PERCENTAGE_COLUMN_PROJECTION',
    decimalPoints: 1,
    widthType: 'lg',
  },
  [DataItemType.filterPercentRow]: {
    name: 'FilterPercentRow',
    displayName: '%Row - Filtered',
    id: DataItemType.filterPercentRow,
    type: DataItemDataType.filter,
    adi: 'FILTER_PERCENTAGE_ROW_PROJECTION',
    cellKey: 'FILTER_PERCENTAGE_ROW_PROJECTION',
    decimalPoints: 1,
    widthType: 'lg',
  },
  [DataItemType.filterIndex]: {
    name: 'FilterIndex',
    displayName: 'Index - Filtered',
    id: DataItemType.filterIndex,
    type: DataItemDataType.filter,
    adi: 'FILTER_INDEX',
    cellKey: 'FILTER_INDEX',
    decimalPoints: 1,
    widthType: 'lg',
  },
  [DataItemType.filterBasePercent]: {
    name: 'FilterBasePercent',
    displayName: 'Base% - Filtered',
    id: DataItemType.filterBasePercent,
    type: DataItemDataType.filter,
    adi: 'FILTER_BASE_PERCENTAGE',
    cellKey: 'FILTER_BASE_PERCENTAGE',
    decimalPoints: 0,
    widthType: 'lg',
  },
  [DataItemType.zScore]: {
    name: 'ZScore',
    displayName: 'Z-Score',
    id: DataItemType.zScore,
    type: DataItemDataType.adi,
    adi: 'ZSCORE',
    cellKey: 'ZSCORE',
    decimalPoints: 0,
    widthType: 'sm',
  },
  [DataItemType.filterMarketSize]: {
    name: 'FilterMarketSize',
    displayName: 'Market size',
    id: DataItemType.filterMarketSize,
    type: DataItemDataType.filter,
    adi: 'FILTER_MARKET_SIZE',
    cellKey: 'FILTER_MARKET_SIZE',
    decimalPoints: 0,
    digitsInfo: '1.0-0',
    widthType: 'md',
  },
  [DataItemType.filterZScore]: {
    name: 'FilterZScore',
    displayName: 'Z-Score - Filtered',
    id: DataItemType.filterZScore,
    type: DataItemDataType.filter,
    adi: 'FILTER_ZSCORE',
    cellKey: 'FILTER_ZSCORE',
    decimalPoints: 1,
    widthType: 'xl',
  },
  [DataItemType.filterColSample]: {
    name: 'FilterColSample',
    displayName: 'Col Resps - Filtered',
    id: DataItemType.filterColSample,
    type: DataItemDataType.filter,
    adi: 'FILTER_COLUMN_SAMPLE',
    cellKey: 'FILTER_COLUMN_SAMPLE',
    decimalPoints: 1,
    widthType: 'lg',
  },
  [DataItemType.filterRowSample]: {
    name: 'FilterRowSample',
    displayName: 'Row Resps - Filtered',
    id: DataItemType.filterRowSample,
    type: DataItemDataType.filter,
    adi: 'FILTER_ROW_SAMPLE',
    cellKey: 'FILTER_ROW_SAMPLE',
    decimalPoints: 1,
    widthType: 'lg',
  },
  [DataItemType.percentColRank]: {
    name: 'PercentColRank',
    displayName: '%Col rank',
    id: DataItemType.percentColRank,
    type: DataItemDataType.adi,
    adi: 'PERCENT_COL_RANK',
    decimalPoints: 0,
    cellKey: 'PERCENT_COL_RANK',
    dependsOnCellKey: 'column',
    widthType: 'md',
  },
  [DataItemType.percentRowRank]: {
    name: 'PercentRowRank',
    displayName: '%Row rank',
    id: DataItemType.percentRowRank,
    type: DataItemDataType.adi,
    adi: 'PERCENT_ROW_RANK',
    decimalPoints: 0,
    cellKey: 'PERCENT_ROW_RANK',
    dependsOnCellKey: 'row',
    widthType: 'md',
  },
  [DataItemType.audienceRank]: {
    name: 'AudienceRank',
    displayName: 'Audience rank',
    id: DataItemType.audienceRank,
    type: DataItemDataType.adi,
    adi: 'AUDIENCE_RANK',
    decimalPoints: 0,
    cellKey: 'AUDIENCE_RANK',
    dependsOnCellKey: 'projected',
    widthType: 'lg',
  },
  [DataItemType.indexRank]: {
    name: 'IndexRank',
    displayName: 'Index rank',
    id: DataItemType.indexRank,
    type: DataItemDataType.adi,
    adi: 'INDEX_RANK',
    decimalPoints: 0,
    cellKey: 'INDEX_RANK',
    dependsOnCellKey: 'index',
    widthType: 'md',
  },
  [DataItemType.percentTable]: {
    name: 'PercentTable',
    displayName: '%Table',
    id: DataItemType.percentTable,
    type: DataItemDataType.adi,
    adi: 'PERCENT_TABLE',
    cellKey: 'PERCENT_TABLE',
    decimalPoints: 1,
    widthType: 'sm',
  },
  [DataItemType.meanStdDev]: {
    name: 'MeanStdDev',
    displayName: 'Mean std dev',
    id: DataItemType.meanStdDev,
    type: DataItemDataType.adi,
    decimalPoints: 3,
    widthType: 'md',
    // adi: 'MEAN_STDDEV',
    // cellKey: 'MEAN_STDDEV'
  },
  [DataItemType.filterCode]: {
    name: 'FilterCode',
    displayName: 'Filter code',
    id: DataItemType.filterCode,
    type: DataItemDataType.filter,
    decimalPoints: 0,
    widthType: 'md',
  },
  [DataItemType.coverage]: {
    name: 'Coverage',
    displayName: '%Coverage',
    id: DataItemType.coverage,
    type: DataItemDataType.default,
    decimalPoints: 1,
    cellKey: 'column',
    widthType: 'md',
  },
  [DataItemType.composition]: {
    name: 'Composition',
    displayName: '%Composition',
    id: DataItemType.composition,
    type: DataItemDataType.default,
    decimalPoints: 1,
    cellKey: 'row',
    widthType: 'md',
  },
  [DataItemType.affinityScore]: {
    name: 'Affinity score',
    displayName: 'Affinity score',
    id: DataItemType.affinityScore,
    type: DataItemDataType.default,
    decimalPoints: 2,
    cellKey: 'affinityScore',
    widthType: 'md',
  },
  [DataItemType.affinityRank]: {
    name: 'Affinity rank',
    displayName: 'Affinity rank',
    id: DataItemType.affinityRank,
    type: DataItemDataType.rank,
    decimalPoints: 0,
    cellKey: 'affinityRank',
    dependsOnCellKey: 'affinityScore',
    widthType: 'md',
  },
  [DataItemType.nTileStart]: {
    name: 'NTile start',
    displayName: 'NTile start',
    id: DataItemType.nTileStart,
    type: DataItemDataType.adi,
    unchangeable: true,
    adi: NTILE_START_ADI_OPTION,
    cellKey: NTILE_START_ADI_OPTION,
    decimalPoints: 0,
    widthType: 'sm',
    description: 'Applicable only on NTiles coding. Cannot be selected',
  },
  [DataItemType.nTileEnd]: {
    name: 'NTile end',
    displayName: 'NTile end',
    id: DataItemType.nTileEnd,
    type: DataItemDataType.adi,
    unchangeable: true,
    adi: NTILE_END_ADI_OPTION,
    cellKey: NTILE_END_ADI_OPTION,
    decimalPoints: 0,
    widthType: 'sm',
    description: 'Applicable only on NTiles coding. Cannot be selected',
  },
};

export type DataItemsSelection = Record<ReportMode, DataItem[]>;

export type DefaultDataItems = Record<ReportMode, DefaultDataItem[]>;

export interface DefaultDataItem {
  id: DataItemType;
  selected: boolean;
}

export const DEFAULT_UNAVAILABLE_DATA_ITEMS: DefaultDataItem[] = [
  DataItemType.percentTable,
  DataItemType.audienceRank,
  DataItemType.percentColRank,
  DataItemType.percentRowRank,
  DataItemType.indexRank,
  DataItemType.wtdIndex,
  DataItemType.correlationPP,
  DataItemType.correlationPL,
  DataItemType.zScore,
  DataItemType.meanStdDev,
  DataItemType.sigma,
  DataItemType.sigmaRank,
  DataItemType.sigmaTol,
  DataItemType.sigmaTolRank,
  DataItemType.coverage,
  DataItemType.composition,
  DataItemType.nTileStart,
  DataItemType.nTileEnd,
].map((id: DataItemId) => ({
  id,
  selected: false,
}));

export const DEFAULT_AVAILABLE_DATA_ITEM_IDS: DataItemId[] = [
  DataItemType.audience,
  DataItemType.sample,
  DataItemType.percentage,
  DataItemType.percentRow,
  DataItemType.index,
];

export const HAS_DATA_FLAGS_DATA_ITEM_IDS: DataItemId[] = [
  DataItemType.sample,
  DataItemType.filterSample,
];

export const DEFAULT_CROSSTAB_DATA_ITEMS: DefaultDataItem[] = [
  ...DEFAULT_AVAILABLE_DATA_ITEM_IDS.map((id: DataItemId) => ({
    id,
    selected: true,
  })),
  ...DEFAULT_UNAVAILABLE_DATA_ITEMS,
];

export const DEFAULT_AFFINITY_DATA_ITEMS: DefaultDataItem[] = [
  {
    id: DataItemType.audience,
    selected: true,
  },
  {
    id: DataItemType.sample,
    selected: false,
  },
  {
    id: DataItemType.percentage,
    selected: false,
  },
  {
    id: DataItemType.percentRow,
    selected: true,
  },
  {
    id: DataItemType.index,
    selected: false,
  },
  ...DEFAULT_UNAVAILABLE_DATA_ITEMS,
  {
    id: DataItemType.affinityScore,
    selected: true,
  },
  {
    id: DataItemType.affinityRank,
    selected: true,
  },
];

export const DEFAULT_SELECTED_AFFINITY_DATA_ITEM_IDS: number[] = [
  DataItemType.audience,
  DataItemType.percentRow,
  DataItemType.filterMarketSize,
  DataItemType.filterPercentRow,
];

export const VOLUMETRIC_DATA_ITEM_IDS: number[] = [
  DataItemType.sample,
  DataItemType.audienceRank,
  DataItemType.audience,
  DataItemType.meanStdDev,
];
