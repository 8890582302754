<div
  fxFill
  fxLayout="column"
  *ngIf="selectedSurvey"
>
  <app-top-bar
    [ichabodsBody]="ichabodsBodyForCharts"
    [selectViewType]="documentViewType.graph"
    [showSurveyInfo]="true"
    [isReadonly]="isReadonly"
    [canExportCharts]="canExportCharts"
    (exportToXlsx)="exportToXlsx()"
    (exportToPptx)="exportToPptx()"
    (exportToSlides)="exportToSlides()"
    (exportToSheets)="exportToSheets()"
  ></app-top-bar>

  <mat-tab-group
    #ichabodsBodyForCharts
    tupMatIchabodsBody
    color="primary"
    class="ichabods-tab-group"
    animationDuration="0ms"
    (selectedTabChange)="onChartsViewTabChange($event)"
  >
    <mat-tab
      *ngFor="
        let mode of surveys.length > 1 ? targetmodestrendingsurvey : targetmodes
      "
    >
      <ng-template mat-tab-label>
        <span
          [matTooltip]="mode.tooltip"
          [attr.data-tracking]="'chart-' + mode.value"
          [attr.data-qa]="mode.value + '-mode'"
          >{{ mode.title }}</span
        >
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <graph-view
    [targetMode]="targetMode"
    [isReadonly]="isReadonly"
    [isLoading]="isFetchingCrosstabData || isExporting"
    [surveys]="surveys"
    [survey]="selectedSurvey"
    (chartExportEnabled)="onChartExportEnabled($event)"
  ></graph-view>
</div>
