<mat-icon
  class="popper-icon"
  [popper]="popperContent"
  [popperTrigger]="'hover'"
  [popperPlacement]="'bottom'"
  [popperAppendTo]="popperAppendTo"
  [popperStyles]="{ 'background-color': 'white' }"
  popperApplyArrowClass="popper-arrow"
>
  {{ icon }}
</mat-icon>
<popper-content
  #popperContent
  fxLayout="column"
>
  <ng-content></ng-content>
</popper-content>
