import { Injectable } from '@angular/core';
import {
  GenericOperatorAction,
  GenericOperatorActionContext,
} from './GenericOperatorAction';
import { CountCodingDialogComponent, CountCodingModel } from '../dialogs';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import { MatDialog } from '@angular/material/dialog';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { CombinedCodingThresholdService } from '../services/combined-coding-threshold.service';

@Injectable({
  providedIn: 'root',
})
export class CountOperatorAction extends GenericOperatorAction {
  constructor(
    protected documentService: DocumentService,
    protected targetService: TargetService,
    protected codebookSelectionService: CodebookSelectionService,
    protected combinedCodingThresholdService: CombinedCodingThresholdService,
    private dialog: MatDialog
  ) {
    super(
      documentService,
      targetService,
      codebookSelectionService,
      combinedCodingThresholdService
    );
  }

  public invoke(context: GenericOperatorActionContext): void {
    if (
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        context.selectedTargets
      )
    ) {
      this.combinedCodingThresholdService.showMaxLimitAlert();
      return;
    }

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(
        context.selectedTargets
      )
    ) {
      return;
    }

    this.dialog
      .open(CountCodingDialogComponent, { closeOnNavigation: true })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((countCoding: CountCodingModel) => {
        context.countCoding = countCoding;
        super.invoke(context);
      });
  }
}
