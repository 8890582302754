import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CELL_BORDER_HEIGHT,
  CELL_LINE_HEIGHT,
  CELL_LINE_MARGIN_BOTTOM,
  CELL_PADDING_BOTTOM,
  CELL_PADDING_TOP,
  CellColor,
  CellColorId,
  CellStyleStatus,
  ColumnFilterClick,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemId,
  DataItemType,
  DEFAULT_CROSSTAB_COLUMN_POSITIONS,
  DEFAULT_CROSSTAB_COLUMNS,
  DropzoneEnterData,
  FreezeTotals,
  HighlightValues,
  MenuEventTrigger,
  ReportMode,
  ReportPreference,
  SortDirection,
  SortSettings,
  StabilityLevels,
  SurveyCode,
  SurveyCodeBackgroundColor,
  TargetColumn,
  VOLUMETRIC_DATA_ITEM_IDS,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
} from 'src/app/models';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Subject } from 'rxjs';
import {
  CrosstabService,
  DataItemsService,
  DocumentService,
  HighlightCellsService,
  RequestLoadingService,
  TargetLoading,
  XlsxService,
} from '../../services';
import {
  DocumentDataState,
  Survey,
  Target,
  TargetItem,
  TargetType,
} from '../../models';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { SurveyBgColorPipe, TargetTitlePipe } from '../../pipes';
import {
  CrosstabTableXlsxBuilder,
  CrosstabTableXlsxData,
} from '../../builders/crosstab-table-xlsx.builder';
import { FormatReportUnitsPipe } from '../../pipes/format-report-units.pipe';
import { CsvService } from '../../services/csv.service';
import { CrosstabTableCsvBuilder } from '../../builders/crosstab-table-csv.builder';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'app-crosstab-table',
  templateUrl: './crosstab-table.component.html',
  styleUrls: ['./crosstab-table.component.scss'],
})
export class CrosstabTableComponent implements OnChanges, OnInit, OnDestroy {
  public readonly headerHeight = 80;
  public readonly zScoreHighlightProb = Z_SCORE_FILTERED_HIGHLIGHT_PROB;
  public targetType: typeof TargetType = TargetType;
  public dataItemType: typeof DataItemType = DataItemType;
  public sortDirectionType: typeof SortDirection = SortDirection;
  public displayedColumnPositions: string[] = DEFAULT_CROSSTAB_COLUMN_POSITIONS;
  public volumetricDataItemIds: number[] = VOLUMETRIC_DATA_ITEM_IDS;
  public displayedColumns: string[] = DEFAULT_CROSSTAB_COLUMNS;
  public targetColumns: TargetColumn[] = [];
  public dataItems: DataItem[];
  public decimalPoints: Record<string, number>;
  public dataItemsMap: Record<DataItemId, DataItem>;
  public color = '';
  private rawData: CrossTabTableData[];
  public data: CrossTabTableData[];
  public totalRow: CrossTabTableData = null;
  public dataSource: MatTableDataSource<CrossTabTableData> =
    new TableVirtualScrollDataSource([]);
  private columnCount = 0;
  public tableInfo = {
    rows: 0,
    columns: 0,
    filtered: -1,
  };
  public sortSettings: SortSettings;
  public isSortActive = false;
  public freezeTotals: FreezeTotals;
  private columnHeaderFilters: ColumnHeaderFilter[] = [];
  public columnHeaderFilterIds: string[] = [];
  public isLoading = false;
  public tvsItemSize: number;
  public frozenTotalsRowHeight: number;
  public surveyColors: Record<SurveyCode, SurveyCodeBackgroundColor> = {};
  public cellColors: Record<CellColorId, CellColor> = {};
  public highlightValues: HighlightValues;
  public zScoreHighlight: boolean;
  public heatmapIndexPercentage: number;
  public cellStyleStatus: CellStyleStatus;
  public stabilityFlagStatus: StabilityLevels;
  public activeReportMode: ReportMode;
  public affinityRow: Target;
  public reportUnits: number;
  private updateCellColorsState = new Subject<CrossTabTableData[]>();
  private updateCellColorsState$ = this.updateCellColorsState.asObservable();

  private unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild('tableCardContainer') tableCardContainer: ElementRef;
  @Input() isReadonly = true;
  @Input() surveys: Survey[];
  @Input() isViewActive: boolean;
  @Input() scrollViewportHeight = 0;
  @Input() dropzoneMenu: MatMenu;
  @Output() targetClick: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Output() dropzoneEnter: EventEmitter<DropzoneEnterData> =
    new EventEmitter<DropzoneEnterData>();
  @Output() dropzoneLeave: EventEmitter<void> = new EventEmitter<void>();
  @Output() dropzoneDrop: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Output() contextMenuTrigger: EventEmitter<MenuEventTrigger> =
    new EventEmitter<MenuEventTrigger>();
  @Output() columnHeaderMenuTrigger: EventEmitter<MenuEventTrigger> =
    new EventEmitter<MenuEventTrigger>();
  @Output() columnFilterClick: EventEmitter<ColumnFilterClick> =
    new EventEmitter<ColumnFilterClick>();

  constructor(
    private requestLoadingService: RequestLoadingService,
    private colorCellsService: HighlightCellsService,
    private documentService: DocumentService,
    private crossTabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private surveyBackgroundColor: SurveyBgColorPipe,
    private targetTitlePipe: TargetTitlePipe,
    private formatReportUnitsPipe: FormatReportUnitsPipe,
    private csvService: CsvService,
    private xlsxService: XlsxService,
    private reportPreferencesService: ReportPreferencesService
  ) {
    this.listenToCellColorStateChanges();
  }

  ngOnInit(): void {
    this.listenToLoadingState();
    this.listenToDocumentDataChanges();
    this.listenToReportUnitsChanges();
    this.listenToCrossTabDataChanges();
    this.listenToReportPreferencesChanges();
    this.listenToFreezeTotalsChanges();
    this.listenToReportModeChanges();
    this.listenToDataItemsChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isViewActive) {
      this.triggerFakeWindowResizeEvent();
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  public onClickRowTitle(itemIndex: number): void {
    this.emitTargetClick(TargetType.rows, itemIndex);
  }

  public onClickColumnTitle(itemIndex: number): void {
    this.emitTargetClick(TargetType.columns, itemIndex);
  }

  public onDropzoneEnter(
    trigger: MatMenuTrigger,
    targetType: TargetType,
    itemIndex: number
  ): void {
    if (!this.isTotalColumnOrRow(targetType, itemIndex)) {
      this.dropzoneEnter.emit({
        trigger,
        targetType,
        targetItem: this.getTargetItem(targetType, itemIndex),
      });
    }
  }

  public onDropzoneLeave(targetType: TargetType, itemIndex: number): void {
    if (!this.isTotalColumnOrRow(targetType, itemIndex)) {
      this.dropzoneLeave.emit();
    }
  }

  public onDrop(targetType: TargetType, index: number): void {
    this.dropzoneDrop.emit(this.getTargetItem(targetType, index));
  }

  public onColumnMenuClick(
    event,
    itemIndex: number,
    surveyCode?: string
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.columnHeaderMenuTrigger.emit({
      event,
      targetItem: this.getTargetItem(TargetType.columns, itemIndex, true),
      surveyCode,
    });
  }

  public onColumnFilterClick(
    event,
    itemIndex: number,
    surveyCode?: string
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.columnFilterClick.emit({
      targetItem: this.getTargetItem(TargetType.columns, itemIndex, true),
      surveyCode,
    });
  }

  public onContextMenu(
    event: MouseEvent,
    targetType: TargetType,
    itemIndex: number,
    surveyCode?: string
  ): void {
    event.preventDefault();

    const targetItem = this.getTargetItem(targetType, itemIndex, true);
    if (!targetItem) {
      return;
    }
    this.contextMenuTrigger.emit({
      event,
      targetItem,
      surveyCode,
    });
  }

  @HostListener('window:resize')
  public onResize() {
    this.updateScrollViewportHeight();
  }

  public isStickyTotalsColumn(columnIndex: number): boolean {
    return (
      this.isTotalColumnOrRow(TargetType.columns, columnIndex) &&
      this.freezeTotals.column
    );
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns }: DocumentDataState) => {
        this.columnCount = columns.length;
      });
  }

  private listenToReportUnitsChanges(): void {
    this.documentService.reportUnits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((units: number) => {
        this.reportUnits = units;
      });
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.crossTabData$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CrossTabTableData[]) => {
        this.rawData = data;
      });

    this.crossTabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CrossTabTableData[]) => {
        this.setupTable(data);
      });
  }

  private listenToReportPreferencesChanges(): void {
    combineLatest([
      this.reportPreferencesService.preference$,
      this.documentService.surveyStabilityLevels$,
    ])
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(
        ([preference, surveyStabilityLevels]: [
          ReportPreference,
          StabilityLevels
        ]) => {
          if (
            this.reportPreferencesService.getActiveReportMode() !==
              ReportMode.crossTab &&
            this.reportPreferencesService.getActiveReportMode() !==
              ReportMode.affinity
          ) {
            return;
          }
          this.stabilityFlagStatus = preference.stabilityFlagOn
            ? surveyStabilityLevels
            : null;
          this.heatmapIndexPercentage = preference.heatmapIndexPercentage;
          this.highlightValues = preference.highlightValues;
          this.cellStyleStatus = preference.cellStyleStatus;
          this.zScoreHighlight =
            preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;
          this.updateCellColors();

          this.sortSettings = preference.sortSettings as SortSettings;
          this.isSortActive = this.sortSettings.columnId !== '';

          this.columnHeaderFilters = preference.filters;
          this.columnHeaderFilterIds = this.columnHeaderFilters.map(
            (headerFilter: ColumnHeaderFilter) => headerFilter.columnId
          );
          this.updateTableInfo();
        }
      );
  }

  private listenToFreezeTotalsChanges(): void {
    this.reportPreferencesService.freezeTotals$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((freezeTotals: FreezeTotals) => {
        const isFreezeTotalRowChanged =
          !!this.freezeTotals && this.freezeTotals?.row !== freezeTotals.row;
        this.freezeTotals = freezeTotals;
        if (isFreezeTotalRowChanged) {
          this.setupTable(this.data);
        }
      });
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.actualDataItems$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.updateTableDataPointsAndCell();
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        if (mode !== ReportMode.crossTab && mode !== ReportMode.affinity) {
          this.unsubscribeAll();
          return;
        }
        this.activeReportMode = mode;
        this.affinityRow = this.reportPreferencesService.affinityRow;
        this.updateTableDataPointsAndCell();
        this.updateCellColors();
      });
  }

  private listenToCellColorStateChanges(): void {
    this.updateCellColorsState$
      .pipe(takeUntil(this.unsubscribe), debounceTime(200))
      .subscribe((data: CrossTabTableData[]) => {
        this.cellColors = this.colorCellsService.updateCellColors(data);
      });
  }

  private setupTable(data: CrossTabTableData[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.addTableColumns();
    this.addTableColumnColors();
    this.triggerFakeWindowResizeEvent();
    this.updateCellColors();
    this.updateTableInfo();
  }

  private populateTableData(data: CrossTabTableData[]): void {
    this.data = data;
    this.dataSource.data = this.formatTableData(data);
  }

  private addTableColumns(): void {
    // target column name needs to be different everytime where there is an update, otherwise it keeps the previous ones
    const randomNumber = Date.now();
    const totalRow =
      this.dataSource.data.find((row: CrossTabTableData) => row.isTotalRow) ||
      this.totalRow;
    this.targetColumns = [
      ...totalRow.data.map((cell: CrossTabTableDataCell, index: number) => ({
        columnId: this.crossTabService.formatTargetColumnId(
          cell.columnTarget,
          cell.surveyCode
        ),
        name: `#title_${randomNumber + index}`,
        target: cell.columnTarget,
        title: cell.title,
        position: index,
        element: cell,
      })),
    ];

    this.displayedColumnPositions.push(
      ...this.targetColumns.map(
        (column: TargetColumn) => `position-${column.position}`
      )
    );
    this.displayedColumns.push(
      ...this.targetColumns.map((column: TargetColumn) => column.name)
    );
  }

  private addTableColumnColors(): void {
    this.surveyColors = {};
    this.dataSource.data[0].data.forEach((cell: CrossTabTableDataCell) => {
      this.surveyColors[cell.surveyCode] = this.surveyBackgroundColor.transform(
        cell.surveyCode,
        this.surveys
      );
    });
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.targetColumns = [];
    this.displayedColumnPositions = [...DEFAULT_CROSSTAB_COLUMN_POSITIONS];
    this.displayedColumns = [...DEFAULT_CROSSTAB_COLUMNS];
  }

  private formatTableData(data: CrossTabTableData[]): CrossTabTableData[] {
    if (this.freezeTotals && this.freezeTotals.row) {
      this.totalRow = data[0];
      data = data.slice(1);
    } else if (!data[0].isTotalRow) {
      data = [this.totalRow, ...data];
    }
    return data;
  }

  private getTargetItem(
    targetType: TargetType,
    itemIndex: number,
    canReturnTotals = false
  ): TargetItem | null {
    if (canReturnTotals && this.isTotalColumnOrRow(targetType, itemIndex)) {
      return {
        index: -1,
        type: targetType,
        target: null,
      };
    }
    const index = this.getActualTargetIndex(targetType, itemIndex);
    const target =
      targetType === TargetType.columns
        ? this.targetColumns[index].element.columnTarget
        : this.dataSource.data[index].data[0].rowTarget;
    const targetIndex =
      targetType === TargetType.columns
        ? this.targetColumns[index].element.columnPosition
        : this.dataSource.data[index].index;
    return target
      ? {
          type: targetType,
          target,
          index: targetIndex - 1,
        }
      : null;
  }

  private getActualTargetIndex(
    targetType: TargetType,
    itemIndex: number
  ): number {
    return targetType === TargetType.rows && this.freezeTotals.row
      ? itemIndex - 1
      : itemIndex;
  }

  private isTotalColumnOrRow(targetType: TargetType, index: number): boolean {
    return targetType === TargetType.rows
      ? index === 0
      : this.targetColumns[index].element.isTotalsColumn;
  }

  private emitTargetClick(targetType: TargetType, itemIndex: number): void {
    const targetItem = this.getTargetItem(targetType, itemIndex);
    if (targetItem) {
      this.targetClick.emit(targetItem);
    }
  }

  private updateScrollViewportHeight(): void {
    if (this.tableCardContainer) {
      this.scrollViewportHeight =
        this.tableCardContainer.nativeElement.offsetHeight;
    }
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  private updateTableInfo(): void {
    this.tableInfo = {
      rows: this.rawData.length - 1,
      columns: this.columnCount,
      filtered:
        this.columnHeaderFilterIds.length > 0 ? this.data.length - 1 : -1,
    };
  }

  private updateTableDataPointsAndCell(): void {
    if (this.activeReportMode === undefined) {
      return;
    }
    this.dataItems = this.dataItemsService.getActiveDataItems(
      this.activeReportMode
    );
    this.dataItemsMap = DATA_ITEMS_MAP;
    this.decimalPoints = this.dataItems.reduce(
      (prev: Record<string, number>, dataItem: DataItem) => ({
        ...prev,
        [dataItem.displayName]: dataItem.decimalPoints,
      }),
      {}
    );
    this.tvsItemSize =
      this.dataItems.length * (CELL_LINE_HEIGHT + CELL_LINE_MARGIN_BOTTOM) +
      CELL_PADDING_TOP +
      CELL_PADDING_BOTTOM +
      CELL_BORDER_HEIGHT;
    this.frozenTotalsRowHeight = this.tvsItemSize + CELL_BORDER_HEIGHT;
  }

  private updateCellColors(): void {
    if (
      !this.affinityRow ||
      this.cellStyleStatus !== CellStyleStatus.highlight
    ) {
      this.updateCellColorsState.next(this.data);
    } else {
      this.updateCellColorsState.next(
        this.data.filter(
          (row: CrossTabTableData) =>
            !row.data[0].rowTarget ||
            this.affinityRow.id !== row.data[0].rowTarget.id
        )
      );
    }
  }

  public exportToCsv(): void {
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addTableData({
      documentName,
      targetColumns: this.targetColumns,
      data: this.data,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: this.sortSettings,
      filters: this.columnHeaderFilters,
      surveys: this.documentService.surveys,
    });
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheets(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  private getXlsxExportData(): [string, CrosstabTableXlsxBuilder] {
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');
    const crossTabTableData: CrosstabTableXlsxData = {
      documentName,
      targetColumns: this.targetColumns,
      data: this.data,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: [this.sortSettings],
      filters: this.columnHeaderFilters,
      surveys: this.documentService.surveys,
      surveyColors: this.surveyColors,
      cellStyleStatus: this.cellStyleStatus,
      highlightValues: this.highlightValues,
      bins: this.colorCellsService.getBins(),
      cellColors: this.cellColors,
      heatmapIndexPercentage: this.heatmapIndexPercentage,
    };
    crossTabTableBuilder.addSeparateColumnTableSheets(crossTabTableData);
    crossTabTableBuilder.addEntireTableSheet(crossTabTableData);
    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
