import { SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { of as ofObservable, Observable } from 'rxjs';
import { CtrlShiftKeyStates } from 'src/app/directives/ctrl-shift.directive';
import { Statement } from 'src/app/models/codebook.model';
import {
  TAG_FOR_SURVEY_WITH_100_RESPONSE_RATE,
  TOOLTIP_MESSAGE_FOR_SURVEY_WITH_100_RESPONSE_RATE,
} from 'src/app/models/survey.model';
import {
  ContextMenuData,
  SelectionTreeFlatNode,
  StatementFactory,
  TreeNodeSelection,
} from './tree.models';
import {
  CodebookSelectionService,
  LoadingNode,
} from '../../services/codebook-selection.service';
import { TargetService } from '../../services/target.service';
import { DisplayType, Target } from '../../models/document.model';
import { TargetTitlePipe } from '../../pipes';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent implements OnInit, AfterViewChecked {
  @Input() validWeights: number[] = [];
  @Input() showValidWeightTooltip: boolean = false;
  @Input() categoryFilter: string[] = [];
  @Input() data: Statement[] = [];
  @Input() searching: boolean = false;
  @Input() autoExpandLimit: number = 100;
  @Output() treeContextMenu = new EventEmitter<ContextMenuData>();
  @Output() loadData = new EventEmitter<SelectionTreeFlatNode>();
  @Input() weightDescriptions: string[];
  @Input() isReadonly = true;

  treeNodePadding: number = 20;

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap: Map<string, SelectionTreeFlatNode> = new Map<
    string,
    SelectionTreeFlatNode
  >();

  treeControl: FlatTreeControl<SelectionTreeFlatNode>;
  treeFlattener: MatTreeFlattener<Statement, SelectionTreeFlatNode>;
  dataSource: MatTreeFlatDataSource<Statement, SelectionTreeFlatNode>;

  keyStates: CtrlShiftKeyStates = {
    ctrlPressed: false,
    shiftPressed: false,
  };
  lastClicked: SelectionTreeFlatNode;
  handleShiftKey = false; // disabled for now...need to fix refresh issues...

  @ViewChild('dndDragImage') dragImage;
  public draggedItemNumber = 0;
  public draggedItems: string[] = [];

  public isLoadingNodes = false;
  private draggingNode: SelectionTreeFlatNode;
  private isDragging = false;

  constructor(
    private codebookSelectionService: CodebookSelectionService,
    private targetService: TargetService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<SelectionTreeFlatNode>(
      this.getLevel,
      this.isExpandable
    );

    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  ngOnInit(): void {
    this.listenToNodeExpansion();
    this.listenToDragSelectedNodesChanges();
  }

  ngAfterViewChecked(): void {
    if (this.dataSource.data !== this.data) {
      setTimeout(() => {
        // let angular finish the hook...
        this.dataSource.data = this.data;
      });
    }
  }

  public refresh() {
    this.dataSource.data = [];
    this.dataSource.data = this.data;
  }

  public unselectAll() {
    this.data.forEach((node) => {
      node.selected = false;
      StatementFactory.unselectChildren(node);
    });
    this.refresh();
  }

  public reset() {
    this.dataSource.data = [];
    this.nestedNodeMap.clear();
    this.data.forEach((node) => {
      node.hiddenFilter = undefined;
      node.selected = undefined;
      node.children = [];
    });
    this.dataSource.data = this.data;
    this.treeControl.collapseAll();
  }

  public getValidWeightDescription(weights: number[]): string {
    return weights
      .map((weightIndex) => this.weightDescriptions[weightIndex - 1])
      .join(' , ');
  }

  getLevel = (node: SelectionTreeFlatNode) => {
    return node.level;
  };
  isExpandable = (node: SelectionTreeFlatNode) => {
    return node.expandable;
  };
  getChildren = (node: Statement): Observable<Statement[]> => {
    return ofObservable(node.children);
  };

  hasChild = (_: number, _nodeData: SelectionTreeFlatNode) => {
    return _nodeData.expandable;
  };

  isHidden = (_: number, _nodeData: SelectionTreeFlatNode) => {
    return (
      _nodeData.data.hiddenCategory === true ||
      _nodeData.data.hiddenFilter === true
    );
  };

  // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
  transformer = (node: Statement, level: number) => {
    let flatNode = this.nestedNodeMap.get(node.path);
    if (!flatNode) {
      flatNode = new SelectionTreeFlatNode();
      this.nestedNodeMap.set(node.path, flatNode);
    }

    flatNode.description = node.description;
    flatNode.coding = node.coding;
    flatNode.validWeights = node.validWeights;
    flatNode.level = level;
    flatNode.expandable =
      !node.coding || !!(node.children && node.children.length);
    flatNode.data = node;

    return flatNode;
  };

  // Whether all the descendants of the node are selected
  descendantsAllSelected(node: SelectionTreeFlatNode): boolean {
    return StatementFactory.descendantsAllSelected(node.data);
  }

  // Whether part of the descendants are selected
  descendantsPartiallySelected(node: SelectionTreeFlatNode): boolean {
    if (node.data.children.length === 0) {
      return false;
    }

    const selection: TreeNodeSelection =
      StatementFactory.descendantsPartiallySelected(node.data);
    if (
      selection.nbSelectedChildren === 0 ||
      selection.nbChildren === selection.nbSelectedChildren
    ) {
      return false;
    }
    return true;
  }

  public hasCompatibleWeights(node: SelectionTreeFlatNode): boolean {
    if (!node.validWeights?.length || !this.validWeights?.length) return true; // always allow if undefined or empty
    return !!this.validWeights.filter((weight) =>
      node.validWeights.includes(weight)
    ).length;
  }

  public isSurveyResponseRate100(node: SelectionTreeFlatNode): boolean {
    return node?.data?.customTag === TAG_FOR_SURVEY_WITH_100_RESPONSE_RATE;
  }

  public getToolTipMessages(node: SelectionTreeFlatNode): string {
    let messages: string[] = [];
    this.isSurveyResponseRate100(node) &&
      messages.push(TOOLTIP_MESSAGE_FOR_SURVEY_WITH_100_RESPONSE_RATE);
    messages.push(
      node.validWeights?.length && this.showValidWeightTooltip
        ? 'Valid weights: ' + this.getValidWeightDescription(node.validWeights)
        : ''
    );

    return messages.join('\n\n'); // Adding newlines to separate tooltip
  }

  public treeNodeSelectionToggle(
    node: SelectionTreeFlatNode,
    fromDragOperation = false
  ): void {
    if (this.isDragging || !node) {
      return;
    }

    if (
      this.lastClicked &&
      (this.lastClicked.data.hiddenCategory ||
        this.lastClicked.data.hiddenFilter)
    ) {
      this.lastClicked = null;
    }

    let checked: boolean = false;

    if (
      (node.data.selected === true ||
        StatementFactory.descendantsAllSelected(node.data)) &&
      !fromDragOperation
    ) {
      node.data.selected = false;
      checked = false;
      StatementFactory.unselectChildren(node.data);
    } else {
      node.data.selected = true;
      checked = true;
      StatementFactory.selectChildren(node.data);
    }

    if (this.keyStates.shiftPressed && this.lastClicked) {
      const lastSelectedNodeIndex = this.treeControl.dataNodes.indexOf(
        this.lastClicked
      );
      const selectedNodeIndex = this.treeControl.dataNodes.indexOf(node);
      let lowerIndex = selectedNodeIndex;
      let upperIndex = lastSelectedNodeIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedNodeIndex;
        upperIndex = selectedNodeIndex;
      }

      this.treeControl.dataNodes.forEach(
        (treeNode: SelectionTreeFlatNode, index: number) => {
          if (index <= upperIndex && index >= lowerIndex) {
            if (treeNode.data.children.length === 0) {
              treeNode.data.selected = checked;
            } else {
              if (treeNode.level !== node.level) {
                return;
              }
              if (checked) {
                StatementFactory.selectChildren(treeNode.data);
              } else {
                StatementFactory.unselectChildren(treeNode.data);
              }
            }
          }
        }
      );
    }
    this.lastClicked = node;

    this.codebookSelectionService.setSelectedNodes(this.getSelectedNodes());
  }

  expandNode(node: Statement, autoLoad = true) {
    if (node.hiddenFilter === true || node.hiddenCategory === true) {
      return;
    }
    this._expandNode(node, autoLoad);
  }

  expandNodes(nodes: Statement[], autoLoad = true) {
    let totalNbChildren: number = 0;
    for (let node of nodes) {
      if (node.hiddenFilter === true || node.hiddenCategory === true) {
        continue;
      }
      totalNbChildren += this._expandNode(node, autoLoad);
      if (totalNbChildren >= this.autoExpandLimit) {
        break;
      }
    }
  }

  private _expandNode(node: Statement, autoLoad: boolean): number {
    let totalNbChildren: number = 0;
    let flatNode = this.nestedNodeMap.get(node.path);
    if (flatNode) {
      totalNbChildren++;
      if (autoLoad || flatNode.data.children?.length > 0) {
        this.treeControl.expand(flatNode);
      }
      for (let child of node.children) {
        totalNbChildren += this._expandNode(child, autoLoad);
        if (totalNbChildren >= this.autoExpandLimit) {
          break;
        }
      }
    }
    return totalNbChildren;
  }

  onCtrlShift(e: CtrlShiftKeyStates) {
    this.keyStates = e;
  }

  getSelectedNodes(): Statement[] {
    return StatementFactory.getSelectedNodes(this.dataSource.data);
  }

  onContextMenu(e: any, node: SelectionTreeFlatNode) {
    const atLeastOneSelected = true;
    /* for (let child of this.data){
      if (StatementFactory.atLeastOneSelectedDescendant(child)){
        atLeastOneSelected = true;
        break;
      }
    } */
    if (atLeastOneSelected) {
      // add node to the selection
      if (!StatementFactory.atLeastOneSelectedDescendant(node.data)) {
        StatementFactory.selectChildren(node.data);
        this.codebookSelectionService.setSelectedNodes(this.getSelectedNodes());
      }
    }

    const path: Statement[] = StatementFactory.getPath(
      node.data,
      this.dataSource.data
    );

    // get selected statements
    const selectedData = this.getSelectedNodes();

    const data: ContextMenuData = {
      event: e,
      node: StatementFactory.cloneData(node.data),
      path,
      level: node.level,
      selectedNodes: selectedData,
      selectionTreeNode: node,
    };
    this.treeContextMenu.emit(data);
  }

  public onDragStart(event: DragEvent): void {
    this.treeNodeSelectionToggle(this.draggingNode, true);
    if (
      this.isLoadingNodes ||
      !this.draggingNode ||
      this.draggedItemNumber === 0
    ) {
      event.preventDefault();
      return;
    }

    this.isDragging = true;
    (event.dataTransfer as any).setDragImage(
      this.dragImage.nativeElement,
      15,
      0
    );
  }

  public onDragEnd(): void {
    this.isDragging = false;
    this.draggingNode = null;
  }

  public onMouseDown(node: SelectionTreeFlatNode): void {
    this.draggingNode = node;
  }

  private listenToNodeExpansion() {
    this.treeControl.expansionModel.changed.subscribe(
      (change: SelectionChange<SelectionTreeFlatNode>) => {
        if (change.added) {
          this.handleNodeExpand(change);
        }
      }
    );
  }

  private handleNodeExpand(change: SelectionChange<SelectionTreeFlatNode>) {
    change.added.forEach((node) => {
      let data: Statement = node.data;
      if (data && data.children.length === 0 && !data.coding) {
        this.loadData.emit(node);
      }
    });
  }

  private listenToDragSelectedNodesChanges() {
    this.codebookSelectionService.loadingSelectedNodes$.subscribe(
      (isLoading: LoadingNode) => {
        this.isLoadingNodes = isLoading.inProgress;
      }
    );

    this.codebookSelectionService.selectedNodes$.subscribe(
      (selectedNodes: Statement[]) => {
        const selectedTargets =
          this.targetService.convertStatementsToTargets(selectedNodes);
        this.draggedItemNumber = selectedTargets.length;
        this.draggedItems = selectedTargets
          .slice(0, 3)
          .map((target: Target) =>
            this.targetTitlePipe.transform(target, DisplayType.shortTitle)
          );
      }
    );
  }
}
