import { Injectable } from '@angular/core';
import {
  GenericOperatorAction,
  GenericOperatorActionContext,
} from './GenericOperatorAction';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import { Operator } from '../models';
import { CombinedCodingThresholdService } from '../services/combined-coding-threshold.service';

@Injectable({
  providedIn: 'root',
})
export class AutoOperatorAction extends GenericOperatorAction {
  constructor(
    protected documentService: DocumentService,
    protected targetService: TargetService,
    protected codebookSelectionService: CodebookSelectionService,
    protected combinedCodingThresholdService: CombinedCodingThresholdService
  ) {
    super(
      documentService,
      targetService,
      codebookSelectionService,
      combinedCodingThresholdService
    );
  }

  public invoke(context: GenericOperatorActionContext): void {
    super.invoke(context);
  }

  protected addToExistingTarget(context: GenericOperatorActionContext): void {
    const targets = this.targetService.groupTargetsWithAutoOperator(
      context.selectedTargets
    );
    let childTarget = targets[0];
    this.targetService.addChildTarget(
      context.targetItem.target,
      childTarget,
      Operator.and
    );
    this.documentService.updateDocumentData([context.targetItem], true);
  }

  protected addNewTarget(context: GenericOperatorActionContext): void {
    const targets = this.targetService.groupTargetsWithAutoOperator(
      context.selectedTargets
    );
    this.documentService.addDocumentData(targets, context.targetType, true);
  }
}
