import { Injectable } from '@angular/core';
import { DataCell, DataRow } from '@telmar-global/tup-charts';
import { cloneDeep, groupBy, uniq, uniqBy } from 'lodash';
import {
  ChartTargetMode,
  CrossTabTableData,
  CrossTabTableDataCell,
  CrossTabTableDataCellMetaData,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemCellKey,
  DataItemChartData,
  DataItemId,
  DataItemType,
  FinalChartData,
  FormattedMultipleSurveysChartData,
  MultipleSurveyChartData,
  Target,
  TargetData,
} from '../models';
import { TargetTitlePipe } from '../pipes';
import { transpose } from '../utils/arrayHelper';
import { DataItemsService } from './data-items.service';
import { TupDocument } from '@telmar-global/tup-document-storage';
import {
  DisplayType,
  DocumentAudienceGroup,
  SaveOwnCodesType,
  Survey,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { DocumentAudienceGroupItem } from '@telmar-global/tup-audience-groups/lib/models/audience-group.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface TrendingSurveyTargetChartListResult {
  insightsGroupedData: MultipleSurveyChartData[];
  surveysGroupedData: MultipleSurveyChartData[];
}

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  // tslint:disable-next-line:variable-name
  private _chartDataItems: DataItemId[];
  // tslint:disable-next-line:variable-name
  private _chartDataItemCells: Record<DataItemId, DataItemCellKey>;

  constructor(
    private targetTitlePipe: TargetTitlePipe,
    private dataItemsService: DataItemsService,
    private audienceGroupsService: TupAudienceGroupsService
  ) {
    this.dataItemsService.chartDataItems$.subscribe((value: DataItem[]) => {
      this._chartDataItems = value.map((dataItem: DataItem) => dataItem.id);
      this._chartDataItemCells = value.reduce(
        (acc, dataItem: DataItem) => ({
          ...acc,
          [dataItem.id]: dataItem.cellKey,
        }),
        {}
      );
    });
  }

  public getInsightsGroupTargetTableData(
    chartData: any,
    tableDataColumns: DataItemId[],
    decimalPlaces: number,
    hasFlagDataItems: boolean
  ): DataRow[] {
    const target = chartData.target;
    const hasVolumetricCoding =
      chartData.cellMetadataSets.filter(
        (cellMetadata: CrossTabTableDataCellMetaData) =>
          cellMetadata.isVolumetricCoding
      ).length > 0;

    const tableData: DataRow[] = chartData.labels.map(
      (label: string, index: number) => {
        const rowData = tableDataColumns.map((dataItemId: DataItemId) =>
          chartData.surveyCodes.map(
            (surveyCode: string, surveyCodeIndex: number) => ({
              columnHeader: `[${surveyCode}]: ${this.targetTitlePipe.transform(
                target
              )}`,
              columnHeaderId: `${target.id}#${surveyCode}`,
              secondaryColumnHeader:
                hasVolumetricCoding && dataItemId === DataItemType.audience
                  ? DATA_ITEMS_MAP[dataItemId].volumetricDisplayName
                  : DATA_ITEMS_MAP[dataItemId].displayName,
              value:
                chartData.dataItems[dataItemId][surveyCodeIndex][
                  index
                ]?.toFixed(decimalPlaces),
              flagValue: hasFlagDataItems
                ? chartData.dataFlags[surveyCodeIndex][index]
                : null,
            })
          )
        );

        const dataCells: DataCell[] = rowData.reduce(
          (previousValue, currentValue) => [...previousValue, ...currentValue],
          [
            {
              columnHeader: 'Item Name',
              columnHeaderId: 'label',
              value: label,
            },
          ]
        );

        return {
          data: dataCells,
        };
      }
    );

    return tableData;
  }

  public getSurveysGroupTargetTableData(
    chartData: FormattedMultipleSurveysChartData,
    tableDataColumns: DataItemId[],
    decimalPlaces: number,
    hasFlagDataItems: boolean
  ): DataRow[] {
    const insightIds = chartData.insightIds;
    const tableData: DataRow[] = chartData.surveyCodes.map(
      (surveyCode: string, surveyCodeIndex: number) => {
        const rowData = tableDataColumns.map((dataItemId: DataItemId) =>
          chartData.targetTitles.map(
            (insightTitle: string, insightIndex: number) => {
              const hasVolumetricCoding =
                chartData.cellMetadataSets[insightIndex].isVolumetricCoding;
              const data = {
                columnHeader: `${insightTitle}`,
                columnHeaderId: `${insightIds[insightIndex]}#${surveyCode}`,
                secondaryColumnHeader:
                  hasVolumetricCoding && dataItemId === DataItemType.audience
                    ? DATA_ITEMS_MAP[dataItemId].volumetricDisplayName
                    : DATA_ITEMS_MAP[dataItemId].displayName,
                value:
                  chartData.dataItems[dataItemId][insightIndex][
                    surveyCodeIndex
                  ]?.toFixed(decimalPlaces),
                flagValue: hasFlagDataItems
                  ? chartData.dataFlags[insightIndex][surveyCodeIndex]
                  : null,
              };
              return data;
            }
          )
        );

        const dataCells: DataCell[] = rowData.reduce(
          (previousValue, currentValue) => [...previousValue, ...currentValue],
          [
            {
              columnHeader: 'Item Name',
              columnHeaderId: 'label',
              value: surveyCode,
            },
          ]
        );

        return {
          data: dataCells,
        };
      }
    );
    return tableData;
  }

  public getTrendingSurveyTargetChartList(
    data: CrossTabTableData[],
    documentTargets: Target[]
  ): TrendingSurveyTargetChartListResult {
    const insightsGroupedData: MultipleSurveyChartData[] = [];
    const surveysGroupedData: MultipleSurveyChartData[] = [];
    const crossTabData = cloneDeep(data);
    const singleTargetInsightData = this.getSingleTargetInsightData(
      crossTabData,
      documentTargets
    );

    const grouped = groupBy(
      singleTargetInsightData,
      (targetData: TargetData) => targetData.originalTargetTitle
    );

    for (const targetLabel in grouped) {
      const groupedByGroup = groupBy(
        grouped[targetLabel],
        (targetData: TargetData) => targetData.title
      );

      // tslint:disable-next-line:forin
      for (const groupTitle in groupedByGroup) {
        const dataItemTargetsMap: DataItemChartData =
          this._chartDataItems.reduce(
            (acc: DataItemChartData, item: DataItemId) => {
              return { ...acc, [item]: [] };
            },
            {} as DataItemChartData
          );

        const labels: string[][] = [];
        const targetIds = [];
        const surveyCodes = [];
        const insightIds = [];
        let title = '';
        let originalTargetTitle = '';
        const cellMetadataSets = [];

        groupedByGroup[groupTitle].forEach((groupedInsight: TargetData) => {
          Object.keys(groupedInsight.dataItems).forEach((key: string) => {
            dataItemTargetsMap[key].push(groupedInsight.dataItems[key]);
          });

          labels.push(groupedInsight.labels);
          targetIds.push(groupedInsight.targetIds);
          surveyCodes.push(groupedInsight.surveyCodes);
          title = groupedInsight.title;
          originalTargetTitle = groupedInsight.originalTargetTitle;
          insightIds.push(groupedInsight.insightIds);
          cellMetadataSets.push(groupedInsight.cellMetadataSets);
        });

        const surveysGroupedChartData: MultipleSurveyChartData = {
          title,
          originalTargetTitle,
          dataItems: dataItemTargetsMap,
          targetIds: uniq(targetIds.map((id) => id[0])),
          insightIds: uniq(insightIds.map((id) => id[0])),
          surveyCodes: surveyCodes[0],
          labels: surveyCodes[0],
          targetTitles: labels.map((label) => label[0]),
          target: documentTargets.find(
            (target) => target.id === targetIds[0][0]
          ),
          cellMetadataSets: cellMetadataSets.map(
            (metadataSet) => metadataSet[0]
          ),
        };
        surveysGroupedData.push(surveysGroupedChartData);

        const insightsGroupedChartData: MultipleSurveyChartData = {
          title,
          originalTargetTitle,
          dataItems: Object.keys(dataItemTargetsMap).reduce(
            (acc, item) => ({
              ...acc,
              [item]: transpose(dataItemTargetsMap[item]),
            }),
            {}
          ),
          targetIds: uniq(targetIds.map((id) => id[0])),
          surveyCodes: transpose(surveyCodes).map((code) => code[0]),
          labels: labels.map((label) => label[0]),
          targetTitles: grouped[targetLabel][0].targetTitles,
          target: documentTargets.find(
            (target) => target.id === targetIds[0][0]
          ),
          cellMetadataSets: cellMetadataSets.map(
            (metadataSet) => metadataSet[0]
          ),
        };
        insightsGroupedData.push(insightsGroupedChartData);
      }
    }

    return {
      insightsGroupedData,
      surveysGroupedData,
    };
  }

  public getCombinedTargetsSingleSurveyChartList(
    data: CrossTabTableData[],
    documentTargets: Target[]
  ): FinalChartData[] {
    const finalDataStructure: FinalChartData[] = [];
    const crossTabData = cloneDeep(data);

    const targetInsightGroupedBySurvey = this.getTargetInsightGroupedBySurvey(
      crossTabData,
      documentTargets
    );

    const grouped = groupBy(
      targetInsightGroupedBySurvey,
      (targetData: TargetData) => targetData.title
    );

    // tslint:disable-next-line:forin
    for (const label in grouped) {
      const dataItemTargetsMap: DataItemChartData = this._chartDataItems.reduce(
        (acc: DataItemChartData, item: DataItemId) => {
          return { ...acc, [item]: [] };
        },
        {} as DataItemChartData
      );

      const labelsTarget: string[][] = [];
      const targetTitles = [];
      const targetIds = [];
      const surveyCodes = [];
      let title = '';
      let originalTargetTitle = '';
      let cellMetadataSets = [];

      grouped[label].forEach((insight: TargetData) => {
        title = label;
        originalTargetTitle = insight.originalTargetTitle;

        Object.keys(insight.dataItems).forEach((key: string) => {
          dataItemTargetsMap[key].push(insight.dataItems[key]);
        });
        labelsTarget.push(insight.labels);
        targetTitles.push(insight.targetTitles);
        targetIds.push(insight.targetIds);
        surveyCodes.push(insight.surveyCodes);
        cellMetadataSets.push(insight.cellMetadataSets);
      });

      const finalChartData: FinalChartData = {
        title,
        originalTargetTitle,
        dataItems: Object.keys(dataItemTargetsMap).reduce(
          (acc, item) => ({
            ...acc,
            [item]: transpose(dataItemTargetsMap[item]),
          }),
          {}
        ),
        targetIds: transpose(targetIds).map((id) => id[0]),
        surveyCodes: surveyCodes.map((code) => code[0]),
        // tslint:disable-next-line:no-shadowed-variable
        labels: labelsTarget.map((label: string[]) => label[0]),
        targetTitles: targetInsightGroupedBySurvey[0].targetTitles,
        cellMetadataSets: cellMetadataSets.map((metadataSet) => metadataSet[0]),
      };
      finalDataStructure.push(finalChartData);
    }
    return finalDataStructure;
  }

  public getSingleChartList(
    combinedChartList: FinalChartData[],
    documentTargets: Target[]
  ) {
    const singleChartList = [];
    for (const insight of combinedChartList) {
      for (let i = 0; i < insight.targetTitles.length; i++) {
        const singleChartDate = {
          title: insight.title,
          targetTitles: insight.targetTitles[i],
          chartMode: ChartTargetMode.single,
          dataItems: Object.keys(insight.dataItems).reduce(
            (acc, item) => ({
              ...acc,
              [item]: insight.dataItems[item][i],
            }),
            {}
          ),
          labels: insight.labels,
          target: documentTargets[i],
          cellMetadataSets: insight.cellMetadataSets,
          surveyCode: uniq(insight.surveyCodes),
        };
        singleChartList.push(singleChartDate);
      }
    }
    return singleChartList;
  }

  public getDynamicAudienceTargets(survey: Survey): Observable<Target[]> {
    return this.audienceGroupsService
      .search()
      .pipe(
        map((data) =>
          this.formatSurveyCustomAudiences(data.hits.hits, survey.code)
        )
      );
  }

  public formatSurveyCustomAudiences(
    customAudienceData: TupDocument<DocumentAudienceGroup>[],
    surveyCode: string
  ): Target[] {
    const audienceDocs = customAudienceData.filter(
      (document: TupDocument<DocumentAudienceGroup>) =>
        document.content.survey.code === surveyCode &&
        document.content.type === SaveOwnCodesType.audience
    );
    return uniqBy(
      audienceDocs.reduce(
        (acc, document: TupDocument<DocumentAudienceGroup>) =>
          acc.concat([
            ...document.content.targets.map(
              (target: DocumentAudienceGroupItem) => {
                const isTargetRenamed =
                  target.title !== target.options.target.title ||
                  (target.options.target.activeTitleMode ===
                    DisplayType.ownTitle &&
                    target.options.target.ownTitle !== target.title);
                if (isTargetRenamed) {
                  target.options.target.activeTitleMode = DisplayType.ownTitle;
                  target.options.target.ownTitle = target.title;
                }
                return target.options.target;
              }
            ),
          ]),
        []
      ),
      (target: Target) => target.coding
    );
  }

  private getSingleTargetInsightData(
    crossTabData: CrossTabTableData[],
    documentTargets: Target[]
  ): TargetData[] {
    const singleTargetInsightData: TargetData[] = [];
    crossTabData.forEach((rowData: CrossTabTableData) => {
      if (rowData.isTotalRow) {
        return;
      }

      const targets: CrossTabTableDataCell[] = rowData.data.filter(
        (item: CrossTabTableDataCell) => item.type === 'target'
      );

      const crossTableDataGroupedByTarget = groupBy(
        targets,
        (target) => target.title
      );

      // tslint:disable-next-line:forin
      for (const theTitle in crossTableDataGroupedByTarget) {
        const dataItemTargetsMap: DataItemChartData =
          this._chartDataItems.reduce(
            (acc: DataItemChartData, item: DataItemId) => {
              return { ...acc, [item]: [] };
            },
            {} as DataItemChartData
          );

        const targetTitles = [];
        const labels = [];
        const targetIds = [];
        const insightIds = [];
        let originalTargetTitle = '';
        const surveyCodes = [];
        const uniqTargetsValue = uniqBy(
          crossTableDataGroupedByTarget[theTitle],
          'surveyCode'
        );
        let title = '';
        const cellMetadataSets = [];

        uniqTargetsValue.forEach((target: CrossTabTableDataCell) => {
          const transformedTargetTitle = this.targetTitlePipe.transform(
            target.columnTarget
          );
          title = `${this.targetTitlePipe.transform(
            target.rowTarget,
            DisplayType.group
          )}`;
          targetTitles.push(`[${target.surveyCode}]${transformedTargetTitle}`);

          Object.keys(this._chartDataItemCells).forEach((key: string) => {
            dataItemTargetsMap[key].push(target[this._chartDataItemCells[key]]);
          });

          labels.push(`${this.targetTitlePipe.transform(target.rowTarget)}`);
          targetIds.push(target.columnTarget.id);
          insightIds.push(target.rowTarget.id);
          surveyCodes.push(target.surveyCode);
          originalTargetTitle = transformedTargetTitle;
          cellMetadataSets.push(target.metadata);
        });

        const targetData: TargetData = {
          title,
          targetTitles,
          originalTargetTitle,
          dataItems: dataItemTargetsMap,
          labels: uniq(labels),
          targetIds: uniq(targetIds),
          insightIds: uniq(insightIds),
          target: documentTargets.find(
            (target) => target.id === uniq(targetIds)[0]
          ),
          surveyCodes,
          cellMetadataSets,
        };

        singleTargetInsightData.push(targetData);
      }
    });

    return singleTargetInsightData;
  }

  private getTargetInsightGroupedBySurvey(
    data: CrossTabTableData[],
    documentTargets: Target[]
  ): TargetData[] {
    const targetInsightGroupedBySurvey = [];
    data.forEach((rowData: CrossTabTableData) => {
      if (rowData.isTotalRow) {
        return;
      }
      const targets: CrossTabTableDataCell[] = rowData.data.filter(
        (item: CrossTabTableDataCell) => item.type === 'target'
      );

      const groupedTargets = groupBy(targets, (target) => target.surveyCode);

      // tslint:disable-next-line:forin
      for (const surveyCode in groupedTargets) {
        const dataItemTargetsMap: DataItemChartData =
          this._chartDataItems.reduce(
            (acc: DataItemChartData, item: DataItemId) => {
              return { ...acc, [item]: [] };
            },
            {} as DataItemChartData
          );

        const targetTitles = [];
        const targetIds = [];
        const labels = [];
        const surveyCodes = [];
        let title = '';
        const cellMetadataSets = [];

        groupedTargets[surveyCode].forEach((target: CrossTabTableDataCell) => {
          title = `[${surveyCode}]: ${this.targetTitlePipe.transform(
            target.rowTarget,
            DisplayType.group
          )}`;
          targetTitles.push(
            this.targetTitlePipe.transform(target.columnTarget)
          );

          Object.keys(this._chartDataItemCells).forEach((key: string) => {
            dataItemTargetsMap[key].push(target[this._chartDataItemCells[key]]);
          });

          labels.push(this.targetTitlePipe.transform(target.rowTarget));
          targetIds.push(target.columnTarget.id);
          surveyCodes.push(target.surveyCode);
          cellMetadataSets.push(target.metadata);
        });

        const targetData: TargetData = {
          title,
          targetTitles,
          originalTargetTitle: uniq(targetTitles)[0],
          dataItems: dataItemTargetsMap,
          labels,
          targetIds,
          surveyCodes,
          target: documentTargets.find(
            (target) => target.id === uniq(targetIds)[0]
          ),
          cellMetadataSets,
        };

        targetInsightGroupedBySurvey.push(targetData);
      }
    });

    return targetInsightGroupedBySurvey;
  }
}
