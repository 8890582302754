<tup-mat-title-bar
  title="Affinity report"
  class="dialog-title"
>
  <mat-icon
    title="Close dialog"
    class="close-icon"
    (click)="cancel()"
    >close</mat-icon
  >
</tup-mat-title-bar>

<div
  mat-dialog-content
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="1rem"
>
  <p class="affinity-report-copy">
    You have selected to run an affinity report, after you select your target,
    it will be displayed as the first row in your crosstab and the rest of the
    rows will be used to calculate the affinity towards your selected target.
  </p>
  <mat-form-field
    appearance="outline"
    tupMatFormField
    class="input-form-field first-row-select"
  >
    <mat-label>First row</mat-label>
    <mat-select [(ngModel)]="selectedRow">
      <mat-option
        *ngFor="let row of rows"
        [value]="row"
      >
        {{ row | targetTitle: row.activeTitleMode:row.titleLevels }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="medium"
    cdkFocusRegionstart
    (click)="continue()"
  >
    Continue
  </button>
</div>
