import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DEFAULT_INDEX_PERCENTAGE,
  HEATMAP_OPTIONS,
  HEATMAP_QUARTILES,
  HEATMAP_QUINTILES,
} from '../../models';

@Component({
  selector: 'app-highlight-values-dialog',
  templateUrl: './heatmap-dialog.component.html',
  styleUrls: ['./heatmap-dialog.component.scss'],
})
export class HeatmapDialogComponent implements OnInit {
  public readonly HEATMAP_OPTIONS = HEATMAP_OPTIONS;
  public readonly HEATMAP_QUARTILES = HEATMAP_QUARTILES;
  public readonly HEATMAP_QUINTILES = HEATMAP_QUINTILES;
  public selectedHeatmapOption: string;
  public indexPercentageValue: number;
  public readonly min = 5;
  public readonly max = 100;
  public readonly defaultIndex = DEFAULT_INDEX_PERCENTAGE;

  constructor(
    public dialogRef: MatDialogRef<HeatmapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.selectedHeatmapOption =
      data.selectedHeatmapOption || 'heatmapQuartiles';
    this.indexPercentageValue = data.value;
  }

  ngOnInit(): void {}

  public onIndexPercentageInputChange(): void {
    if (this.indexPercentageValue === null) {
      this.indexPercentageValue = this.defaultIndex;
    }

    if (this.indexPercentageValue < this.min) {
      this.indexPercentageValue = this.min;
    }
  }

  public onIndexPercentageInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  public onIndexPercentageInputKeyup(): void {
    if (this.indexPercentageValue > this.max) {
      this.indexPercentageValue = this.max;
    }
  }

  public onApplyClick(): void {
    this.dialogRef.close({
      value: this.indexPercentageValue,
      selectedHeatmapOption: this.selectedHeatmapOption,
    });
  }

  public onClearClick(): void {
    this.dialogRef.close({
      value: this.indexPercentageValue,
    });
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }
}
