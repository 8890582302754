import { Pipe, PipeTransform } from '@angular/core';

import { compact, at } from 'lodash';

import { DisplayType, levelSeparator, Target, titleSeparator } from '../models';

/**
 * Formats a Target title depending on the value of the optional displayType and titleLevels function parameters.
 *
 * If the displayType and / or titleLevels function parameters are null or undefined,
 * uses the activeTitleMode / titleLevels Target properties as a fallback
 *
 * If the updatedTargetTitle is provided, it listens to the title changes of a target and
 * enforces the title to be updated accordingly
 */

@Pipe({
  name: 'targetTitle',
})
export class TargetTitlePipe implements PipeTransform {
  public transform(
    target: Target,
    displayType?: DisplayType,
    titleLevels?: number[],
    updatedTargetTitle?: string
  ): string {
    if (target) {
      displayType = displayType || target.activeTitleMode;
      titleLevels = titleLevels || target.titleLevels;

      if (
        target.targets?.length &&
        ![DisplayType.group, DisplayType.ownTitle].includes(displayType)
      ) {
        return this.getTitles(target, target.title, displayType, titleLevels);
      }

      return this._transform(target, displayType, titleLevels);
    }

    return '';
  }

  /**
   * Transforms leaf node Target titles. Replaces matching text in the root node Target title.
   */
  private getTitles(
    target: Target,
    title: string,
    displayType: DisplayType,
    titleLevels: number[]
  ): string {
    return target.targets.reduce(
      (previousValue: string, currentValue: Target) => {
        if (currentValue.targets?.length) {
          return this.getTitles(
            currentValue,
            previousValue,
            displayType,
            titleLevels
          );
        } else {
          return previousValue.replace(
            currentValue.title,
            this._transform(currentValue, displayType, titleLevels)
          );
        }
      },
      title
    );
  }

  private _transform(
    target: Target,
    displayType: DisplayType,
    titleLevels: number[]
  ): string {
    const levels: string[] = this._getLevels(target);

    switch (displayType) {
      case DisplayType.levels:
        return titleLevels && titleLevels.length
          ? compact(
              at(levels, this._reindexLast(titleLevels, levels.length - 1))
            ).join(` ${titleSeparator} `)
          : target.title.replace(
              new RegExp(levelSeparator, 'g'),
              titleSeparator
            );

      case DisplayType.shortTitle:
        return levels.pop();

      case DisplayType.coding:
        return target.coding;

      case DisplayType.group:
        return this._getGroupTitle(target);

      case DisplayType.ownTitle:
        return target.ownTitle || this._getDefaultTitle(levels);

      default:
        return this._getDefaultTitle(levels);
    }
  }

  private _getLevels(target: Target): string[] {
    return target.title
      .split(levelSeparator)
      .map((level: string) => level.trim());
  }

  private _reindexLast(titleLevels: number[], item: number): number[] {
    // tslint:disable-next-line:variable-name
    const _titleLevels = [...titleLevels];

    const index: number = _titleLevels.findIndex(
      (titleLevel: number) => titleLevel === -1
    );

    if (index !== -1) {
      _titleLevels.splice(index, 1, item);
    }

    return [...new Set(_titleLevels.sort())];
  }

  private _getDefaultTitle(levels: string[]): string {
    return compact(levels.slice(-2)).reverse().join(` ${titleSeparator} `);
  }

  private _getGroupTitle(target: Target): string {
    if (target.groupName) {
      return target.groupName;
    }

    let temp: Target = target;

    while (temp.targets && temp.targets.length !== 0) {
      temp = temp.targets[0];
    }

    const levels = this._getLevels(temp);

    return levels.length > 1 ? levels[levels.length - 2] : temp.title;
  }
}
