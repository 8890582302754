<div
  fxLayout="column"
  class="name-document-controls"
>
  <ng-template [ngIf]="!isReadonly">
    <h3>Add a note</h3>

    <mat-form-field
      class="full-width"
      appearance="outline"
      tupMatFormField
    >
      <mat-label>Note title</mat-label>
      <input
        [(ngModel)]="title"
        matInput
        value=""
        (blur)="saveNote()"
      />
    </mat-form-field>

    <mat-form-field
      class="full-width description-field"
      appearance="outline"
      tupMatFormField
    >
      <mat-label>Description</mat-label>
      <textarea
        [(ngModel)]="description"
        matInput
        maxlength="150"
        class="note-description"
        (blur)="saveNote()"
      ></textarea>
    </mat-form-field>
  </ng-template>

  <ng-template [ngIf]="isReadonly">
    <h3>Note:</h3>
    <h4>Title: {{ title }}</h4>
    <h4>Description: {{ description }}</h4>
  </ng-template>
</div>
