<tup-mat-title-bar
  title="Change mapping"
  class="dialog-title"
>
  <mat-icon
    title="Close dialog"
    class="close-icon"
    (click)="close()"
    >close</mat-icon
  >
</tup-mat-title-bar>

<mat-dialog-content fxLayout="column">
  <div
    fxLayout="column"
    fxLayoutGap="16px"
  >
    <mat-radio-group
      [(ngModel)]="graphType"
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <mat-radio-button [value]="'dataItem'"
        >Graph a data item by 2 columns</mat-radio-button
      >
      <mat-radio-button [value]="'column'"
        >Graph a column by 2 data items</mat-radio-button
      >
    </mat-radio-group>

    <mat-form-field
      *ngIf="graphType === 'dataItem'"
      appearance="outline"
      tupMatFormField
      fxFlex
      class="input-form-field"
    >
      <mat-label>Variable to graph</mat-label>
      <mat-select [(ngModel)]="variableToGraphForDataItem">
        <mat-option
          *ngFor="let dataItem of dataItems"
          [value]="dataItem.id"
        >
          {{ dataItem.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <form *ngIf="graphType === 'column'">
      <tup-large-list-selector
        label="Variable to graph"
        [mode]="LargeListSelectorMode.Single"
        [chipListDataSource]="variableToGraphForColumnDataSource"
        [dataService]="columnTargetListService"
        [defaultItem]="columns[0]"
        [populateOnInit]="true"
        [showSearchBox]="true"
        itemLabel="name"
        querySearchField="description"
        [wrapListItems]="true"
      ></tup-large-list-selector>
    </form>

    <form
      *ngIf="graphType === 'dataItem'"
      fxLayout="column"
      fxLayoutGap="16px"
    >
      <tup-large-list-selector
        label="X-Axis"
        [mode]="LargeListSelectorMode.Single"
        [chipListDataSource]="xAxisForDataItemDataSource"
        [dataService]="columnTargetListService"
        [defaultItem]="columns[0]"
        [populateOnInit]="true"
        [showSearchBox]="true"
        itemLabel="name"
        querySearchField="description"
        [wrapListItems]="true"
      ></tup-large-list-selector>

      <tup-large-list-selector
        label="Y-Axis"
        [mode]="LargeListSelectorMode.Single"
        [chipListDataSource]="yAxisForDataItemDataSource"
        [dataService]="columnTargetListService"
        [defaultItem]="columns[0]"
        [populateOnInit]="true"
        [showSearchBox]="true"
        itemLabel="name"
        querySearchField="description"
        [wrapListItems]="true"
      ></tup-large-list-selector>
    </form>

    <ng-container *ngIf="graphType === 'column'">
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>X-Axis</mat-label>
        <mat-select [(ngModel)]="xAxisForColumn">
          <mat-option
            *ngFor="let dataItem of dataItems"
            [value]="dataItem.id"
          >
            {{ dataItem.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Y-Axis</mat-label>
        <mat-select [(ngModel)]="yAxisForColumn">
          <mat-option
            *ngFor="let dataItem of dataItems"
            [value]="dataItem.id"
          >
            {{ dataItem.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="dialog-actions"
>
  <button
    mat-stroked-button
    color="primary"
    appearance="medium"
    tupMatButton
    (click)="close()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    appearance="medium"
    tupMatButton
    cdkFocusRegionstart
    (click)="onApplyMappingClick()"
  >
    Appy mapping
  </button>
</mat-dialog-actions>
