import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Target, TargetType } from '../../models';
import {
  NTileBucketElement,
  NTiles,
  NTILE_FUNCTION_WITH_ZERO_EXCLUDED,
  NTILE_FUNCTION_WITH_ZERO_INCLUDED,
  DEFAULT_NTILES,
  NTileBoundaryTypes,
} from 'src/app/models/n-tiles.model';

export interface NTileDialogResult {
  target: Target;
  targetType: TargetType;
  nTileBuckets: NTileBucketElement[];
  nTileFunction: string;
}

@Component({
  selector: 'app-ntile-dialog',
  templateUrl: './ntile-dialog.component.html',
  styleUrls: ['./ntile-dialog.component.scss'],
})
export class NtileDialogComponent implements OnInit {
  public displayedColumns = [NTileBoundaryTypes.start, NTileBoundaryTypes.end];
  public dataSource: NTileBucketElement[] = [];
  public nTileOptions = Object.keys(NTiles).filter((key) => isNaN(Number(key)));
  public targetTypeOptions = Object.keys(TargetType).filter((key) =>
    isNaN(Number(key))
  );
  public excludeZeroes = true;
  public target: Target;
  public targetTitle: string;
  public targetCoding: string;
  public selectedNtile: string;
  public selectedTargetType: TargetType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NtileDialogComponent>
  ) {
    this.target = cloneDeep(this.data.target);
    this.targetTitle = this.target.title;
    this.targetCoding = this.target.coding;
    this.selectedTargetType = this.data.type;
  }

  ngOnInit(): void {
    this.selectedNtile = DEFAULT_NTILES;
    this.onNTileSelectionAction();
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public onGenerateClick(): void {
    this.dialogRef.close({
      target: {
        ...this.target,
        title: this.targetTitle,
      },
      targetType: this.selectedTargetType,
      nTileBuckets: this.dataSource,
      nTileFunction: this.getNtileFunction(),
    });
  }

  public isGeneratable(): boolean {
    return this.targetTitle && this.selectedNtile in NTiles;
  }

  public onNTileSelectionAction(): void {
    const ranges = this.splitIntoTiles(NTiles[this.selectedNtile]);
    this.dataSource = ranges.map((element: NTileBucketElement) => ({
      start: element[0],
      end: element[1],
    }));
  }

  private splitIntoTiles(noOfSplits: number, value: number = 100): any {
    const splitValue = Math.ceil(value / noOfSplits);
    const ranges = [[0, splitValue]];
    let start = splitValue;
    while (start < value) {
      const end = start + splitValue;
      if (end <= value) {
        ranges.push([start, end]);
      } else {
        ranges.push([start, value]);
      }
      start = end;
    }

    return ranges;
  }

  public getTargetTypeValue(type: string): string {
    return TargetType[type];
  }

  public getNtileFunction(): string {
    return this.excludeZeroes
      ? NTILE_FUNCTION_WITH_ZERO_EXCLUDED
      : NTILE_FUNCTION_WITH_ZERO_INCLUDED;
  }
}
