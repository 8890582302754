import { StabilityLevels, SurveyMetaDataWeights } from './crosstab.model';

export interface Survey {
  code: string;
  title: string;
  meta?: {
    [key: string]: unknown;
  };
  stabilityLevels?: StabilityLevels;
  weights?: SurveyMetaDataWeights[];
  provider: string;
  language: string;
  isMultibased: boolean;
  year: number;
  isTrendable: boolean;
  isMb3Enabled: boolean;
  isMappable: boolean;
  occurrenceCount?: number;
  authorizationGroup: string;
}

export interface PopulationBasesResponse {
  success: boolean;
  results: PopulationBaseItem[];
  during: number;
}

export interface PopulationBaseItem {
  name: string;
  value: string;
}

export interface DataItemsResponse {
  success: boolean;
  results: DataItemsBaseItem[];
}

export interface DataItemsBaseItem {
  name: string;
  value: string;
}

export interface SurveyDataItems {
  availableDataItemIds: number[];
  defaultDataItemIds: number[];
}

export enum SurveyInfoType {
  info = 'info',
  copyright = 'copyright',
}

export const DEFAULT_SURVEY_COPYRIGHT = 'All rights reserved.';
export const DEFAULT_SURVEY_INFO = 'Currently no survey information.';

export const SWAP_ROWS_COLUMNS_WARNING =
  'Swapping ' +
  'columns and rows is not advisable in YouGov as audience should always be in ' +
  'the columns.';

export const COUNT_CODING_WARNING =
  'We advise ' +
  'against any COUNT coding in YouGov surveys because not everyone may have ' +
  'been given the full list of possible answers to a question.';

export const TAG_FOR_SURVEY_WITH_100_RESPONSE_RATE = 'HUNDRED-PCT';

export const TOOLTIP_MESSAGE_FOR_SURVEY_WITH_100_RESPONSE_RATE =
  'These are global variables, which have a 100% response rate from all survey participants';
