import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CategoryResult,
  CodebookNavigationResponse,
  CodebookSearchResponse,
  Statement,
  Survey,
} from '../models';
import { ApiService } from './api.service';
import { StatementFactory } from '../components/tree-view/tree.models';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesType,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TupDocument } from '@telmar-global/tup-document-storage';

@Injectable({
  providedIn: 'root',
})
export class CodebookService {
  categoryResult: CategoryResult;

  constructor(
    private apiService: ApiService,
    private audienceGroupsService: TupAudienceGroupsService
  ) {}

  getCategories(survey: Survey): Observable<CategoryResult> {
    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.codebookNavigation,
          options
        )
        .subscribe(
          (data) => {
            this.categoryResult = {
              success: data.success,
              message: data.message,
              categories: [],
            };

            data.children?.forEach((cat) => {
              this.categoryResult.categories.push({
                category: cat.key,
                count: cat.children,
                minPos: cat.min_pos,
              });
            });

            this.categoryResult.categories.sort((a, b) => {
              return a.minPos - b.minPos;
            });

            observable.next(this.categoryResult);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  getChildren(
    survey: Survey,
    path: string
  ): Observable<CodebookNavigationResponse> {
    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
          category: path,
          sortField: 'pos',
          sortOrder: 'asc',
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.codebookNavigation,
          options
        )
        .subscribe(
          (data) => {
            const result: CodebookNavigationResponse = {
              success: data.success,
              message: data.message,
              children: [],
            };

            if (data.children) {
              data.children.forEach((element) => {
                const node: Statement = {
                  description: element.key.substring(path.length + 1),
                  path: element.key,
                  children: [],
                  validWeights: (element['autoweights'] || []).sort(),
                  pos: element.type === 'node' ? element.min_pos : element.pos,
                };

                if ('customtag' in element && element['customtag']) {
                  node.customTag = element['customtag'];
                }

                const coding: string = element['data-reference'];
                if (coding) {
                  node.coding = coding;
                }
                result.children.push(node);
              });

              result.children.sort((a, b) => {
                if (a.pos === b.pos) {
                  return a.description < b.description
                    ? -1
                    : a.description > b.description
                    ? 1
                    : 0;
                }
                return a.pos - b.pos;
              });
            }

            observable.next(result);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  getAllChildren(
    survey: Survey,
    path: string | string[]
  ): Observable<CodebookSearchResponse> {
    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
          category: path,
          sortField: 'pos',
          sortOrder: 'asc',
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.getleafdata,
          options
        )
        .subscribe(
          (data) => {
            observable.next(this.processCodebook(data, 'children', 'key'));
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  getOwnCodesChildren(
    surveyCode: string,
    path: string
  ): Observable<Statement[]> {
    const ownCodesType = path.startsWith('Custom Audiences')
      ? SaveOwnCodesType.audience
      : SaveOwnCodesType.media;
    return new Observable((observable) => {
      this.audienceGroupsService.search().subscribe(
        (data) => {
          const documents = data.hits.hits.filter(
            (document: TupDocument<DocumentAudienceGroup>) =>
              document.content.survey.code === surveyCode &&
              document.content.type === ownCodesType
          );
          observable.next(this.formatOwnCodesChildrenNodes(documents, path));
          observable.complete();
        },
        (error) => {
          observable.error(error);
          observable.complete();
        }
      );
    });
  }

  static readonly SearchAnyKeyword: string = 'any';
  static readonly SearchAllKeyword: string = 'all';
  static readonly SearchPhrase: string = 'phrase';
  static readonly SearchStartingKeyword: string = 'starting';

  static readonly SearchInTitle = 'title';
  static readonly SearchInCode = 'code';
  // not used for now - static readonly SearchInBoth = 'both';

  search(
    searchText: string,
    category: string | string[],
    survey: Survey,
    matchMode: string = CodebookService.SearchAnyKeyword,
    searchInCode: boolean = false,
    resultSize: number = 10000
  ): Observable<CodebookSearchResponse> {
    const searchIn = searchInCode
      ? CodebookService.SearchInCode
      : CodebookService.SearchInTitle;

    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
          filterCategory: category,
          matchText: searchText,
          matchType: matchMode,
          searchIn,
          weightIndex: 1,
          filterAttributes: null,
          filterDatatype: 'binary',
          resultFrom: 0,
          // TODO: ask why do we need to add a size here. If we leave it empty, only ten results will be returned.
          resultSize,
          includeNodeMatch: true,
          disableCatAggs: true,
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.search,
          options
        )
        .subscribe(
          (data) => {
            observable.next(
              this.processCodebook(data, 'searchResults', 'category')
            );
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  private processCodebook(
    data: any,
    resultKey: string = 'searchResults',
    categoryKey: string = 'category'
  ): CodebookSearchResponse {
    const codebook: CodebookSearchResponse = {
      success: data.success,
      message: data.message,
      tree: { description: 'root', path: '', children: [] }, // used in the expanded search panel
    };

    data[resultKey]?.sort((a, b) => {
      // sort nodes by name, and leafs by position
      const aParentCategory =
        a['data-reference'] === undefined
          ? a[categoryKey]
          : StatementFactory.getParent(a[categoryKey]);
      const bParentCategory =
        b['data-reference'] === undefined
          ? b[categoryKey]
          : StatementFactory.getParent(b[categoryKey]);

      if (aParentCategory === bParentCategory) {
        return a.pos - b.pos;
      }

      return aParentCategory < bParentCategory
        ? -1
        : aParentCategory > bParentCategory
        ? 1
        : 0;
    });

    data[resultKey]?.forEach((element) => {
      const category = element[categoryKey];

      // parse the fulltext
      const levels = category.split('|');
      let children = codebook.tree.children;

      let path = '';
      levels.forEach((level, index) => {
        if (path.length !== 0) {
          path += '|';
        }
        path += level;

        let child = children.find((node) => node.description === level);
        if (!child) {
          const statement: Statement = {
            description: level,
            path,
            coding:
              index === levels.length - 1
                ? element['data-reference']
                : undefined,
            pos: element.pos,
            validWeights: (element['autoweights'] || []).sort(),
            children: [],
          };
          children.push(statement);
          child = children[children.length - 1];
        }
        children = child.children;
      });
    });

    return codebook;
  }

  private formatOwnCodesChildrenNodes(
    documents: TupDocument<DocumentAudienceGroup>[],
    ownCodesPath: string
  ): Statement[] {
    return documents.map((document: TupDocument<DocumentAudienceGroup>) => ({
      description: document.metadata.name,
      path: ownCodesPath + '|' + document.metadata.name,
      isCustomCoding: true,
      children: document.content.targets.map(
        (target: DocumentAudienceGroupItem, index: number) => ({
          description: target.title,
          path:
            ownCodesPath + '|' + document.metadata.name + '|' + target.title,
          coding: target.coding,
          children: [],
          isCustomCoding: true,
          canEditTitle: true,
          customData: {
            index,
            target: target.options.target,
            document,
          },
        })
      ),
    }));
  }
}
