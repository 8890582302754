import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import {
  DocumentDataState,
  MAX_COLUMN_TARGET_LIMIT,
  MAX_ROW_TARGET_LIMIT,
  TargetItem,
  TargetType,
} from '../models';
import { MoveToActionItem } from '../models/action.model';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { first } from 'rxjs/operators';

export interface MoveToTargetActionContext {
  targetItem?: TargetItem;
  targetItems?: TargetItem[];
  actionItem: MoveToActionItem;
}

@Injectable({
  providedIn: 'root',
})
export class MoveToTargetAction extends ActionDefinition<MoveToTargetActionContext> {
  constructor(
    private documentService: DocumentService,
    private messageService: TupUserMessageService
  ) {
    super();
  }

  public invoke(context: MoveToTargetActionContext): void {
    const hasNoTargetItem = !context.targetItem && !context.targetItems;
    if (hasNoTargetItem) {
      return;
    }

    const targetItems = context.targetItem
      ? [context.targetItem]
      : context.targetItems;

    if (this.hasReachedMaxLimit(context, targetItems.length)) {
      this.showMaxLimitAlert(context, targetItems.length);
      return;
    }

    this.documentService.moveTargets(targetItems, context.actionItem.moveTo);
  }

  private hasReachedMaxLimit(
    context: MoveToTargetActionContext,
    targetItemCount
  ): boolean {
    return (
      context.actionItem.moveTo !== TargetType.tables &&
      targetItemCount + this.getNumberOfItemsByType(context.actionItem.moveTo) >
        this.getTargetTypeLimit(context.actionItem.moveTo)
    );
  }

  private showMaxLimitAlert(
    context: MoveToTargetActionContext,
    selectedCount: number
  ): void {
    const currentNumberOfItems = this.getNumberOfItemsByType(
      context.actionItem.moveTo
    );
    const moveToTargetType = this.getMoveToTargetType(context);
    const targetLimit = this.getTargetTypeLimit(context.actionItem.moveTo);
    const isPlural = currentNumberOfItems > 1;
    this.messageService.openMessageDialog(
      `Sorry! You cannot move ${selectedCount} item${
        selectedCount > 1 ? 's' : ''
      } to ${moveToTargetType}s. Maximum allowed limit for ${moveToTargetType}s is ${targetLimit} ` +
        `and ${currentNumberOfItems} ${moveToTargetType}${
          isPlural ? 's' : ''
        } ${isPlural ? 'are' : 'is'} already added.`,
      'SurveyTime'
    );
  }

  private getMoveToTargetType(context: MoveToTargetActionContext): string {
    return context.actionItem.moveTo === TargetType.columns
      ? 'column'
      : context.actionItem.moveTo === TargetType.rows
      ? 'row'
      : 'table';
  }

  private getNumberOfItemsByType(targetType: TargetType): number {
    let numberOfItems = 0;
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns, rows, tables }: DocumentDataState) => {
        numberOfItems =
          targetType === TargetType.columns
            ? columns.length
            : targetType === TargetType.rows
            ? rows.length
            : tables.length;
      });
    return numberOfItems;
  }

  private getTargetTypeLimit(targetType: TargetType): number {
    return targetType === TargetType.columns
      ? MAX_COLUMN_TARGET_LIMIT
      : MAX_ROW_TARGET_LIMIT;
  }
}
