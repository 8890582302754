import { Component, OnInit } from '@angular/core';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { UserAction } from '@telmar-global/tup-auth';
import { AppPackage } from '../../app.package';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  appVersion: string = AppPackage.version;

  constructor(private analyticsService: TupAnalyticsService) {}

  ngOnInit(): void {}

  onUserAction(userAction: UserAction) {
    this.analyticsService.e('user engagement', userAction.toString());
  }
}
