<div
  fxFill
  fxLayout="column"
>
  <app-top-bar
    [selectViewType]="documentViewType.pmaps"
    [showSurveyInfo]="false"
    [isReadonly]="isReadonly"
    (exportToPNG)="exportTo('image/png')"
    (exportToJPEG)="exportTo('image/jpeg')"
    (exportToPDF)="exportTo('application/pdf')"
    (exportToSVG)="exportTo('image/svg+xml')"
  ></app-top-bar>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="10px"
    class="pmaps-control-bar"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <mat-form-field
        *ngIf="surveys?.length > 1"
        appearance="outline"
        class="select-small"
        tupMatFormField
        style="width: 300px"
      >
        <mat-select
          [(ngModel)]="selectedSurvey"
          (ngModelChange)="onSelectedSurveyChanged()"
        >
          <mat-option
            *ngFor="let survey of surveys"
            [value]="survey"
          >
            {{ survey.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        class="data-control-button"
        matTooltip="Change data mapping"
        (click)="changeMappingOptions()"
      >
        <mat-icon class="material-icons-outlined">account_tree</mat-icon>
        Change mapping
      </button>
      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        class="data-control-button"
        matTooltip="Change data"
        (click)="changeData()"
      >
        <mat-icon class="material-symbols-outlined">page_info</mat-icon>
        Change data
      </button>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end start"
      fxLayoutGap="10px"
    >
      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Zoom out"
        (click)="zoomOut()"
      >
        <mat-icon>zoom_out</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Zoom in"
        (click)="zoomIn()"
      >
        <mat-icon>zoom_in</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Move pan"
        [matMenuTriggerFor]="movePan"
      >
        <mat-icon class="material-symbols-outlined tiny-icon"
          >drag_pan</mat-icon
        >
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        [disabled]="!canResetZoom"
        (click)="resetZoom()"
      >
        <mat-icon matTooltip="Reset zoom">restart_alt</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Settings"
        (click)="openSettings()"
      >
        <mat-icon class="tiny-icon">settings</mat-icon>
      </button>
      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Full screen"
        (click)="openFullScreen()"
      >
        <mat-icon class="tiny-icon">open_in_full</mat-icon>
      </button>
    </div>
  </div>

  <tup-highcharts
    *ngIf="mappingOptions"
    id="container"
    [Highcharts]="Highcharts"
    [callbackFunction]="chartCallback"
    [options]="chartOptions"
    class="pmaps-chart"
  >
  </tup-highcharts>

  <div
    class="spinner-container-overlay"
    *ngIf="isLoading"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<mat-menu #movePan="matMenu">
  <button
    mat-menu-item
    (click)="moveLeft()"
  >
    <mat-icon>west</mat-icon>
    Move left
  </button>
  <button
    mat-menu-item
    (click)="moveRight()"
  >
    <mat-icon>east</mat-icon>
    Move right
  </button>
  <button
    mat-menu-item
    (click)="moveUp()"
  >
    <mat-icon>north</mat-icon>
    Move up
  </button>
  <button
    mat-menu-item
    (click)="moveDown()"
  >
    <mat-icon>south</mat-icon>
    Move down
  </button>
</mat-menu>
