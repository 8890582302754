import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DataItem,
  DataItemId,
  DataItemType,
  DocumentDataState,
  ReportMode,
  Target,
} from '../../models';
import { DocumentService } from '../../services/document.service';
import { DataItemsService } from '../../services/data-items.service';
import { first, takeUntil } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import {
  CodeListItem,
  CodeListItemDataSource,
  LargeListSelectorMode,
} from '@telmar-global/tup-large-list-selector';
import { TargetListService } from '../../services/target-list.service';
import { Subject } from 'rxjs';

export type GraphType = 'column' | 'dataItem';

export type ColumnTargetId = string;

export interface MappingOptions {
  graphType: GraphType;
  variableToGraph: DataItemId | ColumnTargetId;
  xAxis: DataItemId | ColumnTargetId;
  yAxis: DataItemId | ColumnTargetId;
}

@Component({
  templateUrl: './mapping-options-dialog.component.html',
  styleUrls: ['./mapping-options-dialog.component.scss'],
})
export class MappingOptionsDialogComponent implements OnInit {
  public readonly LargeListSelectorMode = LargeListSelectorMode;
  public columnTargetListService: TargetListService<CodeListItem> =
    new TargetListService<CodeListItem>([]);
  public variableToGraphForColumnDataSource = new CodeListItemDataSource();
  public xAxisForDataItemDataSource = new CodeListItemDataSource();
  public yAxisForDataItemDataSource = new CodeListItemDataSource();

  public graphType: GraphType = 'dataItem';
  public variableToGraphForColumn: ColumnTargetId;
  public variableToGraphForDataItem: DataItemId;
  public xAxisForColumn: DataItemId;
  public yAxisForColumn: DataItemId;
  public xAxisForDataItem: ColumnTargetId;
  public yAxisForDataItem: ColumnTargetId;
  public dataItems: DataItem[];
  public columns: CodeListItem[] = [];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MappingOptions,
    public dialogRef: MatDialogRef<
      MappingOptionsDialogComponent,
      MappingOptions
    >,
    private dataItemsService: DataItemsService,
    private documentService: DocumentService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    if (!data) {
      return;
    }
    if (data.graphType === 'dataItem') {
      this.variableToGraphForDataItem = data.variableToGraph as DataItemId;
      this.xAxisForDataItem = data.xAxis as ColumnTargetId;
      this.yAxisForDataItem = data.yAxis as ColumnTargetId;
    } else {
      this.graphType = 'column';
      this.variableToGraphForColumn = data.variableToGraph as ColumnTargetId;
      this.xAxisForColumn = data.xAxis as DataItemId;
      this.yAxisForColumn = data.yAxis as DataItemId;
    }
  }

  ngOnInit(): void {
    this.prepareColumnTargetList();
    this.prepareDataItems();
    this.initialiseVariableToGraphForDataItem();
    this.initialiseVariableToGraphForColumn();
  }

  public onApplyMappingClick(): void {
    const mappingOptions =
      this.graphType === 'dataItem'
        ? {
            graphType: this.graphType,
            variableToGraph: this.variableToGraphForDataItem,
            xAxis: this.xAxisForDataItem,
            yAxis: this.yAxisForDataItem,
          }
        : {
            graphType: this.graphType,
            variableToGraph: this.variableToGraphForColumn,
            xAxis: this.xAxisForColumn,
            yAxis: this.yAxisForColumn,
          };

    this.dialogRef.close(mappingOptions);
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  private prepareColumnTargetList() {
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns }: DocumentDataState) => {
        this.columns = [
          {
            id: 'totals',
            name: 'Totals',
            description: 'Totals',
          },
          ...columns.map((target: Target) => ({
            id: target.id,
            name: `${this.targetTitlePipe.transform(target)} (${
              target.coding
            })`,
            description: `${target.coding} - ${
              target.title
            } - ${this.targetTitlePipe.transform(target)}`,
          })),
        ];
      });
    this.columnTargetListService.updateData(this.columns);
  }

  private prepareDataItems() {
    this.dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.crossTab
    );
  }

  private initialiseVariableToGraphForDataItem() {
    this.variableToGraphForDataItem =
      this.variableToGraphForDataItem ??
      this.dataItems.find(
        (dataItem: DataItem) => dataItem.id === DataItemType.index
      )?.id ??
      this.dataItems[0].id;

    this.xAxisForDataItem =
      this.xAxisForDataItem ??
      ((this.columns.length > 1
        ? this.columns[1].id
        : this.columns[0].id) as ColumnTargetId);
    this.updateSelectedDataSourceItem(
      this.xAxisForDataItem,
      this.xAxisForDataItemDataSource
    );

    this.yAxisForDataItem =
      this.yAxisForDataItem ??
      ((this.columns.length > 2
        ? this.columns[2].id
        : this.columns[0].id) as ColumnTargetId);
    this.updateSelectedDataSourceItem(
      this.yAxisForDataItem,
      this.yAxisForDataItemDataSource
    );

    this.xAxisForDataItemDataSource
      .connect()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((listItem: CodeListItem[]) => {
        this.xAxisForDataItem =
          this.formatSelectedColumnTargetListItem(listItem);
      });
    this.yAxisForDataItemDataSource
      .connect()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((listItem: CodeListItem[]) => {
        this.yAxisForDataItem =
          this.formatSelectedColumnTargetListItem(listItem);
      });
  }

  private initialiseVariableToGraphForColumn() {
    this.variableToGraphForColumn =
      this.variableToGraphForColumn ??
      ((this.columns.length > 1
        ? this.columns[1].id
        : this.columns[0].id) as ColumnTargetId);
    this.updateSelectedDataSourceItem(
      this.variableToGraphForColumn,
      this.variableToGraphForColumnDataSource
    );

    this.xAxisForColumn =
      this.xAxisForColumn ??
      this.dataItems.find(
        (dataItem: DataItem) => dataItem.id === DataItemType.audience
      )?.id ??
      this.dataItems[0].id;
    this.yAxisForColumn =
      this.yAxisForColumn ??
      this.dataItems.find(
        (dataItem: DataItem) => dataItem.id === DataItemType.index
      )?.id ??
      (this.columns.length > 1 ? this.dataItems[1] : this.dataItems[0]).id;

    this.variableToGraphForColumnDataSource
      .connect()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((listItem: CodeListItem[]) => {
        this.variableToGraphForColumn =
          this.formatSelectedColumnTargetListItem(listItem);
      });
  }

  private formatSelectedColumnTargetListItem(listItem: CodeListItem[]): string {
    return listItem && listItem.length ? `${listItem[0].id}` : 'totals';
  }

  private updateSelectedDataSourceItem(
    targetId: ColumnTargetId,
    dataSource: CodeListItemDataSource<CodeListItem>
  ): void {
    const items = this.columnTargetListService.searchItems({
      filter: {
        id: targetId,
      },
    });
    if (items?.length) {
      dataSource.update([items[0]]);
    }
  }
}
