import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  public transform(
    text: string,
    length: number = 20,
    suffix: string = '...'
  ): string {
    return text && text.length > length
      ? text.substring(0, length).trim() + suffix
      : text;
  }
}
