import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { CategoryListI } from 'src/app/interfaces/category-list-i';
import {
  CodebookSearchResponse,
  Statement,
} from 'src/app/models/codebook.model';
import { CodebookService } from 'src/app/services/codebook.service';
import { TreeViewComponent } from '../tree-view/tree-view.component';
import {
  ContextMenuData,
  SelectionTreeFlatNode,
  StatementFactory,
} from '../tree-view/tree.models';
import {
  CodebookSelectionService,
  LoadingSource,
} from 'src/app/services/codebook-selection.service';
import { SaveOwnCodesType } from '@telmar-global/tup-audience-groups';
import {
  DocumentDataState,
  Survey,
  SurveyMetaDataWeights,
  TargetType,
} from '../../models';
import { SeparateOperatorAction } from 'src/app/actions/SeparateOperatorAction';
import { DocumentService, TargetService } from 'src/app/services';
import { AddToMultipleAction } from 'src/app/actions/AddToMultipleAction';
import { first, takeUntil } from 'rxjs/operators';
import { CombineActionItem } from 'src/app/models/action.model';
import { SeparateCountAction } from '../../actions/SeparateCountAction';
import { Subject } from 'rxjs';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

class CurrentCache {
  filters: Set<string> = new Set<string>();
  searchs: Set<string> = new Set<string>();
  searchSelected = false;
}

@Component({
  selector: 'app-codebook-navigation',
  templateUrl: './codebook-navigation.component.html',
  styleUrls: ['./codebook-navigation.component.scss'],
})
export class CodebookNavigationComponent
  implements OnInit, OnChanges, OnDestroy
{
  public selectedNodes: Statement[] = [];
  private unsubscribe: Subject<void> = new Subject<void>();
  public weightDescriptions: string[] = [];
  @Input() isReadonly = true;
  @Input() survey: Survey;
  @Input() inCodeBuilder: boolean;
  @Output() codebookContextMenu = new EventEmitter<ContextMenuData>();

  @ViewChild('codebook') private codebookTree: TreeViewComponent;

  cache: CurrentCache = undefined;

  searchOptions = [
    { label: 'Any keyword', value: CodebookService.SearchAnyKeyword },
    { label: 'All keywords', value: CodebookService.SearchAllKeyword },
    { label: 'Exact phrase', value: CodebookService.SearchPhrase },
    { label: 'Starting with', value: CodebookService.SearchStartingKeyword },
  ];
  searchMode: string = this.searchOptions[2].value;

  categories: CategoryListI[] = [];
  categoryFilter: string[];

  codebookData: Statement[] = [];

  filterText: string;
  searchText: string;

  searchSelected = false;
  searchAgain = false;

  searchOwnCodes = false;

  searchForTitle = true;

  searching = false;

  validWeights: number[] = [];
  showValidWeightTooltip: boolean = false;

  private customAudienceUserNode: SelectionTreeFlatNode;
  private customMediaUserNode: SelectionTreeFlatNode;

  constructor(
    private documentService: DocumentService,
    private addToMultipleAction: AddToMultipleAction,
    private separateOperatorAction: SeparateOperatorAction,
    private separateCountAction: SeparateCountAction,
    private targetService: TargetService,
    private codebookService: CodebookService,
    private injector: Injector,
    private codebookSelectionService: CodebookSelectionService,
    private messageService: TupUserMessageService
  ) {}

  ngOnInit(): void {
    this.loadCategories();
    this.listenToSelectedNodesChanges();
    this.listenToDocumentStateChanges();
    this.listenToSurveyDataWeightsChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const shouldReloadCodebook =
      changes.survey &&
      !changes.survey.firstChange &&
      (changes.survey.currentValue.code !== changes.survey.previousValue.code ||
        changes.survey.currentValue.authorizationGroup !==
          changes.survey.previousValue.authorizationGroup);
    if (shouldReloadCodebook) {
      this.resetAll();
      this.loadCategories();
    }
  }

  onUnselectAll(): void {
    this.codebookSelectionService.unselectNodes();
    if (this.inCodeBuilder) {
      this.codebookTree.unselectAll();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectionChanged(e: any): void {
    if (e.id === 'mat-option-cat-0') {
      // Click on All categories => keep selected and remove all the others
      this.categoryFilter = [''];
    } else {
      if (this.categoryFilter.length === 0) {
        this.categoryFilter = [''];
      } else if (this.categoryFilter[0] === '') {
        // need to clone to force refresh
        const clone = [...this.categoryFilter];
        clone.shift();
        this.categoryFilter = clone;
      }
    }
  }

  openedChange(e: boolean) {
    if (!e) {
      this.filterTopCategories();
    }
  }

  public onExpandAllClick() {
    const selectedNodes = this.getSelectedNodes();
    this.codebookFetchAndExpandNodes(selectedNodes);
  }

  public onAddToMultipleClick(type: TargetType): void {
    const selectedNodes = this.getSelectedNodes();
    const customNodes = selectedNodes.filter((node) => node.isCustomCoding);
    if (customNodes.length) {
      this.handleCustomNodes(customNodes).then((children: Statement[]) => {
        selectedNodes.unshift(...children);
        this.addToMultiple(selectedNodes, type);
      });
    } else {
      this.addToMultiple(selectedNodes, type);
    }
  }

  public onSeparateClick(type: TargetType): void {
    const selectedNodes = this.getSelectedNodes();
    const customNodes = selectedNodes.filter((node) => node.isCustomCoding);
    if (customNodes.length) {
      this.handleCustomNodes(customNodes).then((children: Statement[]) => {
        selectedNodes.unshift(...children);
        this.addSelectedNodesToDocument(
          selectedNodes,
          type,
          LoadingSource.fromButton
        );
      });
    } else {
      this.addSelectedNodesToDocument(
        selectedNodes,
        type,
        LoadingSource.fromButton
      );
    }
  }

  public onCombineActionClick(option: {
    actionItem: CombineActionItem;
    type: TargetType;
  }) {
    const selectedNodes = this.getSelectedNodes();
    const customNodes = selectedNodes.filter((node) => node.isCustomCoding);
    if (customNodes.length) {
      this.handleCustomNodes(customNodes).then((children: Statement[]) => {
        selectedNodes.unshift(...children);
        this.combineAction(selectedNodes, option);
      });
    } else {
      this.combineAction(selectedNodes, option);
    }
  }

  public onSeparateCountClick(type: TargetType): void {
    const selectedNodes = this.getSelectedNodes();
    const customNodes = selectedNodes.filter((node) => node.isCustomCoding);
    if (customNodes.length) {
      this.handleCustomNodes(customNodes).then((children: Statement[]) => {
        selectedNodes.unshift(...children);
        this.handleSeparateCountAction(selectedNodes, type);
      });
    } else {
      this.handleSeparateCountAction(selectedNodes, type);
    }
  }

  private combineAction(
    selectedNodes: Statement[],
    option: {
      actionItem: CombineActionItem;
      type: TargetType;
    }
  ): void {
    const { actionItem, type } = option;

    this.codebookSelectionService.setLoadingChildrenNodes(
      true,
      LoadingSource.fromMenu
    );
    this.loadChildren(selectedNodes).then((result: Statement[]) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.injector.get(actionItem.action).invoke({
        targetType: type,
        selectedTargets: this.targetService.convertStatementsToTargets(result),
        targetItem: undefined,
        actionItem,
      });
    });
  }

  private handleSeparateCountAction(
    nodes: Statement[],
    type: TargetType
  ): void {
    this.codebookSelectionService.setLoadingChildrenNodes(
      true,
      LoadingSource.fromMenu
    );
    this.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.separateCountAction.invoke({
        targetType: type,
        selectedTargets: this.targetService.convertStatementsToTargets(result),
      });
    });
  }

  private addToMultiple(selectedNodes: Statement[], type: TargetType) {
    this.codebookSelectionService.setLoadingChildrenNodes(
      true,
      LoadingSource.fromMenu
    );
    this.loadChildren(selectedNodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.documentService.documentState$
        .pipe(first())
        .subscribe(({ columns, rows, tables }: DocumentDataState) => {
          this.addToMultipleAction.invoke({
            targetType: type,
            selectedTargets:
              this.targetService.convertStatementsToTargets(result),
            selectableTargets:
              type === TargetType.tables
                ? tables
                : type === TargetType.columns
                ? columns
                : rows,
          });
        });
    });
  }

  async handleCustomNodes(customNodes: Statement[]): Promise<Statement[]> {
    return new Promise<Statement[]>((resolve) => {
      const customNodeTargets =
        this.targetService.convertStatementsToTargets(customNodes);
      if (customNodeTargets.length === 0) {
        this.codebookService
          .getOwnCodesChildren(this.survey.code, customNodes[0].path)
          .subscribe((children: Statement[]) => {
            return resolve(children);
          });
      } else {
        return resolve([]);
      }
    });
  }

  private addSelectedNodesToDocument(
    nodes: Statement[],
    type: TargetType,
    origin: LoadingSource
  ): void {
    this.codebookSelectionService.setLoadingChildrenNodes(true, origin);
    this.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.separateOperatorAction.invoke({
        targetType: type,
        selectedTargets: this.targetService.convertStatementsToTargets(result),
      });
    });
  }

  private filterTopCategories() {
    this.codebookTree.data.forEach((data) => {
      let visible = false;
      if (
        this.categoryFilter.length > 0 &&
        (this.categoryFilter[0] === '' ||
          this.categoryFilter.includes(data.description))
      ) {
        visible = true;
      }
      this.filterTopCategory(data, visible);
    });

    this.codebookTree.refresh();
  }

  private filterTopCategory(data: Statement, visible: boolean): boolean {
    data.children?.forEach((child) => {
      this.filterTopCategory(child, visible);
    });
    data.hiddenCategory = !visible;
    if (!visible) {
      data.selected = undefined;
    }
    return visible;
  }

  codebookFetchAndExpandNodes(nodes: Statement[]) {
    const nonCustomNodes = nodes.filter((node) => !node.isCustomCoding);
    if (nonCustomNodes.length) {
      this.loadChildren(nonCustomNodes).then((resultNodes) => {
        this.codebookTree.refresh();
        resultNodes.forEach((expandedNode) => {
          this.codebookTree.expandNode(expandedNode);
        });
      });
    }

    const customNodes = nodes.filter((node) => node.isCustomCoding);
    if (customNodes.length) {
      this.codebookTree.expandNode(customNodes[0]);
    }
  }

  expandNode(node: Statement) {
    this.codebookFetchAndExpandNodes([node]);
  }

  getSelectedNodes() {
    return this.codebookTree.getSelectedNodes();
  }

  unselectAllNodesFromCodebook() {
    setTimeout(() => {
      this.codebookTree.unselectAll();
    }, 10);
  }

  unselectAllNodes() {
    this.unselectAllNodesFromCodebook();
  }

  async loadChildren(
    nodes: Statement[],
    noReturnValue: boolean = false
  ): Promise<Statement[]> {
    return new Promise<Statement[]>((resolve) => {
      const missingNodes = StatementFactory.getMissingNodes(nodes);

      if (missingNodes.length === 0) {
        return resolve(nodes);
      }

      const filterCategories: string[] = [];
      missingNodes.forEach((missingNode) => {
        if (missingNode.isCustomCoding) {
          return;
        }
        filterCategories.push(missingNode.path);
        // Load data on the codebook
        const codebookNode: SelectionTreeFlatNode =
          this.codebookTree.nestedNodeMap.get(missingNode.path);
        if (codebookNode) {
          codebookNode.isLoading = true;
        }
      });

      this.codebookService
        .getAllChildren(this.survey, filterCategories)
        .subscribe(
          (data: CodebookSearchResponse) => {
            missingNodes.forEach((missingNode: Statement) => {
              const codebookNode: SelectionTreeFlatNode =
                this.codebookTree.nestedNodeMap.get(missingNode.path);
              const resultData = StatementFactory.getNodeFromTree(
                missingNode,
                data.tree.children
              );
              if (!resultData) {
                if (codebookNode) {
                  codebookNode.isLoading = false;
                }
              } else {
                this._updateData(resultData.children, codebookNode);

                if (!noReturnValue) {
                  const resultNode = StatementFactory.getNodeFromTree(
                    resultData,
                    nodes
                  );
                  if (resultNode) {
                    resultNode.children =
                      StatementFactory.cloneData(resultData).children;
                  }
                }
              }
            });
            return resolve(noReturnValue ? undefined : nodes);
          },
          (error) => {
            missingNodes.forEach((missingNode) => {
              const codebookNode: SelectionTreeFlatNode =
                this.codebookTree.nestedNodeMap.get(missingNode.path);
              if (codebookNode) {
                codebookNode.isLoading = false;
              }
            });
            return resolve([]);
          }
        );
    });
  }

  public refreshCustomAudienceOrMedia(type: SaveOwnCodesType): void {
    const customUserNode =
      type === SaveOwnCodesType.audience
        ? this.customAudienceUserNode
        : this.customMediaUserNode;
    this.onCodebookLoadData(customUserNode);
  }

  public updateCustomAudienceOrMediaNode(
    node: SelectionTreeFlatNode,
    statement: Statement
  ): void {
    node.data.description = statement.description;
    node.data.path = statement.path;
    node.data.customData = statement.customData;
    this.refreshCodebookTree();
  }

  onCodebookLoadData(node: SelectionTreeFlatNode) {
    this._loadData(node);
    this.cacheCustomUserNode(node);
  }

  private _loadData(codebookNode: SelectionTreeFlatNode) {
    if (!codebookNode) {
      return;
    }

    codebookNode.isLoading = true;

    if (codebookNode.data.isCustomCoding) {
      this.codebookService
        .getOwnCodesChildren(this.survey.code, codebookNode.data.path)
        .subscribe(
          (children: Statement[]) => {
            this._updateData(children, codebookNode);
          },
          (error) => {
            codebookNode.isLoading = false;
          }
        );
      return;
    }

    this.codebookService
      .getChildren(this.survey, codebookNode.data.path)
      .subscribe(
        (data) => {
          if (data.success === false) {
            this.messageService.openMessageDialog(data.message, 'Error');
            return;
          }

          this._updateData(data.children, codebookNode);
        },
        (error) => {
          codebookNode.isLoading = false;
        }
      );
  }

  private _updateData(data: Statement[], codebookNode: SelectionTreeFlatNode) {
    if (!codebookNode) {
      return;
    }

    codebookNode.data.children = data;

    if (codebookNode.data.selected) {
      StatementFactory.selectChildren(codebookNode.data);
    }

    this.refreshCodebookTree();
    codebookNode.isLoading = false;
  }

  private cacheCustomUserNode(node: SelectionTreeFlatNode): void {
    if (!node || !node.data.canRefresh) {
      return;
    }
    if (node.data.path.startsWith('Custom Audiences')) {
      this.customAudienceUserNode = node;
    } else {
      this.customMediaUserNode = node;
    }
  }

  onCodebookTreeContextMenu(data: ContextMenuData) {
    this.codebookContextMenu.emit(data);
  }

  private filterCategories(node: Statement, filters: string[]): boolean {
    if (node.hiddenCategory || node.hiddenFilter) {
      return false;
    }

    const treeNode = this.codebookTree.nestedNodeMap.get(node.path);
    if (!treeNode) {
      return false;
    }

    if (
      node.children.length === 0 ||
      !this.codebookTree.treeControl.isExpanded(treeNode)
    ) {
      // end of branch
      for (const filter of filters) {
        if (node.path.toLowerCase().includes(filter.trim())) {
          return true;
        }
      }
      node.hiddenFilter = true;
      node.selected = false;
      return false;
    }

    // Check children
    let visible = false;
    node.children.forEach((child) => {
      if (this.filterCategories(child, filters)) {
        visible = true;
      }
    });
    if (visible) {
      return true;
    }

    node.hiddenFilter = true;
    node.selected = false;
    return false;
  }

  onFilter() {
    if (!this.filterText) {
      return;
    }

    if (!this.cache) {
      this.cache = new CurrentCache();
    }
    this.cache.filters.add(this.filterText);

    this.codebookTree.searching = true;

    setTimeout(() => {
      // async call
      const filters: string[] = this.filterText.toLowerCase().split(';');
      this.codebookTree.data.forEach((node) => {
        this.filterCategories(node, filters);
      });
      this.codebookTree.refresh();
      this.codebookTree.searching = false;
    }, 10);
  }

  private getActiveFilters(): Set<string> {
    if (
      this.categoryFilter.length > 0 &&
      this.categoryFilter[0] === '' &&
      !this.cache
    ) {
      // no filter
      return undefined;
    }
    const filters = new Set<string>();
    const tree: Statement = {
      description: 'root',
      path: '',
      children: this.codebookTree.data,
    };
    this.getChildActiveFilters(tree, filters);
    return filters;
  }

  private getChildActiveFilters(
    node: Statement,
    filters: Set<string>
  ): boolean {
    if (node.hiddenFilter || node.hiddenCategory) {
      return false;
    }

    if (node.children.length === 0) {
      filters.add(node.path);
      return true;
    }

    node.children.forEach((child) => {
      if (this.getChildActiveFilters(child, filters)) {
        // already added some children
        return true;
      }
    });

    return false;
  }

  private filterSelected(node: Statement): boolean {
    if (node.children.length === 0) {
      if (!node.selected) {
        node.hiddenFilter = true;
      }
      return node.selected;
    }

    let selected: boolean;
    node.children.forEach((child) => {
      if (this.filterSelected(child)) {
        selected = true;
      }
    });

    if (!selected) {
      node.hiddenFilter = true;
    }
    return selected;
  }

  private setHiddenFilter(
    value: boolean,
    forceUnselect: boolean = false,
    nodes?: Statement[]
  ) {
    let refresh = false;
    if (!nodes) {
      refresh = true;
      nodes = this.codebookTree.data;
    }
    nodes.forEach((node) => {
      node.hiddenFilter = value ? true : undefined;
      if (node.hiddenFilter || forceUnselect) {
        node.selected = undefined;
      }
      this.setHiddenFilter(value, forceUnselect, node.children);
    });
    if (refresh) {
      this.codebookTree.refresh();
    }
  }

  private hideParentIfNoVisibleChild(node: Statement): boolean {
    const parentNode: SelectionTreeFlatNode =
      this.codebookTree.nestedNodeMap.get(
        StatementFactory.getParent(node.path)
      );
    if (!parentNode) {
      return;
    }
    let nbVisibleChildren = 0;
    parentNode.data.children.forEach((child) => {
      if (child.hiddenCategory !== true && child.hiddenFilter !== true) {
        nbVisibleChildren++;
      }
    });
    if (nbVisibleChildren === 0) {
      parentNode.data.hiddenFilter = true;
      this.hideParentIfNoVisibleChild(parentNode.data);
    }
  }

  private resetFilter(forceUnselect: boolean = false) {
    if (this.cache?.searchs.size > 0) {
      this.codebookTree.reset();
      this.resetOwnCodesCategories();
    } else {
      this.setHiddenFilter(false, forceUnselect);
      this.codebookTree.treeControl.collapseAll();
    }
    this.cache = undefined;
  }

  private addSearchResultToCodebook(tree: Statement, result: Statement) {
    if (!result || (result.children.length === 0 && !result.coding)) {
      // no result
      tree.hiddenFilter = true;
      tree.selected = undefined;
      tree.children = [];

      this.hideParentIfNoVisibleChild(tree);
      return;
    }

    if (!tree.coding) {
      tree.children = result.children;
      return;
    }

    // coding => on a leaf: refresh coding
    tree.coding = result.coding;
  }

  onSearch() {
    if (!this.searchText) {
      return;
    }

    if (this.searchAgain) {
      this.resetFilter();
      this.filterText = undefined;
    }

    if (this.searchSelected) {
      // Add selection to filter
      if (!this.cache) {
        this.cache = new CurrentCache();
      }
      this.cache.searchSelected = true;
      this.codebookTree.data.forEach((node) => {
        this.filterSelected(node);
      });
      this.codebookTree.refresh();
    }

    const filters: Set<string> = this.getActiveFilters();

    if (!this.cache) {
      this.cache = new CurrentCache();
    }
    this.cache.searchs.add(this.searchText);

    if (filters && filters.size === 0) {
      return;
    }

    let filterCategories: string | string[];

    if (!filters) {
      filterCategories = '';
    } else {
      filterCategories = Array.from(filters);
    }

    const apiSearch = this.searchText.trim();

    this.codebookTree.searching = true;
    this.codebookService
      .search(
        apiSearch,
        filterCategories,
        this.survey,
        this.searchMode,
        !this.searchForTitle
      )
      .subscribe(
        (data) => {
          this.codebookTree.data.forEach((node) => {
            let found: Statement;
            for (const result of data.tree.children) {
              if (result.path === node.path) {
                found = result;
                break;
              }
            }
            this.addSearchResultToCodebook(node, found);
            if (node.selected) {
              StatementFactory.selectChildren(node);
            }
            this.codebookTree.refresh();
            this.codebookTree.expandNode(node, false);
          });

          this.codebookTree.searching = false;
        },
        (error) => {
          this.codebookTree.searching = false;
        }
      );
  }

  onReset() {
    this.resetAll();
  }

  refreshCodebookTree(): void {
    this.codebookTree.refresh();
  }

  private getOwnCodesCategories(): Statement[] {
    return [
      {
        description: 'Custom audiences',
        path: 'Custom Audiences',
        children: [
          {
            description: 'Own audiences',
            path: 'Custom Audiences|Own Audiences',
            children: [],
            isCustomCoding: true,
            canRefresh: true,
            shouldDisableAdding: true,
          },
        ],
        isCustomCoding: true,
        shouldHighlight: true,
        shouldDisableAdding: true,
      },
      // todo: this is temporarily hidden due to further discussion/specs required
      /*{
        description: 'Custom Media',
        path: 'Custom Media',
        children: [
          {
            description: 'Own Media',
            path: 'Custom Media|Own Media',
            children: [],
            isCustomCoding: true,
            canRefresh: true,
            shouldDisableAdding: true,
          },
        ],
        isCustomCoding: true,
        shouldHighlight: true,
        shouldDisableAdding: true,
      },*/
    ];
  }

  private resetOwnCodesCategories(): void {
    const ownCodesCategories = this.getOwnCodesCategories();
    this.codebookData[0] = ownCodesCategories[0];
    // this.codebookData[1] = ownCodesCategories[1];
  }

  private resetAll(): void {
    this.resetFilter(true);
    this.filterText = undefined;
    this.searchText = undefined;
  }

  private loadCategories(): void {
    this.searching = true;
    this.codebookService.getCategories(this.survey).subscribe(
      (result) => {
        if (!result.success) {
          this.categories = [];
          this.messageService.openMessageDialog(result.message, 'Error');
        } else {
          this.categories = [
            {
              name: 'Custom Audiences',
              value: 'Custom Audiences',
            },
            /*{
              name: 'Custom Media',
              value: 'Custom Media',
            },*/
            ...result.categories.map((cat) => ({
              name: cat.category,
              value: cat.category,
            })),
          ];

          // Convert categories to tree
          this.codebookData = [
            ...this.getOwnCodesCategories(),
            ...result.categories.map((cat) => ({
              description: cat.category,
              path: cat.category,
              pos: cat.minPos,
              children: [],
            })),
          ];
        }

        this.categories.unshift({ name: 'All categories', value: '' });
        this.categoryFilter = [this.categories[0].value];
        this.searching = false;
      },
      (error) => {
        this.categories = [];
        this.categories.unshift({ name: 'Categories', value: '' });
        this.categoryFilter = [this.categories[0].value];
        this.searching = false;
      }
    );
  }
  private listenToSelectedNodesChanges(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        this.selectedNodes = selectedNodes;
      });
  }
  private listenToDocumentStateChanges() {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: DocumentDataState) => {
        this.validWeights = this.documentService.getValidWeightsFromDocument();
        this.showValidWeightTooltip = !!(
          state.columns.length + state.rows.length
        );
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private listenToSurveyDataWeightsChanges(): void {
    this.documentService.surveyDataWeights$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((weights: SurveyMetaDataWeights[]) => {
        this.weightDescriptions = weights.map((weights) => weights.description);
      });
  }
}
