import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CreateTargetCallback,
  Operator,
  Statement,
  StatementsToTargetsCallback,
  Survey,
  Target,
  UpdateTitleAndCodingCallback,
  autoGroupTargetsCallback,
  groupTargetsCallback,
  hasMoreThanMaxCombinedTargetsCallback,
} from '../../models';
import { cloneDeep } from 'lodash';
import {
  CodebookSelectionService,
  LoadingNode,
  LoadingSource,
} from 'src/app/services/codebook-selection.service';
import { Subject, Subscription } from 'rxjs';
import { DropDataContext } from '../../pages';
import { CodebookNavigationComponent } from 'src/app/components';
import { DocumentService, TargetService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { CombinedCodingThresholdService } from 'src/app/services/combined-coding-threshold.service';
import { VisualCodeBuilderComponent } from '@telmar-global/tup-audience-groups';

@Component({
  templateUrl: './visual-code-builder-dialog.component.html',
  styleUrls: ['./visual-code-builder-dialog.component.scss'],
})
export class VisualCodeBuilderDialogComponent implements OnInit, OnDestroy {
  public selectedSurvey: Survey;
  public target: Target;
  public resultTarget: Target = null;
  public targetUserTitle: string;

  public selectedNodes: Statement[] = [];
  public isLoadingSelectedNodes = false;
  public isLoadingFromDrag = false;

  public createTarget: CreateTargetCallback;
  public convertStatementsToTargets: StatementsToTargetsCallback;
  public updateTitleAndCoding: UpdateTitleAndCodingCallback;
  public groupTargets: groupTargetsCallback;
  public groupTargetsWithAutoOperator: autoGroupTargetsCallback;
  public hasMoreThanMaxCombinedTargets: hasMoreThanMaxCombinedTargetsCallback;
  public showMaxLimitAlert: () => void;

  public dropNode = new EventEmitter<DropDataContext<Operator>>();
  public isLoadingNodes = true;
  private loadingSelectedNodes$: Subscription;
  @ViewChild('codebookNavigation')
  private codebookNavigation: CodebookNavigationComponent;

  @ViewChild('visualCodeBuilder')
  private visualCodeBuilder: VisualCodeBuilderComponent;
  private documentService: DocumentService;
  public targetService: TargetService;
  public combinedCodingThresholdService: CombinedCodingThresholdService;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VisualCodeBuilderDialogComponent>,
    private codebookSelectService: CodebookSelectionService
  ) {
    this.target = cloneDeep(this.data.target);
    this.targetUserTitle = this.target.ownTitle;
    this.selectedSurvey = this.data.survey;
    this.documentService = this.data.documentService;
    this.targetService = this.data.targetService;
    this.combinedCodingThresholdService =
      this.data.combinedCodingThresholdService;

    this.createTarget = (options?: Partial<Target>) =>
      this.targetService.createTarget(options);
    this.convertStatementsToTargets = (statements: Statement[]) =>
      this.targetService.convertStatementsToTargets(statements);
    this.updateTitleAndCoding = (target: Target) =>
      this.targetService.updateTitleAndCoding(target);
    this.groupTargets = (
      targets: Target[],
      operator: Operator,
      forceGrouping: boolean
    ) => this.targetService.groupTargets(targets, operator, forceGrouping);
    this.groupTargetsWithAutoOperator = (targets: Target[]) =>
      this.targetService.groupTargetsWithAutoOperator(targets);
    this.hasMoreThanMaxCombinedTargets = (targets: Target[]) =>
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        targets
      );
    this.showMaxLimitAlert = () =>
      this.combinedCodingThresholdService.showMaxLimitAlert();
  }

  ngOnInit(): void {
    this.loadingSelectedNodes$ =
      this.codebookSelectService.loadingSelectedNodes$.subscribe((loading) => {
        this.isLoadingNodes = loading.inProgress;
      });
    this.listenToSelectedNodesChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoadingSelectedNodes = false;
    this.loadingSelectedNodes$.unsubscribe();
  }

  private listenToSelectedNodesChanges(): void {
    this.codebookSelectService.loadingSelectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isLoading: LoadingNode) => {
        this.isLoadingSelectedNodes = isLoading.inProgress;
        this.isLoadingFromDrag = isLoading.origin === LoadingSource.fromDrag;
      });

    this.codebookSelectService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        this.selectedNodes = selectedNodes;
      });
  }

  public onTargetChange(target: Target): void {
    this.resultTarget = target;
    this.targetUserTitle = this.resultTarget.ownTitle.trim();
  }

  public onDropNode(event: DropDataContext<Operator>): void {
    if (this.isLoadingNodes) {
      return;
    }

    this.codebookNavigation.unselectAllNodes();

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(event.selectedTargets)
    ) {
      this.visualCodeBuilder.unsetDrag();
      return;
    }

    this.dropNode.emit(event);
  }

  onButtonClick(buttonOption: boolean): void {
    this.codebookSelectService.unselectNodes();
    if (this.isLoadingNodes) {
      return;
    }
    this.dialogRef.close(buttonOption ? this.resultTarget : null);
  }

  public close(): void {
    this.codebookSelectService.unselectNodes();
    if (this.isLoadingNodes) {
      return;
    }
    this.dialogRef.close(null);
  }

  onUnselectNodes() {
    this.codebookSelectService.unselectNodes();
  }
}
