<div
  fxLayout="row"
  fxLayoutGap="10px"
  fxLayoutAlign="start center"
  class="tablebase-select--compact"
  matTooltip="Tablebase"
>
  <mat-form-field
    appearance="outline"
    tupMatFormField
    fxFlex
    class="input-form-field"
    [class.is-disabled]="isLoading"
  >
    <mat-label>Tablebase</mat-label>
    <mat-select
      #select
      [formControl]="tablebaseControl"
      (openedChange)="onSelectPanelOpenedChanged()"
      panelClass="tablebase-select-panel"
    >
      <div
        class="tablebase-options-container"
        fxFlex
        fxLayout="column"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          class="input-form-field-without-label filter-field"
        >
          <mat-icon
            matPrefix
            class="tiny-icon"
            >search</mat-icon
          >
          <input
            placeholder="Search"
            matInput
            [formControl]="tablebaseFilterControl"
            autocomplete="off"
          />
          <button
            matSuffix
            mat-icon-button
            (click)="resetFilterText()"
            matTooltip="Reset"
          >
            <mat-icon class="tiny-icon">clear</mat-icon>
          </button>
        </mat-form-field>

        <div
          fxFlex
          fxLayout="column"
          class="tablebase-options"
        >
          <div
            *ngFor="let targetItem of filteredTablebases | async"
            class="tablebase-option-wrapper"
          >
            <mat-option [value]="targetItem.target">
              {{
                targetItem.target
                  | targetTitle
                    : targetItem.target.activeTitleMode
                    : targetItem.target.titleLevels
                  | truncate: 22
              }}
            </mat-option>
            <div
              *ngIf="!isReadonly"
              class="tablebase-icon-container"
            >
              <mat-icon
                class="material-icons-outlined tiny-icon tablebase-icon"
                (click)="onEditTablebaseClicked(targetItem)"
                >edit</mat-icon
              >
              <mat-icon
                *ngIf="targetItem.index !== noneDeletableIndex"
                class="tiny-icon tablebase-icon tablebase-icon--delete"
                (click)="onRemoveTablebaseClicked(targetItem)"
                >clear</mat-icon
              >
            </div>
          </div>
          <div
            *ngIf="!isReadonly"
            (click)="onNewTablebaseClicked()"
            class="tablebase-option-wrapper new-tablebase-option"
          >
            <div class="mat-option">
              <span class="mat-option-text">Add new tablebase</span>
            </div>
            <div class="tablebase-icon-container">
              <mat-icon class="tiny-icon tablebase-icon">add</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </mat-select>
  </mat-form-field>
</div>
