<div
  class="view-container"
  fxFlex
  fxLayout="column"
  #viewContainer
  *ngIf="!isLoading && !isInitialising"
  [ngClass]="targetMode"
  [class.is-trending]="surveys.length > 1"
>
  <div
    #chartSettingsRow
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="
      chartList.length > 0 ||
      selectedDataViewModeInSingleTarget === dataViewModeType.dynamic
    "
  >
    <div
      *ngIf="targetMode === chartTargetModeType.single"
      class="data-view-toggle-button-control"
    >
      <mat-button-toggle-group
        [(ngModel)]="selectedDataViewModeInSingleTarget"
        (change)="onDataViewModeInSingleTargetChanged()"
      >
        <mat-button-toggle
          *ngFor="let mode of dataViewModes"
          [attr.data-tracking]="'single-target-' + mode.title"
          [disableRipple]="true"
          [value]="mode.value"
          [matTooltip]="mode.tooltip"
        >
          {{ mode.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div
      *ngIf="targetMode === chartTargetModeType.combined"
      class="data-view-toggle-button-control"
    >
      <mat-button-toggle-group
        [(ngModel)]="selectedDataViewModeInCombinedTargets"
      >
        <mat-button-toggle
          [attr.data-tracking]="'combined-targets-' + mode.title"
          *ngFor="let mode of dataViewModes"
          [disableRipple]="true"
          [value]="mode.value"
          [matTooltip]="mode.tooltip"
          [disabled]="mode.value === dataViewModeType.dynamic"
        >
          {{ mode.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div *ngIf="surveys.length > 1">
      <mat-button-toggle-group
        *ngIf="targetMode === chartTargetModeType.single"
        [(ngModel)]="selectedSurveysInSingleTarget"
        (change)="onSingleTargetChanged()"
        multiple
      >
        <mat-button-toggle
          *ngFor="let survey of surveys"
          [disableRipple]="true"
          [value]="survey.code"
          [disabled]="
            selectedSurveysInSingleTarget.length < 2 &&
            selectedSurveysInSingleTarget.includes(survey.code)
          "
        >
          {{ survey.code }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group
        *ngIf="targetMode === chartTargetModeType.combined"
        [(ngModel)]="selectedSurveysInCombinedTargets"
        (change)="onSelectedSurveysInCombinedTargetChanged()"
        multiple
      >
        <mat-button-toggle
          *ngFor="let survey of surveys"
          [disableRipple]="true"
          [value]="survey.code"
          [disabled]="
            selectedSurveysInCombinedTargets.length < 2 &&
            selectedSurveysInCombinedTargets.includes(survey.code)
          "
        >
          {{ survey.code }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      class="global-chart-control"
    >
      <div>
        <span class="chart-number">
          Showing:
          <b>{{ chartNumber }} chart{{ chartNumber > 1 ? "s" : "" }}</b>
        </span>
      </div>

      <mat-chip-list
        class="global-filters"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <ng-container
          *ngFor="let filter of chartSettings.filters; let i = index"
        >
          <mat-chip
            [disableRipple]="false"
            matTooltip="Update global filter"
            (removed)="onFilterRemove(filter)"
            (click)="openChartFilter()"
            *ngIf="
              filter.operator !== chartFilterOperator.none &&
              filter.dataItem !== dataItemType.none
            "
            class="global-filter-item"
          >
            {{ filter | formatChartFilter }}
            <mat-icon
              matChipRemove
              matTooltip="Remove filter"
            >
              cancel
            </mat-icon>
          </mat-chip>
        </ng-container>
      </mat-chip-list>

      <button
        data-tracking="global-chart-filter"
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        matTooltip="Chart filters"
        (click)="openChartFilter()"
      >
        <mat-icon> filter_list</mat-icon>
      </button>
      <button
        data-tracking="global-chart-settings"
        class="chart-global-settings-button"
        color="primary"
        tupMatButton
        mat-stroked-button
        appearance="tiny"
        matTooltip="Global settings"
        (click)="openChartSettings()"
      >
        <mat-icon class="tiny-icon"> settings</mat-icon>
        Chart settings
      </button>
    </div>
  </div>

  <div
    *ngIf="
      (chartList.length > 0 ||
        selectedDataViewModeInSingleTarget === dataViewModeType.dynamic) &&
      (targetMode === chartTargetModeType.single ||
        targetMode === chartTargetModeType.combined)
    "
    class="additional-chart-setting-row"
    #additionalChartSettingsRow
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="start start"
  >
    <ng-container
      *ngIf="selectedDataViewModeInSingleTarget === dataViewModeType.default"
    >
      <div
        *ngIf="targetMode === chartTargetModeType.single"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact"
        matTooltip="Target"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Target</mat-label>
          <mat-select
            [(ngModel)]="selectedTargetOption"
            (ngModelChange)="onSingleTargetChanged()"
          >
            <mat-option value="all"> All</mat-option>
            <mat-option
              *ngFor="let target of defaultTargets"
              [value]="target"
            >
              {{
                target | targetTitle: target.activeTitleMode:target.titleLevels
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container
      *ngIf="selectedDataViewModeInSingleTarget === dataViewModeType.dynamic"
    >
      <div
        *ngIf="targetMode === chartTargetModeType.single"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Custom audiences</mat-label>
          <mat-select
            [(ngModel)]="selectedCustomAudiencesOption"
            (ngModelChange)="onCustomAudienceTargetChanged()"
          >
            <mat-option value="none"> None</mat-option>
            <mat-option
              *ngFor="let target of customAudienceTargets"
              [value]="target"
            >
              {{
                target | targetTitle: target.activeTitleMode:target.titleLevels
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="targetMode === chartTargetModeType.single">
        <div
          *ngFor="let groupName of defaultTargetGroupNames"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="target-control--compact"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field"
          >
            <mat-label>{{ groupName }}</mat-label>
            <mat-select
              [(ngModel)]="selectedTargetGroupTarget[groupName]"
              (ngModelChange)="onGroupTargetChanged()"
            >
              <mat-option value="none"> None</mat-option>
              <mat-option
                *ngFor="let target of defaultTargetGroups[groupName]"
                [value]="target"
              >
                {{
                  target
                    | targetTitle: target.activeTitleMode:target.titleLevels
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="chart-container"
    [class.is-additional-settings-row-expanded]="
      isAdditionalChartSettingsRowExpanded
    "
    [style.height.px]="chartContainerHeight"
  >
    <div
      *ngIf="targetMode !== chartTargetModeType.single"
      class="chart-scrollbar"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="loadMoreCharts()"
      fxLayout="row wrap"
      #chartScrollbar
    >
      <chart
        fxFlex="auto"
        [isReadonly]="isReadonly"
        class="chart combined-chart"
        *ngFor="let chartData of chartList"
        [targetMode]="targetMode"
        [dataViewMode]="selectedDataViewModeInCombinedTargets"
        [targetColors]="targetColors"
        [chartData]="chartData"
        [chartSettings]="chartSettings"
        [dataItemKeys]="dataItemKeys"
        (colorChange)="refreshGlobalSettings()"
      ></chart>
      <tup-mat-card
        class="empty-copy"
        fxLayout="column"
        *ngIf="chartList.length === 0"
      >
        <span>
          No charts are available. Please add some columns and rows in the
          crosstab
        </span>
      </tup-mat-card>
    </div>

    <div
      *ngIf="targetMode === chartTargetModeType.single"
      class="chart-scrollbar"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="loadMoreCharts()"
      fxLayout="row wrap"
      #chartScrollbar
    >
      <chart
        class="chart"
        [isReadonly]="isReadonly"
        *ngFor="let chartData of chartList"
        [targetMode]="targetMode"
        [dataViewMode]="selectedDataViewModeInSingleTarget"
        [targetColors]="targetColors"
        [chartData]="chartData"
        [chartSettings]="chartSettings"
        [dataItemKeys]="dataItemKeys"
        (colorChange)="refreshGlobalSettings()"
        [ngClass]="{
          full: singleChartFullWidthStatus[
            chartData.target.id + '_' + chartData.title
          ]
        }"
        (fullWidthNeeded)="increaseWidthChartContainer($event, chartData)"
      ></chart>
      <tup-mat-card
        class="empty-copy"
        fxLayout="column"
        *ngIf="chartList.length === 0"
      >
        <span
          *ngIf="
            selectedDataViewModeInSingleTarget === dataViewModeType.default
          "
        >
          No charts are available. Please add some columns and rows in the
          crosstab
        </span>
        <span
          *ngIf="
            selectedDataViewModeInSingleTarget === dataViewModeType.dynamic
          "
        >
          It appears that no charts are available for viewing. <br />Please
          select your intended audience from the custom audiences dropdown list
          or create a custom audience from the data view if none are currently
          available.
        </span>
      </tup-mat-card>
    </div>
  </div>
</div>

<div
  class="spinner-container-overlay"
  *ngIf="isLoading || isInitialising || isLoadingDynamicData"
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
