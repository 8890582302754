import { Injectable } from '@angular/core';
import { DocumentService } from './document.service';
import {
  CHART_COLORS,
  CHART_TYPES,
  ChartSettings,
  ChartTargetMode,
  DataViewMode,
  DEFAULT_CHART_SETTINGS,
  GLOBAL_SETTINGS_KEY,
} from '../models/charts.model';
import { cloneDeep } from 'lodash';
import { DataItem, DataItemId, DataItemType, Survey, Target } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChartSettingsService {
  constructor(private documentService: DocumentService) {}

  public saveChartSettings(settings: ChartSettings): void {
    if (this.isGlobalChartSettings(settings)) {
      this.documentService.resetAllChartSettings(
        settings.targetMode,
        settings.dataViewMode,
        false
      );
    } else {
      this.updateGlobalSeriesColors(settings);
    }

    if (this.isNewChartSettings(settings)) {
      this.documentService.addChartSettings(settings);
    } else {
      this.documentService.updateChartSettings(settings);
    }
  }

  public getGlobalChartSettings(
    surveys: Survey[],
    dataItemKeys: Record<DataItemId, DataItem>,
    targetMode: ChartTargetMode,
    targets: Target[],
    dataViewMode: DataViewMode = DataViewMode.default
  ): ChartSettings {
    let globalChartSettings = this.documentService.findChartSettings(
      targetMode,
      GLOBAL_SETTINGS_KEY,
      GLOBAL_SETTINGS_KEY,
      dataViewMode
    );
    if (!globalChartSettings) {
      globalChartSettings = cloneDeep(DEFAULT_CHART_SETTINGS);
      globalChartSettings.targetMode = targetMode;
      globalChartSettings.dataViewMode = dataViewMode;
      globalChartSettings.seriesColor = this.getChartSeriesColours(
        targets,
        dataViewMode
      );
      this.documentService.addChartSettings(globalChartSettings, true);

      if (surveys.length > 1) {
        const multipleSurveysPrimaryDataItem =
          DataItemType.audience in dataItemKeys
            ? DataItemType.audience
            : DataItemType.filterMarketSize in dataItemKeys
            ? DataItemType.filterMarketSize
            : Number(Object.keys(dataItemKeys)[0]);
        globalChartSettings.primaryDataItem = multipleSurveysPrimaryDataItem;
        globalChartSettings.extraTableSettings = [
          multipleSurveysPrimaryDataItem,
        ];
        globalChartSettings.primaryChartType = CHART_TYPES[7].value;
      } else {
        const defaultPrimaryDataItem =
          DataItemType.percentRow in dataItemKeys
            ? DataItemType.percentRow
            : DataItemType.filterPercentRow in dataItemKeys
            ? DataItemType.filterPercentRow
            : Number(Object.keys(dataItemKeys)[0]);
        globalChartSettings.primaryDataItem = defaultPrimaryDataItem;
        globalChartSettings.extraTableSettings = [defaultPrimaryDataItem];
      }
    }

    return globalChartSettings;
  }

  public getChartSeriesColours(
    targets: Target[],
    viewType: DataViewMode = DataViewMode.default
  ): Record<string, string> {
    return viewType === DataViewMode.default
      ? targets.reduce(
          (acc, target: Target, index: number) => ({
            ...acc,
            [target.id]:
              CHART_COLORS[
                index >= CHART_COLORS.length
                  ? index % CHART_COLORS.length
                  : index
              ],
          }),
          {}
        )
      : {
          dynamic: CHART_COLORS[0],
        };
  }

  public isGlobalChartSettings(settings: ChartSettings): boolean {
    return (
      settings.associatedInsight === GLOBAL_SETTINGS_KEY &&
      settings.associatedTarget === GLOBAL_SETTINGS_KEY
    );
  }

  private isNewChartSettings(settings: ChartSettings): boolean {
    return !this.documentService.findChartSettings(
      settings.targetMode,
      settings.associatedTarget,
      settings.associatedInsight,
      settings.dataViewMode
    );
  }

  private updateGlobalSeriesColors(localSettings: ChartSettings): void {
    if (localSettings.dataViewMode === DataViewMode.dynamic) {
      return;
    }
    const chartSettings = this.documentService.getAllChartSettings(
      localSettings.targetMode,
      localSettings.dataViewMode
    );
    chartSettings.forEach((settings: ChartSettings) => {
      Object.keys(localSettings.seriesColor).forEach((targetKey: string) => {
        settings.seriesColor[targetKey] = localSettings.seriesColor[targetKey];
      });
    });
  }
}
