import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActionDefinition } from './ActionDefinition';
import { DialogService, DocumentService } from '../services';
import { DocumentDataState, TargetItem } from '../models';

export interface DeleteSeveralTargetsActionContext {
  targets: TargetItem[];
}

@Injectable({
  providedIn: 'root',
})
export class DeleteSeveralTargetsAction extends ActionDefinition<DeleteSeveralTargetsActionContext> {
  constructor(
    private documentService: DocumentService,
    private dialog: DialogService
  ) {
    super();
  }

  public invoke(): void {
    this.documentService.documentState$
      .pipe(first())
      .subscribe((state: DocumentDataState) =>
        this.dialog
          .manageReportItems(state)
          .afterClosed()
          .subscribe((targetItems: TargetItem[]) => {
            if (targetItems) {
              this.documentService.deleteDocumentData(targetItems, true);
            }
          })
      );
  }
}
