import { Component, OnDestroy, OnInit } from '@angular/core';
import Highcharts, { ExportingMimeTypeValue, SVGElement } from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import Fullscreen from 'highcharts/modules/full-screen';
import {
  CrosstabService,
  DataItemsService,
  DialogService,
  DocumentService,
  RequestLoadingService,
  TargetLoading,
} from '../../services';
import {
  CrossTabTableData,
  DEFAULT_SURVEY_COPYRIGHT,
  DataItem,
  DataItemType,
  DocumentDataState,
  DocumentViewType,
  ReportMode,
  Survey,
  SurveyTimeDocument,
  DataItemId,
} from '../../models';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { MappingOptions } from '../../dialogs';
import { PMapsService } from '../../services/p-maps.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNotEmpty } from '../../utils/pipeable-operators';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { Router } from '@angular/router';
import {
  ChartMappingOptionsData,
  ExtendedChart,
  ExtendedChartOptions,
  ExtendedOptions,
  PMapsPreferences,
  PMapsSeriesDataItem,
  PMapsSettings,
} from '../../models/p-maps.model';
import { isEqual } from 'lodash';

More(Highcharts);
Exporting(Highcharts);
Fullscreen(Highcharts);

@Component({
  selector: 'app-p-maps',
  templateUrl: './p-maps.component.html',
  styleUrls: ['./p-maps.component.scss'],
})
export class PMapsComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  public Highcharts: typeof Highcharts = Highcharts;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;
  public isReadonly = true;
  public isLoading = true;
  public chartOptions: ExtendedOptions;
  private maxX: number;
  private minX: number;
  private maxY: number;
  private minY: number;
  private offsetX: number;
  private offsetY: number;
  public canResetZoom = false;

  public surveys: Survey[];
  public selectedSurvey: Survey;

  private chartRef: ExtendedChart;
  private crosstabData: CrossTabTableData[];

  private surveyData: CrossTabTableData[];

  public mappingOptions: MappingOptions;

  private settings: PMapsSettings;

  public pMapsPreferences: PMapsPreferences;

  chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as ExtendedChart;
      }
    }, 0);
  };

  constructor(
    private router: Router,
    private pMapsService: PMapsService,
    private documentService: DocumentService,
    private crosstabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private requestLoadingService: RequestLoadingService,
    private dialogService: DialogService
  ) {
    this.isReadonly =
      this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
    this.listenToLoadingState();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public changeMappingOptions(): void {
    this.openMappingOptionsDialog(this.mappingOptions);
  }

  public openSettings(): void {
    this.dialogService
      .pMapsSettings(this.settings)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: PMapsSettings) => {
        if (!isEqual(this.settings, result)) {
          this.setChartSettings(result);
        }
      });
  }

  public onSelectedSurveyChanged(): void {
    this.prepareSelectedSurveyData(this.selectedSurvey);
    this.setMappingOptions(this.mappingOptions);
  }

  public exportTo(type: ExportingMimeTypeValue): void {
    this.chartRef.exportChart(
      {
        type,
        sourceWidth: 1280,
        scale: 3,
      },
      {}
    );
  }

  public openFullScreen(): void {
    this.chartRef.fullscreen.toggle();
  }

  public zoomOut(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min - this.offsetX,
      this.chartRef.xAxis[0].max + this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min - this.offsetY,
      this.chartRef.yAxis[0].max + this.offsetY
    );

    this.canResetZoom = true;
  }

  public zoomIn(): void {
    this.offStick();

    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min + this.offsetX,
      this.chartRef.xAxis[0].max - this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min + this.offsetY,
      this.chartRef.yAxis[0].max - this.offsetY
    );
    this.canResetZoom = true;
  }

  public moveLeft(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min - this.offsetX,
      this.chartRef.xAxis[0].max - this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveRight(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min + this.offsetX,
      this.chartRef.xAxis[0].max + this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveUp(): void {
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min + this.offsetY,
      this.chartRef.yAxis[0].max + this.offsetY
    );
    this.chartRef.xAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveDown(): void {
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min - this.offsetY,
      this.chartRef.yAxis[0].max - this.offsetY
    );
    this.chartRef.xAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public resetZoom(): void {
    this.chartRef.xAxis[0].setExtremes();
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = false;
  }

  private offStick(): void {
    this.chartRef.xAxis[0].options.startOnTick = false;
    this.chartRef.xAxis[0].options.endOnTick = false;
    this.chartRef.yAxis[0].options.startOnTick = false;
    this.chartRef.yAxis[0].options.endOnTick = false;
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) => targetLoading.target === 'crosstab'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  public changeData(): void {
    this.dialogService
      .changePMapsData(this.settings.data)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: PMapsSeriesDataItem[]) => {
        if (!isEqual(this.settings.data, result)) {
          this.setChartSettings({
            ...this.settings,
            data: result,
          });
        }
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.surveys = doc.content.surveys;
      });
    this.crosstabService.crossTabData$
      .pipe(takeUntil(this.unsubscribe), isNotEmpty())
      .subscribe((data: CrossTabTableData[]) => {
        if (data[0].isPlaceholder) {
          return;
        }
        this.crosstabData = data;
        this.initialiseChart();
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private initialiseChart(): void {
    this.pMapsPreferences = this.documentService.getPMapsPreferences();
    if (this.pMapsPreferences) {
      this.selectedSurvey = this.surveys.find(
        (survey: Survey) =>
          survey.code === this.pMapsPreferences.survey.code &&
          survey.authorizationGroup ===
            this.pMapsPreferences.survey.authorizationGroup
      );
      this.prepareSelectedSurveyData(this.selectedSurvey);
      this.mappingOptions = this.pMapsPreferences.mappingOptions;
      this.settings = this.pMapsPreferences.settings;
      this.setChartOptions(this.settings, false);
    } else {
      this.selectedSurvey = this.documentService.activeSurvey;
      this.prepareSelectedSurveyData(this.selectedSurvey);
      this.renderChartWithDefaultChartOptions();
    }
  }

  private prepareSelectedSurveyData(survey: Survey): void {
    this.surveyData = this.pMapsService.formatCrosstabDataForSingleSurvey(
      this.surveys,
      survey,
      this.crosstabData
    );
  }

  private renderChartWithDefaultChartOptions(): void {
    this.setMappingOptions(this.getDefaultChartOptions());
  }

  private getDefaultChartOptions(): MappingOptions {
    const dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.crossTab
    );
    let firstColumnTargetId;
    let secondColumnTargetId;
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns }: DocumentDataState) => {
        firstColumnTargetId = columns.length > 0 ? columns[0].id : 'totals';
        secondColumnTargetId =
          columns.length > 1 ? columns[1].id : firstColumnTargetId;
      });
    return {
      graphType: 'dataItem',
      variableToGraph:
        dataItems.find(
          (dataItem: DataItem) => dataItem.id === DataItemType.index
        )?.id ?? dataItems[0].id,
      xAxis: firstColumnTargetId,
      yAxis: secondColumnTargetId,
    };
  }

  private openMappingOptionsDialog(options?: MappingOptions): void {
    this.dialogService
      .mappingOptions(options)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: MappingOptions) => {
        if (!isEqual(result, options)) {
          this.setMappingOptions(result);
        }
      });
  }

  private setMappingOptions(options: MappingOptions): void {
    this.mappingOptions = options;
    const mappingOptionsData = this.pMapsService.formatChartMappingOptionsData(
      this.mappingOptions,
      this.surveyData
    );
    this.settings = this.composeChartSettings(mappingOptionsData);
    this.setChartOptions(this.settings);
  }

  private setChartSettings(settings: PMapsSettings): void {
    this.settings = settings;
    this.setChartOptions(this.settings);
  }

  private composeChartSettings(
    chartData: ChartMappingOptionsData
  ): PMapsSettings {
    const showPlotLines =
      this.mappingOptions.graphType === 'dataItem' &&
      (this.mappingOptions.variableToGraph as DataItemId) ===
        DataItemType.index;
    return {
      title: 'All respondents',
      xAxisLabel: chartData.xAxisLabel,
      yAxisLabel: chartData.yAxisLabel,
      showWeightedSymbols: true,
      showDataLabel: true,
      displayGridlines: true,
      displayQuadrantTitles: false,
      quadrant1: '',
      quadrant2: '',
      quadrant3: '',
      quadrant4: '',
      showPlotLines,
      data: chartData.data,
    };
  }

  private getXYRange(data): void {
    const xArray = data.map((d) => d.x);
    const yArray = data.map((d) => d.y);
    this.maxX = Math.max(...xArray);
    this.minX = Math.min(...xArray);

    this.maxY = Math.max(...yArray);
    this.minY = Math.min(...yArray);

    this.offsetX = (this.maxX - this.minX) / 10;
    this.offsetY = (this.maxY - this.minY) / 10;
  }

  private getCopyrightHTML(): string {
    const surveyProvider =
      this.documentService.activeSurvey.meta['survey-provider'];
    const surveyCopyright =
      this.documentService.activeSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `<span>Survey provided by: <b>${surveyProvider}.</b></span> <b>${surveyCopyright}</b>`;
  }

  private setChartOptions(
    settings: PMapsSettings,
    shouldPersistSettings: boolean = true
  ): void {
    const {
      title,
      xAxisLabel,
      yAxisLabel,
      showWeightedSymbols,
      showDataLabel,
      displayGridlines,
      displayQuadrantTitles,
      quadrant1,
      quadrant2,
      quadrant3,
      quadrant4,
      data,
      showPlotLines,
    } = settings;

    this.getXYRange(data);
    const midX = 100;
    const midY = 100;

    this.chartOptions = {
      exporting: {
        enabled: false,
      },

      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        events: {
          render() {
            const chart = this as ExtendedChart;
            chart.quadrantTitles?.forEach((textElement: SVGElement) => {
              textElement.destroy();
            });

            if (!chart.options.quadrantTitles.enabled) {
              chart.quadrantTitles = [];
              return;
            }

            const offset = 10;
            const fontSize = 12;
            const width = chart.plotLeft + chart.plotWidth;
            const height = chart.plotTop + chart.plotHeight;
            const left = chart.plotLeft + offset;
            const right = width - offset;
            const top = chart.plotTop + fontSize + offset;
            const bottom = height - offset;
            chart.quadrantTitles = [
              chart.renderer
                .text(chart.options.quadrantTitles.quadrant1, right, top)
                .add(),
              chart.renderer
                .text(chart.options.quadrantTitles.quadrant2, left, top)
                .add(),
              chart.renderer
                .text(chart.options.quadrantTitles.quadrant3, left, bottom)
                .add(),
              chart.renderer
                .text(chart.options.quadrantTitles.quadrant4, right, bottom)
                .add(),
            ];
            [chart.quadrantTitles[0], chart.quadrantTitles[3]].forEach(
              (element: SVGElement) => {
                element.attr({
                  x: right - element.getBBox().width,
                });
              }
            );
          },
        },
      } as ExtendedChartOptions,
      caption: {
        text: this.getCopyrightHTML(),
      },

      quadrantTitles: {
        enabled: displayQuadrantTitles,
        quadrant1,
        quadrant2,
        quadrant3,
        quadrant4,
      },

      legend: {
        enabled: false,
      },

      title: {
        text: title,
      },

      xAxis: {
        maxPadding: 0,
        minPadding: 0,
        startOnTick: true,
        endOnTick: true,
        crosshair: true,
        gridLineWidth: displayGridlines ? 1 : 0,
        title: {
          text: yAxisLabel,
        },
        labels: {
          format: '{value}',
        },
        plotLines: showPlotLines
          ? [
              {
                color: 'black',
                dashStyle: 'Dot',
                width: 2,
                value: midX,
                label: {
                  rotation: 0,
                  y: 15,
                  style: {
                    fontStyle: 'italic',
                  },
                  text: xAxisLabel,
                },
                zIndex: 3,
              },
            ]
          : [],
      },

      yAxis: {
        crosshair: true,
        maxPadding: 0,
        minPadding: 0,
        startOnTick: true,
        endOnTick: true,
        gridLineWidth: displayGridlines ? 1 : 0,
        title: {
          text: xAxisLabel,
        },
        labels: {
          format: '{value}',
        },
        plotLines: showPlotLines
          ? [
              {
                color: 'black',
                dashStyle: 'Dot',
                width: 2,
                value: midY,
                label: {
                  align: 'right',
                  style: {
                    fontStyle: 'italic',
                  },
                  text: yAxisLabel,
                  x: -10,
                },
                zIndex: 3,
              },
            ]
          : [],
      },

      tooltip: {
        useHTML: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><th colspan="2"><h3>{point.title}</h3></th></tr>' +
          '<tr><th>x:</th><td>{point.x}</td></tr>' +
          '<tr><th>y:</th><td>{point.y}</td></tr>' +
          '<tr><th>weight:</th><td>{point.z:.2f}</td></tr>',
        footerFormat: '</table>',
        followPointer: true,
      },

      plotOptions: {
        series: {
          dataLabels: {
            align: 'left',
            style: {
              color: 'black',
            },
            enabled: showDataLabel,
            format: '{point.title}',
            x: 10,
          },
        },
        bubble: {
          maxSize: showWeightedSymbols ? undefined : 10,
          minSize: showWeightedSymbols ? undefined : 10,
        },
      },

      series: [
        {
          data,
        } as any,
      ],
    };
    this.isLoading = false;
    if (shouldPersistSettings) {
      this.persistSettings(settings);
    }
  }

  private persistSettings(settings: PMapsSettings): void {
    this.pMapsPreferences = {
      ...this.pMapsPreferences,
      survey: this.selectedSurvey,
      settings,
      mappingOptions: this.mappingOptions,
    };
    this.documentService.savePMapsPreferences(this.pMapsPreferences);
  }
}
