import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import { TupUserContainerService } from '@telmar-global/tup-document-storage';
import { IchabodsTabsComponent } from '@telmar-global/tup-material';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RequestLoadingService, TargetLoading } from 'src/app/services';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy {
  public userName;
  public currentContainer: UserContainer;
  private unsubscribe: Subject<void> = new Subject<void>();
  public selectedIndex: number = 0;
  public userEmail: string;
  public isLoading = false;

  @ViewChild('ichabodsBody', { static: false }) tabGroup: MatTabGroup;
  @ViewChild('ichabodsTab', { static: false })
  ichabodsTab: IchabodsTabsComponent;

  constructor(
    private authService: TupAuthService,
    private requestLoadingService: RequestLoadingService,
    private userContainerService: TupUserContainerService
  ) {
    this.setMyDrive();
    this.listenToLoadingState();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) => targetLoading.target === 'global'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  changeSelectedIndex(selectedIndex: number) {
    this.selectedIndex = selectedIndex;
    this.ichabodsTab.activeTabIndex = selectedIndex;
  }

  public setMyDrive() {
    const user = this.authService.user;
    const userEmail = user.attributes.email;
    this.userName = user.attributes.name.split(' ')[0];
    let usersContainer = this.authService.user.containers.find(
      (container) => container.name === userEmail
    );
    this.userContainerService.setContainer(usersContainer);
  }
}
