import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DataItem,
  DataItemType,
  DEFAULT_SORT_OPTIONS,
  DocumentDataState,
  ReportMode,
  SelectMenuOption,
  SortSettings,
  Survey,
  Target,
} from '../../models';
import { DocumentService } from '../../services/document.service';
import { first } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { TargetTitlePipe } from '../../pipes';
import { DataItemsService } from '../../services/data-items.service';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { CrosstabService } from 'src/app/services';

export interface SortColumnDialogDataModel {
  sortSettings: SortSettings;
  crossTabService?: CrosstabService;
}

@Component({
  templateUrl: './sort-column-dialog.component.html',
  styleUrls: ['./sort-column-dialog.component.scss'],
})
export class SortColumnDialogComponent implements OnInit {
  public dataItems: DataItem[];

  public surveys: Survey[];
  public columns: SelectMenuOption<string>[] = [
    {
      title: 'None',
      value: '',
    },
    {
      title: 'Totals',
      value: 'totals',
    },
  ];

  public sortSettings: SortSettings;
  public showTop = false;
  public showBottom = false;
  public hasVolumetricCoding: boolean;
  public dataItemType: typeof DataItemType = DataItemType;
  private crossTabService: CrosstabService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SortColumnDialogDataModel,
    public dialogRef: MatDialogRef<
      SortColumnDialogComponent,
      SortColumnDialogDataModel
    >,
    private documentService: DocumentService,
    private targetTitlePipe: TargetTitlePipe,
    private reportPreferencesService: ReportPreferencesService,

    private dataItemsService: DataItemsService
  ) {}

  ngOnInit(): void {
    this.reportPreferencesService.reportMode$
      .pipe(first())
      .subscribe((mode: ReportMode) => {
        this.dataItems = this.dataItemsService.getActiveDataItems(mode);
      });
    this.surveys = this.documentService.getVisibleSurveys();
    this.setupSortColumns();
    this.sortSettings = cloneDeep(this.data.sortSettings);

    this.handleDataItem();

    this.crossTabService = this.data.crossTabService;

    this.handleVolumetricCoding();

    this.setupSortOptions();
  }

  public onShowTopOrBottomChange(type: 'top' | 'bottom'): void {
    if (type === 'top') {
      if (this.showTop) {
        this.showBottom = !this.showTop;
      }
    } else {
      if (this.showBottom) {
        this.showTop = !this.showBottom;
      }
    }
    this.sortSettings.showTopOrBottomRows = this.showTop
      ? 'top'
      : this.showBottom
      ? 'bottom'
      : '';
  }

  public onStartingRowInputBlur(): void {
    if (this.sortSettings.startingRow === null) {
      this.sortSettings.startingRow = 1;
    }
  }

  public onStartingRowInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  public onReloadDefaultsClick(): void {
    this.sortSettings = {
      ...cloneDeep(DEFAULT_SORT_OPTIONS),
      columnId: this.sortSettings.columnId,
    };
    this.setupSortOptions();
  }

  public onApplyButtonClick(): void {
    this.dialogRef.close({
      sortSettings: this.sortSettings,
    });
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public onModelChange(): void {
    this.handleVolumetricCoding();
  }

  private handleDataItem(): void {
    const sortDataItem = this.dataItems.find(
      (dataItem: DataItem) => this.sortSettings.dataItem === dataItem.id
    );
    if (!sortDataItem) {
      this.sortSettings.dataItem = this.dataItems[0].id;
    }
  }

  private setupSortColumns(): void {
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns }: DocumentDataState) => {
        this.columns.push(
          ...columns.map((target: Target) => ({
            title: this.targetTitlePipe.transform(target),
            value: target.id,
          }))
        );
      });
  }

  private setupSortOptions(): void {
    if (this.sortSettings.showTopOrBottomRows) {
      this.showTop = this.sortSettings.showTopOrBottomRows === 'top';
      this.showBottom = this.sortSettings.showTopOrBottomRows === 'bottom';
    }
  }

  private handleVolumetricCoding(): void {
    const columnIdVolumetricCodingSet =
      this.crossTabService.getColumnIdVolumetricCodingMap();
    this.hasVolumetricCoding = columnIdVolumetricCodingSet.has(
      `${this.sortSettings.columnId}_${this.sortSettings.survey}`
    );
  }
}
