import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ColumnFilter,
  ColumnFilterOperator,
  DataItem,
  DataItemType,
  DocumentDataState,
  Target,
  ReportMode,
  SelectMenuOption,
} from '../../models';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { CrosstabService } from '../../services/crosstab.service';
import { first } from 'rxjs/operators';
import { DataItemsService } from '../../services/data-items.service';
import { DocumentService } from '../../services/document.service';
import { TargetTitlePipe } from '../../pipes';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { cloneDeep } from 'lodash';

export interface ColumnFiltersDialogDataModel {
  columnId?: string;
  surveyCode: string;
  showColumnOptions: boolean;
}

export interface ColumnFiltersDialogResultDataModel {
  columnId: string;
  filters: ColumnFilter[];
}

@Component({
  templateUrl: './column-filters-dialog.component.html',
  styleUrls: ['./column-filters-dialog.component.scss'],
})
export class ColumnFiltersDialogComponent implements OnInit {
  public readonly maxFilterNumber = 5;
  public targetColumns: SelectMenuOption<string>[] = [];
  public columnId: string;
  public filters: ColumnFilter[] = [];
  public dataItems: DataItem[];
  public readonly conditionalOperators: string[] = [
    ColumnFilterOperator.greaterThan,
    ColumnFilterOperator.lessThan,
    ColumnFilterOperator.equal,
    ColumnFilterOperator.greaterThanOrEqual,
    ColumnFilterOperator.lessThanOrEqual,
    ColumnFilterOperator.between,
  ];
  public shouldShowClearButton = false;
  public hasVolumetricCoding: boolean;
  public dataItemType: typeof DataItemType = DataItemType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ColumnFiltersDialogDataModel,
    public dialogRef: MatDialogRef<
      ColumnFiltersDialogComponent,
      ColumnFiltersDialogResultDataModel
    >,
    private messageService: TupUserMessageService,
    private crossTabService: CrosstabService,
    private reportPreferencesService: ReportPreferencesService,
    private dataItemsService: DataItemsService,
    private documentService: DocumentService,
    private targetTitlePipe: TargetTitlePipe
  ) {}

  ngOnInit(): void {
    if (this.data.showColumnOptions) {
      this.getTargetColumns();
    }
    this.columnId = this.data.columnId || this.targetColumns[0].value;
    this.reportPreferencesService.reportMode$
      .pipe(first())
      .subscribe((mode: ReportMode) => {
        this.dataItems = this.dataItemsService.getActiveDataItems(mode);
      });

    this.handleVolumetricCoding();
    this.updateFilters();
  }

  public onColumnIdChange(): void {
    this.updateFilters();
  }

  public onFilterRemoveClick(filterIndex: number): void {
    this.filters.splice(filterIndex, 1);
  }

  public onFilterAddClick(): void {
    const nextFilterDataItem =
      this.filters.length < this.dataItems.length
        ? this.dataItems[this.filters.length].id
        : this.dataItems[0].id;
    this.filters.push(this.createNewFilter(nextFilterDataItem));
  }

  public onFilterInputBlur(filter: ColumnFilter): void {
    if (filter.value[0] === null) {
      filter.value[0] = 0;
    }

    if (filter.value[1] === null) {
      filter.value[1] = 0;
    }
  }

  public onFilterInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  public onClearButtonClick(): void {
    this.dialogRef.close({
      filters: [],
      columnId: this.columnId,
    });
  }

  public onApplyButtonClick(): void {
    const filterDataItems = this.filters.map(
      (filter: ColumnFilter) => filter.dataItem
    );
    const duplicates = filterDataItems.filter(
      (item: DataItemType, index: number) =>
        index !== filterDataItems.indexOf(item)
    );

    if (duplicates.length > 0) {
      const duplicateNames = duplicates.map(
        (type: DataItemType) =>
          this.dataItems.find((dataItem: DataItem) => dataItem.id === type)
            .displayName
      );
      this.messageService.openMessageDialog(
        `In one expression DataItem cannot be used more than once.
        '${duplicateNames[0]}' DataItem is being repeated.`,
        'Alert'
      );
      return;
    }

    this.dialogRef.close({
      filters: this.filters,
      columnId: this.columnId,
    });
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  private getTargetColumns(): void {
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns }: DocumentDataState) => {
        this.targetColumns = [null, ...columns].map((column: Target) => ({
          title: column ? this.targetTitlePipe.transform(column) : 'Totals',
          value: this.crossTabService.formatTargetColumnId(
            column,
            this.data.surveyCode
          ),
        }));
      });
  }

  private updateFilters(): void {
    this.filters = cloneDeep(
      this.reportPreferencesService.getColumnHeaderFilters(this.columnId)
    );
    if (this.filters.length > 0) {
      this.shouldShowClearButton = true;
    } else {
      this.filters = [this.createNewFilter(this.dataItems[0].id)];
    }
  }

  private createNewFilter(dataItem: DataItemType): ColumnFilter {
    return {
      operator: 'AND',
      dataItem,
      conditionalOperator: ColumnFilterOperator.greaterThanOrEqual,
      value: [0, 0],
    };
  }

  private handleVolumetricCoding(): void {
    const columnIdVolumetricCodingSet =
      this.crossTabService.getColumnIdVolumetricCodingMap();

    this.hasVolumetricCoding = columnIdVolumetricCodingSet.has(this.columnId);
  }
}
