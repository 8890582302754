import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { PMapsSeriesDataItem } from '../../models/p-maps.model';
import { CtrlShiftKeyStates } from '../../directives';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';

type RowId = number;
type IsSelected = boolean;

@Component({
  templateUrl: './p-maps-data-dialog.component.html',
  styleUrls: ['./p-maps-data-dialog.component.scss'],
})
export class PMapsDataDialogComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'selected',
    'title',
    'xAxis',
    'yAxis',
    'weight',
    'visibility',
  ];

  public dataSource: MatTableDataSource<PMapsSeriesDataItem> =
    new MatTableDataSource([]);
  private shiftPressed: boolean;

  public selectedRowIds: Record<RowId, IsSelected> = {};

  public selectedRows = 0;

  private lastSelectedRowId: RowId;

  private rowIds: RowId[] = [];

  constructor(
    public dialogRef: MatDialogRef<PMapsDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PMapsSeriesDataItem[]
  ) {
    this.populateTableData(cloneDeep(data));
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public setDataVisible(isVisible, element?: PMapsSeriesDataItem): void {
    if (element) {
      element.visible = isVisible;
    } else {
      Object.keys(this.selectedRowIds).forEach((rowId: string) => {
        if (this.selectedRowIds[rowId]) {
          this.dataSource.data[Number(rowId) - 1].visible = isVisible;
        }
      });
    }
  }

  public onCtrlShift(states: CtrlShiftKeyStates): void {
    this.shiftPressed = states.shiftPressed;
  }

  public isAllSelected() {
    return this.selectedRows === this.dataSource.data.length;
  }

  public onAllRowsSelectedChange(event: MatCheckboxChange): void {
    this.setAllRowsSelected(event.checked);
    this.lastSelectedRowId = null;
  }

  public onSingleRowSelectedChange(
    event: MatCheckboxChange,
    element: PMapsSeriesDataItem
  ): void {
    const selectedRowId = element.id;

    if (this.shiftPressed && this.lastSelectedRowId) {
      const lastSelectedRowIndex = this.rowIds.indexOf(this.lastSelectedRowId);
      const selectedRowIndex = this.rowIds.indexOf(selectedRowId);

      let lowerIndex = selectedRowIndex;
      let upperIndex = lastSelectedRowIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedRowIndex;
        upperIndex = selectedRowIndex;
      }

      for (let i = lowerIndex; i <= upperIndex; i++) {
        const id = this.rowIds[i];

        this.selectedRowIds[id] = event.checked;
      }
    } else {
      this.selectedRowIds[selectedRowId] = event.checked;
    }

    this.lastSelectedRowId = selectedRowId;
    this.updateSelectedRows();
  }

  public onApplyClick(): void {
    this.dialogRef.close(this.dataSource.data);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  private populateTableData(data: PMapsSeriesDataItem[]): void {
    this.dataSource.data = data;
    this.rowIds = this.dataSource.data.map((row) => row.id);
  }

  private setAllRowsSelected(isSelected: boolean): void {
    this.dataSource.data.forEach((row: PMapsSeriesDataItem) => {
      this.selectedRowIds[row.id] = isSelected;
    });
    this.updateSelectedRows();
  }

  private updateSelectedRows(): void {
    this.selectedRows = Object.keys(this.selectedRowIds).filter(
      (key: string) => this.selectedRowIds[key] === true
    ).length;
  }
}
