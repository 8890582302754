<div
  fxLayout="row"
  tupDocumentEventEmitter
  (update)="onUpdateName($event)"
>
  <tup-document-updater
    fxFlex
    [document]="currentDoc"
    path="metadata.name"
    [id]="inputId"
    appearance="tiny"
    [required]="true"
    [alignRight]="true"
    [checkUnicityType]="documentType"
    [confirmBox]="documentId"
    confirmMessage="Are you sure you want to rename your document?"
    confirmButton="Rename"
  ></tup-document-updater>
</div>
