import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import {
  DocumentViewType,
  Survey,
  SurveyTimeDocument,
  ViewType,
} from 'src/app/models';
import {
  DocumentService,
  RequestLoadingService,
  TargetLoading,
  TopBarService,
} from 'src/app/services';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IchabodsTabsComponent } from '@telmar-global/tup-material';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() ichabodsBody: MatTabGroup;
  @Input() ichabodsSelectedTabIndex: number;
  @Input() isReadonly = true;
  @Input() selectViewType: DocumentViewType;
  @Input() showSurveyInfo = false;
  @Input() canExportCharts = true;
  @Output() exportToXlsx: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToSheets: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToCsv: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToPptx: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToSlides: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToPNG: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToJPEG: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToPDF: EventEmitter<null> = new EventEmitter<null>();
  @Output() exportToSVG: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('ichabodsTabs') ichabodsTabs: IchabodsTabsComponent;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;

  public viewType: typeof ViewType = ViewType;
  public selectedTab = this.viewType.crossTab;
  private unsubscribe: Subject<void> = new Subject<void>();
  public docOwnerName: string;
  public docName: string;
  public surveys: Survey[];
  public isLoading = false;
  public isSaving = false;
  public isUndoing = false;
  public canUndo = false;

  constructor(
    private documentService: DocumentService,
    private router: Router,
    private topBarService: TopBarService,
    private activatedRoute: ActivatedRoute,
    private requestLoadingService: RequestLoadingService
  ) {}

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
    this.listenToLoadingState();
  }

  ngAfterViewInit(): void {
    this.updateActiveTab();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ichabodsSelectedTabIndex && this.ichabodsTabs) {
      this.updateActiveTab();
    }
  }

  ngOnDestroy(): void {
    this.topBarService.setRightSidebarOpenedState(false);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onOpenRightSidebarClick(): void {
    this.topBarService.setRightSidebarOpenedState(true);
  }

  public undo(): void {
    this.isUndoing = true;
    this.documentService.undoDocument();
  }

  public onExportToCsv(): void {
    this.exportToCsv.emit();
  }

  public onExportToXlsx(): void {
    this.exportToXlsx.emit();
  }

  public onExportToSheets(): void {
    this.exportToSheets.emit();
  }

  public onExportToPptx(): void {
    this.exportToPptx.emit();
  }

  public onExportToSlides(): void {
    this.exportToSlides.emit();
  }

  public onExportToPNG(): void {
    this.exportToPNG.emit();
  }

  public onExportToPDF(): void {
    this.exportToPDF.emit();
  }

  public onExportToJPEG(): void {
    this.exportToJPEG.emit();
  }

  public onExportToSVG(): void {
    this.exportToSVG.emit();
  }

  public onToggleViewChanged(): void {
    this.documentService.resetRestoreState();

    let url = `../chart`;
    let option: NavigationExtras = {
      relativeTo: this.activatedRoute,
      state: {
        isReadonly: this.isReadonly,
      },
    };

    if (this.selectViewType === this.documentViewType.data) {
      url = `../data`;
      option.queryParams = {
        tab: ViewType.crossTab,
      };
    }
    this.router.navigate([url], option);
  }

  public onTabChange(tab: MatTabChangeEvent): void {
    this.selectedTab = tab.index;
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.docOwnerName = doc.metadata.by.attributes.name;
        this.docName = doc.metadata.name;
        this.surveys = doc.content.surveys;
      });

    this.documentService.restoreDocumentState$
      .pipe(takeUntil(this.unsubscribe), delay(0))
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.canUndo = !!doc;
        this.updateSurveys();
      });
  }

  private updateSurveys(): void {
    this.surveys = this.documentService.surveys;
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) => targetLoading.target === 'auto-save'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isSaving = targetLoading.isLoading;
        if (!this.isSaving) {
          this.isUndoing = false;
        }
      });
  }

  private updateActiveTab(): void {
    if (this.ichabodsSelectedTabIndex) {
      this.ichabodsTabs.activeTabIndex = this.ichabodsSelectedTabIndex;
    }
  }
}
