import { ChartSettings } from './charts.model';
import { Survey } from './survey.model';
import { DefaultDataItem } from './data-item.model';
import {
  CellStyleStatus,
  ColumnHeaderFilter,
  HighlightValues,
  ReportMode,
  SortSettings,
} from './crosstab.model';
import { PMapsPreferences } from './p-maps.model';

export const SURVEYTIME_DOCUMENT_VERSION = 1;
export const SURVEYTIME_TARGET_VERSION = 1;

export enum Operator {
  or = 'OR',
  and = 'AND',
  andNot = 'AND NOT',
  orNot = 'OR NOT',
  separate = 'SEPARATE',
  separator = '|',
  count = 'COUNT',
  auto = 'AUTO', // note: is actually OR operator
  plus = '+',
  divide = '/',
  vind = 'VIND',
  vdiv = 'VDIV',
  vmul = 'VMUL',
  ampersand = '&',
  dot = '.',
  comma = ',',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  equal = '=',
  notEqual = '<>',
  range = 'RAN',
  any = 'ANY',
  all = 'ALL',
  solus = 'SOLUS',
  sum = 'SUM',
  ntiles = 'NTILES',
}

export interface Note {
  title: string;
  description: string;
  associatedTarget: string;
  associatedInsight: string;
}

export interface Target {
  id: string;
  fileVersion: number;
  title: string;
  titleLevels?: number[];
  ownTitle?: string;
  activeTitleMode?: DisplayType;
  titlePrefix?: Operator; // note: only for solus/ntiles operator and the target cannot be expanded
  coding: string;
  validWeights?: number[];
  countCoding?: {
    operator: Operator;
    value: string;
  };
  nTilesCoding?: boolean;
  operator: Operator;
  created: number;
  audience: number;
  resps: number;
  percent: number;
  targets: Target[];
  groupName?: string;
}

export interface ReportPreference {
  sortSettings: SortSettings | SortSettings[];
  filters: ColumnHeaderFilter[];
  heatmapIndexPercentage: number;
  highlightValues: HighlightValues;
  cellStyleStatus: CellStyleStatus;
  stabilityFlagOn: boolean;
  dataItems?: DefaultDataItem[];
}

export type ReportPreferences = Record<ReportMode, ReportPreference>;

export class SurveyTimeDocument {
  fileVersion = SURVEYTIME_DOCUMENT_VERSION;
  surveys: Survey[];
  activeSurveyId?: string;
  audience = 0;
  resps = 0;
  percent = 0;
  weight = DEFAULT_WEIGHT_INDEX;
  status: string;
  tables: Target[];
  columns: Target[];
  rows: Target[];
  notes: Note[];
  chartSettings: ChartSettings[];
  activeTableId?: string;
  activeTitleMode?: DisplayType;
  titleLevels?: number[];
  reportPreferences?: ReportPreferences;
  pMapsPreferences?: PMapsPreferences | null;

  constructor() {
    this.surveys = [];
    this.tables = [];
    this.columns = [];
    this.rows = [];
    this.notes = [];
    this.chartSettings = [];
  }
}

export interface DocumentDataState {
  tables: Target[];
  columns: Target[];
  rows: Target[];
  changes?: DocumentDataChange[];
}

export enum TargetAction {
  create = 0,
  update = 1,
  delete = 2,
}

export interface DocumentDataChange {
  action: TargetAction;
  items: TargetItem[];
}

export interface TargetItem {
  type: TargetType;
  target: Target;
  index: number;
}

export enum TargetType {
  tables = 0,
  columns = 1,
  rows = 2,
}

export enum ViewType {
  crossTab = 0,
  codingGrid = 1,
}

export enum DocumentViewType {
  data = 0,
  graph = 1,
  pmaps = 2,
}

export enum DisplayType {
  title = 'title',
  shortTitle = 'shortTitle',
  ownTitle = 'ownTitle',
  coding = 'coding',
  levels = 'levels',
  group = 'group',
}

export const levelSeparator = '~>';

export const titleSeparator = '~';

export const ALL_RESPONDENTS_CODING = '#1';

export const ALL_RESPONDENTS_TITLE = 'All Respondents';

export const EMPTY_TARGET_ITEM_INDEX = -1;

export const MAX_COLUMN_TARGET_LIMIT = 150;

export const MAX_ROW_TARGET_LIMIT = 5000;

export const MAX_COMBINED_TARGETS = 20;

export const MAX_NUMBER_TREND_SURVEYS = 5;

export const DEFAULT_SURVEY_COLOR = '#ffffff';

export const CODEBOOK_INITIAL_SIZE_IN_PIXEL = 405;
export const CODEBOOK_MIN_SIZE_IN_PIXEL = 270;

export const DEFAULT_WEIGHT_INDEX = 1;

export enum TrendingCalculationType {
  diff = 0,
  percentDiff = 1,
  sum = 3,
  average = 2,
}

export interface TrendingCalculationItem {
  coding: string;
  surveys: Survey[];
}
