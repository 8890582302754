import {
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CellColor,
  CellColorId,
  CellStyleStatus,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemId,
  DataItemType,
  DEFAULT_SORT_OPTIONS,
  HighlightValues,
  ReportMode,
  ReportPreference,
  SortDirection,
  SortSettings,
  StabilityLevels,
  Survey,
  TargetColumn,
  TargetType,
  VOLUMETRIC_DATA_ITEM_IDS,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
} from 'src/app/models';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Subject } from 'rxjs';
import {
  CrosstabService,
  DataItemsService,
  DocumentService,
  HighlightCellsService,
  RequestLoadingService,
  TargetLoading,
  XlsxService,
} from '../../services';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import {
  CrosstabTableXlsxBuilder,
  CrosstabTableXlsxData,
} from '../../builders/crosstab-table-xlsx.builder';
import { FormatReportUnitsPipe } from '../../pipes/format-report-units.pipe';
import { CsvService } from '../../services/csv.service';
import { SortColumnTargetAction } from '../../actions/SortColumnTargetAction';
import { CrosstabTableCsvBuilder } from '../../builders/crosstab-table-csv.builder';
import { cloneDeep } from 'lodash';
import { AddOrEditFilterAction } from '../../actions/AddOrEditFilterAction';
import { ColumnHeaderFiltersService } from '../../services/column-header-filters.service';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'app-combined-rank-report-table',
  templateUrl: './combined-rank-report-table.component.html',
  styleUrls: ['./combined-rank-report-table.component.scss'],
})
export class CombinedRankReportTableComponent
  implements OnChanges, OnInit, OnDestroy
{
  public readonly zScoreHighlightProb = Z_SCORE_FILTERED_HIGHLIGHT_PROB;
  public targetType: typeof TargetType = TargetType;
  public dataItemType: typeof DataItemType = DataItemType;
  public sortDirectionType: typeof SortDirection = SortDirection;
  public displayedColumns: string[] = ['insight'];
  public targetColumns: TargetColumn[] = [];
  public dataItems: DataItem[];
  public decimalPoints: Record<string, number>;
  public dataItemsMap: Record<DataItemId, DataItem>;
  public data: CrossTabTableData[];
  public totalRow: CrossTabTableData = null;
  public dataSource: MatTableDataSource<CrossTabTableData> =
    new TableVirtualScrollDataSource([]);

  public sortSettings: SortSettings;
  public isSortActive = false;
  public isLoading = false;
  public cellColors: Record<CellColorId, CellColor> = {};
  public highlightValues: HighlightValues;
  public heatmapIndexPercentage: number;
  public cellStyleStatus: CellStyleStatus;
  public zScoreHighlight: boolean;
  public stabilityFlagStatus: StabilityLevels;
  public reportUnits: number;
  private updateCellColorsState = new Subject<CrossTabTableData[]>();
  private updateCellColorsState$ = this.updateCellColorsState.asObservable();
  private unsubscribe: Subject<void> = new Subject<void>();
  public volumetricDataItemIds: number[] = VOLUMETRIC_DATA_ITEM_IDS;
  public columnIdVolumetricCodingSet: Set<string> = new Set();

  @Input() isViewActive: boolean;

  public activeSurvey: Survey;
  public columnHeaderFilters: ColumnHeaderFilter[] = [];
  public columnHeaderFiltersMap: Set<string> = new Set();

  @ViewChild('tableContainer') tableContainer: ElementRef;
  @ViewChild('tableTitleSection') tableTitleSection: ElementRef;
  @Input() scrollViewportHeight = 0;

  public hoveringColumnDataItem: string;

  constructor(
    private requestLoadingService: RequestLoadingService,
    private colorCellsService: HighlightCellsService,
    private documentService: DocumentService,
    private crossTabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private targetTitlePipe: TargetTitlePipe,
    private formatReportUnitsPipe: FormatReportUnitsPipe,
    private injector: Injector,
    private csvService: CsvService,
    private xlsxService: XlsxService,
    private columnHeaderFiltersService: ColumnHeaderFiltersService,
    private reportPreferencesService: ReportPreferencesService
  ) {}

  ngOnInit(): void {
    this.listenToLoadingState();
    this.listenToReportUnitsChanges();
    this.listenToReportModeChanges();
    this.listenToCrossTabDataChanges();
    this.listenToReportPreferencesChanges();
    this.listenToDataItemsChanges();
    this.listenToCellColorStateChanges();
    this.listenToActiveSurveyChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isViewActive) {
      this.triggerFakeWindowResizeEvent();
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  @HostListener('window:resize')
  public onResize() {
    this.updateScrollViewportHeight();
  }

  public onColumnDataItemMouseEnter(
    column: TargetColumn,
    dataItem: DataItem
  ): void {
    this.hoveringColumnDataItem = `${column.columnId}_${dataItem.id}`;
  }

  public onColumnDataItemMouseLeave(): void {
    this.hoveringColumnDataItem = '';
  }

  public onColumnDataItemClick(column: TargetColumn, dataItem: DataItem): void {
    this.hoveringColumnDataItem = '';
    let sortDirection;
    const isNewSortColumnOrDataItem =
      this.sortSettings.dataItem !== dataItem.id ||
      this.sortSettings.columnId !== column.columnId;
    if (isNewSortColumnOrDataItem) {
      sortDirection = SortDirection.asc;
    } else {
      sortDirection =
        this.sortSettings.order === SortDirection.asc
          ? SortDirection.desc
          : this.sortSettings.order === SortDirection.desc
          ? SortDirection.none
          : SortDirection.asc;
    }

    this.injector.get(SortColumnTargetAction).invoke({
      targetItem: {
        target: column.target,
        index: column.position,
        type: TargetType.columns,
      },
      surveyCode: column.element.surveyCode,
      actionItem: {
        name: dataItem.displayName,
        action: SortColumnTargetAction,
        dataItem: dataItem.id,
        direction: sortDirection,
      },
    });
  }

  public onFilterClick(): void {
    this.injector.get(AddOrEditFilterAction).invoke({
      surveyCode: this.activeSurvey.code,
      showColumnOptions: true,
    });
  }

  public onColumnHeaderFilterClick(
    columnHeaderFilter: ColumnHeaderFilter
  ): void {
    this.injector.get(AddOrEditFilterAction).invoke({
      columnId: columnHeaderFilter.columnId,
      surveyCode: this.activeSurvey.code,
      showColumnOptions: true,
    });
  }

  public onColumnHeaderFilterRemove(
    columnHeaderFilter: ColumnHeaderFilter
  ): void {
    this.reportPreferencesService.removeColumnHeaderFilters(
      columnHeaderFilter.columnId
    );
  }

  public exportToCsv(): void {
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addTableData({
      documentName,
      targetColumns: this.targetColumns,
      data: this.getRawTableData(),
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: { ...cloneDeep(DEFAULT_SORT_OPTIONS) },
      filters: [],
      surveys: [this.documentService.activeSurvey],
    });
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheets(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private listenToReportUnitsChanges(): void {
    this.documentService.reportUnits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((units: number) => {
        this.reportUnits = units;
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        if (mode !== ReportMode.combinedRank) {
          this.unsubscribeAll();
        }
      });
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CrossTabTableData[]) => {
        this.setupTable(data);
      });
  }

  private listenToReportPreferencesChanges(): void {
    combineLatest([
      this.reportPreferencesService.preference$,
      this.documentService.surveyStabilityLevels$,
    ])
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(
        ([preference, surveyStabilityLevels]: [
          ReportPreference,
          StabilityLevels
        ]) => {
          this.stabilityFlagStatus = preference.stabilityFlagOn
            ? surveyStabilityLevels
            : null;
          this.heatmapIndexPercentage = preference.heatmapIndexPercentage;
          this.highlightValues = preference.highlightValues;
          this.cellStyleStatus = preference.cellStyleStatus;
          this.zScoreHighlight =
            preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;
          this.updateCellColors();

          this.sortSettings = preference.sortSettings as SortSettings;
          this.isSortActive = this.sortSettings.columnId !== '';

          this.columnHeaderFilters = preference.filters.map(
            (columnFilter: ColumnHeaderFilter) => ({
              ...columnFilter,
              tooltip: this.getColumnHeaderFilterTooltip(columnFilter),
            })
          );
          this.columnHeaderFiltersMap = new Set(
            this.columnHeaderFilters.map(
              (columnFilter: ColumnHeaderFilter) => columnFilter.columnId
            )
          );
        }
      );
  }

  private getColumnHeaderFilterTooltip(
    columnHeaderFilter: ColumnHeaderFilter
  ): string {
    const column = this.targetColumns.find(
      (targetColumn: TargetColumn) =>
        targetColumn.columnId === columnHeaderFilter.columnId
    );
    return this.columnHeaderFiltersService.formatFilterConditions(
      columnHeaderFilter,
      column
    );
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.actualDataItems$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.updateTableDataPointsAndCell();
      });
  }

  private listenToCellColorStateChanges(): void {
    this.updateCellColorsState$
      .pipe(takeUntil(this.unsubscribe), debounceTime(200))
      .subscribe((data: CrossTabTableData[]) => {
        this.cellColors = this.colorCellsService.updateCellColors(data);
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.activeSurvey = survey;
      });
  }

  private setupTable(data: CrossTabTableData[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.addTableColumns();
    this.triggerFakeWindowResizeEvent();
    this.updateCellColors();
  }

  private populateTableData(data: CrossTabTableData[]): void {
    this.data = data.map((crossTabData: CrossTabTableData) => {
      return {
        ...crossTabData,
        data: crossTabData.data.filter(
          (column: CrossTabTableDataCell) =>
            column.surveyCode === this.documentService.activeSurvey.code
        ),
      };
    });
    this.dataSource.data = this.formatTableData(this.data);
    this.columnIdVolumetricCodingSet =
      this.crossTabService.getColumnIdVolumetricCodingMap(this.data);
  }

  private addTableColumns(): void {
    this.targetColumns = this.crossTabService.getTargetColumns(this.data);
    this.displayedColumns.push(
      ...this.targetColumns.map((column: TargetColumn) => column.name)
    );
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.targetColumns = [];
    this.displayedColumns = ['insight'];
    this.columnIdVolumetricCodingSet = new Set();
  }

  private formatTableData(data: CrossTabTableData[]): CrossTabTableData[] {
    this.totalRow = data[0];
    return data.slice(1);
  }

  private updateScrollViewportHeight(): void {
    if (this.tableContainer) {
      // 5 for title section margin bottom, 2 for table borders (top/bottom)
      const extraOffset = 5 + 2;
      this.scrollViewportHeight =
        this.tableContainer.nativeElement.offsetHeight -
        this.tableTitleSection.nativeElement.offsetHeight -
        extraOffset;
    }
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  private updateTableDataPointsAndCell(): void {
    this.dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.combinedRank
    );
    this.dataItemsMap = DATA_ITEMS_MAP;
    this.decimalPoints = this.dataItems.reduce(
      (prev: Record<string, number>, dataItem: DataItem) => ({
        ...prev,
        [dataItem.displayName]: dataItem.decimalPoints,
      }),
      {}
    );
  }

  private updateCellColors(): void {
    if (this.cellStyleStatus !== CellStyleStatus.none) {
      this.updateCellColorsState.next(this.data);
    } else {
      this.updateCellColorsState.next([]);
    }
  }

  private getXlsxExportData(): [string, CrosstabTableXlsxBuilder] {
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');

    const crossTabTableDataForCombinedRank: CrosstabTableXlsxData = {
      documentName,
      targetColumns: this.targetColumns,
      data: this.data,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: [this.sortSettings],
      filters: this.columnHeaderFilters,
      surveys: [this.documentService.activeSurvey],
      surveyColors: this.crossTabService.getSurveyColors(
        this.data,
        this.documentService.surveys
      ),
      cellStyleStatus: this.cellStyleStatus,
      highlightValues: this.highlightValues,
      bins: this.colorCellsService.getBins(),
      cellColors: this.cellColors,
      heatmapIndexPercentage: this.heatmapIndexPercentage,
    };
    const rawData = cloneDeep(this.getRawTableData());
    const crossTabTableDataForEntireTable = {
      ...crossTabTableDataForCombinedRank,
      data: rawData,
      filters: [],
      cellColors: this.colorCellsService.updateCellColors(rawData),
      sortSettings: [{ ...cloneDeep(DEFAULT_SORT_OPTIONS) }],
    };

    crossTabTableBuilder.addCombinedRankTableSheet(
      crossTabTableDataForCombinedRank
    );
    crossTabTableBuilder.addEntireTableSheet(crossTabTableDataForEntireTable);
    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private getRawTableData(): CrossTabTableData[] {
    let rawData;
    this.crossTabService.crossTabData$
      .pipe(first())
      .subscribe((data: CrossTabTableData[]) => {
        rawData = data.map((crossTabData: CrossTabTableData) => {
          return {
            ...crossTabData,
            data: crossTabData.data.filter(
              (column: CrossTabTableDataCell) =>
                column.surveyCode === this.documentService.activeSurvey.code
            ),
          };
        });
      });
    return rawData;
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
