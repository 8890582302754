<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="P-Maps settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Map title</mat-label>
        <input
          matInput
          [(ngModel)]="settings.title"
        />
      </mat-form-field>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">Axis titles</h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="margin-adjustment"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>X-Axis</mat-label>
        <input
          matInput
          [(ngModel)]="settings.yAxisLabel"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Y-Axis</mat-label>
        <input
          matInput
          [(ngModel)]="settings.xAxisLabel"
        />
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      style=""
    >
      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="settings.showWeightedSymbols"
      >
        Show weighted symbols
      </mat-checkbox>

      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="settings.showDataLabel"
      >
        Show data label
      </mat-checkbox>

      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="settings.displayGridlines"
      >
        Display gridlines
      </mat-checkbox>

      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="settings.displayQuadrantTitles"
      >
        Display quadrant titles
      </mat-checkbox>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <ng-container *ngIf="settings.displayQuadrantTitles">
      <h3 class="setting-section-title margin-adjustment">Quadrant titles</h3>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="margin-adjustment"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Quadrant 1</mat-label>
          <input
            matInput
            [(ngModel)]="settings.quadrant1"
          />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Quadrant 2</mat-label>
          <input
            matInput
            [(ngModel)]="settings.quadrant2"
          />
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="margin-adjustment"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Quadrant 3</mat-label>
          <input
            matInput
            [(ngModel)]="settings.quadrant3"
          />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Quadrant 4</mat-label>
          <input
            matInput
            [(ngModel)]="settings.quadrant4"
          />
        </mat-form-field>
      </div>
    </ng-container>

    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <h3 class="setting-section-title margin-adjustment">Edit data color</h3>
      <div
        fxLayout="column"
        class="edit-color-container margin-adjustment"
      >
        <cdk-virtual-scroll-viewport
          itemSize="35"
          class="virtual-scroll-viewport"
        >
          <div
            *cdkVirtualFor="let dataItem of settings.data; let i = index"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="edit-color-row"
          >
            <h4
              class="mat-h5 target-color-title-container"
              fxLayout="row"
            >
              <span
                class="target-color-title"
                [matTooltip]="dataItem.title"
              >
                {{ dataItem.title }}
              </span>
            </h4>
            <div
              fxLayout="row"
              fxLayoutGap="10px"
            >
              <ng-container *ngFor="let color of defaultColors">
                <ng-container
                  *ngTemplateOutlet="
                    colorDot;
                    context: {
                      color: color,
                      isSelected: color === dataItem.color,
                      index: i
                    }
                  "
                >
                </ng-container>
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  colorDot;
                  context: {
                    color: defaultColors.includes(dataItem.color)
                      ? placeholderColor
                      : dataItem.color,
                    isSelected: !(
                      defaultColors.includes(dataItem.color) &&
                      dataItem.color !== placeholderColor
                    ),
                    index: i
                  }
                "
              ></ng-container>
              <button
                mat-mini-fab
                (click)="colorPickerDialog(i)"
                class="color-dot add-color-dot"
                [ngStyle]="{ 'background-color': 'white' }"
              >
                <mat-icon class="tiny-icon">add</mat-icon>
              </button>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</div>

<ng-template
  #colorDot
  let-color="color"
  let-index="index"
  let-isSelected="isSelected"
>
  <button
    mat-mini-fab
    class="color-dot"
    [ngStyle]="{
      'background-color': color
    }"
    [ngClass]="{
      'color-dot-border': isSelected
    }"
    (click)="changeColor(index, color)"
    [disableRipple]="true"
  ></button>
</ng-template>
