import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserContainer } from '@telmar-global/tup-auth';
import {
  Empty,
  TupDocument,
  TupDocumentService,
  TupDocumentTypeId,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SurveyTimeDocument } from 'src/app/models';
import { DocumentService } from 'src/app/services';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss'],
})
export class EditNameComponent implements OnInit, OnDestroy {
  @Input() inputId: string;

  currentDoc: TupDocument<SurveyTimeDocument>;
  documentId: string;
  containerName: string;

  // don't use documentType for now...
  documentType: TupDocumentTypeId; // = TupDocumentTypeId.SURVEYTIME_CAMPAIGN;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private userContainerService: TupUserContainerService,
    private userMessageService: TupUserMessageService,
    private tupDocumentService: TupDocumentService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.userContainerService.container
      .pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe),
        map((userContainer: UserContainer) => userContainer.name)
      )
      .subscribe(
        (containerName: string) => (this.containerName = containerName)
      );

    this.documentService.activeDocumentId$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((documentId: string) => (this.documentId = documentId));

    this.documentService.currentDoc
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.currentDoc = doc;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onUpdateName(e: any) {
    const updatedDocument = e.document;
    this.tupDocumentService
      .update(this.containerName, updatedDocument)
      .subscribe(
        (response: HttpResponse<Empty>) => {
          this.documentService.resetDocumentChanges();
          this.userMessageService.showSnackBar(
            `Renamed document ${e.document.metadata.name}`,
            'OK'
          );
        },
        (error: HttpErrorResponse) => {
          this.userMessageService.showSnackBar(
            `Error renaming document ${e.document.metadata.name}: ${error}`,
            'OK'
          );
        }
      );
  }
}
