<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="N-Tiles settings"
    class="ntile-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxFlex
      fxLayout="column grid"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="split-wrapper"
    >
      <ui-split
        unit="pixel"
        direction="horizontal"
        #split="uiSplit"
      >
        <ui-split-area
          [size]="480"
          [minSize]="380"
          [maxSize]="580"
        >
          <div
            fxLayout="column"
            class="builder-container"
          >
            <div fxLayout="column">
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex="grow"
                class="display-text-field"
              >
                <mat-label>Coding statement title</mat-label>
                <textarea
                  [(ngModel)]="targetTitle"
                  matInput
                  required
                  class="display-text-field-textarea"
                ></textarea>
              </mat-form-field>
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxGrow="1"
              class="display-container coding-preview__container"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <span class="coding-preview__label"
                  >Coding statement preview:</span
                >
              </div>

              <div class="coding-preview">
                <span>{{ targetCoding }}</span>
              </div>
            </div>
          </div>
        </ui-split-area>

        <ui-split-area>
          <div
            fxLayout="column"
            fxLayoutGap="20px"
          >
            <div
              fxLayout="row"
              class="ntile-settings"
            >
              <div
                fxLayout="column"
                fxLayoutGap="10px"
                fxFlex
              >
                <mat-label>Apply N-Tiles to</mat-label>
                <mat-radio-group
                  aria-label="Select an option"
                  fxLayout="column"
                  fxLayoutGap="10px"
                  [(ngModel)]="selectedTargetType"
                >
                  <mat-radio-button
                    *ngFor="let type of targetTypeOptions"
                    [value]="getTargetTypeValue(type)"
                  >
                    {{
                      type.charAt(0).toUpperCase() + type.slice(1)
                    }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>

              <div
                fxlayout="column"
                fxFlex
              >
                <div
                  fxLayout="column"
                  fxLayoutGap="10px"
                >
                  <mat-label>Select N-Tiles</mat-label>
                  <mat-form-field
                    appearance="outline"
                    tupMatFormField
                  >
                    <mat-label>Tiles</mat-label>
                    <mat-select
                      required
                      [(ngModel)]="selectedNtile"
                      (selectionChange)="onNTileSelectionAction()"
                      fxLayout="column"
                    >
                      <mat-option
                        *ngFor="let ntile of nTileOptions"
                        [value]="ntile"
                      >
                        {{ ntile }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <mat-checkbox
                  tupMatCheckbox
                  required
                  appearance="small"
                  color="primary"
                  style="font-weight: 700"
                  [(ngModel)]="excludeZeroes"
                >
                  <span>Exclude zeroes</span>
                </mat-checkbox>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="table-container">
              <table
                mat-table
                [dataSource]="dataSource"
              >
                <ng-container matColumnDef="start">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Start %
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.start }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="end">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    End %
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.end }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="total">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Total
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.total.from }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </ui-split-area>
      </ui-split>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onGenerateClick()"
      data-qa="generate-button"
      [disabled]="!isGeneratable()"
    >
      Generate
    </button>
  </mat-dialog-actions>
</div>
