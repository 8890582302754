import { Observable } from 'rxjs';
import {
  CodeListBase,
  CodeListItem,
  CodeListQuery,
  CodeListSearchResult,
  CodeListService,
} from '@telmar-global/tup-large-list-selector';

export class TargetListService<T extends CodeListItem>
  extends CodeListBase<T>
  implements CodeListService<T>
{
  private targetListItems: T[];

  constructor(targetList: T[]) {
    super();
    this.updateData(targetList);
  }

  updateData(targetList: T[]) {
    this.targetListItems = targetList;
  }

  searchItems(query: CodeListQuery) {
    return this.filterCodeList(query, this.targetListItems);
  }

  get(): Observable<T[]> {
    return new Observable<T[]>((observer) => {
      this.search({}).subscribe((r) => {
        observer.next(r.data);
        observer.complete();
      });
    });
  }

  getOne(id: number | string): Observable<T> {
    return new Observable<T>((observer) => {
      this.search({ filter: { id: String(id) } }).subscribe((r) => {
        observer.next(r.data.length > 0 ? r.data[0] : null);
        observer.complete();
      });
    });
  }

  search(query: CodeListQuery): Observable<CodeListSearchResult<T>> {
    return new Observable<CodeListSearchResult<T>>((observer) => {
      observer.next({
        data: this.filterCodeList(query, this.targetListItems),
        total: this.targetListItems.length,
      });

      observer.complete();
    });
  }
}
